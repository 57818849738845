import {
  useGetFitbitStatusesQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {Box, printDateAndTime, Text, Tooltip} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";

import {InfoLine} from "./InfoLine";
import {TapToEditRow} from "./TapToEditRow";

interface FitbitStatusProps {
  userId: string;
}

export const FitbitStatusCard = ({userId}: FitbitStatusProps): React.ReactElement | null => {
  const {data: statusData} = useGetFitbitStatusesQuery({userId});
  const {data: userData} = useGetUsersByIdQuery(userId);
  const [updateUser] = usePatchUsersByIdMutation();
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  // Display the user data once it's loaded.
  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);

  const fitbitStatusData = statusData?.data?.[0];
  const timeSinceLastSync = fitbitStatusData?.lastSync
    ? Math.floor(DateTime.now().diff(DateTime.fromISO(fitbitStatusData?.lastSync)).minutes)
    : 0;

  if (!currentUser) {
    return null;
  }

  return (
    <Box marginBottom={4} paddingY={2}>
      <Box direction="row" justifyContent="start" paddingY={1} width="100%">
        <Box direction="column" minWidth={350}>
          {timeSinceLastSync > 3 && (
            <Box>
              <InfoLine
                text={`Fitbit has not been synced in ${timeSinceLastSync} days.`}
                type="error"
              />
            </Box>
          )}
          <Tooltip idealPosition="left" text="Disable Fitbit data collection for this Patient">
            <TapToEditRow
              setValue={(value: boolean): void => {
                setCurrentUser({
                  ...currentUser,
                  fitbitConfig: {...currentUser.fitbitConfig, enabled: value},
                });
              }}
              title="Enabled"
              type="boolean"
              value={currentUser.fitbitConfig?.enabled}
              onSave={async (val): Promise<void> => {
                await updateUser({
                  id: userId,
                  body: {
                    fitbitConfig: {
                      ...currentUser.fitbitConfig,
                      enabled: val,
                    },
                  },
                });
              }}
            />
          </Tooltip>
          {currentUser.fitbitConfig?.enabled && (
            <>
              <Tooltip
                idealPosition="left"
                text="If there are any details to note about the Patients Fitbit usage."
              >
                <TapToEditRow
                  setValue={(value: string): void => {
                    setCurrentUser({
                      ...currentUser,
                      fitbitConfig: {...currentUser.fitbitConfig, note: value},
                    });
                  }}
                  title="Note"
                  type="textarea"
                  value={currentUser.fitbitConfig?.note || ""}
                  onSave={async (val): Promise<void> => {
                    await updateUser({
                      id: userId,
                      body: {
                        fitbitConfig: {
                          ...currentUser.fitbitConfig,
                          note: val,
                        },
                      },
                    });
                  }}
                />
              </Tooltip>
              <Tooltip
                idealPosition="left"
                text="Disable if Patient will not be wearing Fitbit to bed"
              >
                <TapToEditRow
                  setValue={(value: boolean): void => {
                    setCurrentUser({
                      ...currentUser,
                      fitbitConfig: {...currentUser.fitbitConfig, sleepEnabled: value},
                    });
                  }}
                  title="Sleep Tracking"
                  type="boolean"
                  value={currentUser.fitbitConfig?.sleepEnabled}
                  onSave={async (val): Promise<void> => {
                    await updateUser({
                      id: userId,
                      body: {
                        fitbitConfig: {
                          ...currentUser.fitbitConfig,
                          sleepEnabled: val,
                        },
                      },
                    });
                  }}
                />
              </Tooltip>
              <Tooltip
                idealPosition="left"
                text="Disable if Patient will not be wearing Fitbit during the day"
              >
                <TapToEditRow
                  setValue={(value: boolean): void => {
                    setCurrentUser({
                      ...currentUser,
                      fitbitConfig: {...currentUser.fitbitConfig, activityEnabled: value},
                    });
                  }}
                  title="Activity Tracking"
                  type="boolean"
                  value={currentUser.fitbitConfig?.activityEnabled}
                  onSave={async (val): Promise<void> => {
                    await updateUser({
                      id: userId,
                      body: {
                        fitbitConfig: {
                          ...currentUser.fitbitConfig,
                          activityEnabled: val,
                        },
                      },
                    });
                  }}
                />
              </Tooltip>
              <Tooltip
                idealPosition="left"
                text="Allow automated notifications when Patient hasn't synced tracker in over 3 days"
              >
                <TapToEditRow
                  setValue={(value: boolean): void => {
                    setCurrentUser({
                      ...currentUser,
                      fitbitConfig: {...currentUser.fitbitConfig, allowReminders: value},
                    });
                  }}
                  title="Allow Reminder Notifications"
                  type="boolean"
                  value={currentUser.fitbitConfig?.allowReminders}
                  onSave={async (val): Promise<void> => {
                    await updateUser({
                      id: userId,
                      body: {
                        fitbitConfig: {
                          ...currentUser.fitbitConfig,
                          allowReminders: val,
                        },
                      },
                    });
                  }}
                />
              </Tooltip>
            </>
          )}
          {fitbitStatusData?.lastSync && (
            <>
              <Box direction="row" justifyContent="between" paddingX={3} paddingY={2}>
                <Text bold>Last Sync: </Text>
                <Text truncate>{printDateAndTime(fitbitStatusData.lastSync)}</Text>
              </Box>
              <Box direction="row" justifyContent="between" paddingX={3} paddingY={2} width="100%">
                <Box>
                  <Text bold>Initial Sync: </Text>
                </Box>
                <Box>
                  <Text truncate>
                    {fitbitStatusData.initialSync
                      ? printDateAndTime(fitbitStatusData.initialSync)
                      : "No initial sync"}
                  </Text>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
