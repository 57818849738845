import {Box, BoxProps, Heading} from "ferns-ui";
import React, {ReactElement} from "react";

export const HeadingBox = ({
  boxProps = {},
  prompt,
}: {
  boxProps?: BoxProps;
  prompt: string;
}): ReactElement | null => {
  const finalBoxProps: BoxProps =
    Object.keys(boxProps).length === 0 ? {marginBottom: 4, marginTop: 8} : boxProps;
  return (
    <Box {...finalBoxProps}>
      <Heading size="sm">{prompt}</Heading>
    </Box>
  );
};
