import {Box, printDate, Text, TextField, useStoredState} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {CarePodDropdown, DataExplorer, DownloadFileButton, UserStatusDropdown} from "../components";
import {baseUrl} from "../ferns-rtk";
import {useGetCarePodQAQuery} from "../store";
import {StaffStackScreenProps} from "../types";

interface CarePodQAScreenProps extends StaffStackScreenProps<"CarePodQA"> {}

export const CarePodQAScreen: React.FC<CarePodQAScreenProps> = ({navigation}) => {
  const [carePodId, setCarePodId] = useStoredState<string | undefined>(
    "carepod-qa-carePodId",
    undefined
  );
  const [userStatusId, setUserStatusId] = useStoredState<string | undefined>(
    "carepod-qa-userStatusId",
    undefined
  );
  const [searchFilter, setSearchFilter] = useStoredState<string>("carepod-qa-searchFilter", "");
  const [page, setPage] = useState(1);

  // Set the header buttons on initial load
  useEffect((): void => {
    navigation.setOptions({
      headerRight: () => {
        const url = `${baseUrl}/carePodQA?csv=true`;
        let currentUrl = url;
        if (carePodId) {
          currentUrl += `&carePodId=${carePodId}`;
        }
        if (userStatusId) {
          currentUrl += `&userStatusId=${userStatusId}`;
        }
        if (searchFilter) {
          currentUrl += `&searchFilter=${searchFilter}`;
        }
        return (
          <Box direction="row" gap={4} paddingX={2}>
            <DownloadFileButton
              filename={`carepod-qa-${printDate(new Date().toISOString())}.csv`}
              text="Download Current View"
              url={currentUrl}
            />
            <DownloadFileButton
              filename={`carepod-qa-all-${printDate(new Date().toISOString())}.csv`}
              text="Download All"
              url={`${url}&all=true`}
            />
          </Box>
        );
      },
    });
    // Need to update this button when context changes or it won't be updated with the new data.
  }, [carePodId, navigation, searchFilter, userStatusId]);

  return (
    <Box height="100%">
      <Box
        alignItems="center"
        direction="row"
        gap={4}
        justifyContent="end"
        marginBottom={4}
        paddingX={4}
        paddingY={2}
      >
        <Box width={200}>
          <TextField
            placeholder="Type a name or full ID..."
            title="Search By Name or ID"
            value={searchFilter ?? ""}
            onChange={(input: string) => {
              void setSearchFilter(input);
              setPage(1);
            }}
          />
        </Box>
        <Box width={200}>
          <CarePodDropdown
            errorText={carePodId ? undefined : "Care Pod is required"}
            title="Care Pod"
            value={carePodId ?? ""}
            onChange={(id) => {
              void setCarePodId(id);
              setPage(1);
            }}
          />
        </Box>
        <Box width={200}>
          <UserStatusDropdown
            placeholder="Any status"
            title="User Status"
            value={userStatusId ?? ""}
            onChange={(id) => {
              void setUserStatusId(id);
              setPage(1);
            }}
          />
        </Box>
      </Box>
      {Boolean(carePodId) ? (
        <DataExplorer
          filters={{
            carePodId,
            userStatusId,
            searchFilter,
          }}
          hook={useGetCarePodQAQuery}
          page={page}
        />
      ) : (
        <Box alignItems="center" height="100%" justifyContent="center">
          <Text>Select a care pod to view QA data.</Text>
        </Box>
      )}
    </Box>
  );
};
