// A tool button that runs a migration as a dry run, shows the results in a modal, and provides
// a button for running a wet run, showing the results of that as well.
import {Box, Button, IconName, Modal, Text, Tooltip} from "ferns-ui";
import {isAPIError, printAPIError} from "ferns-ui/dist/Utilities";
import React, {ReactElement, useCallback} from "react";

import {useSentryAndToast} from "../../store";

interface MigrationResult {
  results: string[];
  wetRun: boolean;
  success: boolean;
}

interface MigrationButtonProps {
  mutation: any;
  name: string;
  iconName: IconName;
  tooltip?: string;
}

export const MigrationButton = ({
  mutation,
  name,
  iconName,
  tooltip,
}: MigrationButtonProps): ReactElement => {
  const sentryAndToast = useSentryAndToast();
  const [showModal, setShowModal] = React.useState(false);
  const [doMigration, {error: migrationError, isLoading, data}] = mutation();

  const errorMessage = isAPIError(migrationError?.data)
    ? printAPIError(migrationError.data)
    : (migrationError?.data?.message ??
      migrationError?.data?.results?.join("\n") ??
      "Unknown error");

  const migrationResult = data as MigrationResult;

  const runMigration = useCallback(
    async (wetRun: boolean) => {
      try {
        await doMigration({wetRun}).unwrap();
      } catch (error: any) {
        sentryAndToast(`Migration ${name} failed`, error);
      }
    },
    [doMigration, name, sentryAndToast]
  );

  return (
    //   Expanded to show the error message
    <Box>
      <Modal
        primaryButtonDisabled={isLoading}
        primaryButtonOnClick={async () => {
          if (migrationResult?.wetRun) {
            setShowModal(false);
            return;
          } else {
            await runMigration(true);
          }
        }}
        primaryButtonText={migrationResult?.wetRun ? "Close" : "Wet Run"}
        secondaryButtonOnClick={(): void => {
          if (migrationResult?.wetRun) {
            undefined;
          } else {
            setShowModal(false);
          }
        }}
        secondaryButtonText={migrationResult?.wetRun ? undefined : "Close"}
        size="lg"
        title={`Migration - ${name}`}
        visible={showModal}
        onDismiss={(): void => setShowModal(false)}
      >
        <Box maxHeight={600} scroll width="100%">
          {isLoading && (
            <Box paddingY={2}>
              <Text>Running migration...</Text>
            </Box>
          )}
          {migrationError && (
            <Box paddingY={2}>
              <Text color="error">Error: {errorMessage}</Text>
            </Box>
          )}
          {migrationResult && (
            <Box>
              <Text>
                Migration results ({migrationResult.wetRun ? "Wet Run" : "Dry Run"}) -{" "}
                {migrationResult.success ? "Success" : "Error"}
              </Text>
              <Box>
                {migrationResult.results.map((r: string, i: number) => (
                  <Box key={i}>
                    <Text>{r}</Text>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      <Tooltip text={tooltip || ""}>
        <Box marginRight={5} paddingY={2} width={350}>
          <Button
            fullWidth
            iconName={iconName}
            text={name}
            onClick={async (): Promise<void> => {
              setShowModal(true);
              await runMigration(false);
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
