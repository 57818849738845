import {skipToken} from "@reduxjs/toolkit/query";
import {useGetUsersQuery, useOtherUsersEditingFormQuestion} from "@store";
import {Box, Icon, Text} from "ferns-ui";
import React, {useMemo} from "react";

interface FormQuestionPresenceTextProps {
  questionId?: string;
}

export const FormQuestionPresenceText: React.FC<FormQuestionPresenceTextProps> = ({questionId}) => {
  const otherUsersEditing = useOtherUsersEditingFormQuestion(questionId);

  const {data: usersData} = useGetUsersQuery(
    otherUsersEditing?.length ? {_id: {$in: otherUsersEditing}} : skipToken
  );

  const editingMessage = useMemo(() => {
    if (!usersData?.data?.length) {
      return null;
    }

    const editingUsers = usersData.data.map((user) => user.name);

    if (editingUsers.length > 3) {
      return "Several users are editing...";
    } else {
      return editingUsers.map((name) => `${name} is editing...`).join(", ");
    }
  }, [usersData]);

  // Ensure the box is always the same height, whether someone is typing or not.
  // This ensures the layout doesn't shift while people are typing.
  return (
    <Box alignItems="center" direction="row" gap={2} height={16} marginTop={2} width="100%">
      {otherUsersEditing?.length > 0 && (
        <>
          <Icon color="warning" iconName="triangle-exclamation" size="sm" />
          <Text size="sm">{editingMessage}</Text>
        </>
      )}
    </Box>
  );
};
