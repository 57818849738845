import {SelectField} from "ferns-ui";
import React from "react";

export type TimeResolution = "1" | "5" | "10" | "15" | "30" | "60";
interface TimeDropdownProps {
  hour: number;
  minute: number;
  onChange: (result: {minute: number; hour: number}) => void;
  minHour?: number;
  maxHour?: number;
  resolution: TimeResolution;
}

export const TimeDropdown = ({
  hour,
  minute,
  onChange,
  minHour = 0,
  maxHour = 23,
  resolution = "30",
}: TimeDropdownProps): React.ReactElement => {
  const timeSlots = React.useMemo(() => {
    let minArray: number[] = [];
    if (resolution === "1") {
      // Generate all minutes from 0 to 59
      minArray = Array.from(Array(60).keys());
    } else if (resolution === "5") {
      // Generate all minutes from 0 to 59 at 5 minute intervals
      minArray = Array.from(Array(12).keys()).map((i) => i * 5);
    } else if (resolution === "10") {
      // Generate all minutes from 0 to 59 at 10 minute intervals
      minArray = Array.from(Array(6).keys()).map((i) => i * 10);
    } else if (resolution === "15") {
      // Generate all minutes from 0 to 59 at 15 minute intervals
      minArray = Array.from(Array(4).keys()).map((i) => i * 15);
    } else if (resolution === "30") {
      // Generate all minutes from 0 to 59 at 30 minute intervals
      minArray = Array.from(Array(2).keys()).map((i) => i * 30);
    }

    const ts: {label: string; value: {hour: number; minute: number}}[] = [];

    // Generate the list of time options from 12:00am to 11:30pm at half hour intervals
    for (let hr = minHour; hr <= maxHour; hr++) {
      for (const min of minArray) {
        const labelHour = hr % 12 === 0 ? 12 : hr % 12;
        const period = hr < 12 ? "am" : "pm";
        const label = `${labelHour}:${min.toString().padStart(2, "0")}${period}`;

        ts.push({label, value: {hour: hr, minute: min}});
      }
    }
    return ts;
  }, [maxHour, minHour, resolution]);

  const timeOptions = timeSlots.map((t, i) => ({label: t.label, value: String(i)}));
  const valueIndex = timeSlots.findIndex((t) => t.value.hour === hour && t.value.minute === minute);

  return (
    <SelectField
      options={timeOptions}
      requireValue
      value={String(valueIndex)}
      onChange={(i: string): void => {
        const {hour: newHour, minute: newMinute} = timeSlots[Number(i)].value;
        onChange({hour: newHour, minute: newMinute});
      }}
    />
  );
};
