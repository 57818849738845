import {
  DownloadFileButton,
  HeaderRightButtons,
  UserExplorerColumn,
  UserExplorerContext,
  UserExplorerController,
} from "@components";
import {baseUrl} from "@ferns-rtk";
import {StaffStackScreenProps} from "@types";
import {
  Box,
  Button,
  printDate,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from "ferns-ui";
import React, {useContext, useEffect} from "react";

import {BillingTable} from "../components/BillingTable";
import {
  acceptingReferralsColumn,
  additionalNotesColumn,
  authorizationConfirmedColumn,
  authorizationDateColumn,
  billableColumn,
  careAdvocateColumn,
  carePodColumn,
  clinicalIntakeDateColumn,
  dischargeDateColumn,
  dischargeMedsReceivedColumn,
  dobColumn,
  documentationRequestSentColumn,
  eligibilityInterviewDateColumn,
  enrolledDateColumn,
  estimatedDischargeDateColumn,
  fitbitColumn,
  googleDriveColumn,
  guardianColumn,
  guideVisitDateColumn,
  healthInsuranceColumn,
  healthPlanColumn,
  hospitalDischargeDateColumn,
  lastClaimDateColumn,
  medicalRecordsRequestedColumn,
  nameColumn,
  preferredPharmacyColumn,
  providerInfoColumn,
  providerInfoReceivedColumn,
  psychiatryIntakeDateColumn,
  referralAcceptedDateColumn,
  referralDateColumn,
  referralMethodColumn,
  referralSourceColumn,
  referringClinicianColumn,
  referringPsychiatristColumn,
  roiSentColumn,
  serviceStartDateColumn,
  signedROIColumn,
  signedROISentToProvidersColumn,
  smsNotificationConsentColumn,
  stateColumn,
  therapistColumn,
  therapyIntakeDateColumn,
  userInitialsColumn,
  userStatusColumn,
} from "../components/ExplorerColumns";
import {FeatureFlagTable} from "../components/FeatureFlagTable";
import {StaffExplorer} from "../components/StaffExplorer";

const patientTrackerColumns: UserExplorerColumn[] = [
  userStatusColumn,
  userInitialsColumn,
  nameColumn,
  dobColumn,
  hospitalDischargeDateColumn,
  authorizationDateColumn,
  enrolledDateColumn,
  serviceStartDateColumn,
  estimatedDischargeDateColumn,
  referralDateColumn,
  referralAcceptedDateColumn,
  carePodColumn,
  eligibilityInterviewDateColumn,
  clinicalIntakeDateColumn,
  therapyIntakeDateColumn,
  psychiatryIntakeDateColumn,
  careAdvocateColumn,
  guideVisitDateColumn,
  lastClaimDateColumn,
  dischargeDateColumn,
  referralSourceColumn,
  referralMethodColumn,
  referringClinicianColumn,
  referringPsychiatristColumn,
  healthPlanColumn,
  billableColumn,
  acceptingReferralsColumn,
  googleDriveColumn,
  smsNotificationConsentColumn,
  dischargeMedsReceivedColumn,
  authorizationConfirmedColumn,
  documentationRequestSentColumn,
  preferredPharmacyColumn,
  healthInsuranceColumn,
  providerInfoReceivedColumn,
  roiSentColumn,
  signedROIColumn,
  signedROISentToProvidersColumn,
  medicalRecordsRequestedColumn,
  fitbitColumn,
  providerInfoColumn,
  guardianColumn,
  additionalNotesColumn,
];

const panelColumns: UserExplorerColumn[] = [
  userStatusColumn,
  nameColumn,
  dobColumn,
  hospitalDischargeDateColumn,
  enrolledDateColumn,
  serviceStartDateColumn,
  estimatedDischargeDateColumn,
  referralDateColumn,
  eligibilityInterviewDateColumn,
  clinicalIntakeDateColumn,
  therapyIntakeDateColumn,
  psychiatryIntakeDateColumn,
  guideVisitDateColumn,
  referralSourceColumn,
  referringClinicianColumn,
  referringPsychiatristColumn,
  healthPlanColumn,
  googleDriveColumn,
  smsNotificationConsentColumn,
  dischargeMedsReceivedColumn,
  authorizationConfirmedColumn,
  documentationRequestSentColumn,
  preferredPharmacyColumn,
  healthInsuranceColumn,
  providerInfoColumn,
  roiSentColumn,
  signedROIColumn,
  signedROISentToProvidersColumn,
  medicalRecordsRequestedColumn,
  fitbitColumn,
  guardianColumn,
  additionalNotesColumn,
];

const enrollmentColumns: UserExplorerColumn[] = [
  userStatusColumn,
  userInitialsColumn,
  nameColumn,
  dobColumn,
  carePodColumn,
  stateColumn,
  therapistColumn,
  hospitalDischargeDateColumn,
  therapyIntakeDateColumn,
  psychiatryIntakeDateColumn,
  guideVisitDateColumn,
  enrolledDateColumn,
  referralDateColumn,
  referralAcceptedDateColumn,
  referralSourceColumn,
  referralMethodColumn,
  referringClinicianColumn,
  referringPsychiatristColumn,
  healthPlanColumn,
  authorizationDateColumn,
  serviceStartDateColumn,
  estimatedDischargeDateColumn,
  lastClaimDateColumn,
  dischargeDateColumn,
  billableColumn,
  acceptingReferralsColumn,
  googleDriveColumn,
  smsNotificationConsentColumn,
  dischargeMedsReceivedColumn,
  authorizationConfirmedColumn,
  documentationRequestSentColumn,
  preferredPharmacyColumn,
  healthInsuranceColumn,
  providerInfoReceivedColumn,
  roiSentColumn,
  signedROIColumn,
  signedROISentToProvidersColumn,
  medicalRecordsRequestedColumn,
  fitbitColumn,
  guardianColumn,
  providerInfoColumn,
  additionalNotesColumn,
];

interface Props extends StaffStackScreenProps<"UserExplorer"> {}

const UserExplorerTable = ({navigation}: Props): React.ReactElement => {
  const context = useContext(UserExplorerContext)!;
  const {setSort, users, view, page, setPage, total} = context;

  // Set the header buttons on initial load
  useEffect((): void => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <Box direction="row">
            <Box paddingX={2}>
              <Button
                iconName="plus"
                text="Create User"
                onClick={(): void => navigation.navigate("CreateUser")}
              />
            </Box>
            <Box paddingX={2}>
              <DownloadFileButton
                filename={`user-explorer-${printDate(new Date().toISOString())}.csv`}
                text="Download CSV"
                url={`${baseUrl}/csv`}
              />
            </Box>
            <HeaderRightButtons defaultIcons />
          </Box>
        );
      },
    });
    // Need to update this button when context changes or it won't be updated with the new data.
  }, [navigation]);

  let columns: UserExplorerColumn[] = patientTrackerColumns;
  if (view === 1) {
    columns = panelColumns;
  } else if (view === 2) {
    return <BillingTable />;
  } else if (view === 3) {
    return <StaffExplorer />;
  } else if (view === 4) {
    return <FeatureFlagTable />;
  } else if (view === 5) {
    columns = enrollmentColumns;
  }

  const totalPages = Math.ceil((total ?? 0) / 50);

  return (
    <Table
      columns={columns.map((c) => c.width)}
      more={page * 50 < total}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
    >
      <TableHeader>
        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.title}
            index={index}
            sortable={Boolean(column.sort)}
            onSortChange={(direction): void => {
              if (!column.sort) {
                setSort(undefined);
              } else {
                setSort(direction ? [column.sort, direction] : undefined);
              }
            }}
          >
            <Box flex="grow" justifyContent="center" width="100%" wrap>
              <Text bold size="sm">
                {column.title}
              </Text>
            </Box>
          </TableHeaderCell>
        ))}
      </TableHeader>
      {users.map((user) => (
        <TableRow key={user._id}>
          {columns.map((column) => (
            <React.Fragment key={column.title}>{column.Component(user, navigation)}</React.Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

export const UserExplorerScreen = ({navigation, route}: Props): React.ReactElement => {
  return (
    <Box color="base" height="100%" paddingX={4} paddingY={4}>
      <UserExplorerController>
        <UserExplorerTable navigation={navigation} route={route} />
      </UserExplorerController>
    </Box>
  );
};
