import {
  useGetPatientWeeklyUpdatesQuery,
  useGetUsersByIdQuery,
  usePatchPatientWeeklyUpdatesByIdMutation,
  usePostPatientWeeklyUpdatesMutation,
} from "@store";
import {PatientWeeklyUpdate} from "@types";
import {getDateOnly, isStaff} from "@utils";
import {Box, Button, DateTimeField, printDate, Text, TextArea} from "ferns-ui";
import React, {useState} from "react";

const UPDATES_TEMPLATE =
  "- What is this patient’s clinical status? [Mild, Moderate, Severe]\n" +
  "- Did the patient attend therapy this week? Y / N\n" +
  "- Did the patient engage during guiding this week? Y / N\n" +
  "- Did the caregiver engage during guiding this week? Y / N\n" +
  "- Is the patient getting what they need from us?\n" +
  "- What do we need to do for this patient next week?";

export const CurrentPatientWeeklyUpdates = ({
  userId,
}: {
  userId: string;
}): React.ReactElement | null => {
  const {data: user} = useGetUsersByIdQuery(userId);
  // `page: 1` because we're only showing the most recent update
  const {data: weeklyUpdatesData} = useGetPatientWeeklyUpdatesQuery({patientId: userId, page: 1});
  const [createPatientWeeklyUpdate] = usePostPatientWeeklyUpdatesMutation();
  const [updatePatientWeeklyUpdate] = usePatchPatientWeeklyUpdatesByIdMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [weekOfDate, setWeekOfDate] = useState<string | undefined>(undefined);
  const [updateText, setUpdateText] = useState<string>(UPDATES_TEMPLATE);
  if (!user || isStaff(user?.type)) {
    return null;
  }

  const create = async (): Promise<void> => {
    await createPatientWeeklyUpdate({
      weekOfDate: getDateOnly(weekOfDate),
      patientId: userId,
      updateText,
    });
  };

  const update = async (): Promise<void> => {
    if (!getCurrentWeeklyUpdate()._id) {
      return;
    }
    await updatePatientWeeklyUpdate({
      id: getCurrentWeeklyUpdate()._id!,
      body: {
        weekOfDate: getDateOnly(weekOfDate),
        patientId: userId,
        updateText,
      },
    });
  };

  const getCurrentWeeklyUpdate = function (): PatientWeeklyUpdate {
    if (weeklyUpdatesData?.data && weeklyUpdatesData?.data.length) {
      return weeklyUpdatesData?.data[0];
    }
    // If there aren't any weekly update records associated with this patient,
    // the most recent one is stored on the patient.
    return {weekOfDate: undefined, updateText: user.weeklyUpdates ?? ""};
  };

  if (isEditing || isCreating) {
    return (
      <Box>
        <Box marginTop={2} paddingY={1}>
          <DateTimeField
            title="Week of:"
            type="date"
            value={weekOfDate}
            onChange={(value?: string): void => {
              setWeekOfDate(value);
            }}
          />
        </Box>
        <Box paddingY={4}>
          <TextArea
            blurOnSubmit={false}
            grow
            value={updateText}
            onChange={(result): void => setUpdateText(result)}
          />
        </Box>
        <Box direction="row" paddingY={2} width={180}>
          <Button
            text="Cancel"
            variant="muted"
            onClick={(): void => {
              setIsEditing(false);
              setIsCreating(false);
            }}
          />
          <Box marginRight={4} />
          {isEditing ? (
            <Button
              disabled={!weekOfDate || !updateText}
              text="Save"
              variant="secondary"
              onClick={async (): Promise<void> => {
                await update();
                setIsEditing(false);
              }}
            />
          ) : (
            <Button
              disabled={!weekOfDate || !updateText}
              text="Save New Weekly Update"
              variant="primary"
              onClick={async (): Promise<void> => {
                await create();
                setIsCreating(false);
              }}
            />
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box marginTop={2} paddingY={1}>
          {getCurrentWeeklyUpdate()?.weekOfDate && (
            <Text>{`Week of ${printDate(getCurrentWeeklyUpdate()?.weekOfDate!)}:`}</Text>
          )}
        </Box>
        <Box paddingY={4}>
          <Text>{getCurrentWeeklyUpdate()?.updateText}</Text>
        </Box>
        <Box direction="row" paddingY={2} width={180}>
          {weeklyUpdatesData?.data && weeklyUpdatesData?.data[0] && (
            <Box marginRight={4}>
              <Button
                iconName="pencil"
                text="Edit"
                variant="secondary"
                onClick={(): void => {
                  setIsEditing(true);
                  setWeekOfDate(getCurrentWeeklyUpdate()?.weekOfDate);
                  setUpdateText(getCurrentWeeklyUpdate().updateText);
                }}
              />
            </Box>
          )}
          <Button
            iconName="plus"
            text="Create New Weekly Update"
            variant="primary"
            onClick={(): void => {
              setIsCreating(true);
              setWeekOfDate(undefined);
              setUpdateText(UPDATES_TEMPLATE);
            }}
          />
        </Box>
      </Box>
    );
  }
};
