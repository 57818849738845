import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useGetDiagnosesByIdQuery,
  useGetDiagnosesQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
} from "@store";
import {Box, Button, printDate, ScrollView, Text, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useMemo, useState} from "react";
import {FlatList, TouchableOpacity} from "react-native";

import {FilterItem} from "./FilterItems";

// TODO: Add a search select component to the ferns-ui library
interface SearchSelectComponentProps {
  options: {label: string; value: string}[];
  inputValue: string;
  title: string;
  placeholder?: string;
  disabled?: boolean;
  onSearchChange: (value: string) => void;
  onChange: (value: string) => void;
}

const SearchSelectComponent = ({
  options,
  inputValue = "",
  title,
  placeholder,
  disabled,
  onSearchChange,
  onChange,
}: SearchSelectComponentProps): ReactElement => {
  const handleSelect = (value: string): void => {
    onChange(value);
  };

  const renderItem = ({item}: {item: {value: string; label: string}}): ReactElement => (
    <TouchableOpacity onPress={() => handleSelect(item.value)}>
      <Box borderBottom="default" color="neutralLight" overflow="hidden" padding={2}>
        <Text>{item.label}</Text>
      </Box>
    </TouchableOpacity>
  );

  return (
    <ScrollView>
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        title={title}
        type="search"
        value={inputValue} // Controlled by the parent component
        onChange={(result): void => onSearchChange(result)}
      />
      <Box border="default" marginLeft={2} marginRight={2} maxHeight={250} rounding="md">
        <FlatList data={options} keyExtractor={(item) => item.value} renderItem={renderItem} />
      </Box>
    </ScrollView>
  );
};

const Diagnosis = ({
  diagnosisId,
  onDelete,
}: {
  diagnosisId: string;
  onDelete: () => Promise<void>;
}): ReactElement | null => {
  const {data: diagnosesData} = useGetDiagnosesByIdQuery(diagnosisId);
  if (!diagnosesData) {
    return null;
  }
  return (
    <FilterItem
      confirmationText="Are you sure you want to delete this?"
      showDelete
      text={diagnosesData?.label}
      withConfirmation
      onDismiss={onDelete}
    />
  );
};

export const PatientDiagnosis = ({userId}: {userId: string}): ReactElement => {
  const toast = useToast();
  const [updateUser] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [addDiagnosis, setAddDiagnosis] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const diagnoses$andQuery = useMemo(() => {
    if (searchTerm) {
      // Trim the label and split it into words
      const words = searchTerm.trim().split(/\s+/);
      // Create an array of regex match conditions for each word
      const matchConditions = words.map((word) => {
        // Remove parentheses from the word if present
        const cleanedWord = word.replace(/[\(\)]/g, "");
        return {
          // exclusively searching for the label field
          label: {$regex: cleanedWord, $options: "i"},
        };
      });
      return {
        $and: matchConditions,
      };
    }
    // TODO support pagination for diagnoses
    return {page: 1};
  }, [searchTerm]);

  const {data: diagnoses} = useGetDiagnosesQuery(diagnoses$andQuery);

  const diagnosisOptions =
    diagnoses?.data?.map((d) => ({
      label: d?.label ?? "Description not found",
      value: d?._id,
    })) ?? [];

  const onDelete = async (index: number): Promise<void> => {
    try {
      const updatedDiagnoses =
        user?.diagnosis && user.diagnosis?.diagnoses ? [...user?.diagnosis?.diagnoses] : [];
      updatedDiagnoses.splice(index, 1);
      await updateUser({id: userId, body: {diagnosis: {diagnoses: updatedDiagnoses}}});
    } catch (error: any) {
      const deletionError = error?.data?.errors?.title;
      toast.catch(error, deletionError ?? "Error deleting diagnosis");
    }
  };

  const onSelect = async (value: string): Promise<void> => {
    try {
      const updatedDiagnoses =
        user?.diagnosis && user.diagnosis?.diagnoses ? [...user?.diagnosis?.diagnoses] : [];
      updatedDiagnoses.push(value);
      await updateUser({id: userId, body: {diagnosis: {diagnoses: updatedDiagnoses}}});
      setSearchTerm("");
      setAddDiagnosis(false);
    } catch (error: any) {
      const addError = error?.data?.errors?.title;
      toast.catch(error, addError ?? "Error adding diagnosis");
    }
  };

  return (
    <Box>
      <Box gap={4}>
        {user?.diagnosis?.diagnoses?.map((diag, index) => (
          <Diagnosis
            key={`diagnosis-${index}`}
            diagnosisId={diag}
            onDelete={(): Promise<void> => onDelete(index)}
          />
        ))}
      </Box>

      {addDiagnosis ? (
        <Box marginTop={4}>
          <SearchSelectComponent
            inputValue={searchTerm}
            options={diagnosisOptions}
            title="*Search Using ICD-10-CM Codes from DSM-5"
            onChange={onSelect}
            onSearchChange={setSearchTerm}
          />
          <Box direction="row" paddingY={3}>
            <Button
              text="Close"
              variant="muted"
              onClick={(): void => {
                setSearchTerm("");
                setAddDiagnosis(false);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box direction="row" justifyContent="between" paddingY={3}>
          <Button
            iconName="plus"
            text="Add Diagnosis"
            variant="secondary"
            onClick={async (): Promise<void> => {
              setAddDiagnosis(true);
            }}
          />
          {user?.diagnosis?.updated && (
            <Box alignSelf="center">
              <Text color="secondaryLight">Last Updated: {printDate(user.diagnosis.updated)}</Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
