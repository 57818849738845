import {useEffect, useRef} from "react";

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
