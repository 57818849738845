// TODO: Create dashboard of staff phone numbers so we can see which are assigned and which
// are not.
// TODO: support removing staff phone number.
import {useGetUsersByIdQuery, usePostGetStaffPhoneNumberMutation} from "@store";
import {isStaffPhoneNumberRequired} from "@utils";
import {Box, Text, useToast} from "ferns-ui";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, {ReactElement} from "react";

import {LinkButton} from "./LinkButton";

export const StaffPhoneNumber = ({userId}: {userId: string}): ReactElement | null => {
  const [getStaffPhoneNumber] = usePostGetStaffPhoneNumberMutation();
  const {data: userData} = useGetUsersByIdQuery(userId);
  const toast = useToast();

  const phone = parsePhoneNumberFromString(userData?.staffPhoneNumber ?? "", "US");
  const phoneNumber = phone?.formatNational() ?? userData?.staffPhoneNumber;

  const shouldShowRequiredStar = Boolean(
    userData && !userData.staffPhoneNumber && isStaffPhoneNumberRequired(userData)
  );

  return (
    <Box justifyContent="between" width="100%">
      <Box direction="row" width="100%">
        <Box flex="grow">
          <Text
            bold
          >{`Staff Phone Number (Visible To Patients)${shouldShowRequiredStar ? "*" : ""}`}</Text>
        </Box>
        <Box direction="row" justifyContent="start" marginLeft={2}>
          <Box justifyContent="start">
            {Boolean(userData?.staffPhoneNumber) && <Text align="right">{phoneNumber}</Text>}
            {!Boolean(userData?.staffPhoneNumber) && (
              <LinkButton
                text="+ Get Staff Number"
                onClick={async () => {
                  if (!userData?.phoneNumber) {
                    toast.error(
                      "Please set a personal phone number before getting a staff phone number."
                    );
                    return;
                  }
                  await getStaffPhoneNumber({userId});
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box flex="grow" paddingY={1}>
        <Text color="secondaryLight" size="sm">
          Caller ID for SMS & calls to patients and family members
        </Text>
      </Box>
    </Box>
  );
};
