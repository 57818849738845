import {FORM_PRESENCE_DELAY_MS, FORM_PRESENCE_INTERVAL_MS, logFormPresence} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Form,
  FormInstance,
  FormQuestion,
  FormValidator,
  isSuperUserOrSupervisor,
  setCurrentFocusedAnswer,
  SingleCheckboxOptions,
  useAppDispatch,
  useFormInstanceIsReadOnly,
  useGetUsersByIdQuery,
  useLocalFormInstance,
  usePatchFormInstanceAnswerMutation,
  useSelectIsQuestionDisabled,
  useSelectRemoteFormInstance,
  useSentryAndToast,
} from "@store";
import {isStaff as isStaffFn} from "@utils";
import {Box, Text} from "ferns-ui";
import isEqual from "lodash/isEqual";
import React, {ReactElement, useCallback, useEffect, useMemo} from "react";

import {useSocket} from "../SocketProvider";
import {FollowUpResponseBox} from "./FollowUpResponseBox";
import {FormQuestionPresenceText} from "./FormQuestionPresenceText";
import {FreeformTextArea} from "./FreeformTextArea";
import {HeadingBox} from "./HeadingBox";
import {DateInput, EmailInput, NumberInput, PhoneNumberInput} from "./Input";
import {MultiSelectBox} from "./MultiSelectBox";
import {QuestionCheckBox} from "./QuestionCheckbox";
import {QuestionPromptText} from "./QuestionPromptText";
import {ShortformTextField} from "./ShortformTextField";
import {SingleSelect} from "./SingleSelect";

const compileUpdatedAnswer = (
  existingAnswer: FormInstance["answers"][number] | undefined,
  value: string | string[],
  question: Form["questions"][number],
  isFollowUpResponse: boolean
): FormInstance["answers"][number] => {
  const updatedAnswer = {...(existingAnswer ?? {})} as FormInstance["answers"][number];

  if (isFollowUpResponse) {
    updatedAnswer.followUpResponse = value as string;
  } else {
    const answerArray = Array.isArray(value) ? value : [value];
    const score = answerArray.reduce((total, v) => {
      return total + (question?.options?.find((o) => o.label === v)?.score ?? 0);
    }, 0);

    const requiresNewFollowUpResponse =
      question?.followUpResponseSettings?.allowFollowUpResponse &&
      !answerArray.some((answer) =>
        question.followUpResponseSettings?.qualifyingOptions?.includes(answer)
      );

    updatedAnswer.answers = answerArray;
    updatedAnswer.score = score;
    updatedAnswer.followUpResponse = requiresNewFollowUpResponse
      ? ""
      : updatedAnswer.followUpResponse;
  }

  return updatedAnswer;
};

interface ComponentMap {
  Date: typeof DateInput;
  CarePlan: typeof FreeformTextArea;
  ClinicalStatus: typeof SingleSelect;
  SafetyPlan: typeof FreeformTextArea;
  Email: typeof EmailInput;
  Freeform: typeof FreeformTextArea;
  Number: typeof NumberInput;
  PhoneNumber: typeof PhoneNumberInput;
  Shortform: typeof ShortformTextField;
  Select: typeof SingleSelect;
  Multiselect: typeof MultiSelectBox;
  SingleCheckbox: typeof QuestionCheckBox;
}

interface FormInstanceQuestionProps {
  formInstanceId: string;
  questionId: string;
  index: number;
  followUpResponse?: string;
  userId?: string;
}

const QuestionSupport = ({
  question,
  isStaff,
  isSupervisor,
}: {
  formInstanceId: string;
  question: FormQuestion;
  isStaff: boolean;
  isSupervisor: boolean;
}): ReactElement | null => {
  const populateFieldsText = useMemo(() => {
    return question.populateFields?.map((field) => `${field.schema} ${field.key}`).join(", ");
  }, [question.populateFields]);

  return (
    <Box width="100%">
      <FormQuestionPresenceText questionId={question._id} />
      {Boolean(question.description) && (
        <Box marginTop={2}>
          <Text>{question.description}</Text>
        </Box>
      )}
      {Boolean(isStaff && question.charmKey) && (
        <Box marginTop={2}>
          <Text size="sm">Charm Key: {question.charmKey}</Text>
        </Box>
      )}
      {Boolean(isStaff && populateFieldsText) && (
        <Box marginTop={2}>
          <Text size="sm">Updates {populateFieldsText} upon completion</Text>
        </Box>
      )}
      {Boolean(isStaff && ["CarePlan", "SafetyPlan"].includes(question.type)) && (
        <Box marginTop={2}>
          <Text size="sm">**Visible in Patient App**</Text>
        </Box>
      )}
      {Boolean(!isSupervisor && question.isSupervisorOnly) && (
        <Box marginTop={2}>
          <Text size="sm">This question is to be completed by your supervisor.</Text>
        </Box>
      )}
    </Box>
  );
};

export const FormQuestionComponent: React.FC<FormInstanceQuestionProps> = React.memo(
  ({formInstanceId, questionId, index, userId}: FormInstanceQuestionProps) => {
    const dispatch = useAppDispatch();
    const profile = useReadProfile();
    const {socket} = useSocket();
    const formInstance = useLocalFormInstance(formInstanceId);
    const remoteFormInstance = useSelectRemoteFormInstance(formInstanceId);
    const question = formInstance?.form?.questions?.find((q) => q._id === questionId);
    const answers = formInstance?.answers.filter((a) => a.questionId === questionId);
    const followUpResponse = answers?.[0]?.followUpResponse;
    const {data: formUser} = useGetUsersByIdQuery(userId ?? skipToken);
    const isStaff = isStaffFn(profile?.type);
    const isSupervisor = isSuperUserOrSupervisor(profile);
    const sentryAndToast = useSentryAndToast();
    const [patchFormInstanceAnswer] = usePatchFormInstanceAnswerMutation();

    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
    // Because the timeout and interval refs are set up in onFocus,
    // we need to update the disabled ref when the disabled state changes,
    // otherwise it will get a stale value for disabled.
    // This can happen if someone focuses on a disabled question, then the other user blurs the
    // question, so the form is no longer focused. It also can happen the user is focused on a
    // question, switches tabs, then comes back to a disabled question.
    // We could complete disable focusing on disabled inputs but then copy/pasting becomes very
    // challenging, and we use disabled questions when viewing a form as read only.
    const disabledRef = React.useRef(false);

    // Clean up timeouts and intervals on component unmount
    useEffect(() => {
      return (): void => {
        if (timeoutRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearInterval(intervalRef.current);
        }
      };
    }, []);

    const existingAnswer = useMemo(
      () => answers?.find((a) => a?.questionId === question?._id),
      [answers, question?._id]
    );
    const questionAnswers = useMemo<string[]>(
      () => existingAnswer?.answers ?? [],
      [existingAnswer?.answers]
    );

    const value = useMemo(() => {
      if (!question) {
        return [];
      }
      let result: string[] = [];
      if (questionAnswers?.length) {
        result = questionAnswers;
      } else if (["Freeform", "Shortform"].includes(question.type)) {
        result = [""];
      } else if (["ClinicalStatus", "Multiselect", "Select"].includes(question.type)) {
        result = [];
      } else if (question.type === "SingleCheckbox") {
        result = [SingleCheckboxOptions.unchecked];
      } else if (question.type === "CarePlan" && formUser) {
        result = [formUser.carePlan ?? ""];
      } else if (question.type === "SafetyPlan" && formUser) {
        result = [formUser.safetyPlan ?? ""];
      }
      return result;
    }, [question, questionAnswers, formUser]);

    const componentMap: ComponentMap = {
      Date: DateInput,
      CarePlan: FreeformTextArea,
      ClinicalStatus: SingleSelect,
      SafetyPlan: FreeformTextArea,
      Email: EmailInput,
      Number: NumberInput,
      PhoneNumber: PhoneNumberInput,
      Freeform: FreeformTextArea,
      Shortform: ShortformTextField,
      Select: SingleSelect,
      Multiselect: MultiSelectBox,
      SingleCheckbox: QuestionCheckBox,
    };
    function isComponentMapKey(key: any): key is keyof ComponentMap {
      return key in componentMap;
    }
    const QuestionComponent =
      question && isComponentMapKey(question.type) ? componentMap[question.type] : null;

    const answerRequiredErr =
      question && FormValidator.validateAnswerRequirements(question, value, isSupervisor);

    const readOnly = useFormInstanceIsReadOnly(formInstanceId);
    const disabled = useSelectIsQuestionDisabled(formInstanceId, questionId);

    // See comment about why we have to use a ref for disabled.
    useEffect(() => {
      disabledRef.current = disabled;
    }, [disabled]);

    // Emit a presence event to the websocket for the given form instance, user, question, and
    // focus/blur.
    const emitFormInstancePresence = useCallback(
      (focusedQuestionId: string, presence: "focus" | "blur") => {
        if (!socket?.connected) {
          console.warn("Socket is not connected to emit formInstance-presence");
          return;
        }
        logFormPresence(`Emitting ${presence} for questionId: ${focusedQuestionId}`);
        socket.emit("formInstance-presence", {
          formInstanceId,
          userId: profile?._id,
          questionId: focusedQuestionId,
          presence,
        });
      },
      [socket, profile?._id, formInstanceId]
    );

    // Patch a single answer in the answers array
    const patchAnswer = useCallback(
      async (answer: FormInstance["answers"][number]) => {
        if (readOnly || !answer) {
          return;
        }
        await patchFormInstanceAnswer({
          formInstanceId,
          answerId: answer._id!,
          answer,
        })
          .unwrap()
          .catch((error) => {
            sentryAndToast(
              `Error saving update to form. Please try again before continuing.`,
              error
            );
            // Re-throw the error so the caller can handle it and not clear the local answer
            throw error;
          });
      },
      [readOnly, formInstanceId, patchFormInstanceAnswer, sentryAndToast]
    );

    // When the question is blurred, we want to patch the answer immediately.
    const onBlur = useCallback(
      async (v: string | string[], isFollowUpResponse = false): Promise<void> => {
        // Clear any existing timeouts or intervals for presence events
        if (timeoutRef?.current) {
          clearTimeout(timeoutRef?.current);
        }
        if (intervalRef?.current) {
          clearInterval(intervalRef?.current as any);
        }
        emitFormInstancePresence(questionId, "blur");

        if (disabled || !question) {
          return;
        }
        const patchedAt = new Date().toISOString();
        const updatedAnswer = compileUpdatedAnswer(existingAnswer, v, question, isFollowUpResponse);
        const isEdited =
          !isEqual(
            remoteFormInstance?.answers.find((a) => a.questionId === questionId)?.answers,
            updatedAnswer.answers
          ) ||
          !isEqual(
            remoteFormInstance?.answers.find((a) => a.questionId === questionId)?.followUpResponse,
            updatedAnswer.followUpResponse
          );
        // Mark the answer as ready to be cleared locally once the remote patch is completed.
        dispatch(
          setCurrentFocusedAnswer({
            questionId: question._id!,
            answers: updatedAnswer.answers,
            isEdited,
            followUpResponse: updatedAnswer.followUpResponse,
            instanceId: formInstanceId,
            // We'll use this time to determine if the local answer is newer than the remote answer.
            patchedAt,
          })
        );

        // Only patch if the answer has changed.
        if (isEdited) {
          try {
            await patchAnswer(updatedAnswer);
          } catch {
            return;
          }
        }
      },
      [
        disabled,
        question,
        emitFormInstancePresence,
        questionId,
        existingAnswer,
        remoteFormInstance?.answers,
        dispatch,
        formInstanceId,
        patchAnswer,
      ]
    );

    const onFocus = useCallback((): void => {
      if (!question?._id) {
        console.warn("No question ID on focus");
        return;
      }
      dispatch(
        setCurrentFocusedAnswer({
          instanceId: formInstanceId,
          questionId: question._id!,
          answers: existingAnswer?.answers ?? [],
          isEdited: false,
        })
      );

      // Ensure the blur from the previous question has been sent before emitting the focus for this
      // question.
      timeoutRef.current = setTimeout(() => {
        if (disabledRef.current) {
          return;
        }
        logFormPresence(`initial focus for question ${question._id}`);
        emitFormInstancePresence(question._id!, "focus");
      }, FORM_PRESENCE_DELAY_MS);

      // Emit a focus event every 5 seconds while the question is focused.
      intervalRef.current = setInterval(() => {
        if (disabledRef.current) {
          return;
        }
        logFormPresence(
          `${FORM_PRESENCE_INTERVAL_MS / 1000} second focus for question ${question._id}`
        );
        emitFormInstancePresence(question._id!, "focus");
      }, FORM_PRESENCE_INTERVAL_MS);
    }, [dispatch, existingAnswer?.answers, formInstanceId, question, emitFormInstancePresence]);

    // When changing, save the value locally only.
    // It will be updated on the server when the question is blurred.
    const onChange = useCallback(
      async (v: string | string[], isFollowUpResponse = false): Promise<void> => {
        // Skip setting the local answer for single checkbox, select, and multiselect questions,
        // because they onBlur right away. However, followup responses should still be saved
        // locally.
        const skipSettingLocalAnswer =
          ["SingleCheckbox", "Select", "Multiselect"].includes(question?.type ?? "") &&
          !isFollowUpResponse;
        if (!question || skipSettingLocalAnswer) {
          return;
        }
        const updatedAnswer = compileUpdatedAnswer(existingAnswer, v, question, isFollowUpResponse);
        dispatch(
          setCurrentFocusedAnswer({
            instanceId: formInstanceId,
            questionId: question._id!,
            answers: updatedAnswer.answers,
            isEdited: true,
            followUpResponse: updatedAnswer.followUpResponse,
          })
        );
      },
      [question, existingAnswer, dispatch, formInstanceId]
    );

    if (!question) {
      return null;
    }

    return (
      <Box key={question._id} direction="row" paddingY={2}>
        <Box direction="column" flex="grow">
          {question.type === "SingleCheckbox" ? (
            <Box paddingY={2}>
              <Box marginTop={2} width="100%">
                {QuestionComponent && (
                  <QuestionComponent
                    {...{
                      answerRequiredErr,
                      index,
                      question,
                      value,
                      title: "",
                      onChange,
                      onBlur,
                      disabled,
                      onFocus,
                    }}
                  />
                )}
                <QuestionSupport
                  formInstanceId={formInstanceId}
                  isStaff={isStaff}
                  isSupervisor={isSupervisor}
                  question={question}
                />
              </Box>
            </Box>
          ) : (
            <Box paddingY={2}>
              {question.type === "Heading" ? (
                <HeadingBox prompt={question.prompt} />
              ) : (
                <QuestionPromptText index={index} prompt={question.prompt} />
              )}
              <QuestionSupport
                formInstanceId={formInstanceId}
                isStaff={isStaff}
                isSupervisor={isSupervisor}
                question={question}
              />
              <Box marginTop={2} width="100%">
                {QuestionComponent && (
                  <QuestionComponent
                    {...{
                      index,
                      question,
                      value,
                      onChange,
                      onBlur,
                      title: "",
                      disabled,
                      answerRequiredErr,
                      onFocus,
                    }}
                  />
                )}
                <FollowUpResponseBox
                  {...{
                    followUpResponse,
                    question,
                    onChange,
                    onBlur,
                    onFocus,
                    selectedOptions: value,
                    disabled,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

FormQuestionComponent.displayName = "FormQuestionComponent";
