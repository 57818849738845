import {substituteDotPhrases, useGetDotPhrasesQuery} from "@store";
import {TextField} from "ferns-ui";
import React, {ReactElement} from "react";

export const ShortformTextField = ({
  answerRequiredErr,
  disabled = false,
  value = [""],
  onChange,
  onFocus,
}: {
  answerRequiredErr?: string;
  disabled: boolean;
  value: string[];
  onChange: (value: string[]) => void | Promise<void>;
  onFocus?: () => void;
}): ReactElement => {
  const {data: dotPhraseData} = useGetDotPhrasesQuery({});
  const dotPhrases = (dotPhraseData?.data ?? []).map((dp) => ({
    phrase: dp.phrase,
    replacement: dp.replacement,
  }));

  return (
    <TextField
      blurOnSubmit={false}
      disabled={disabled}
      errorText={answerRequiredErr}
      value={value[0] ?? ""}
      onBlur={async (): Promise<void> => {
        await onChange(value);
      }}
      onChange={async (result): Promise<void> => {
        await onChange([substituteDotPhrases(result, dotPhrases)]);
      }}
      onFocus={() => onFocus && onFocus()}
    />
  );
};
