export const sha512 = async (str: string): Promise<string> => {
  // get the Byte value of the UTF8 string provided
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  // hash the string using the SHA-512 algorithm
  const hashBuffer = await crypto.subtle.digest("SHA-512", data);
  // convert the hash buffer to a base64 string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashBase64 = btoa(String.fromCharCode(...hashArray));
  return hashBase64;
};
