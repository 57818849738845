import {IsNative} from "@utils";
import {Box, Heading, Modal, Text} from "ferns-ui";
import React, {useState} from "react";
import {browserName, isMobile} from "react-device-detect";

function shouldShowStaffBrowserAlert(): boolean {
  if (IsNative) {
    return false;
  }
  if (isMobile) {
    return true;
  }
  return browserName !== "Chrome";
}

export const StaffBrowserChecker = (): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(shouldShowStaffBrowserAlert());

  return (
    <Modal
      primaryButtonOnClick={(): void => setVisible(false)}
      primaryButtonText="Close"
      visible={visible}
      onDismiss={(): void => setVisible(false)}
    >
      <Box>
        <Box marginBottom={4}>
          <Heading>Unsupported Browser / Mobile Web Detected</Heading>
        </Box>
        <Box paddingY={4}>
          <Text>
            This app is only supported on Desktop Chrome or native mobile. Unexpected issues may
            occur if you continue to use this desktop or mobile browser. Please switch to Chrome if
            you are on a computer or download the mobile app in the App Store or Google Play Store.
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};
