import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetUsersQuery, User, userName} from "@store";
import {StaffStackParamList} from "@types";
import {isStaff} from "@utils";
import {Box, Button, Heading, IconButton, Text} from "ferns-ui";
import React, {ReactElement} from "react";

interface ScheduleItemUserRowProps {
  user: User;
  userIds: string[];
  onChangeUserIds: (usersIds: string[]) => void;
  showTypes?: boolean;
  extraProps?: any;
  disabled?: boolean;
}
const ScheduleItemUserRow = ({
  disabled,
  user,
  userIds,
  onChangeUserIds,
}: ScheduleItemUserRowProps): ReactElement => {
  return (
    <Box
      key={user?._id}
      alignItems="center"
      color="base"
      direction="row"
      justifyContent="between"
      marginBottom={2}
      paddingX={4}
      paddingY={2}
      rounding="md"
      width="100%"
    >
      <Box direction="column">
        <Heading size="sm">
          {userName(user)} {user?.testUser ? "- Test User" : ""}
        </Heading>
        {Boolean(!isStaff(user?.type)) && (
          <Box marginTop={2} width={300}>
            <Box marginBottom={1}>
              <Text color="secondaryLight" size="sm">
                Accepts SMS Notifications: {user.smsNotifications ? "Yes" : "No"}
              </Text>
            </Box>
            {!user.smsEnabled && (
              <Box marginBottom={1}>
                <Text color="secondaryLight" size="sm">
                  Note: SMS currently disabled for this user.
                </Text>
              </Box>
            )}
            <Box>
              <Text color="secondaryLight" size="sm">
                Accepts Push Notifications: {user.pushNotifications ? "Yes" : "No"}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
      <Box direction="row" justifyContent="end" width={70}>
        {!disabled && (
          <IconButton
            accessibilityLabel="remove user"
            iconName="trash"
            variant="destructive"
            onClick={(): void => {
              onChangeUserIds(userIds.filter((u) => u !== user._id));
            }}
          />
        )}
      </Box>
    </Box>
  );
};

interface UserSelectorProps {
  userIds: string[];
  onChangeUserIds: (userIds: string[]) => void;
  staff?: boolean;
  patient?: boolean;
  familyMember?: boolean;
  disabled?: boolean;
}
export const UserSelector = ({
  disabled,
  userIds,
  onChangeUserIds,
  staff,
  patient,
  familyMember,
}: UserSelectorProps): ReactElement => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const {data: userData} = useGetUsersQuery(
    userIds.length > 0
      ? {
          _id: {$in: userIds},
        }
      : skipToken
  );
  const users = userData?.data;

  return (
    <Box marginTop={4}>
      <Box paddingY={1}>
        <Text bold size="lg">
          {staff ? "Staff" : "Users"}
        </Text>
      </Box>
      {userIds.length > 0 &&
        users?.map((user) => (
          <ScheduleItemUserRow
            key={user?._id}
            disabled={disabled}
            user={user}
            userIds={userIds}
            onChangeUserIds={onChangeUserIds}
          />
        ))}
      <Box marginTop={4}>
        <Button
          disabled={disabled}
          iconName="plus"
          text="Add User"
          onClick={(): void => {
            navigation.navigate("UserPicker", {
              title: "Select User",
              patient,
              familyMember,
              staff,
              onSelect: (user: User | null): void => {
                if (!user) {
                  return;
                }
                onChangeUserIds([...userIds, user._id]);
              },
            });
          }}
        />
      </Box>
    </Box>
  );
};
