import {
  useCurrentWorkflowMapping,
  useCurrentWorkflowOwner,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useGetUsersByIdQuery,
  usePostConversationsMutation,
  userName,
  useSelectWorkflowStaffId,
} from "@store";
import {isStaff} from "@utils";
import {Box, Button, Text} from "ferns-ui";
import React, {ReactElement} from "react";

export const NoConversation = (): ReactElement | null => {
  const currentWorkflowMapping = useCurrentWorkflowMapping();
  const [createConversation, {isLoading: isLoadingPostConversation}] =
    usePostConversationsMutation();
  const userId = currentWorkflowMapping?.userId?._id;
  const {data: displayedUser} = useGetUsersByIdQuery(userId ?? skipToken);
  const staffId = useSelectWorkflowStaffId();
  const profile = useReadProfile();
  const workflowOwner = useCurrentWorkflowOwner();
  const {
    conversation,
    isLoading: isLoadingConversation,
    isFetching,
  } = useGetConversationForWorkflowMapping(staffId ? currentWorkflowMapping : undefined);

  const isLoading = isLoadingPostConversation || isLoadingConversation || isFetching;

  if (
    isLoading ||
    !displayedUser ||
    !currentWorkflowMapping ||
    !profile ||
    userId !== displayedUser._id ||
    conversation
  ) {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);

  const doCreateConversation = (): Promise<any> => {
    return createConversation({
      type: displayedUser?.type,
      users: [{userId: displayedUser?._id}, {userId: profile._id}] as any,
    });
  };

  let message;
  if (isStaff(displayedUser?.type)) {
    message = "Staff conversations are displayed in Internal Chat.";
  } else if (isMyWorkflow) {
    message = `You don't have a conversation with ${displayedUser?.name}.`;
  } else {
    const username = userName(workflowOwner);
    message =
      `${username} doesn't have a conversation with ${displayedUser?.name}.\n\n` +
      "Cannot create a conversation for another user.";
  }

  return (
    <Box alignItems="center" padding={6} width="100%">
      <Box paddingY={4}>
        <Text align="center" size="lg">
          {message}
        </Text>
      </Box>
      {Boolean(
        displayedUser._id !== profile._id && isMyWorkflow && !isStaff(displayedUser.type)
      ) && (
        <Box alignSelf="center">
          <Button
            disabled={isLoading}
            loading={isLoading}
            text="Create Conversation"
            onClick={doCreateConversation}
          />
        </Box>
      )}
    </Box>
  );
};
