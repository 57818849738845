import {generateAuthSlice} from "@ferns-rtk";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {createSentryReduxEnhancer} from "@utils";
import {useDispatch} from "react-redux";
import {persistReducer, persistStore} from "redux-persist";

import appState from "./appState";
import {rtkQueryErrorMiddleware} from "./errors";
import {localFormInstanceReducer} from "./formInstanceStore";
import {flourishApi} from "./sdk";

export * from "./appState";
export {useSentryAndToast} from "./errors";
export * from "./modelTypes";
export * from "./utils";

const authSlice = generateAuthSlice(flourishApi);

export const {logout} = authSlice;

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
  timeout: 0, // The code base checks for falsy, so 0 disables
  blacklist: ["tracking", "flourishApi", "profiles"],
};

const rootReducer = combineReducers({
  appState,
  localFormInstance: localFormInstanceReducer,
  auth: authSlice.authReducer,
  flourishApi: flourishApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = createSentryReduxEnhancer();

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    }).concat([...authSlice.middleware, flourishApi.middleware as any, rtkQueryErrorMiddleware]);
  },
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers({
      autoBatch: {type: "tick"},
    }).concat(sentryReduxEnhancer),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch`
export const useAppDispatch: () => AppDispatch = useDispatch;
export {useAppSelector} from "./appState";

// eslint-disable-next-line import/no-default-export
export default store;

export * from "./formInstanceStore";
export * from "./formValidator";
export * from "./notificationSlice";
export * from "./sdk";
