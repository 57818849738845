import {NotificationOptions} from "@constants";
import {skipToken} from "@reduxjs/toolkit/query";
import {NotificationItem, useGetUsersQuery, userName} from "@store";
import {Badge, BooleanField, Box, Button, Icon, IconButton, SelectField, Text} from "ferns-ui";
import React, {ReactElement, useMemo, useState} from "react";

import {DeliveryStatuses} from "./DeliveryStatuses";

const getUserFriendlyNotificationTimeString = (minutesBefore: number): string => {
  if (minutesBefore === 60) {
    return "1 hour before";
  } else if (minutesBefore === 1440) {
    return "1 day before";
  } else {
    return `${minutesBefore} minutes before`;
  }
};

interface NotificationManagerProps {
  notifications: NotificationItem[];
  setNotifications: (notifications: NotificationItem[]) => void;
  attendeeIds: string[];
  disabled?: boolean;
}
export const NotificationManager = ({
  notifications,
  setNotifications,
  attendeeIds,
  disabled,
}: NotificationManagerProps): ReactElement => {
  const [addNotificationActive, setAddNotificationActive] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>("5");
  const [sendAsPush, setSendAsPush] = useState<boolean>(true);
  const [sendAsSms, setSendAsSms] = useState<boolean>(true);
  const {data: attendeeData} = useGetUsersQuery(
    attendeeIds.length > 0 ? {_id: {$in: attendeeIds}} : skipToken
  );
  const userNotificationWarnings: string[] = useMemo(() => {
    const attendees = attendeeData?.data || [];
    const warnings: string[] = [];
    for (const attendee of attendees) {
      const warningMsgs: string[] = [];
      if (!attendee.pushNotifications) {
        warningMsgs.push(`Push Notifications not consented`);
      }
      if (!attendee.smsNotifications) {
        warningMsgs.push(`SMS Notifications not consented`);
      }
      if (!attendee.smsEnabled) {
        if (attendee.smsErrors && attendee.smsErrors.length > 0) {
          warningMsgs.push(
            `SMS Notifications not enabled due to ` +
              `${attendee.smsErrors.length} error(s) when attempting to send SMS messages`
          );
        } else {
          warningMsgs.push(`SMS Notifications not enabled`);
        }
      }
      if (warningMsgs.length > 0) {
        warnings.push(`${userName(attendee)}: ${warningMsgs.join(", ")}`);
      }
    }
    return warnings;
  }, [attendeeData?.data]);

  return (
    <Box paddingY={2}>
      {userNotificationWarnings.length > 0 && (
        <Box marginBottom={2}>
          <Box direction="row">
            <Box marginRight={4}>
              <Icon color="error" iconName="triangle-exclamation" />
            </Box>
            <Box direction="column">
              <Box marginBottom={2}>
                <Text bold color="error">
                  The following User(s) may not receive certain types of notifications:
                </Text>
              </Box>
              {userNotificationWarnings.map((user) => (
                <Box key={user} marginBottom={2}>
                  <Text>- {user}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box>
        {notifications.map((notification) => (
          <Box
            key={notification.minutesBefore}
            alignItems="center"
            color="base"
            direction="row"
            justifyContent="between"
            marginBottom={2}
            padding={2}
            rounding="md"
            width="100%"
          >
            <Box direction="column">
              <Text>{getUserFriendlyNotificationTimeString(notification.minutesBefore)}</Text>
              <Box direction="row" justifyContent="start" paddingY={2}>
                {notification.sendAsSms && <Badge status="success" value="SMS" />}
                {notification.sendAsPush && <Badge status="info" value="Push" />}
              </Box>
            </Box>
            <Box>
              <DeliveryStatuses notification={notification} />
            </Box>
            {!disabled && (
              <Box direction="row" justifyContent="end" width={70}>
                <IconButton
                  accessibilityLabel="remove user"
                  iconName="trash"
                  variant="destructive"
                  onClick={(): void => {
                    setNotifications(
                      notifications.filter((n) => n.minutesBefore !== notification.minutesBefore)
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box width={200}>
        <Button
          disabled={disabled}
          text="Add Notification"
          variant="secondary"
          onClick={(): void => {
            setAddNotificationActive(true);
          }}
        />
      </Box>
      {addNotificationActive && (
        <Box>
          <SelectField
            options={Array.from(NotificationOptions.keys()).map((key) => ({
              label: key,
              value: NotificationOptions.get(key)!.toString(),
            }))}
            requireValue
            title="Add Notification"
            value={selectedTime}
            onChange={setSelectedTime}
          />
          <Box maxWidth={300}>
            <Box paddingY={2}>
              <BooleanField
                title="Send As Push Notification"
                value={sendAsPush}
                onChange={setSendAsPush}
              />
            </Box>
            <Box paddingY={2}>
              <BooleanField title="Send as SMS" value={sendAsSms} onChange={setSendAsSms} />
            </Box>
          </Box>
          <Box direction="row" paddingY={4} width={200}>
            <Box flex="grow" marginRight={4}>
              <Button
                text="Add"
                onClick={(): void => {
                  if (selectedTime) {
                    if (notifications.find((n) => n.minutesBefore === parseInt(selectedTime))) {
                      // don't allow duplicates
                      return;
                    }
                    setNotifications([
                      ...notifications,
                      {minutesBefore: parseInt(selectedTime), sendAsPush, sendAsSms},
                    ]);
                  }
                  setAddNotificationActive(false);
                }}
              />
            </Box>
            <Box flex="grow">
              <Button
                text="Cancel"
                variant="destructive"
                onClick={(): void => {
                  setAddNotificationActive(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
