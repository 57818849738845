import {PreClinicUpdate, useDeletePreClinicUpdatesByIdMutation, useSentryAndToast} from "@store";
import {IsMobileDevice} from "@utils";
import {Box, Button, printDate, printDateAndTime, Text} from "ferns-ui";
import React, {ReactElement} from "react";

const styles = {
  mobile: {
    psychiatristSelectorWidth: "100%",
    datePickerWidth: "100%",
    createUpdateButtonWidth: "100%",
  },
  web: {psychiatristSelectorWidth: "33%", datePickerWidth: "33%", createUpdateButtonWidth: "25%"},
};

interface ReadOnlyUpdateCardProps {
  preClinicUpdate: PreClinicUpdate;
  readOnly: boolean;
  onStartEdit: () => void;
}

export const ReadOnlyUpdateCard = ({
  preClinicUpdate,
  onStartEdit,
}: ReadOnlyUpdateCardProps): ReactElement => {
  const [removePreClinicUpdate] = useDeletePreClinicUpdatesByIdMutation();
  const sentryAndToast = useSentryAndToast();

  return (
    <>
      <Box
        gap={4}
        width={IsMobileDevice ? styles.mobile.datePickerWidth : styles.web.datePickerWidth}
      >
        <Box>
          <Box marginBottom={3}>
            <Text bold size="md">
              Clinic Date
            </Text>
          </Box>
          <Text>{printDate(preClinicUpdate.clinicDate, {ignoreTime: true})}</Text>
        </Box>
      </Box>
      <Box>
        <Box marginBottom={3}>
          <Text bold size="md">
            Update
          </Text>
        </Box>
        <Text>{preClinicUpdate.text}</Text>
      </Box>
      <Box alignItems="center" direction="row" justifyContent="between" paddingX={1}>
        {Boolean(preClinicUpdate._id) && (
          <Box>
            <Text color="secondaryLight" italic>
              Last saved on{IsMobileDevice ? "\n" : " "}
              {preClinicUpdate.updated
                ? printDateAndTime(preClinicUpdate.updated)
                : printDateAndTime(preClinicUpdate.created)}
            </Text>
          </Box>
        )}
        <Box direction="row">
          <Box>
            <Button
              confirmationText="Are you sure you want to delete this update? This cannot be undone."
              modalTitle="Delete Pre-Clinic Update"
              text="Delete"
              variant="destructive"
              withConfirmation
              onClick={() => {
                removePreClinicUpdate(preClinicUpdate._id)
                  .unwrap()
                  .catch((error) => {
                    sentryAndToast(
                      `Error deleting update. Please try again before continuing.`,
                      error
                    );
                  });
              }}
            />
          </Box>
          <Box marginLeft={5}>
            <Button text="Edit" variant="secondary" onClick={onStartEdit} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
