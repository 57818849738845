import {skipToken} from "@reduxjs/toolkit/query/react";
import {FormInstanceWithForm, useGetUsersQuery, useLocalFormInstance, User} from "@store";
import {Box, Heading, printDate, printDateAndTime, Text} from "ferns-ui";
import React, {FC} from "react";
import {Image} from "react-native";

interface FormInstanceSignatureProps {
  formInstanceId: string;
}

type SignatureDetail = {
  userId: string | undefined;
  signedDate: string | undefined;
  hash: string | undefined;
  isSupervisorSignature: boolean;
};

const getNameByIdFromUserList = (id: string, users: User[]): string => {
  const user = users.find((u) => u._id === id);
  return user ? user.name : "Unknown";
};

const extractSignatureDetails = (
  formInstanceData: FormInstanceWithForm | null
): {signatureDetails: SignatureDetail[]; userIdsToFetch: string[]} => {
  const signatureDetails: SignatureDetail[] = [];
  const userIdsToFetch: string[] = [];

  if (!formInstanceData) {
    return {signatureDetails, userIdsToFetch};
  }

  const addSignatureDetails = (signature: {
    signedById?: string;
    signedByUserId?: string;
    signedDate?: string;
    typedName?: string;
    image?: string;
    hash?: string;
    isSupervisorSignature?: boolean;
    _id?: string;
  }): SignatureDetail | void => {
    const signedUserId = signature.signedByUserId || signature.signedById;
    if (signedUserId) {
      userIdsToFetch.push(signedUserId);
      signatureDetails.push({
        userId: signedUserId,
        signedDate: signature?.signedDate,
        hash: signature?.hash,
        isSupervisorSignature: signature.isSupervisorSignature ?? false,
      });
    }
    return;
  };
  // Extract details from user (patient/family member) signatures
  if (formInstanceData?.userSignatures) {
    formInstanceData.userSignatures.forEach((signature) => addSignatureDetails(signature));
  }
  // Extract details from the createdBy staff signature
  if (formInstanceData?.signature?.signedById) {
    addSignatureDetails(formInstanceData.signature);
  }
  // Extract details from supervisor signatures
  if (formInstanceData?.supervisorSignatures?.length) {
    formInstanceData.supervisorSignatures.forEach((signature) =>
      addSignatureDetails({isSupervisorSignature: true, ...signature})
    );
  }

  return {signatureDetails, userIdsToFetch};
};

// Component to display all signatures that have been added to a form instance
export const AllFormInstanceSignatures: FC<FormInstanceSignatureProps> = ({formInstanceId}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const {signatureDetails, userIdsToFetch} = extractSignatureDetails(formInstance);
  const {data: signatureUsersData} = useGetUsersQuery(
    userIdsToFetch.length > 0 ? {_id: {$in: userIdsToFetch}} : skipToken
  );
  const signatureUsers = signatureUsersData?.data ?? ([] as User[]);

  if (!formInstance || !signatureDetails.length) {
    return null;
  }

  const status = formInstance?.status;

  if (!status || !["Requires Supervisor Action", "Signed"].includes(status)) {
    return null;
  }

  return (
    <Box paddingY={2}>
      {signatureDetails.map((user, i) => (
        <Text key={`signed-${user.userId}-${i}`}>
          Signed By{user.isSupervisorSignature && " Supervisor"}:{" "}
          {getNameByIdFromUserList(user.userId as string, signatureUsers)} on{" "}
          {printDate(user.signedDate)} - {user.hash ?? "No hash"}
        </Text>
      ))}
    </Box>
  );
};

// Component to display user-specific signatures with additional details for patients and caregivers
export const UserSignatures: FC<FormInstanceSignatureProps> = ({formInstanceId}) => {
  const formInstance = useLocalFormInstance(formInstanceId);
  const {userIdsToFetch} = extractSignatureDetails(formInstance);
  const {data: signatureUsersData} = useGetUsersQuery(
    userIdsToFetch.length > 0 ? {_id: {$in: userIdsToFetch}} : skipToken
  );
  const signatureUsers = signatureUsersData?.data ?? ([] as User[]);

  if (!formInstance) {
    return null;
  }

  const userSignatures = formInstance?.userSignatures;

  if (!userSignatures || !userSignatures.length) {
    return null;
  }

  return (
    <Box paddingY={2}>
      <Heading>Signatures</Heading>
      {userSignatures.map((signature, index) => (
        <Box key={`signature-${index}`} color="base" margin={4} padding={4} rounding="md">
          {signature.signedByUserId && (
            <Box direction="row" paddingY={2}>
              <Text bold size="lg">
                Signed by:{" "}
              </Text>
              <Text size="lg">
                {" "}
                {getNameByIdFromUserList(signature.signedByUserId!, signatureUsers)}
              </Text>
            </Box>
          )}
          <Text>On: {printDateAndTime(signature.signedDate)}</Text>
          {signature.image && (
            <Image
              resizeMode="contain"
              source={{uri: signature.image}}
              style={{
                width: 300,
                height: 50,
                borderWidth: 1,
                borderColor: "black",
              }}
            />
          )}
          {signature.typedName && (
            <Box direction="row" paddingY={2}>
              <Text bold>Typed Name:</Text>
              <Text> {signature.typedName}</Text>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
