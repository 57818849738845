import {UserSettings} from "@store";
import {DateTime} from "luxon";

export const isWorkflowPinned = (
  currentWorkflowMappingId: string,
  settings: UserSettings
): boolean => {
  if (!settings) {
    return false;
  }
  return Boolean(
    settings.panelConfig?.reorganizedWorkflowMappings?.find(
      (rwm) => rwm.workflowMappingId === currentWorkflowMappingId && rwm.pinned
    )
  );
};

export const isWorkflowSnoozed = (
  currentWorkflowMappingId: string,
  settings: UserSettings
): boolean => {
  if (!settings) {
    return false;
  }
  return Boolean(
    settings.panelConfig?.reorganizedWorkflowMappings?.find(
      (rwm) =>
        rwm.workflowMappingId === currentWorkflowMappingId &&
        rwm.snoozedUntil &&
        DateTime.fromISO(rwm.snoozedUntil) > DateTime.now()
    )
  );
};

export const isWorkflowCompleted = (
  currentWorkflowMappingId: string,
  settings: UserSettings
): boolean => {
  if (!settings) {
    return false;
  }
  return Boolean(
    settings.panelConfig?.reorganizedWorkflowMappings?.find(
      (rwm) => rwm.workflowMappingId === currentWorkflowMappingId && rwm.completed
    )
  );
};
