import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetFormInstancesByIdQuery} from "@store";
import {Box, Icon, Text} from "ferns-ui";
import React from "react";

interface AssessmentScoreProps {
  formInstanceId: string;
}

export const AssessmentScore = ({
  formInstanceId,
}: AssessmentScoreProps): React.ReactElement | null => {
  const {data: formInstance} = useGetFormInstancesByIdQuery(formInstanceId ?? skipToken);

  if (
    !formInstance ||
    formInstance.totalScore === undefined ||
    formInstance.type !== "Assessment"
  ) {
    return null;
  }

  let scores = formInstance?.scores;

  // Filter out some scores from old assessments that were not properly grouped and scored.
  scores = scores.filter((s) => !(s.grouping === "undefined" && s.score === 0));

  // Old assessments wound up with "undefined" as the grouping, now if there's no grouping,
  // they default to "". Either way, print "Score" for the default grouping.
  const printGrouping = (grouping: string | undefined): string => {
    return !grouping || grouping === "undefined" ? "Score" : grouping;
  };

  return (
    <Box
      alignItems="center"
      direction="row"
      justifyContent="center"
      paddingX={4}
      paddingY={4}
      width="100%"
    >
      <Box alignItems="center" height={60} justifyContent="center" width={60}>
        <Icon color="error" iconName="heart-pulse" size="xl" />
      </Box>
      <Box direction="column" paddingX={4}>
        {scores.map((s) => (
          <Box key={s.grouping} paddingY={2}>
            <Text bold size="lg">
              {printGrouping(s.grouping)}: {s.score}
            </Text>
          </Box>
        ))}
        {/* scoreText defaults to the total score, no need to print it twice. */}
        {Boolean(
          formInstance.scoreText && formInstance.scoreText !== String(formInstance.totalScore)
        ) && (
          <Box paddingY={1}>
            <Text bold size="lg">
              {formInstance.scoreText}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
