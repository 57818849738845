import {Audio} from "expo-av";

import {captureMessage} from "./sentry";

const soundFiles: {[key: string]: any} = {
  // add more sounds here once developed
  default: require("../assets/audio/notification.mp3"),
};

const loadedSounds: {[key: string]: Audio.Sound} = {};

export const playSound = async (soundName: string): Promise<void> => {
  try {
    if (!soundFiles[soundName]) {
      console.error(`Sound "${soundName}" not found while trying to play sound`);
      captureMessage(`Sound "${soundName}" not found`);
    }
    let sound;
    if (loadedSounds[soundName]) {
      sound = loadedSounds[soundName];
    } else {
      const {sound: loadedSound} = await Audio.Sound.createAsync(soundFiles[soundName]);
      loadedSounds[soundName] = loadedSound;
      sound = loadedSound;
    }
    await sound.replayAsync();
  } catch (error) {
    console.error("Error playing sound", error);
  }
};

export const unloadSounds = async (): Promise<void> => {
  const unloadPromises = Object.values(loadedSounds).map(async (sound) => {
    try {
      await sound.unloadAsync();
    } catch (error) {
      console.error("Error unloading sound", error);
    }
  });

  await Promise.all(unloadPromises);
  Object.keys(loadedSounds).forEach((key) => delete loadedSounds[key]);
};
