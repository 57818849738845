import {User} from "@store";
import {Box} from "ferns-ui";
import get from "lodash/get";
import React from "react";

import {LinkButton} from "./LinkButton";

// A cell that shows a clickable link.
export const LinkButtonCell = ({
  user,
  field,
  text,
}: {
  user: User;
  text: string;
  field: "documentFolder";
}): React.ReactElement => {
  const value = get(user.growth, field);

  if (!value) {
    return <Box flex="grow" justifyContent="center" width="100%" />;
  }
  return (
    <Box flex="grow" justifyContent="center" width="100%">
      <LinkButton href={value} text={text} />
    </Box>
  );
};
