import {MarkdownView} from "@components";
import {latestConsentFormOfType} from "@store";
import {ConsentFormScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Page} from "ferns-ui";
import React from "react";

interface PrivacyPolicyScreenProps extends ConsentFormScreenProps<"Privacy"> {}

export const PrivacyPolicyScreen = ({navigation}: PrivacyPolicyScreenProps): React.ReactElement => {
  const privacy = latestConsentFormOfType("privacy");
  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <MarkdownView>{privacy?.text}</MarkdownView>
    </Page>
  );
};
