import {
  ActivityLogView,
  ClinicalView,
  ConversationViewer,
  FitbitView,
  StaffRightBar,
  UserInfoView,
} from "@components";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  setWorkflowMappingId,
  useAppDispatch,
  useGetConversationsQuery,
  useGetUsersByIdQuery,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {IsMobileDevice} from "@utils";
import {Box, Card, SelectField, Spinner, Text} from "ferns-ui";
import {printAPIError} from "ferns-ui/dist/Utilities";
import React, {ReactElement, useEffect, useState} from "react";

type StaffTab = "Chat" | "Clinical" | "Info" | "Fitbit" | "Activity";

interface UserScreenProps extends StaffStackScreenProps<"User"> {}

export const UserScreen = ({navigation, route}: UserScreenProps): ReactElement => {
  const userId = route.params.userId;
  const [tab, setTab] = useState<StaffTab>("Chat");
  const [selectedConversationId, setSelectedConversationId] = useState<string | undefined>(
    undefined
  );

  const dispatch = useAppDispatch();

  const {data: user, error, isLoading} = useGetUsersByIdQuery(userId);
  const profile = useReadProfile();

  // Set the title to the user's name
  useEffect(() => {
    if (user?.name) {
      navigation.setOptions({headerTitle: user.name});
    } else {
      navigation.setOptions({headerTitle: ""});
    }
  }, [navigation, user?.name]);

  const query = {
    $and: [{"users.userId": userId}],
    type: {$in: ["Patient", "FamilyMember"]},
  } as any;
  const {data: conversationData} = useGetConversationsQuery(
    profile?._id && userId ? query : skipToken
  );

  const myConversation = conversationData?.data?.find((c) =>
    c.users.some((u) => u.userId?._id === profile?._id)
  );
  const otherConversations =
    conversationData?.data?.filter((c) => c._id !== myConversation?._id) ?? [];

  const conversations = [myConversation, ...otherConversations].filter((c) => c);
  // Label conversations with the user that is not the page user.
  const conversationOptions = conversations.map((c) => {
    if (!c) {
      return {label: "No conversation", value: ""};
    }
    return {
      label: `Chat with: ${
        c.users.find((u) => u.userId?._id !== userId)?.userId?.name || "Unknown"
      }`,
      value: c._id,
    };
  });

  // once conversationOptions is set, set the default selectedConversationId to the first
  // conversation
  useEffect(() => {
    if (conversationOptions.length > 0 && !selectedConversationId) {
      setSelectedConversationId(conversationOptions[0].value);
    }
  }, [conversationOptions, selectedConversationId]);

  if (isLoading) {
    return (
      <Box alignItems="center" height="100%" justifyContent="center" width="100%">
        <Spinner size="md" />
      </Box>
    );
  }
  if (error) {
    return (
      <Box alignItems="center" height="100%" justifyContent="center" width="100%">
        <Card alignSelf="center" width={300}>
          <Text bold>Error fetching user: {printAPIError(error as any)}</Text>
        </Card>
      </Box>
    );
  } else if (IsMobileDevice) {
    return (
      <Box height="100%">
        <Box padding={2} width="100%">
          <SelectField
            options={["Chat", "Clinical", "Info", "Fitbit", "Activity"].map((t) => ({
              label: t,
              value: t,
            }))}
            requireValue
            value={tab}
            onChange={(value) => {
              setTab(value as StaffTab);
            }}
          />
        </Box>

        <Box flex="grow" width="100%">
          {Boolean(tab === "Chat") && (
            <Box height="100%" paddingX={2} width="100%">
              <SelectField
                options={conversationOptions}
                requireValue={false}
                value={selectedConversationId}
                onChange={setSelectedConversationId}
              />
              {selectedConversationId && (
                <ConversationViewer
                  key={selectedConversationId}
                  conversationId={selectedConversationId}
                />
              )}
            </Box>
          )}
          {Boolean(tab === "Clinical") && (
            <Box height="100%" scroll width="100%">
              <ClinicalView userId={userId} />
            </Box>
          )}
          {Boolean(tab === "Info") && (
            <Box height="100%" scroll width="100%">
              <UserInfoView
                userId={userId}
                onRemoveUser={(): void => {
                  dispatch(setWorkflowMappingId(undefined));
                }}
              />
            </Box>
          )}
          {Boolean(tab === "Fitbit") && (
            <Box height="100%" scroll width="100%">
              <FitbitView userId={userId} />
            </Box>
          )}
          {Boolean(tab === "Activity") && (
            <Box height="100%" scroll width="100%">
              <ActivityLogView userId={userId || ""} />
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box direction="row" flex="grow" marginBottom={7} padding={2} width="100%">
        <Box direction="column" flex="grow" maxWidth="50%">
          <SelectField
            options={conversationOptions}
            requireValue={false}
            value={selectedConversationId}
            onChange={setSelectedConversationId}
          />
          {selectedConversationId && (
            <ConversationViewer
              key={selectedConversationId}
              conversationId={selectedConversationId}
            />
          )}
        </Box>
        <Box direction="column" flex="grow" maxWidth="50%">
          <StaffRightBar userId={userId} />
        </Box>
      </Box>
    );
  }
};
