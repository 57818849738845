import {useGetUserStatusesQuery, UserStatus} from "@store";
import {SelectField, SelectFieldPropsWithoutRequire} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React from "react";

interface UserStatusDropdownProps extends Omit<SelectFieldPropsWithoutRequire, "options"> {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const UserStatusDropdown = ({
  value,
  onChange,
  ...fieldProps
}: UserStatusDropdownProps): React.ReactElement => {
  const {data: userStatuses} = useGetUserStatusesQuery({});
  const options = [
    ...(sortBy(userStatuses?.data, "index") as UserStatus[]).map((us) => ({
      label: us?.name ?? "",
      value: us?._id ?? "",
    })),
  ];

  return (
    <SelectField
      requireValue={false}
      title="User Status"
      {...fieldProps}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
