import {GPTMemoryModal, MarkdownView} from "@components";
import {baseUrl, getAuthToken} from "@ferns-rtk";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  useDeleteGptHistoriesByIdMutation,
  useGetGptHistoriesByIdQuery,
  useGetGptHistoriesQuery,
  usePatchGptHistoriesByIdMutation,
  usePostGptHistoriesMutation,
  useSentryAndToast,
} from "@store";
import {
  Box,
  Button,
  Heading,
  IconButton,
  Text,
  TextArea,
  TextField,
  useTheme,
  useToast,
} from "ferns-ui";
import React, {useCallback, useEffect, useState} from "react";

import {StaffStackParamList, StaffStackScreenProps} from "../types";

interface Props extends StaffStackScreenProps<"GPT"> {}

interface GPTMessage {
  isPrompt?: boolean; // true if submitted prompt by user, false if from GPT
  text: string;
}

const GPTMessageContainer: React.FC<{
  messages: GPTMessage[];
  historyId?: string;
  onDelete: (id: string) => void;
}> = ({messages, historyId, onDelete}) => {
  const {theme} = useTheme();
  const {data: history} = useGetGptHistoriesByIdQuery(historyId || skipToken);
  const [updateHistory] = usePatchGptHistoriesByIdMutation();
  const [deleteHistory] = useDeleteGptHistoriesByIdMutation();
  const toast = useToast();
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState(history?.title);

  // Update the title when the history changes
  useEffect(() => {
    setTitle(history?.title);
  }, [history]);

  if (!historyId) {
    return null;
  }

  return (
    <Box>
      <Box
        alignItems="center"
        borderBottom="default"
        color="base"
        direction="row"
        justifyContent="between"
        paddingX={4}
        paddingY={2}
        width="100%"
      >
        {Boolean(editTitle) ? (
          <>
            <Box flex="grow" marginRight={4} maxWidth={500}>
              <TextField title="Conversation Title" value={title} onChange={setTitle} />
            </Box>
            <Button
              text="Save"
              variant="primary"
              onClick={async (): Promise<any> => {
                await updateHistory({id: historyId, body: {title}}).catch(toast.catch);
                setEditTitle(false);
              }}
            />
          </>
        ) : (
          <>
            <Heading>{title ?? "New Conversation"}</Heading>
            <Box direction="row" gap={2}>
              <IconButton
                accessibilityLabel="Edit title"
                iconName="pencil"
                variant="muted"
                onClick={(): void => setEditTitle(true)}
              />
              <Box alignSelf="end">
                <IconButton
                  accessibilityLabel="Delete conversation"
                  iconName="trash"
                  variant="destructive"
                  onClick={(): void => {
                    deleteHistory(historyId!).catch(toast.catch);
                    onDelete(historyId);
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box color="base" gap={4} marginBottom={6} paddingX={2} paddingY={4}>
        {messages.map((message, index) => {
          return message.isPrompt ? (
            <Box
              key={index}
              alignSelf="end"
              border="default"
              dangerouslySetInlineStyle={{
                __style: {backgroundColor: theme.surface.secondaryDark},
              }}
              marginLeft={6}
              maxWidth="50%"
              padding={2}
              rounding="lg"
            >
              <MarkdownView inverted>{message.text}</MarkdownView>
            </Box>
          ) : (
            <Box marginRight={6}>
              <Box
                key={index}
                dangerouslySetInlineStyle={{
                  __style: {backgroundColor: theme.primitives.secondary100},
                }}
                padding={2}
                rounding="lg"
              >
                <MarkdownView>{message.text}</MarkdownView>
              </Box>

              <Box direction="row" gap={2} justifyContent="end" marginTop={2} width="100%">
                {Boolean(historyId) && (
                  <IconButton
                    accessibilityHint="Share this conversation"
                    accessibilityLabel="Share"
                    iconName="share"
                    tooltipText="Share this conversation"
                    variant="secondary"
                    onClick={(): void => {
                      // TODO: Share and jump to the specific gpt message,
                      // not the whole conversation
                      void navigator.clipboard.writeText(
                        `https://app.flourish.health/GPT?historyId=${historyId}`
                      );
                      toast.show("Copied conversation link to clipboard");
                    }}
                  />
                )}
                {/* TODO support regenerating GPT messages */}
                {/* {Boolean(isLastMessage) && (
                  <IconButton
                    accessibilityHint="Regenerate the message"
                    accessibilityLabel="Regenerate"
                    iconName="arrows-rotate"
                    tooltipText="Regenerate the message"
                    variant="secondary"
                    onClick={(): void => {
                      console.log("Regenerate");
                    }}
                  />
                )} */}
                <IconButton
                  accessibilityHint="Copy the message"
                  accessibilityLabel="Copy"
                  iconName="copy"
                  tooltipText="Copy the message"
                  variant="secondary"
                  onClick={(): void => {
                    void navigator.clipboard.writeText(message.text);
                    toast.show("Copied to clipboard");
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const GPTScreen: React.FC<Props> = ({route}) => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const sentryAndToast = useSentryAndToast();
  const [prompt, setPrompt] = useState("");
  const [historyId, setHistoryId] = useState<string | undefined>(route.params?.historyId);
  const [messages, setMessages] = useState<GPTMessage[]>([]);
  const [showMemoryModal, setShowMemoryModal] = useState<boolean>(false);
  const {data: historyData} = useGetGptHistoriesQuery({});
  const [createHistory] = usePostGptHistoriesMutation();

  // Set the history id from the route params
  useEffect(() => {
    setHistoryId(route.params?.historyId);
  }, [route.params?.historyId]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    let hid = historyId;
    if (!historyId) {
      // Create a conversation history
      const history = await createHistory({}).unwrap();
      setHistoryId(history?._id);
      // Keep track of the history id locally, state may not be updated for the next call
      hid = history?._id;
    }
    const token = await getAuthToken();
    setMessages((m) => [...m, {isPrompt: true, text: prompt}, {isPrompt: false, text: ""}]);
    // Have to use fetch because axios doesn't support streaming responses
    let response;
    try {
      response = await fetch(`${baseUrl}/gpt/prompt`, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({prompt, historyId: hid}),
      });
    } catch (error: any) {
      sentryAndToast("GPT request failed.", error);
      return;
    }
    if (!response.ok || !response.body) {
      sentryAndToast(`GPT returned an invalid response`);
      return;
    }

    // Here we start prepping for the streaming response
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    const loopRunner = true;

    while (loopRunner) {
      // Here we start reading the stream, until its done.
      const {value, done} = await reader.read();
      if (done) {
        setPrompt("");
        break;
      }
      const decodedChunk = decoder.decode(value, {stream: true});
      // Add the response to the last message
      setMessages((m) => {
        const lastMessage = m[m.length - 1];
        if (!lastMessage) {
          return [...m];
        }
        lastMessage.text = (lastMessage.text ?? "") + decodedChunk;
        return [...m];
      });
    }
  }, [createHistory, historyId, prompt, sentryAndToast]);

  // When history id changes, load up the messages
  useEffect(() => {
    const history = historyData?.data?.find((h) => h._id === historyId);
    if (!history) {
      return;
    }
    setMessages(history.prompts.map((p) => ({isPrompt: p.type === "user", text: p.text})));
  }, [historyData?.data, historyId]);

  // On initial load, set up the memory modal button in the header
  useEffect(() => {
    navigation.setOptions({
      headerRight: (): React.ReactNode => (
        <Box marginRight={4}>
          <Button
            iconName="pencil"
            text="Edit Memory"
            variant="muted"
            onClick={(): void => {
              setShowMemoryModal(true);
            }}
          />
        </Box>
      ),
    });
  }, [navigation]);

  // TODO: manage left and right view with new split page when we get that in.
  return (
    <>
      <GPTMemoryModal
        visible={showMemoryModal}
        onDismiss={() => {
          setShowMemoryModal(false);
        }}
      />
      <Box direction="row" height="100%" padding={4} width="100%">
        <Box direction="column" height="100%" paddingX={2} width="30%">
          <Box gap={2} scroll>
            <Heading size="sm">Conversations</Heading>
            {historyData?.data?.map((history) => (
              <Box
                key={history._id}
                accessibilityHint="Click to view conversation"
                accessibilityLabel="Conversation"
                border={historyId === history._id ? "dark" : undefined}
                borderBottom={historyId === history._id ? undefined : "default"}
                color="base"
                padding={2}
                rounding="sm"
                onClick={(): void => {
                  setHistoryId(history._id);
                }}
              >
                <Box>
                  <Text bold>{history.title}</Text>
                </Box>
                <Box>
                  <Text>{history.prompts[0]?.text?.substring(0, 100)}</Text>
                </Box>
              </Box>
            ))}
            <Box maxWidth={200} paddingY={4}>
              <Button
                iconName="plus"
                text="New Conversation"
                variant="primary"
                onClick={async (): Promise<void> => {
                  const history = await createHistory({}).unwrap();
                  setHistoryId(history?._id);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box direction="column" flex="grow" height="10 0%" paddingX={2} width="70%">
          <Box scroll>
            <GPTMessageContainer
              historyId={historyId}
              messages={messages}
              onDelete={() => setHistoryId(undefined)}
            />
            <Box>
              <TextArea
                grow
                helperText={
                  "Note: Always double check what ChatGPT says. It is not always correct and will confidently " +
                  "make up answers. It also has limited knowledge of the world, ending around 2023, so answers may be out " +
                  "of date."
                }
                placeholder="Text to prompt GPT"
                value={prompt}
                onChange={(result: string): void => {
                  setPrompt(result);
                }}
                onSubmitEditing={handleSubmit}
              />
              <Box maxWidth={200} paddingY={2}>
                <Button text="Submit" variant="primary" onClick={handleSubmit} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
