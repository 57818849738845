import React from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {useCallbackOne} from "use-memo-one";

import Color from "./Color";
import {useChatContext} from "./GiftedChatContext";
import {ActionsProps} from "./Models";

export const Actions = ({
  options = {},
  optionTintColor = Color.optionTintColor,
  icon,
  wrapperStyle,
  iconTextStyle,
  onPressActionButton,
  containerStyle,
}: ActionsProps): React.ReactElement => {
  const {actionSheet} = useChatContext();
  const onActionsPress = useCallbackOne(() => {
    const optionKeys = Object.keys(options);
    const cancelButtonIndex = optionKeys.indexOf("Cancel");
    actionSheet().showActionSheetWithOptions(
      {
        options: optionKeys,
        cancelButtonIndex,
        tintColor: optionTintColor,
      },
      (buttonIndex: number) => {
        const key = optionKeys[buttonIndex];
        if (key) {
          options[key]();
        }
      }
    );
  }, []);

  const renderIcon = useCallbackOne(() => {
    if (icon) {
      return icon();
    }
    return (
      <View style={[styles.wrapper, wrapperStyle]}>
        <Text style={[styles.iconText, iconTextStyle]}>+</Text>
      </View>
    );
  }, []);

  return (
    <Pressable
      style={[styles.container, containerStyle]}
      onPress={onPressActionButton || onActionsPress}
    >
      {renderIcon()}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    marginLeft: 10,
    marginBottom: 10,
  },
  wrapper: {
    borderRadius: 13,
    borderColor: Color.defaultColor,
    borderWidth: 2,
    flex: 1,
  },
  iconText: {
    color: Color.defaultColor,
    fontWeight: "bold",
    fontSize: 16,
    backgroundColor: Color.backgroundTransparent,
    textAlign: "center",
  },
});
