import {
  useCurrentWorkflowMapping,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {useSelectWorkflowStaffId} from "@store";
import {Banner, Box} from "ferns-ui";
import React from "react";

import {isStaff, UserTypes} from "../utils";

export const StaffOfflineBanner = (): React.ReactElement | null => {
  const profile = useReadProfile();
  const currentWorkflowMapping = useCurrentWorkflowMapping();

  const staffId = useSelectWorkflowStaffId();
  const {conversation, isLoading: isLoadingConversation} = useGetConversationForWorkflowMapping(
    staffId ? currentWorkflowMapping : undefined
  );

  if (isLoadingConversation || !currentWorkflowMapping || !profile?._id) {
    return null;
  }

  let text: undefined | string;
  if (!profile || !isStaff(profile?.type)) {
    return null;
  } else if (!profile?.online?.forFamilyMembers && conversation?.type === UserTypes.FamilyMember) {
    text = "You appear offline to family members.";
  } else if (!profile?.online?.forPatients && conversation?.type === UserTypes.Patient) {
    text = "You appear offline to patients.";
  } else {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);

  if (conversation && isMyWorkflow) {
    return (
      <Box direction="column" display="flex" marginBottom={2} width="100%">
        <Banner id="offlineStaffWarning" status="alert" text={text} />
      </Box>
    );
  } else {
    return null;
  }
};
