import {FilterItem, TimezoneDropdown, UserList, UserRowProps} from "@components";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  AppointmentSlot,
  CarePod,
  ScheduleItem,
  ScheduleItemType,
  useGetAppointmentSlotsQuery,
  useGetCarePodsQuery,
  useGetUsersByIdQuery,
  User,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  MultiselectField,
  SelectField,
  Spinner,
  Text,
  Tooltip,
  useTheme,
  useToast,
} from "ferns-ui";
import uniq from "lodash/uniq";
import {DateTime} from "luxon";
import React, {useEffect, useMemo, useState} from "react";
import {Calendar} from "react-native-calendars";
import {MarkedDates} from "react-native-calendars/src/types";

import {SchedulingColors} from "../constants";

const COLORS = {
  "Psychiatry Intake": SchedulingColors.blue,
  "Guide Clinical Intake": SchedulingColors.orange,
};

const appointmentTypeOptions = [
  {label: "Clinical Intake", value: "Guide Clinical Intake"},
  {label: "Psychiatry Intake", value: "Psychiatry Intake"},
];

const AppointmentSlotComponent = ({
  slot,
  timezone,
  onClick,
}: {
  slot: AppointmentSlot;
  userId?: string;
  timezone?: string;
  onClick: () => void;
}): React.ReactElement => {
  const start = DateTime.fromISO(slot.startDatetime!, {zone: timezone ?? slot.timezone}).toFormat(
    "hh:mm"
  );
  let end;
  // For Guide Clinical Intake, the end time is shown as the end of the associated Therapy Clinical
  // Intake.
  if (slot.type === "Guide Clinical Intake" && slot.associatedSlots?.[0]) {
    end = DateTime.fromISO(slot.associatedSlots?.[0].endDatetime!, {
      zone: timezone ?? slot.timezone,
    }).toFormat("hh:mm a");
  } else {
    end = DateTime.fromISO(slot.endDatetime!, {zone: timezone ?? slot.timezone}).toFormat(
      "hh:mm a"
    );
  }
  const zone = DateTime.fromISO(slot.startDatetime!, {zone: timezone ?? slot.timezone}).toFormat(
    "ZZZZ"
  );

  const staff = [
    slot.staffName,
    ...(slot.otherStaffNames ?? []),
    ...(slot.associatedSlots?.map((s) => s.staffName) ?? []),
  ].join(", ");

  return (
    <Box key={slot.type + slot.startTime + slot.carePodName} marginBottom={2} width="100%">
      <Tooltip text={slot.warnings?.join(", ")}>
        <Box
          accessibilityHint="Select appointment slot"
          accessibilityLabel="Select"
          border="default"
          direction="row"
          paddingX={4}
          paddingY={2}
          rounding="md"
          onClick={onClick}
        >
          {Boolean(slot?.warnings?.length) && (
            <Box alignItems="center" height="100%" justifyContent="center" marginRight={2}>
              <Icon color="warning" iconName="triangle-exclamation" />
            </Box>
          )}
          <Box direction="column" flex="grow">
            <Text align="center" bold>
              {start} - {end} {zone}
            </Text>
            <Text align="center">{slot.carePodName}</Text>
            <Text align="center">{staff}</Text>
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

const AppointmentUserRow = ({user, users, onChangeUsers}: UserRowProps): React.ReactElement => {
  const text = `${userName(user)} ${user?.testUser ? "- Test User" : ""}`;
  return (
    <FilterItem
      text={text}
      onDismiss={() => onChangeUsers(users.filter((u) => u._id !== user._id))}
    />
  );
};

interface AppointmentSlotProps {
  days: Record<string, AppointmentSlot[]>;
  date?: string;
  timezone?: string;
  calendarMonth?: string;
  setCalendarMonth: (month: string) => void;
  setDate: (date: string) => void;
  setTimezone: (timezone: string) => void;
}

const AppointmentCalendar = ({
  days,
  date,
  timezone,
  calendarMonth,
  setCalendarMonth,
  setDate,
  setTimezone,
}: AppointmentSlotProps): React.ReactElement => {
  const {theme} = useTheme();

  const markedDates = useMemo((): MarkedDates => {
    const psychiatry = {key: "Psychiatry Intake", color: COLORS["Psychiatry Intake"]};
    const guides = {
      key: "Guide Clinical Intake",
      color: COLORS["Guide Clinical Intake"],
    };

    const marked: MarkedDates = {};

    Object.keys(days).forEach((d) => {
      days[d].forEach((t) => {
        if (!marked[d]?.dots) {
          marked[d] = {dots: []};
        }

        if (marked[d].dots!.find((dot) => dot.key === t.type)) {
          return;
        }

        if (t.type === "Psychiatry Intake") {
          marked[d].dots!.push(psychiatry);
        } else if (t.type === "Guide Clinical Intake") {
          marked[d].dots!.push(guides);
        } else {
          console.warn("Unknown type", t.type, t);
        }
      });
    });

    if (date) {
      marked[date] = {selected: true, selectedColor: theme.surface.primary};
    }
    return marked;
  }, [date, days, theme.surface.primary]);

  return (
    <Box
      color="base"
      marginBottom={4}
      marginRight={4}
      maxWidth="33%"
      padding={4}
      rounding="lg"
      width="33%"
    >
      <Calendar
        date={date}
        initialDate={calendarMonth}
        markedDates={markedDates}
        markingType="multi-dot"
        renderArrow={(direction: "left" | "right") => (
          <IconButton
            accessibilityLabel={`go to ${direction === "left" ? "previous" : "next"} month`}
            iconName={direction === "left" ? "arrow-left" : "arrow-right"}
            variant="muted"
            onClick={() => {
              setCalendarMonth(
                DateTime.fromISO(calendarMonth!, {zone: timezone})
                  .plus({months: direction === "left" ? -1 : 1})
                  .startOf("month")
                  .toFormat("yyyy-MM-dd")
              );
            }}
          />
        )}
        theme={{
          backgroundColor: "#ffffff",
          calendarBackground: "#ffffff",
          textSectionTitleColor: theme.surface.primary,
          selectedDayBackgroundColor: theme.surface.primary,
          selectedDayTextColor: theme.surface.base,
          todayTextColor: theme.surface.primary,
          dayTextColor: theme.text.primary,
          textDisabledColor: theme.text.secondaryLight,
          arrowColor: "orange",
        }}
        onDayPress={(day: {dateString: string}) => {
          setDate(day.dateString);
        }}
      />

      <Box marginTop={4} paddingX={4} width="100%">
        <TimezoneDropdown value={timezone ?? "America/New_York"} onChange={setTimezone} />
      </Box>
    </Box>
  );
};

interface IntakeSchedulingScreenProps extends StaffStackScreenProps<"IntakeScheduling"> {}

export const IntakeSchedulingScreen = ({
  navigation,
  route,
}: IntakeSchedulingScreenProps): React.ReactElement => {
  const toast = useToast();
  const [date, setDate] = useState<string | undefined>(undefined);
  const [timezone, setTimezone] = useState<string>("America/New_York");
  const [type, setType] = useState<ScheduleItemType | undefined>(undefined);
  const [calendarMonth, setCalendarMonth] = useState<string>(
    DateTime.now().setZone("America/New_York").toFormat("yyyy-MM-dd")
  );
  const userId = route.params?.selectedUserId;

  const {data: selectedStaff} = useGetUsersByIdQuery(route.params?.selectedStaffId ?? skipToken);
  const {data: selectedUser} = useGetUsersByIdQuery(userId ?? skipToken);

  const [staffId, _setStaffId] = useState<string | undefined>(undefined);
  const [staff, setStaff] = useState<ScheduleItem["staff"]>([]);
  const [users, setUsers] = useState<ScheduleItem["users"]>([]);
  const [carePodIds, setCarePodIds] = useState<string[] | undefined>(undefined);
  const now = DateTime.now().setZone(timezone);
  // Ensure we don't get older dates.
  const startDate = DateTime.max(now, DateTime.fromISO(calendarMonth).startOf("month")).toFormat(
    "yyyy-MM-dd"
  );
  const endDate = DateTime.fromISO(calendarMonth).endOf("month").toFormat("yyyy-MM-dd");

  // Once we fetch selected staff and/or user from the route params, set the staff or user state.
  useEffect(() => {
    if (selectedStaff) {
      setStaff([{userId: selectedStaff}]);
    }
    if (selectedUser) {
      setUsers([{userId: selectedUser}]);
    }
  }, [selectedStaff, selectedUser]);

  const shouldFetchSlots = Boolean(type || staffId) && userId;
  const {
    data: slotData,
    error,
    refetch,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetAppointmentSlotsQuery(
    shouldFetchSlots
      ? {
          startDate,
          endDate,
          type,
          staffId,
          carePodIds,
          userId,
        }
      : skipToken
  );

  // Whenever one of the filters changes, refetch the slots. For some reason, RTK Query doesn't
  // handle this automatically.
  useEffect(() => {
    // Don't refetch before the initial fetch is done.
    if (!isLoading && isSuccess) {
      refetch().catch(toast.catch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, type, staff, users, calendarMonth]);

  // Show an error toast if we have an error.
  useEffect(() => {
    if (isError) {
      toast.error(`Error fetching slots: ${error?.data?.title ?? "Unknown error"}`);
    }
  }, [isError, error, toast]);

  const days = slotData?.slots ?? {};
  const billingState = selectedUser?.billingInfo?.address?.state ?? selectedUser?.address?.state;
  const {data: carePodData} = useGetCarePodsQuery(
    billingState ? {states: billingState as any} : skipToken
  );
  const carePodOptions =
    carePodData?.data?.map((cp: CarePod) => ({label: cp.name, value: cp._id})) ?? [];

  const onChangeAppointmentType = (value: string | undefined): void => {
    setType(value as ScheduleItemType);
  };

  // const onChangeStaff = (changeUsers: User[]): void =>
  //   setStaff(changeUsers.map((cu) => ({userId: cu})));

  const onChangeUsers = (changeUsers: User[]): void =>
    setUsers(changeUsers.map((cu) => ({userId: cu})));

  return (
    <Box color="neutralLight" height="100%" padding={5} width="100%">
      <Box
        alignSelf="center"
        direction="row"
        marginBottom={5}
        maxHeight="100%"
        maxWidth={1200}
        width="100%"
      >
        <Box color="base" marginBottom={4} marginRight={4} padding={4} rounding="lg" width="33%">
          <Box direction="row" justifyContent="between" marginBottom={4}>
            <Heading size="sm">Filters</Heading>
            <Box alignItems="center" height={40} justifyContent="center" width={40}>
              {Boolean(
                (isFetching && (isSuccess || isError)) || (!isSuccess && shouldFetchSlots)
              ) && <Spinner size="md" />}
              {Boolean(!isFetching && isSuccess) && (
                <IconButton
                  accessibilityLabel="loading refresh"
                  iconName="arrows-rotate"
                  variant="secondary"
                  onClick={() => {
                    refetch().catch(toast.catch);
                  }}
                />
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <Text>Use these filters to find open intake slots.</Text>
          </Box>
          <Box marginBottom={4} paddingY={2}>
            <UserList
              UserRowComponent={AppointmentUserRow}
              buttonText="Select Users"
              familyMember
              patient
              title="Users"
              userIds={users.map((u) => u.userId?._id)}
              userPickerTitle="Users"
              onChangeUsers={onChangeUsers}
            />
          </Box>
          <Box width={250}>
            <SelectField
              options={appointmentTypeOptions}
              requireValue={false}
              title="Appointment Type"
              value={type}
              onChange={onChangeAppointmentType}
            />
          </Box>
          {/* TODO: Support care pod filtering in intake scheduling */}
          {Boolean(type) && (
            <Box paddingY={4} width={250}>
              <MultiselectField
                options={carePodOptions}
                title="(optional) Care Pod Filter"
                value={carePodIds ?? []}
                onChange={setCarePodIds}
              />
            </Box>
          )}

          {/* TODO: Support scheduling for arbitrary staff. We need to decide if two staff
            requires both staff or if we accept a slot that has one of the staff. */}
          {/* <Box paddingY={2}> */}
          {/*   <UserList */}
          {/*     UserRowComponent={AppointmentUserRow} */}
          {/*     buttonText="Select Staff" */}
          {/*     heading="Staff" */}
          {/*     staff */}
          {/*     userPickerTitle="Staff" */}
          {/*     users={staff.map((u) => u.userId)} */}
          {/*     onChangeUsers={onChangeStaff} */}
          {/*   /> */}
          {/* </Box> */}
        </Box>

        <AppointmentCalendar
          calendarMonth={calendarMonth}
          date={date}
          days={days}
          setCalendarMonth={setCalendarMonth}
          setDate={setDate}
          setTimezone={setTimezone}
          timezone={timezone}
        />

        {Boolean(date) && (
          <Box direction="column" width="33%">
            <Box color="base" flex="grow" marginBottom={4} padding={4} rounding="lg" scroll>
              <Box direction="row" marginBottom={4} width="100%">
                <Box flex="grow" justifyContent="center">
                  <Heading size="sm">Pick a slot:</Heading>
                </Box>
              </Box>
              {days[date!]?.length === 0 && (
                <Box justifyContent="center" paddingY={4} width="100%">
                  <Text>No available slots.</Text>
                </Box>
              )}
              {days[date!]?.map((s) => (
                <AppointmentSlotComponent
                  key={s.id}
                  slot={s}
                  timezone={timezone}
                  userId={users[0]?._id}
                  onClick={() => {
                    navigation.navigate("CreateIntake", {
                      slot: s,
                      userIds: users.map((u) => u.userId._id),
                      staffIds: uniq([
                        ...staff.map((su) => su.userId._id),
                        s.staffId,
                        ...(s.otherStaffIds ?? []),
                        s.associatedSlots?.[0]?.staffId,
                      ]),
                    });
                  }}
                />
              ))}

              <Button
                text="Manual Appointment"
                variant="muted"
                onClick={() => {
                  navigation.navigate("CreateScheduleItem", {
                    startDatetime: date
                      ? DateTime.fromISO(date, {zone: timezone}).toISO()!
                      : undefined,
                    type,
                    selectedUserId: users[0]?.userId?._id,
                    selectedStaffId: staff[0]?.userId?._id,
                  });
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
