import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteReferralSourcesByIdMutation,
  useGetReferralSourcesQuery,
  usePatchReferralSourcesByIdMutation,
  usePostReferralSourcesMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"ReferralSourceAdmin"> {}

export const ReferralSourceAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this referral source from all users before deleting, or the app will behave weirdly."
      description="Referral Sources are the generalized source of a referral."
      display={(m): {title: string; subtitle?: string} => ({title: m?.name})}
      fields={[{fieldKey: "name", type: "text", title: "Name"}] as FieldConfig[]}
      navigation={navigation}
      useCreate={usePostReferralSourcesMutation}
      useList={useGetReferralSourcesQuery}
      useRemove={useDeleteReferralSourcesByIdMutation}
      useUpdate={usePatchReferralSourcesByIdMutation}
    />
  );
};
