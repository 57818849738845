import {useReadProfile} from "@hooks";
import {Form, useLocalFormInstance, useSentryAndToast} from "@store";
import {Box, humanDateAndTime, Text} from "ferns-ui";
import React, {useMemo} from "react";

import {FormQuestionComponent} from "../formInstanceQuestions";

interface FormQuestionProps {
  formInstanceId: string;
}

export const FormQuestions: React.FC<FormQuestionProps> = ({formInstanceId}) => {
  const profile = useReadProfile();
  const formInstance = useLocalFormInstance(formInstanceId);
  const form = formInstance?.form as Form | undefined;
  const answers = formInstance?.answers;
  const sentryAndToast = useSentryAndToast();
  const formQuestionsWithoutHeadings = form?.questions?.filter((q) => q.type !== "Heading");

  const duplicateAnswerCheck = useMemo(() => {
    if (!formInstance || !answers) {
      return false;
    }
    const answerMap = new Map<string, number>();
    for (const answer of answers) {
      const count = answerMap.get(answer.questionId) || 0;
      answerMap.set(answer.questionId, count + 1);
    }

    let hasDuplicates = false;
    answerMap.forEach((count, questionId) => {
      if (count > 1) {
        hasDuplicates = true;
        sentryAndToast(
          `Multiple answers found for question ${questionId} and form instance ${formInstance?._id}. Please contact support.`
        );
      }
    });

    return hasDuplicates;
  }, [answers, formInstance, sentryAndToast]);

  if (duplicateAnswerCheck) {
    console.warn("Duplicate answers found");
  }

  if (!formInstance || !form || !answers || !profile) {
    return null;
  }

  return (
    <Box paddingY={2}>
      {form.questions?.map((q) => {
        if (!q._id) {
          console.error("Form question has no ID");
          return null;
        }
        return (
          <FormQuestionComponent
            key={q._id}
            formInstanceId={formInstance._id}
            index={formQuestionsWithoutHeadings?.findIndex((f) => f._id === q._id) ?? 0}
            questionId={q._id}
            userId={formInstance.userId._id}
          />
        );
      })}
      <Box marginBottom={4}>
        <Text>Last saved: {humanDateAndTime(formInstance.updated)}</Text>
      </Box>
    </Box>
  );
};
