import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {User} from "@store";
import {StaffStackParamList} from "@types";
import {Box, printOnlyDate, Text} from "ferns-ui";
import get from "lodash/get";
import React from "react";

// Show the intake date and link to the page to manage the intake.
export const IntakeCell = ({user, field}: {user: User; field: string}): React.ReactElement => {
  const {date, scheduleItemId} = get(user, field) ?? {};
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  return (
    <Box
      accessibilityHint="Tap to edit intake date."
      accessibilityLabel="Edit"
      flex="grow"
      justifyContent="center"
      width="100%"
      onClick={(): void => {
        navigation.navigate("CreateScheduleItem", {scheduleItemId});
      }}
    >
      <Text underline>{date ? `${printOnlyDate(date)}` : ""}</Text>
    </Box>
  );
};
