import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteHealthEventsByIdMutation,
  useGetHealthEventsQuery,
  usePatchHealthEventsByIdMutation,
  usePostHealthEventsMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"HealthItemAdminScreen"> {}

export const HealthItemAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this health item from all patients, or the app will behave weirdly."
      description="Health items are events that a patient experiences and we want as a part of their clinical history."
      display={(healthItem): {title: string; subtitle?: string} => ({title: healthItem?.name})}
      fields={[{fieldKey: "name", type: "text", title: "Name"}] as FieldConfig[]}
      navigation={navigation}
      useCreate={usePostHealthEventsMutation}
      useList={useGetHealthEventsQuery}
      useRemove={useDeleteHealthEventsByIdMutation}
      useUpdate={usePatchHealthEventsByIdMutation}
    />
  );
};
