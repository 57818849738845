import {Link} from "ferns-ui";
import React from "react";

export const PlayStoreLink = (): React.ReactElement => {
  return (
    <Link
      href="https://play.google.com/store/apps/details?id=health.flourish.android"
      text="Flourish Health on Play Store"
    />
  );
};
