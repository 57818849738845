// eslint-disable-next-line import/no-default-export
export default {
  defaultColor: "#b2b2b2",
  backgroundTransparent: "transparent",
  defaultBlue: "#0084ff",
  leftBubbleBackground: "#f0f0f0",
  black: "#000",
  neutralLight: "#DEDEDE",
  white: "#fff",
  carrot: "#e67e22",
  emerald: "#2ecc71",
  peterRiver: "#3498db",
  wisteria: "#8e44ad",
  alizarin: "#e74c3c",
  turquoise: "#1abc9c",
  midnightBlue: "#2c3e50",
  optionTintColor: "#007AFF",
  timeTextColor: "#aaa",
};
