import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetVoicemailsQuery, useSelectShowSideDrawer, Voicemail} from "@store";
import {
  Box,
  Button,
  Heading,
  IconButton,
  MultiselectField,
  Pagination,
  Spinner,
  Text,
} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {VoicemailCard} from "./VoicemailCard";

export const VoicemailsView = ({}: {}): React.ReactElement | null => {
  const [tab, setTab] = useState<"inbox" | "archived">("inbox");
  const [isVoicemailFilterCollapsed, setIsVoicemailFilterCollapsed] = useState(true);
  const [readVoicemailFilter, setReadVoicemailFilter] = useState<boolean[]>([]);
  const [page, setPage] = useState(1);
  const showSideDrawer = useSelectShowSideDrawer();
  const profile = useReadProfile();

  const {data: voicemailsData, isLoading} = useGetVoicemailsQuery(
    profile
      ? {
          page,
          archived: Boolean(tab === "archived"),
          ownerId: profile._id,
          read: {$in: readVoicemailFilter},
        }
      : skipToken
  );

  const organizeVoicemails = (voicemails: Voicemail[]): Voicemail[] => {
    if (!voicemails.length) {
      return [];
    }
    const sortVoicemailsByDate = (a: Voicemail, b: Voicemail): number =>
      new Date(b.created).getTime() - new Date(a.created).getTime();
    if (tab === "archived") {
      return voicemails.sort(sortVoicemailsByDate);
    }
    const unreadVoicemails = voicemails.filter((vm) => !vm.read);
    const readVoicemails = voicemails.filter((vm) => vm.read);

    unreadVoicemails.sort(sortVoicemailsByDate);
    readVoicemails.sort(sortVoicemailsByDate);

    return [...unreadVoicemails, ...readVoicemails];
  };

  const organizedVoicemails = organizeVoicemails(voicemailsData?.data ?? []);

  const resetPage = function (): void {
    setPage(1);
  };

  const resetFilters = function (): void {
    setReadVoicemailFilter([]);
    resetPage();
  };

  // The dependency array ensures this effect only runs when `showSideDrawer` changes
  useEffect(() => {
    const resetState = function (): void {
      resetFilters();
      setTab("inbox");
      setIsVoicemailFilterCollapsed(true);
    };
    if (!showSideDrawer) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideDrawer]);

  const renderFilterDropdown = (): React.ReactElement | null => {
    if (isVoicemailFilterCollapsed) {
      return null;
    }
    return (
      <Box
        color="base"
        dangerouslySetInlineStyle={{
          __style: {borderTopRightRadius: 0},
        }}
        direction="column"
        paddingX={3}
        paddingY={1}
        rounding="md"
      >
        <Box alignItems="center" direction="row" justifyContent="between">
          <Heading size="sm">Filters</Heading>
          <Button
            text="Reset"
            variant="muted"
            onClick={(): void => {
              resetFilters();
            }}
          />
        </Box>
        <MultiselectField
          options={[
            {label: "Read", value: "true"},
            {label: "Unread", value: "false"},
          ]}
          title="By read status"
          value={[String(readVoicemailFilter[0]), String(readVoicemailFilter[1])]}
          onChange={(filters): void => {
            resetPage();
            setReadVoicemailFilter(filters.map((f) => f === "true"));
          }}
        />
      </Box>
    );
  };

  return (
    <Box margin={1} maxHeight="100%" padding={2}>
      <Box alignItems="center" justifyContent="center" paddingY={1}>
        <Heading size="md">Voicemails</Heading>
      </Box>
      <Box direction="row" justifyContent="between">
        <Box color="secondaryLight" direction="row" rounding="md">
          <Box color="neutralLight" rounding="md">
            <Button
              text="Inbox"
              variant={tab === "inbox" ? "outline" : "secondary"}
              onClick={(): void => {
                resetPage();
                setTab("inbox");
              }}
            />
          </Box>
          <Box color="neutralLight" rounding="md">
            <Button
              text="Archived"
              variant={tab === "archived" ? "outline" : "secondary"}
              onClick={(): void => {
                resetPage();
                setTab("archived");
              }}
            />
          </Box>
        </Box>

        <Box
          alignItems="center"
          color={isVoicemailFilterCollapsed ? undefined : "base"}
          dangerouslySetInlineStyle={{
            __style: {borderTopLeftRadius: 14, borderTopRightRadius: 14},
          }}
          direction="row"
          justifyContent="between"
        >
          <IconButton
            accessibilityLabel="alert center filter toggle"
            iconName="filter"
            variant={isVoicemailFilterCollapsed ? "primary" : "secondary"}
            onClick={(): void => setIsVoicemailFilterCollapsed(!isVoicemailFilterCollapsed)}
          />
        </Box>
      </Box>
      {renderFilterDropdown()}
      {!voicemailsData?.data?.length ? (
        <Box alignItems="center" justifyContent="center" padding={4}>
          <Text bold>No voicemails to show</Text>
        </Box>
      ) : (
        <Box>
          {isLoading ? (
            <Box alignItems="center" justifyContent="center" padding={4}>
              <Spinner size="md" />
            </Box>
          ) : (
            organizedVoicemails?.map((vm) => <VoicemailCard key={vm._id} voicemail={vm} />)
          )}
          <Box direction="row" marginTop={4}>
            <Pagination page={page} setPage={setPage} totalPages={voicemailsData?.total ?? 0} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
