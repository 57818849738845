import {pageOnError} from "@utils";
import {Box, Button, Heading, Page} from "ferns-ui";
import React, {ReactElement} from "react";

interface AuthScreenProps {
  navigation: any;
}

export const AuthScreen = ({navigation}: AuthScreenProps): ReactElement => {
  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box
        alignSelf="center"
        color="base"
        marginTop={8}
        maxWidth={400}
        minWidth={300}
        padding={6}
        rounding="lg"
        width="100%"
      >
        <Box paddingY={5}>
          <Heading align="center">Welcome to Flourish!</Heading>
        </Box>
        <Box paddingY={3} width="100%">
          <Button text="Sign Up" onClick={(): void => navigation.push("SignUpScreen")} />
        </Box>
        <Box paddingY={3}>
          <Button
            text="Log In"
            variant="secondary"
            onClick={(): void => navigation.push("LogInScreen")}
          />
        </Box>
      </Box>
    </Page>
  );
};
