import {FormQuestion} from "@store";
import {Box, BoxProps, MultiselectField} from "ferns-ui";
import React, {ReactElement} from "react";

export const MultiSelectBox = ({
  answerRequiredErr,
  boxProps = {},
  disabled,
  question,
  title,
  value = [],
  onChange,
  onBlur,
}: {
  answerRequiredErr?: string;
  disabled?: boolean;
  boxProps?: BoxProps;
  question: FormQuestion;
  title: string;
  value: string[];
  onChange: (value: string[]) => void | Promise<void>;
  onBlur: (value: string[]) => Promise<void>;
}): ReactElement | null => {
  const options = question.options
    ? [
        ...question.options.map((o) => {
          return {label: o.label, value: o.label};
        }),
      ]
    : [];
  if (!options || options.length === 0) {
    return null;
  }
  return (
    <Box
      {...boxProps}
      alignItems="start"
      border={disabled ? "dark" : "default"}
      color={disabled ? "transparent" : "base"}
      padding={4}
      rounding="md"
    >
      <MultiselectField
        disabled={disabled}
        errorText={answerRequiredErr}
        options={options}
        title={title}
        value={value}
        variant="rightText"
        onChange={async (val: string[]): Promise<void> => {
          if (disabled) {
            return;
          }
          await onChange(val);
          // We do the actual saving in onBlur
          await onBlur(val);
        }}
      />
    </Box>
  );
};
