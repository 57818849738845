import {IsWeb} from "@utils";
import {Text, TextTheme, useTheme} from "ferns-ui";
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  NativeSyntheticEvent,
  Pressable,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from "react-native";
import {ScrollView} from "react-native-gesture-handler";

import {MentionData, MentionInputProps, MentionPartType, Suggestion} from "../types";
import {
  generateValueFromPartsAndChangedText,
  generateValueWithAddedSuggestion,
  getMentionPartSuggestionKeywords,
  getMentionsFromParts,
  isMentionPartType,
  parseValue,
} from "../utils";

const MentionInput: FC<MentionInputProps> = ({
  value,

  onChange,

  partTypes = [],

  inputRef: propInputRef,

  containerStyle,

  taggableUsers = [],

  taggedUsers = [],

  isRoomTag,

  onSelectionChange,

  ...textInputProps
}) => {
  const textInput = useRef<TextInput | null>(null);

  const [selection, setSelection] = useState({start: 0, end: 0});

  const [showSuggestedTags, setShowSuggestedTags] = useState(false);

  const {plainText, parts} = useMemo(() => parseValue(value, partTypes), [value, partTypes]);

  // index that increments or decrements based on arrow key presses when mention suggestions are
  // visible after pressing trigger ('@'). used for styling and taking action on the correct user
  // selected on enter or tab press
  const [selectedSuggestion, setSelectedSuggestion] = useState<number>(0);

  const {theme} = useTheme();

  const mentionTypeMemo = useMemo(() => {
    return partTypes.filter((one) => isMentionPartType(one))[0] as MentionPartType;
  }, [partTypes]);

  // The keyword by trigger is an object with the trigger as a key and the keyword as a value.(i.e.
  // if you type "@abcd", the keywordByTrigger = { @:
  // "abcd" })
  const keywordByTrigger = useMemo(() => {
    return getMentionPartSuggestionKeywords(parts, plainText, selection, partTypes);
  }, [parts, plainText, selection, partTypes]);

  // The keyword is everything you type after the trigger. (i.e., if you type "@abcd",
  // the keyword is "abcd"). the keyword is undefined if no trigger has been typed yet the keyword
  // is an empty string if the trigger has been typed but no keyword has been typed yet We memoize
  // the keyword to use to filter the tagggableUsers when the mention suggestions are showing,
  // and a keyword that !== undefined means the mention suggestions menu should be showing
  const keyword: string | undefined = useMemo(
    () => keywordByTrigger[mentionTypeMemo.trigger],
    [keywordByTrigger, mentionTypeMemo.trigger]
  );

  const taggableUsersMemo = useMemo(() => {
    // Anytime the keyword, taggableUsers, or value changes,
    // we want to reset the selected suggestion index to 0 so the menu isn't stuck on a previous
    // suggestion index that is no longer accurate
    if (selectedSuggestion !== 0) {
      setSelectedSuggestion(0);
    }
    // If there is no text or if the keyword is undefined, we don't want to show any suggestions
    if (!value || keyword === undefined) {
      return;
    }

    // filter out the users already tagged
    const taggableUsersNotYetTagged = taggableUsers.filter(
      (one) => !taggedUsers.find((tagged) => tagged.id === one.id)
    );

    // filter out the room tag option if @room has already been tagged to get available options
    const availableTagOptions = isRoomTag
      ? taggableUsersNotYetTagged.filter((tagOption) => tagOption.name !== "Room")
      : taggableUsersNotYetTagged;

    // A keyword is initiated by a trigger.
    // An empty keyword is acceptable and should show all available tags
    if (keyword === "") {
      setShowSuggestedTags(true);
      return availableTagOptions;
    } else {
      // Filter the taggable users by the keyword
      const filteredTaggableUsers = availableTagOptions.filter((one) =>
        one.name.toLocaleLowerCase().includes((keyword ?? "").toLocaleLowerCase())
      );
      if (filteredTaggableUsers.length > 0) {
        setShowSuggestedTags(true);
      }
      return filteredTaggableUsers;
    }
    // we don't want this to re-run when the selected suggestion changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, taggableUsers, value, taggedUsers, isRoomTag]);

  // Set the initial value. We only want this to run once if there is already a value from local
  // storage
  useEffect(() => {
    if (value) {
      onChangeInput(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange = (
    event: NativeSyntheticEvent<TextInputSelectionChangeEventData>
  ): void => {
    setSelection(event.nativeEvent.selection);

    onSelectionChange && onSelectionChange(event);
  };

  /**
   * Callback that trigger on TextInput text change
   *
   * @param changedText
   */
  const onChangeInput = (changedText: string): void => {
    if (!changedText) {
      onChange(
        generateValueFromPartsAndChangedText(parts, plainText, changedText),
        getMentionsFromParts([])
      );
    } else {
      onChange(
        generateValueFromPartsAndChangedText(parts, plainText, changedText),
        getMentionsFromParts(parts),
        changedText
      );
    }
  };

  /**
   * Callback on mention suggestion press. We should:
   * - Get updated value
   * - Trigger onChange callback with new value
   */
  const onSuggestionPress =
    (mentionType: MentionPartType) =>
    (suggestion: Suggestion): void => {
      const newValue = generateValueWithAddedSuggestion(
        parts,
        mentionType,
        plainText,
        selection,
        suggestion
      );

      if (!newValue) {
        return;
      }

      onChange(newValue, [...getMentionsFromParts(parts), suggestion as Partial<MentionData>]);

      textInput.current?.focus();

      /**
       * Move cursor to the end of just added mention starting from trigger string and including:
       * - Length of trigger string
       * - Length of mention name
       * - Length of space after mention (1)
       *
       * Not working now due to the RN bug
       */
      // const newCursorPosition = currentPart.position.start + triggerPartIndex + trigger.length +
      // suggestion.name.length + 1;

      // textInput.current?.setNativeProps({selection: {start: newCursorPosition, end: newCursorPosition}});
    };

  // This function's logic is copied over from onSuggestionPress,
  // it is called on tab or enter when on web to select a mention suggestion
  const onSuggestionTabOrEnter = useCallback(
    (suggestion: Suggestion): void => {
      const newValue = generateValueWithAddedSuggestion(
        parts,
        // this argument is the hardcoded "mention type"
        // this function will not work with triggers besides `@` as a result
        {
          trigger: "@",
          textStyle: {
            fontWeight: "bold",
            color: "#4a90e2",
          },
          isInsertSpaceAfterMention: true,
        },
        plainText,
        selection,
        suggestion
      );

      if (!newValue) {
        return;
      }

      onChange(newValue, [...getMentionsFromParts(parts), suggestion as Partial<MentionData>]);

      textInput.current?.focus();

      /**
       * Move cursor to the end of just added mention starting from trigger string and including:
       * - Length of trigger string
       * - Length of mention name
       * - Length of space after mention (1)
       *
       * Not working now due to the RN bug
       */
      // const newCursorPosition = currentPart.position.start + triggerPartIndex + trigger.length +
      // suggestion.name.length + 1;

      // textInput.current?.setNativeProps({selection: {start: newCursorPosition, end: newCursorPosition}});
    },
    [onChange, parts, plainText, selection]
  );

  const handleTextInputRef = (ref: TextInput): void => {
    textInput.current = ref as TextInput;

    if (propInputRef) {
      if (typeof propInputRef === "function") {
        propInputRef(ref);
      } else {
        (propInputRef as MutableRefObject<TextInput>).current = ref as TextInput;
      }
    }
  };

  // refactor onKeyPress from the TextInput to here using useCallback
  // to avoid re-rendering
  const handleKeyPress = useCallback(
    (event: any): void => {
      if (showSuggestedTags && taggableUsersMemo?.length) {
        if (
          selectedSuggestion < taggableUsersMemo.length - 1 &&
          IsWeb &&
          event.nativeEvent.key === "ArrowDown"
        ) {
          setSelectedSuggestion(selectedSuggestion + 1);
          event.preventDefault();
        }

        if (selectedSuggestion > 0 && IsWeb && event.nativeEvent.key === "ArrowUp") {
          setSelectedSuggestion(selectedSuggestion - 1);
          event.preventDefault();
        }

        if (IsWeb && (event.nativeEvent.key === "Enter" || event.nativeEvent.key === "Tab")) {
          {
            taggableUsersMemo?.map((one, index) => {
              if (index === selectedSuggestion) {
                onSuggestionTabOrEnter(one);
                event.preventDefault();
              }
            });
          }
          setShowSuggestedTags(false);
          return;
        }
      } else {
        if (textInputProps.onKeyPress) {
          return textInputProps.onKeyPress(event);
        }
      }
    },
    [
      onSuggestionTabOrEnter,
      selectedSuggestion,
      showSuggestedTags,
      taggableUsersMemo,
      textInputProps,
    ]
  );

  return (
    <View style={containerStyle}>
      <View
        style={{
          position: "absolute",
          bottom: (containerStyle as ViewStyle).height,
          backgroundColor: "neutralLight",
        }}
      >
        {/* Mention modal */}
        {showSuggestedTags && (
          <ScrollView
            style={{
              borderRadius: theme.primitives.radiusMd,
              borderWidth: 1,
              borderColor: theme.surface.secondaryDark,
              maxHeight: 200,
            }}
          >
            {taggableUsersMemo?.map((one, index) => {
              const isSelectedSuggestion = index === selectedSuggestion;
              const pressableStyle = isSelectedSuggestion
                ? {padding: 12, backgroundColor: theme.surface.secondaryDark}
                : {padding: 12, backgroundColor: theme.surface.neutralLight};
              const textColor = isSelectedSuggestion ? "inverted" : "primaryDark";
              return (
                <Pressable
                  key={one.id}
                  style={{
                    ...pressableStyle,
                  }}
                  onPress={(): void => {
                    onSuggestionPress(mentionTypeMemo)(one);
                  }}
                >
                  <Text color={textColor as keyof TextTheme}>{one.name}</Text>
                </Pressable>
              );
            })}
          </ScrollView>
        )}
      </View>

      <TextInput
        {...textInputProps}
        ref={handleTextInputRef}
        value={plainText}
        {...(IsWeb ? {selection} : {})}
        onChangeText={onChangeInput}
        onKeyPress={handleKeyPress}
        onSelectionChange={handleSelectionChange}
      />
    </View>
  );
};

export {MentionInput};
