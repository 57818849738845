import {NotificationManager, UserList} from "@components";
import {APPOINTMENT_CONFIG, SCHEDULE_ITEM_DEFAULT_USER_NOTE} from "@constants";
import {
  generateScheduleItemTitle,
  printAddress,
  ScheduleItemType,
  useGetUsersQuery,
  usePostScheduleItemsMutation,
  User,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {isStaff} from "@utils";
import {Box, Button, Heading, Page, printDateRange, TapToEdit, Text, useToast} from "ferns-ui";
import React, {useCallback, useEffect, useState} from "react";

const generateScheduleItemLocation = ({
  type,
  users,
  staff,
}: {
  type: ScheduleItemType;
  users: User[];
  staff: User[];
}): {location: string; helperText: string} => {
  const appointmentConfig = APPOINTMENT_CONFIG[type];
  if (!appointmentConfig) {
    console.warn(`No appointment config found for type: ${type}`);
    return {location: "", helperText: ""};
  }

  let location = "";
  let helperText = "";

  if (appointmentConfig.video) {
    // If this is a clinical intake, we always want to use the guide's link.
    if (type === "Clinical Intake" || type === "Guide Clinical Intake") {
      const guide = staff?.find((s) => s.staffRoles.PatientGuide && Boolean(s.videoChatLink));
      if (guide?.videoChatLink) {
        helperText = `Automatically set to ${guide.name}'s video chat link.`;
        location = guide.videoChatLink;
      }
    } else {
      // In all other cases, grab the first staff with a video chat link.
      const videoStaff = staff?.find((s) => Boolean(s.videoChatLink));
      if (videoStaff?.videoChatLink) {
        helperText = `Automatically set to ${videoStaff.name}'s video chat link.`;
        location = videoStaff.videoChatLink;
      }
    }
  } else if (type === "In Home Onboarding Visit" || type === "In Home Guide Visit") {
    // Grab the first user with an address.
    const selectedAddressUser = users.find((m) => m.address?.address1);
    // For in person, automatically use the address of the first user.
    if (selectedAddressUser) {
      helperText = `Autofilled address of ${selectedAddressUser.name}`;
      location = printAddress(selectedAddressUser.address);
    }
  }

  return {location, helperText};
};

interface CreateIntakeScreenProps extends StaffStackScreenProps<"CreateIntake"> {}

export const CreateIntakeScreen = ({
  navigation,
  route,
}: CreateIntakeScreenProps): React.ReactElement | null => {
  const {slot, userIds, staffIds} = route.params;
  const toast = useToast();
  const [createScheduleItem] = usePostScheduleItemsMutation();
  const [users, setUsers] = useState<User[]>([]);
  const [staff, setStaff] = useState<User[]>([]);
  const [staffNotes, setStaffNotes] = useState("");
  const [userNotes, setUserNotes] = useState(SCHEDULE_ITEM_DEFAULT_USER_NOTE);
  const [notifications, setNotifications] = useState(APPOINTMENT_CONFIG[slot.type]!.reminders);
  const [location, setLocation] = useState(
    slot ? generateScheduleItemLocation({type: slot.type, users, staff}).location : ""
  );

  const {data: userAndStaffData} = useGetUsersQuery({_id: {$in: [...userIds, ...staffIds]}});
  const title = generateScheduleItemTitle(slot.type, users, staff, "NoRepeat");

  // Initial fetch of users and staff
  useEffect(() => {
    if (!userAndStaffData?.data?.length) {
      return;
    }
    const fetchedUsers = userAndStaffData.data.filter((u) => !isStaff(u.type));
    const fetchedStaff = userAndStaffData?.data.filter((u) => isStaff(u.type));
    setUsers(fetchedUsers);
    setStaff(fetchedStaff);
    setLocation(
      generateScheduleItemLocation({type: slot.type, users: fetchedUsers, staff: fetchedStaff})
        .location
    );
  }, [slot.type, userAndStaffData]);

  const onSave = useCallback(async (): Promise<void> => {
    if (slot.type === "Guide Clinical Intake") {
      if (!slot.associatedSlots?.[0]) {
        toast.error("Error scheduling Therapy Clinical Intake, no associated slot found");
        return;
      }
    }

    // Only send user ids and role, instead of the entire user objects.
    // That was causing request too large errors.
    const userIdsMapped = users.map((u) => ({userId: u._id}));
    let staffIdsMapped;
    if (slot.type === "Guide Clinical Intake") {
      // Remove the therapist from the initial staff list for Guide Clinical Intake, they'll
      // be scheduled separately.
      staffIdsMapped = staff
        .filter((s) => !Boolean(s.staffRoles.Therapist))
        .map((u) => ({userId: u}));
    } else {
      staffIdsMapped = staff.map((u) => ({userId: u}));
    }

    await createScheduleItem({
      title,
      type: slot.type,
      startDatetime: slot.startDatetime,
      endDatetime: slot.endDatetime,
      location,
      staff: staffIdsMapped,
      users: userIdsMapped as any,
      staffNotes,
      userNotes,
      notifications: notifications as any,
    })
      .unwrap()
      .catch(toast.catch);

    if (slot.type === "Guide Clinical Intake") {
      // Also schedule the associated slot.
      const associatedSlot = slot.associatedSlots?.[0];
      const therapistStaff = staff.filter((s) => Boolean(s.staffRoles.Therapist));
      const therapistsMapped = therapistStaff.map((u) => ({userId: u._id}));
      const therapyTitle = generateScheduleItemTitle(
        associatedSlot.type,
        users,
        therapistStaff,
        "NoRepeat"
      );

      const res = await createScheduleItem({
        title: therapyTitle,
        type: associatedSlot.type,
        startDatetime: associatedSlot.startDatetime,
        endDatetime: associatedSlot.endDatetime,
        location,
        staff: therapistsMapped as any,
        users: userIdsMapped as any,
        staffNotes,
        userNotes,
        notifications: notifications as any,
      })
        .unwrap()
        .catch(toast.catch);
      if (res) {
        toast.show(`Clinical Intake Appointment scheduled successfully`);
        navigation.pop();
      }
    } else {
      toast.show(`${slot.type} Appointment scheduled successfully`);
      navigation.pop();
    }
  }, [
    createScheduleItem,
    location,
    navigation,
    notifications,
    slot.associatedSlots,
    slot.endDatetime,
    slot.startDatetime,
    slot.type,
    staff,
    staffNotes,
    title,
    toast,
    userNotes,
    users,
  ]);

  if (!slot) {
    return null;
  }

  if (!APPOINTMENT_CONFIG[slot.type]) {
    console.warn(`No config found for appointment type: ${slot.type}`);
    return null;
  }

  let time;
  if (slot.type === "Guide Clinical Intake") {
    if (!slot.associatedSlots?.[0]) {
      console.warn("No associated slot found for Guide Clinical Intake");
      return null;
    }
    time = printDateRange(slot.startDatetime, slot.associatedSlots[0]!.endDatetime, {
      timezone: slot.timezone,
    });
  } else {
    time = printDateRange(slot.startDatetime, slot.endDatetime, {timezone: slot.timezone});
  }

  const saveDisabled = false;

  return (
    <Page maxWidth={800} navigation={navigation} scroll>
      <Box paddingY={4} width="100%">
        <Heading>{title}</Heading>
      </Box>
      <Box marginBottom={2}>
        <Text bold>Time: {time}</Text>
      </Box>
      {Boolean(slot.type === "Guide Clinical Intake") && (
        <Box marginBottom={2}>
          <Text>Note: two events will be created, one for guides and one for the therapist.</Text>
        </Box>
      )}

      <Box maxWidth={400} paddingY={2}>
        <UserList
          familyMember
          patient
          title="Users"
          userIds={users.map((u) => u._id)}
          onChangeUsers={setUsers}
        />
      </Box>

      {/* TODO: check if the staff are free if they change. */}
      <Box maxWidth={400} paddingY={2}>
        <UserList staff title="Staff" userIds={staff.map((u) => u._id)} onChangeUsers={setStaff} />
      </Box>

      <Box maxWidth={400}>
        <Box>
          <Text bold>Notifications</Text>
        </Box>
        <NotificationManager
          attendeeIds={users.map((u) => u._id)}
          notifications={notifications}
          setNotifications={setNotifications}
        />
        <TapToEdit
          helperText={
            slot.type === "In Home Onboarding Visit"
              ? "Address where the onboarding will take place"
              : "Video Link for the meeting."
          }
          setValue={setLocation}
          title="Location"
          type="text"
          value={location}
          onSave={setLocation}
        />
        <TapToEdit
          helperText="Internal notes about the meeting. These will not show up in the user's app."
          setValue={setStaffNotes}
          title="Staff-Facing Notes"
          type="textarea"
          value={staffNotes}
          onSave={setStaffNotes}
        />
        <TapToEdit
          helperText="These will show up in the user's app"
          setValue={setUserNotes}
          title="User-Facing Notes"
          type="textarea"
          value={userNotes}
          onSave={setUserNotes}
        />
        <Box paddingY={4}>
          <Button disabled={saveDisabled} text="Schedule Intake" onClick={onSave} />
        </Box>
      </Box>
    </Page>
  );
};
