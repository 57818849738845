import {Box, Text} from "ferns-ui";
import React from "react";

export const TextCell = ({text}: {text: string}): React.ReactElement => {
  return (
    <Box flex="grow" justifyContent="center">
      <Text>{text}</Text>
    </Box>
  );
};
