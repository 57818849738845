import {UserList} from "@components";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Conversation,
  useGetConversationsByIdQuery,
  usePatchConversationsByIdMutation,
  usePostConversationsMutation,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {BooleanField, Box, Button, Heading, Page, Text, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

// Transform from populated users to user IDs to interact with the API
function transformConversationParticipants(
  conversationParticipants?: Conversation["users"]
): {userId: string}[] {
  return (conversationParticipants ?? []).map((participant) => ({
    userId: participant.userId._id,
  }));
}

// Transform from populated users to user IDs to interact with the API
function transformReferencedUsers(
  referencedUsers?: Conversation["referencedUsers"]
): {userId: string; primary?: boolean}[] {
  return (referencedUsers ?? []).map((user) => ({
    userId: user.userId._id,
    primary: user.primary,
  }));
}

interface ManageConversationsScreenProps
  extends StaffStackScreenProps<"ManageConversationsScreen"> {}

export const ManageConversationsScreen = ({
  route,
  navigation,
}: ManageConversationsScreenProps): ReactElement => {
  const [createConversation, {isLoading: createLoading}] = usePostConversationsMutation();
  const [updateConversation, {isLoading: updateLoading}] = usePatchConversationsByIdMutation();

  const toast = useToast();

  const [name, setName] = useState<string>("");
  const [conversationParticipants, setConversationParticipants] = useState<Conversation["users"]>(
    []
  );
  const [referencedUsers, setReferencedUsers] = useState<Conversation["referencedUsers"]>([]);

  const {data: conversationData} = useGetConversationsByIdQuery(
    route.params.conversationId ? route.params.conversationId : skipToken
  );
  // If we have conversation data, set the name and participants
  useEffect(() => {
    if (!conversationData) {
      return;
    }
    conversationData.name && setName(conversationData.name);
    conversationData.users && setConversationParticipants(conversationData.users);
    conversationData.referencedUsers && setReferencedUsers(conversationData.referencedUsers);
  }, [conversationData]);

  // If we're creating a new conversation, and we have a user to start with, add them to the
  useEffect(() => {
    // we only want to do this if it's a new conversation
    if (!route.params.conversationId && route.params.currentUser) {
      setConversationParticipants([{userId: route.params.currentUser}]);
    }
  }, [route.params.conversationId, route.params.currentUser]);

  const headingTitle = route.params.conversationId ? "Edit Conversation" : "Create Conversation";

  const disableCreateOrSave =
    createLoading || updateLoading || conversationParticipants?.length < 2;

  const handleCreateOrUpdateConversation = useCallback(async (): Promise<void> => {
    try {
      if (conversationData?._id) {
        await updateConversation({
          id: conversationData?._id,
          body: {
            name,
            users: transformConversationParticipants(
              conversationParticipants
            ) as unknown as Conversation["users"],
            referencedUsers: transformReferencedUsers(
              referencedUsers
            ) as unknown as Conversation["referencedUsers"],
          },
        }).unwrap();
      } else {
        await createConversation({
          _id: conversationData?._id,
          name,
          users: transformConversationParticipants(
            conversationParticipants
          ) as unknown as Conversation["users"],
          referencedUsers: transformReferencedUsers(
            referencedUsers
          ) as unknown as Conversation["referencedUsers"],
          type: "Multi",
        }).unwrap();
      }
      toast.show("Conversation saved successfully");
      navigation.pop();
    } catch (error) {
      toast.catch(error, "Unable to save conversation");
    }
  }, [
    conversationData,
    createConversation,
    navigation,
    name,
    referencedUsers,
    updateConversation,
    conversationParticipants,
    toast,
  ]);

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box gap={5}>
        <Box paddingY={2}>
          <Heading size="lg">{headingTitle}</Heading>
        </Box>
        <Box paddingY={2}>
          <Heading size="sm">Conversation Name</Heading>
          <TextField
            value={name}
            onChange={(value): void => {
              setName(value);
            }}
          />
        </Box>

        {/* don't allow editing participants or referenced users unless new convo or editing convo type Multi or AllStaff */}
        {Boolean(
          !conversationData?._id ||
            (conversationData?.type && ["Multi", "AllStaff"].includes(conversationData?.type))
        ) && (
          <Box gap={5}>
            <Box paddingY={2}>
              <Heading size="sm">Conversation Participants</Heading>
              <Text>Staff users who will participate in the conversation.</Text>
              <UserList
                buttonText="Add Staff"
                staff
                userIds={conversationParticipants.map((user) => user.userId._id)}
                userPickerTitle="Conversation Staff"
                onChangeUsers={(users): void => {
                  setConversationParticipants(users.map((user) => ({userId: user})));
                }}
              />
            </Box>
            <Box gap={2} paddingY={2}>
              <Heading size="sm">Referenced Users</Heading>
              <Text>
                Patients and family members whose care will be discussed in the conversation. These
                users do not have access to this conversation. This allows quick access to user
                information and documentation in the UI to the right of the chat.
              </Text>
              <UserList
                buttonText="Add Referenced User"
                familyMember
                patient
                userIds={referencedUsers.map((user) => user.userId._id)}
                userPickerTitle="Referenced Users"
                onChangeUsers={(users): void => {
                  setReferencedUsers(
                    users.map((user) => ({
                      userId: user,
                      primary:
                        referencedUsers.find((u) => u.userId?._id === user._id)?.primary || false,
                    }))
                  );
                }}
              />
            </Box>
            {Boolean(referencedUsers?.length) && (
              <Box gap={2} paddingY={2}>
                <Heading size="sm">Set Primary Conversation</Heading>
                <Text>
                  When toggled on, this conversation is considered the main conversation for this
                  user. Any automated alerting for the patient will go to this conversation. A
                  referenced user should only be primary for one conversation.
                </Text>

                {referencedUsers.map((user, index) => (
                  <Box key={index}>
                    <Text>{userName(user.userId)}</Text>
                    <BooleanField
                      value={user.primary ?? false}
                      onChange={(value: boolean): void => {
                        const updatedReferencedUsers = referencedUsers.map((u) => ({
                          userId: u.userId,
                          // maintain the primary status for all users unless it's the user
                          // we're updating
                          primary: u.userId === user.userId ? value : u.primary,
                        }));
                        setReferencedUsers(updatedReferencedUsers);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        <Box paddingY={4} width={225}>
          <Button
            disabled={disableCreateOrSave}
            loading={createLoading || updateLoading}
            text={conversationData?._id ? "Save Conversation" : "Create Conversation"}
            onClick={handleCreateOrUpdateConversation}
          />
        </Box>
      </Box>
    </Page>
  );
};
