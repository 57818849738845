import {useAnalytics, useLogoutUser, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {linkFitbit, useAppDispatch, useGetFitbitStatusesQuery, User} from "@store";
import {IsMobileDevice, isPatientOrFamilyMember, pageOnError} from "@utils";
import * as WebBrowser from "expo-web-browser";
import {Box, Button, Heading, Page, printDateAndTime, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

import {PatientStackParamList} from "../types";
import {ProfileFields} from "./ProfileFields";
import {ProfileSettings} from "./ProfileSettings";
import {SectionDivider} from "./SectionDivider";
import {UserBioPane} from "./UserBioPane";
import {VersionString} from "./VersionString";

interface PatientProfileProps {}

export const PatientProfile = ({}: PatientProfileProps): ReactElement | null => {
  const navigation = useNavigation<NativeStackScreenProps<PatientStackParamList>["navigation"]>();
  const logEvent = useAnalytics();
  const dispatch = useAppDispatch();
  const user = useReadProfile();
  const logoutUser = useLogoutUser();
  const {data: fitbitStatusList} = useGetFitbitStatusesQuery(
    user?._id && user?.fitbitId ? {userId: user?._id} : skipToken
  );

  const fitbitStatusData = fitbitStatusList?.data?.[0];

  const doLinkFitBit = async (): Promise<void> => {
    const res = await dispatch(linkFitbit());
    const url = (res as any).payload;
    await WebBrowser.openAuthSessionAsync(url, "/");
    await logEvent({
      name: "ConnectFitbit",
      collectionModel: "users",
      isActivityLogEvent: true,
      appliedUserId: user?._id,
    });
  };

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  // Once the user is loaded, set the current user.
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  if (!user || !currentUser || !isPatientOrFamilyMember(user.type)) {
    return null;
  }

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box
        alignItems="center"
        alignSelf="center"
        color="base"
        gap={5}
        justifyContent="center"
        maxWidth={420}
        padding={6}
        width="100%"
      >
        <ProfileFields />

        <SectionDivider />

        <Box width="100%">
          <Heading size="lg">About Me</Heading>
          <UserBioPane userId={user?._id} />
        </Box>

        <SectionDivider />

        <ProfileSettings />

        {Boolean(user.fitbitConfig?.enabled) &&
          (fitbitStatusData ? (
            <Box marginBottom={3} width="100%">
              <Box>
                <Text>Fitbit Successfully Linked!</Text>
                <Box direction="row" justifyContent="start" paddingY={1} width="100%">
                  <Box>
                    <Text bold>Last Sync: </Text>
                  </Box>
                  <Box direction="row">
                    <Box>
                      <Text truncate>
                        {printDateAndTime(fitbitStatusData.lastSync, {showTimezone: true})}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box width="100%">
              <Button
                iconName="link"
                text="Link Fitbit"
                variant="secondary"
                onClick={doLinkFitBit}
              />
            </Box>
          ))}

        <Box marginBottom={3} width="100%">
          <Box paddingY={2}>
            <Text bold size="lg">
              In the event that your care team is currently unavailable:
            </Text>
          </Box>
          <Box paddingY={2} width="100%">
            <Text size="lg">If you are experiencing a medical emergency, please call 911.</Text>
          </Box>
          <Box paddingY={2} width="100%">
            <Text size="lg">
              If you feel that you or someone else is struggling with a mental health emergency,
              please call/text 988.
            </Text>
          </Box>
        </Box>

        <Box width="100%">
          <Button
            confirmationText="Are you sure you want to log out?"
            iconName="right-from-bracket"
            text="Log Out"
            tooltipText={IsMobileDevice ? undefined : "Log out"}
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await logoutUser();
            }}
          />
        </Box>
      </Box>

      <VersionString />
    </Page>
  );
};
