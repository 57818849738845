// This should get moved to Ferns UI

import {Box, Icon, IconButton, Text, useTheme} from "ferns-ui";
import React from "react";
import {Pressable, View} from "react-native";

interface FilterItemProps {
  text?: string;
  editable?: boolean;
  dismissable?: boolean;
  showDelete?: boolean;
  onEdit?: () => void;
  onDismiss?: () => void;
  withConfirmation?: boolean;
  confirmationText?: string;
  children?: React.ReactNode;
}

export const FilterItem: React.FC<FilterItemProps> = ({
  text,
  editable = false,
  dismissable = true,
  showDelete = false,
  onEdit,
  onDismiss,
  withConfirmation = false,
  confirmationText,
  children,
}) => {
  const {theme} = useTheme();

  if (!text && !children) {
    console.warn("FilterItem must have text or children");
  }

  if (dismissable && !onDismiss) {
    console.warn("FilterItem is dismissable but no onDismiss handler was provided");
  }

  if (editable && !onEdit) {
    console.warn("FilterItem is editable but no onEdit handler was provided");
  }

  return (
    <View
      style={{
        alignItems: "center",
        width: "100%",
        gap: theme.spacing.md,
        paddingTop: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
        backgroundColor: theme.surface.base,
        borderRadius: theme.primitives.radiusMd,
        borderWidth: 1,
        borderColor: theme.border.default,
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flexGrow: 1,
          flexShrink: 1,
          gap: theme.spacing.sm,
          flexWrap: "wrap",
        }}
      >
        <View style={{flex: 1}}>
          <Text>{text}</Text>
          {children}
        </View>
      </View>
      <Box alignItems="center" direction="row" gap={2}>
        {editable && (
          <Pressable onPress={onEdit}>
            <Icon color="link" iconName="pencil" />
          </Pressable>
        )}
        {Boolean(dismissable) && (
          <IconButton
            accessibilityLabel={showDelete ? "delete option" : `dismiss ${text ?? "option"}`}
            confirmationText={confirmationText}
            iconName={showDelete ? "trash" : "x"}
            variant="destructive"
            withConfirmation={withConfirmation}
            onClick={() => onDismiss?.()}
          />
        )}
      </Box>
    </View>
  );
};
