import {Text} from "ferns-ui";
import React from "react";
import {StyleSheet, View} from "react-native";

// @ts-ignore
import {IMessage, MessageTextProps} from "./Models";

const styles = {
  left: StyleSheet.create({
    container: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }),
  right: StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    container: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }),
};

export const MessageText = <TMessage extends IMessage = IMessage>({
  currentMessage = {} as TMessage,
  position = "left",
  containerStyle,
}: MessageTextProps<TMessage>): React.ReactElement | null => {
  return (
    <View style={[styles[position].container, containerStyle?.[position]]}>
      <Text color={position === "left" ? "primary" : "inverted"}>{currentMessage!.text}</Text>
    </View>
  );
};
