import {DateTime} from "luxon";
import PropTypes from "prop-types";

import {IMessage} from "./Models";

export const StylePropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.number,
  PropTypes.bool,
]);

export function isSameDay(
  currentMessage: IMessage,
  diffMessage: IMessage | null | undefined
): boolean {
  if (!diffMessage || !diffMessage.createdAt) {
    return false;
  }

  const currentCreatedAt = DateTime.fromISO(currentMessage.createdAt);
  const diffCreatedAt = DateTime.fromISO(diffMessage.createdAt);

  if (!currentCreatedAt.isValid || !diffCreatedAt.isValid) {
    return false;
  }

  return (
    currentCreatedAt.year === diffCreatedAt.year &&
    currentCreatedAt.month === diffCreatedAt.month &&
    currentCreatedAt.day === diffCreatedAt.day
  );
}

export function isSameUser(
  currentMessage: IMessage,
  diffMessage: IMessage | null | undefined
): boolean {
  return !!(
    diffMessage &&
    diffMessage.user &&
    currentMessage.user &&
    diffMessage.user._id === currentMessage.user._id
  );
}
