import {User} from "@store";
import {Box, Text} from "ferns-ui";
import React from "react";

interface TapToEditCheckboxProps {
  checked: boolean;
  description?: string;
  border?: boolean;
  title: string;
  currentUser: User;
  value?: any;
}

// TODO: Enhance TapToEdit with a custom right field to get rid of TapToEditCheckbox/Schedule
export const TapToEditCheckbox = ({
  checked,
  description,
  border,
  title,
}: TapToEditCheckboxProps): React.ReactElement => {
  return (
    <Box
      borderBottom={border ? "default" : undefined}
      direction="row"
      justifyContent="between"
      paddingY={2}
      width="100%"
    >
      <Box flex="grow">
        <Text bold>{title}:</Text>
        {Boolean(description && !checked) && (
          <Text color="secondaryLight" size="sm">
            {description}
          </Text>
        )}
      </Box>
      <Box alignItems="center" direction="row" marginLeft={2}>
        <Box marginLeft={2}>
          <Text>{checked ? "Yes" : "No"}</Text>
        </Box>
      </Box>
    </Box>
  );
};
