import {ModelAdminScreen, StatusReasonsEditor} from "@components";
import {
  useDeleteUserStatusesByIdMutation,
  useGetUserStatusesQuery,
  usePatchUserStatusesByIdMutation,
  usePostUserStatusesMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

import {BadgeColors} from "../constants";

interface Props extends StaffStackScreenProps<"UserStatusAdmin"> {}

export const UserStatusAdminScreen = ({navigation}: Props): React.ReactElement => {
  const colorOptions = BadgeColors.map((color) => ({label: color.label, value: color.label}));

  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this user status from all users before deleting, or the app will behave weirdly."
      description="User Statuses are stages of the patient lifecycle."
      display={(status): {title: string; subtitle?: string} => ({
        title: `${status?.index}: ${status?.name}`,
      })}
      extraFields={[
        (instanceData, setInstanceData): React.ReactElement => (
          <StatusReasonsEditor instanceData={instanceData} setInstanceData={setInstanceData} />
        ),
      ]}
      fields={
        [
          {fieldKey: "name", type: "text", title: "Name"},
          {
            fieldKey: "color",
            type: "select",
            title: "Color",
            options: colorOptions,
          },
          {fieldKey: "index", type: "number", title: "Ordering Index"},
          {
            fieldKey: "isGrowth",
            type: "boolean",
            title: "If the status is part of the growth team's workflow",
          },
          {
            fieldKey: "isCare",
            type: "boolean",
            title: "If a user with this status is considered to be in care.",
          },
          {
            fieldKey: "isInactive",
            type: "boolean",
            title:
              "If a user was but is no longer in the program, due to discharge, being ineligible, etc.",
          },
          {fieldKey: "isBillable", type: "boolean", title: "Is Billable"},
          {fieldKey: "captureReasons", type: "boolean", title: "Capture Reasons"},
        ] as any[]
      }
      navigation={navigation}
      useCreate={usePostUserStatusesMutation}
      useList={useGetUserStatusesQuery}
      useRemove={useDeleteUserStatusesByIdMutation}
      useUpdate={usePatchUserStatusesByIdMutation}
    />
  );
};
