import {MongoDashboard} from "@components";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {StaffStackParamList} from "@types";
import {pageOnError} from "@utils";
import {Box, Page} from "ferns-ui";
import React, {ReactElement} from "react";
import {useWindowDimensions} from "react-native";

export const StatsScreenWeb = (): ReactElement => {
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Stats">>();
  const {width, height} = useWindowDimensions();
  return (
    <Page maxWidth="100%" navigation={navigation} onError={pageOnError}>
      <Box height="100%" width="100%">
        <MongoDashboard
          dashboardId="650b1fd1-a017-4c05-8c49-347b96b2b2ff"
          height={height - 100}
          width={width}
        />
      </Box>
    </Page>
  );
};
