import {MarkdownView} from "@components";
import {latestConsentFormOfType} from "@store";
import {ConsentFormScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Page} from "ferns-ui";
import React from "react";

interface HipaaPolicyScreenProps extends ConsentFormScreenProps<"HIPAA"> {}

export const HipaaPolicyScreen = ({navigation}: HipaaPolicyScreenProps): React.ReactElement => {
  const hipaa = latestConsentFormOfType("hipaa");
  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <MarkdownView>{hipaa?.text}</MarkdownView>
    </Page>
  );
};
