// Note: we will open source this when we get a chance, so there should be no imports from private
// files.
import AsyncStorage from "@react-native-async-storage/async-storage";
import {createListenerMiddleware, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Api, BaseQueryFn, EndpointBuilder} from "@reduxjs/toolkit/query/react";
import {IsWeb, UserTypes} from "@utils";
import * as SecureStore from "expo-secure-store";
import {useSelector} from "react-redux";

import {LOGOUT_ACTION_TYPE, RootState} from "./constants";

type AuthState = {
  userId: string | null;
  error: string | null;
};

export interface UserResponse {
  data: {
    userId: string;
    token: string;
    refreshToken: string;
  };
}

export interface EmailLoginRequest {
  email: string;
  password: string;
}

export interface EmailSignupRequest {
  email: string;
  password: string;
  // Extra data
  [key: string]: any;
}

export interface ResetPasswordRequest {
  _id: string;
  // send to reset password when forgotten
  password?: string;
  // send to change password
  oldPassword?: string;
  newPassword?: string;
}

export interface OnlineToggleRequest {
  _id: string;
  type: string;
  offlineDateTime: Date;
}
export interface OfflineToggleRequest {
  _id: string;
  type: UserTypes.Patient | UserTypes.FamilyMember;
}

export interface UpdateLastReadRequest {
  conversationId: string;
  lastReadDateTime: Date;
}

export interface UpdateConversationMutedRequest {
  conversationId: string;
  muted: boolean;
}

// Define a service using a base URL and expected endpoints
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function generateProfileEndpoints(
  builder: EndpointBuilder<BaseQueryFn<any>, any, string>,
  path: string
) {
  return {
    emailLogin: builder.mutation<UserResponse, EmailLoginRequest>({
      query: ({email, password}) => ({
        url: "auth/login",
        method: "POST",
        body: {email, password},
      }),
      extraOptions: {maxRetries: 0},
      invalidatesTags: [path],
    }),
    emailSignUp: builder.mutation<UserResponse, EmailSignupRequest>({
      query: ({email, password, ...body}) => ({
        url: `auth/signup`,
        method: "POST",
        body: {email, password, ...body},
      }),
      invalidatesTags: [path],
    }),
    // This is a slightly different version of emailSignUp for creating another user using the
    // auth/signup endpoint. This is useful for things like creating a user from an admin account.
    // Unlike emailSignUp, this doesn't log in as the user.
    createEmailUser: builder.mutation<UserResponse, EmailSignupRequest>({
      query: ({email, password, ...body}) => ({
        url: `auth/signup`,
        method: "POST",
        body: {email, password, ...body},
      }),
      invalidatesTags: [path, "conversations"],
    }),
    resetPassword: builder.mutation<UserResponse, ResetPasswordRequest>({
      query: ({_id, password, oldPassword, newPassword, ...body}) => ({
        url: `/resetPassword`,
        method: "POST",
        body: {_id, password, oldPassword, newPassword, ...body},
      }),
      extraOptions: {maxRetries: 0},
    }),
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateAuthSlice = (api: Api<any, any, any, any, any>) => {
  const authSlice = createSlice({
    name: "auth",
    initialState: {userId: null, error: null} as AuthState,
    reducers: {
      setUserId: (state, {payload: {userId}}: PayloadAction<{userId: string}>) => {
        state.userId = userId;
      },
      logout: (state) => {
        state.userId = null;
      },
    },

    extraReducers: (builder) => {
      builder.addMatcher(api.endpoints.emailLogin.matchFulfilled, () => {
        console.debug("Login success");
      });
      builder.addMatcher(
        api.endpoints.emailLogin.matchRejected,
        (state, action: PayloadAction<{data: any}>) => {
          state.error = action.payload?.data?.message;
          console.debug("Login rejected", action.payload?.data?.message);
        }
      );
      builder.addMatcher(api.endpoints.emailLogin.matchPending, (state) => {
        state.error = null;
        console.debug("Login pending");
      });
      builder.addMatcher(api.endpoints.emailSignUp.matchFulfilled, () => {
        console.debug("Signup success");
      });
      builder.addMatcher(
        api.endpoints.emailSignUp.matchRejected,
        (state, action: PayloadAction<{data: any}>) => {
          state.error = action.payload?.data?.message;
          console.debug("Signup rejected", action.payload);
        }
      );
      builder.addMatcher(api.endpoints.emailSignUp.matchPending, (state) => {
        state.error = null;
        console.debug("Signup pending");
      });
    },
  });
  // Since we need to do async actions to store tokens in expo-secure-store,
  // we need to use a listener middleware.
  const loginListenerMiddleware = createListenerMiddleware();
  loginListenerMiddleware.startListening({
    // TODO this listener needs to be made generic to open source ferns-rtk.
    type: "flourishApi/executeMutation/fulfilled",
    effect: async (action: any, listenerApi) => {
      if (
        action.payload?.token &&
        (action.meta?.arg?.endpointName === "emailLogin" ||
          action.meta?.arg?.endpointName === "emailSignUp")
      ) {
        if (!IsWeb) {
          if (!action.payload.token) {
            console.error("No token found in app login response.");
            return;
          }
          try {
            await SecureStore.setItemAsync("AUTH_TOKEN", action.payload.token);
            await SecureStore.setItemAsync("REFRESH_TOKEN", action.payload.refreshToken);
            console.debug("Saved auth token to secure storage.");
          } catch (error) {
            console.error(`Error setting auth token: ${error}`);
            throw error;
          }
        } else {
          if (!action.payload.token) {
            console.error("No token found in web login response.");
            return;
          }
          // On web, we don't have secure storage, and cookie support is not in Expo yet,
          // so this is what we're left with. This can be vulnerable to XSS attacks.
          try {
            await AsyncStorage.setItem("AUTH_TOKEN", action.payload.token);
            await AsyncStorage.setItem("REFRESH_TOKEN", action.payload.refreshToken);
            console.debug("Saved auth token to async storage.");
          } catch (error) {
            console.error(`Error setting auth token: ${error}`);
            throw error;
          }
        }
        listenerApi.dispatch(authSlice.actions.setUserId({userId: action.payload.userId}));
      }
    },
  });

  // const clearLocalStorage = async (): Promise<void> => {
  //   try {
  //     const keys = await AsyncStorage.getAllKeys();
  //     const keysToRemove = keys.filter((key) => key.includes("formInstance"));
  //     if (keysToRemove.length > 0) {
  //       await AsyncStorage.multiRemove(keysToRemove);
  //       console.debug("Cleared local storage.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  // Since we need to do async actions to store tokens in expo-secure-store,
  // we need to use a listener middleware.
  const logoutListenerMiddleware = createListenerMiddleware();
  logoutListenerMiddleware.startListening({
    type: LOGOUT_ACTION_TYPE,
    effect: async () => {
      // TODO: We should only clear local storage when we're logging out, not disconnected.
      // await clearLocalStorage();
      if (!IsWeb) {
        await SecureStore.deleteItemAsync("AUTH_TOKEN");
        await SecureStore.deleteItemAsync("REFRESH_TOKEN");
      } else {
        await AsyncStorage.removeItem("AUTH_TOKEN");
        await AsyncStorage.removeItem("REFRESH_TOKEN");
      }
      console.debug("Cleared auth token from secure storage as part of logout.");
    },
  });
  return {
    middleware: [logoutListenerMiddleware.middleware, loginListenerMiddleware.middleware],
    authSlice,
    authReducer: authSlice.reducer,
    logout: authSlice.actions.logout,
    setUserId: authSlice.actions.setUserId,
  };
};

export const selectCurrentUserId = (state: RootState): string | undefined => state.auth?.userId;

export const useSelectCurrentUserId = (): string | undefined => {
  return useSelector((state: RootState): string | undefined => {
    return state.auth?.userId;
  });
};

export async function getAuthToken(): Promise<string | null> {
  let token;

  if (!IsWeb) {
    token = await SecureStore.getItemAsync("AUTH_TOKEN");
  } else {
    token = await AsyncStorage.getItem("AUTH_TOKEN");
  }
  return token;
}
