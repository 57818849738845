import {skipToken} from "@reduxjs/toolkit/query/react";
import {ActivityLogEvent, useGetAuditLogQuery} from "@store";
import {IsMobileDevice} from "@utils";
import {BooleanField, Box, Button, printDateAndTime, Text} from "ferns-ui";
import React, {useEffect, useState} from "react";

interface ActivityLogParams {
  userId: string;
}

interface ActivityLogCardParams {
  item: ActivityLogEvent;
}

const ActivityLogCard = ({item}: ActivityLogCardParams): React.ReactElement => {
  const hasChanges = Boolean(item.payload?.prevValue) || Boolean(item.payload?.newValue);
  // Skip showing the previous value for forms, unless it's a system automation event (such as risk
  // manager alert)
  const shouldShowChanges =
    hasChanges && (!item.payload?.formName || item.authUserType === "SystemAutomation");

  return (
    <Box border="default" gap={2} padding={2} rounding="md">
      <Box direction="column">
        <Text bold size="lg">
          {item.description}
        </Text>
      </Box>
      {Boolean(shouldShowChanges) && (
        <Box alignContent="center" justifyContent="center" paddingX={2} width="100%">
          {Boolean(item?.payload?.prevValue) && (
            <Box marginBottom={2}>
              <Text bold>Previous value:</Text>
              <Text>{item?.payload?.prevValue || ""}</Text>
            </Box>
          )}
          <Box>
            <Text bold>New value: </Text>
            <Text>{item?.payload?.newValue || ""}</Text>
          </Box>
        </Box>
      )}
      <Text align="right">{printDateAndTime(item.created)}</Text>
    </Box>
  );
};

export const ActivityLogView = ({userId}: ActivityLogParams): React.ReactElement | null => {
  const [localPage, setLocalPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [combinedData, setCombinedData] = useState<ActivityLogEvent[]>([]);

  const paginateResponse = useGetAuditLogQuery(
    userId && hasMore
      ? {
          isActivityLogEvent: true,
          appliedUserId: userId,
          page: localPage,
        }
      : skipToken
  );
  const {data: paginatedData = [], page: remotePage = 1, more} = paginateResponse?.data || {};
  const [showUserUpdateLog, setShowUserUpdateLog] = React.useState<boolean>(false);

  const filteredActivityLogItems = combinedData?.filter((a) => a.isUserUpdateEvent);

  // Set has more, combine data from the server with the local data, and update the local page
  useEffect(() => {
    // If the response is successful and indicates there are more remotes pages of data,
    // update the hasMore boolean to load more data when prompted
    if (paginateResponse.isSuccess && more !== undefined) {
      setHasMore(more);
    }
    if (combinedData.length !== paginatedData.length) {
      setCombinedData(paginatedData);
    }
  }, [more, paginateResponse, combinedData, paginatedData]);

  const loadEarlier = (): void => {
    if (hasMore && paginateResponse.isSuccess && Number(remotePage) === localPage) {
      setLocalPage(Number(remotePage) + 1);
      setCombinedData([...combinedData, ...paginatedData]);
    }
  };

  if (combinedData?.length === 0) {
    return (
      <Box alignItems="center" justifyContent="center" padding={4}>
        <Text bold>You&apos;re all caught up!</Text>
      </Box>
    );
  }
  return (
    <Box>
      <Box margin={2} width={IsMobileDevice ? "50%" : "40%"}>
        <BooleanField
          title="Show User Updates"
          value={showUserUpdateLog}
          onChange={(val): void => setShowUserUpdateLog(val)}
        />
      </Box>
      <Box gap={4}>
        {(showUserUpdateLog ? filteredActivityLogItems : combinedData)?.map((item) => (
          <ActivityLogCard key={item._id} item={item} />
        ))}
      </Box>
      {Boolean(hasMore) && (
        <Box alignSelf="center" padding={2} width="25%">
          <Button text="Load Earlier" onClick={loadEarlier} />
        </Box>
      )}
    </Box>
  );
};
