import {useReadProfile} from "@hooks";
import {passwordHasErrors, User, useResetPasswordMutation} from "@store";
import {StaffStackScreenProps} from "@types";
import {isStaff, isSuperUser, pageOnError} from "@utils";
import {Box, Button, Heading, Page, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface StaffResetPasswordProps extends StaffStackScreenProps<"ResetPassword"> {}

export const StaffResetPasswordScreen = ({
  route,
  navigation,
}: StaffResetPasswordProps): ReactElement => {
  const [resetPassword, {isLoading}] = useResetPasswordMutation();
  const [password, setPassword] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(route.params?.user ?? null);
  const currentUser = useReadProfile();
  const toast = useToast();

  const submit = async (): Promise<void> => {
    if (!selectedUser) {
      toast.error("Please select a user");
      return;
    }

    const validationError = passwordHasErrors(selectedUser?.type, password);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    let err;
    await resetPassword({
      password,
      _id: selectedUser?._id,
    } as any)
      .unwrap()
      .catch((error: any) => {
        err = error?.data?.title;
        toast.catch("Error resetting password", error);
        return;
      });
    if (!err) {
      navigation.pop();
    }
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={3}>
        <Heading size="lg">Reset password</Heading>
      </Box>
      {Boolean(selectedUser?.name) && (
        <Box paddingY={2}>
          <Heading size="sm">{selectedUser?.name ? `User: ${selectedUser?.name}` : ""}</Heading>
        </Box>
      )}
      <Button
        iconName={selectedUser?._id ? "pencil" : "plus"}
        text={selectedUser?._id ? "Edit User Selection" : "Select User"}
        onClick={(): void => {
          navigation.navigate("UserPicker", {
            patient: true,
            familyMember: true,
            staff: true,
            onSelect: setSelectedUser,
            userFilter:
              currentUser && !isSuperUser(currentUser)
                ? (u: User | null): boolean =>
                    Boolean(isStaff(u?.type) || u?._id === currentUser?._id)
                : undefined,
          });
        }}
      />
      {Boolean(selectedUser) && (
        <>
          <Box maxWidth={400} paddingY={2}>
            <TextField
              title="New Password"
              value={password}
              onChange={(value): void => {
                setPassword(value);
              }}
            />
          </Box>
          <Box maxWidth={175} paddingY={2}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Reset Password"
              onClick={submit}
            />
          </Box>
        </>
      )}
    </Page>
  );
};
