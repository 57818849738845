import {Link} from "ferns-ui";
import React from "react";

export const AppStoreLink = (): React.ReactElement => {
  return (
    <Link
      href="https://apps.apple.com/us/app/flourish-health/id1633281339"
      text="Flourish Health on App Store"
    />
  );
};
