import {TagDescription} from "@reduxjs/toolkit/query";
import {flourishApi, TagType, useAppDispatch} from "@store";
import {useCallback} from "react";

// Uses RTK Query's invalidateTags to mark cached data as "out of date" after an API call.
// Triggering an automatic refetch from the server the next time the data is accessed,
// ensuring up-to-date data.

// Possible operation types include: "delete" | "invalidate" | "update" | "replace" | "insert" |
// "drop" | "dropDatabase" | "rename" Currently only were only invalidating in response to "insert"
// and "update" events, and "update" covers the delete case as well since we do soft deletes.
// see useSocket.ts
export function useInvalidateTags(): (tags: TagDescription<TagType>[]) => void {
  const dispatch = useAppDispatch();
  // Memoize the invalidateTags function. The dependency array [dispatch] ensures that
  // invalidateTags is consistent across re-renders,
  // as long as the dispatch function from useAppDispatch remains the same.
  const invalidateTags = useCallback(
    (tags: TagDescription<TagType>[]) => {
      dispatch(flourishApi.util.invalidateTags(tags));
    },
    [dispatch]
  );

  return invalidateTags;
}
