import {PreClinicUpdateEditor, ReadOnlyUpdateCard, SocketContext} from "@components";
import {useReadProfile} from "@hooks";
import {useFocusEffect} from "@react-navigation/native";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  PreClinicUpdate,
  useGetPreClinicUpdatesQuery,
  useGetUsersQuery,
  usePatchPreClinicUpdatesByIdMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {IsMobileDevice} from "@utils";
import {Box, Button, Card, Page, SelectField, Text} from "ferns-ui";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";

interface Props extends StaffStackScreenProps<"PreClinicUpdates"> {}

interface PreClinicUpdateCardProps {
  preClinicUpdate?: PreClinicUpdate;
  psychiatristId: string;
  readOnly?: boolean;
  creatingUpdate?: boolean;
  onFinishEdit?: () => void;
}

const styles = {
  mobile: {
    psychiatristSelectorWidth: "100%",
    datePickerWidth: "100%",
    createUpdateButtonWidth: "100%",
  },
  web: {psychiatristSelectorWidth: "33%", datePickerWidth: "33%", createUpdateButtonWidth: "25%"},
};

export const PreClinicUpdateCard = ({
  readOnly = false,
  preClinicUpdate,
  psychiatristId,
  creatingUpdate = false,
  onFinishEdit = (): void => {},
}: PreClinicUpdateCardProps): ReactElement => {
  const [editing, setEditing] = useState<boolean>(creatingUpdate ?? false);

  const handleCloseEditor = useCallback((): void => {
    setEditing(false);
    onFinishEdit();
  }, [onFinishEdit, setEditing]);

  return (
    <Card marginBottom={5} padding={5}>
      {editing && (
        <PreClinicUpdateEditor
          preClinicUpdate={preClinicUpdate}
          psychiatristId={psychiatristId}
          onClose={handleCloseEditor}
        />
      )}
      {!editing && preClinicUpdate && (
        <ReadOnlyUpdateCard
          preClinicUpdate={preClinicUpdate}
          readOnly={readOnly}
          onStartEdit={() => setEditing(true)}
        />
      )}
    </Card>
  );
};

export const PreClinicUpdateScreen = ({navigation}: Props): React.ReactElement => {
  const currentUser = useReadProfile();
  const isPsychiatrist = currentUser?.staffRoles.Psychiatrist;
  const [selectedPsychiatristId, setSelectedPsychiatristId] = useState<string | undefined>(
    isPsychiatrist ? currentUser._id : undefined
  );
  const [creatingUpdate, setCreatingUpdate] = useState<boolean>(false);
  const [updatePreClinicUpdate] = usePatchPreClinicUpdatesByIdMutation();
  const {data: userData} = useGetUsersQuery({"staffRoles.Psychiatrist": true});
  const [localPage, setLocalPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [combinedData, setCombinedData] = useState<PreClinicUpdate[]>([]);
  const paginateResponse = useGetPreClinicUpdatesQuery(
    selectedPsychiatristId
      ? {
          ownerId: selectedPsychiatristId,
          page: localPage,
        }
      : skipToken
  );

  const isUninitialized = paginateResponse.isUninitialized;

  const {data: paginatedData = [], page: remotePage = 1, more} = paginateResponse?.data || {};

  const {socket} = useContext(SocketContext);

  // When a preclinic update is deleted, remove it from the combined data.
  useEffect(() => {
    const onDeleteEvent = (data: any): void => {
      if (data.type === "delete" && data.collection === "preclinicupdates") {
        setCombinedData((prev) => {
          return prev.filter((update) => update._id !== data._id);
        });
      }
    };

    socket?.on("changeEvent", onDeleteEvent);

    return (): void => {
      socket?.off("changeEvent", onDeleteEvent);
    };
  }, [setCombinedData, socket]);

  // Set has more, combine data from the server with the local data, and update the local page
  useEffect(() => {
    // Once the update fetch is completed, if there are no more results,
    // stop trying to fetch more updates.
    if (paginateResponse.isSuccess && more !== undefined) {
      setHasMore(more);
    }

    // We clear out combined data when we switch psychiatrists
    if (combinedData.length && combinedData[0].ownerId !== selectedPsychiatristId) {
      setCombinedData([]);
    }

    // Ensure paginated data comes before combined data in the uniqBy so we get the newer
    // paginated data, rather than the potentially stale data in combinedData.
    const uniquePreClinicUpdates = uniqBy(
      [...(paginatedData ?? []), ...(combinedData ?? [])],
      "_id"
    );
    if (
      !creatingUpdate &&
      (uniquePreClinicUpdates.length !== combinedData?.length ||
        uniquePreClinicUpdates?.[0]?.updated !== combinedData?.[0]?.updated)
    ) {
      setCombinedData((previousData) => {
        return orderBy(
          uniqBy([...(paginatedData ?? []), ...(previousData ?? [])], "_id"),
          "created",
          "desc"
        );
      });
    }

    // We remove combinedData from the dependencies because it causes an infinite loop of requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginateResponse.isSuccess, more, paginatedData, localPage, creatingUpdate]);

  // Mark all updates as read when the screen is focused and the user is a psychiatrist
  useFocusEffect(
    useCallback(() => {
      if (!isUninitialized) {
        const markRead = async (): Promise<void> => {
          const unread = combinedData.filter((update) => !update.readByOwner);
          const promises = unread.map((update) => {
            return updatePreClinicUpdate({
              id: update._id,
              body: {
                readByOwner: true,
              },
            });
          });
          await Promise.all(promises);
        };

        if (isPsychiatrist && combinedData.length > 0) {
          void markRead();
        }
      }
    }, [combinedData, updatePreClinicUpdate, isPsychiatrist, isUninitialized])
  );

  const loadEarlier = (): void => {
    if (hasMore && paginateResponse.isSuccess && Number(remotePage) === localPage) {
      setLocalPage(Number(remotePage) + 1);
    }
  };

  return (
    <Page navigation={navigation} scroll>
      <Box gap={4} paddingY={2}>
        {!isPsychiatrist && (
          <>
            <Box
              width={
                IsMobileDevice
                  ? styles.mobile.psychiatristSelectorWidth
                  : styles.web.psychiatristSelectorWidth
              }
            >
              <SelectField
                options={userData?.data?.map((psy) => ({value: psy._id, label: psy.name})) || []}
                placeholder="Select Psychiatrist..."
                requireValue={false}
                value={selectedPsychiatristId}
                onChange={(val: string | undefined) => {
                  setSelectedPsychiatristId(val);
                  setCreatingUpdate(false);
                  setLocalPage(1);
                }}
              />
            </Box>
            {Boolean(selectedPsychiatristId) && (
              <Box
                width={
                  IsMobileDevice
                    ? styles.mobile.createUpdateButtonWidth
                    : styles.web.createUpdateButtonWidth
                }
              >
                <Button text="+ Create New Update" onClick={() => setCreatingUpdate(true)} />
              </Box>
            )}
            {creatingUpdate && (
              <PreClinicUpdateCard
                creatingUpdate
                psychiatristId={selectedPsychiatristId || ""}
                onFinishEdit={() => setCreatingUpdate(false)}
              />
            )}
          </>
        )}
        {Boolean(selectedPsychiatristId) &&
          combinedData?.map((update: PreClinicUpdate) => (
            <PreClinicUpdateCard
              key={update._id}
              preClinicUpdate={update}
              psychiatristId={selectedPsychiatristId || ""}
              readOnly={isPsychiatrist}
            />
          ))}
        {isPsychiatrist && combinedData?.length === 0 && (
          <Box alignItems="center" justifyContent="center" padding={4}>
            <Text bold size="lg">
              There are no updates available.
            </Text>
          </Box>
        )}
        {Boolean(hasMore) && (
          <Box alignSelf="center" padding={2} width="25%">
            <Button text="Load Earlier" onClick={loadEarlier} />
          </Box>
        )}
      </Box>
    </Page>
  );
};
