import {
  ONLINE_PRESENCE_TIMEOUT_MILLISECONDS,
  ONLINE_PRESENCE_UPDATE_INTERVAL_MILLISECONDS,
} from "@constants";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useGetUserSessionsQuery,
  usePatchUserSessionsByIdMutation,
  usePostUserSessionsMutation,
} from "@store";
import {isStaff} from "@utils";
import {DateTime} from "luxon";
import {useCallback, useEffect, useRef} from "react";
import {useIdleTimer} from "react-idle-timer";

import {useReadProfile} from "./useReadProfile";

// In the future we may want to limit this to when AUTH_DEBUG is true, but to help us track Sentry
// errors, enabling this for everyone.
const log = (s: string): void => console.debug(`[online] ${s}`);

export const useOnlinePresenceDetection = (): void => {
  const profile = useReadProfile();
  const {data: userSessionsData} = useGetUserSessionsQuery(
    profile ? {ownerId: profile._id} : skipToken
  );
  const userSession = userSessionsData?.data?.[0];
  const [createUserSessions] = usePostUserSessionsMutation();
  const [updateUserSessions] = usePatchUserSessionsByIdMutation();

  // for all staff members, we want to update the lastOnlineWeb time immediately on mount in
  // addition to setting an interval to update at a constant interval while the user is online.
  // We only want this to run once, when the profile and userSession is loaded
  useEffect(() => {
    if (!profile || !isStaff(profile?.type)) {
      return;
    }
    // Once we've loaded userSessions, create a user sessions document for the user if it does not
    // exist
    if (userSessionsData?.data?.length === 0) {
      log("Creating user session");
      void createUserSessions({
        ownerId: profile._id!,
        lastOnlineWeb: new Date().toISOString(),
      });
    } else if (userSession) {
      updateSession();
      interval.current = setInterval(updateSession, ONLINE_PRESENCE_UPDATE_INTERVAL_MILLISECONDS);
    }
    return (): void => {
      clearInterval(interval.current as any);
    };

    // We only want this to run once when profile and userSessions are loaded,
    // onPresenceChange handles the rest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession?._id, profile?._id]);

  // We need to access in both the use effect and the activity state changes
  const interval = useRef<NodeJS.Timer | undefined>();

  // handle state changes between idle and active
  const onPresenceChange = async (presence: any): Promise<void> => {
    log(`Presence change: ${presence?.type}`);
    // ensure profile and userSessions are loaded and that user is staff before executing
    if (!isStaff(profile?.type) || !userSession) {
      return;
    }

    // if changing from idle to active, update last online immediately,
    // and set interval to update at constant interval
    if (presence.type === "active") {
      log(`tab became active ${DateTime.now().toISO()}`);
      updateSession();
      interval.current = setInterval(updateSession, ONLINE_PRESENCE_UPDATE_INTERVAL_MILLISECONDS);
    } else if (presence.type === "idle") {
      log(`tab became idle ${DateTime.now().toISO()}`);
      // if changing from active to idle, clear interval so lastOnlineWeb stops updating
      clearInterval(interval.current as any);
    }
  };

  const {isLeader} = useIdleTimer({
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    // name is required to use more than one timer
    name: "online_presence",
    timeout: ONLINE_PRESENCE_TIMEOUT_MILLISECONDS,
    throttle: 1000,
    syncTimers: 1000,
    crossTab: true,
    // allows one tab to be the "leader" so we don't update the user session in multiple tabs
    leaderElection: true,
    onPresenceChange,
  });

  const updateSession = useCallback(() => {
    if (!userSession || !isLeader()) {
      log(`Not updating user session (leader: ${isLeader()})`);
      return;
    }

    log(`Updating user session (leader: ${isLeader()}) ${new Date().toISOString()}`);
    void updateUserSessions({
      id: userSession._id,
      body: {
        lastOnlineWeb: new Date().toISOString(),
      },
    });
  }, [isLeader, updateUserSessions, userSession]);
};
