import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  ExplorerAlerts,
  separateOnUpperCase,
  StaffRoles,
  useGetAlertsExplorerDataQuery,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {
  BooleanField,
  Box,
  Button,
  DateTimeField,
  MultiselectField,
  SelectField,
  Spinner,
  Text,
  useToast,
} from "ferns-ui";
import React, {createContext, useContext, useEffect, useState} from "react";
import {Dimensions} from "react-native";

import {CarePodDropdown} from "./CarePodDropdown";

export type AlertsExplorerNav = NativeStackNavigationProp<
  StaffStackParamList,
  "AlertsExplorer",
  undefined
>;

export interface AlertsExplorerColumn {
  title: string;
  width: number;
  sort?: string;
  Component: (alert: ExplorerAlerts) => React.ReactElement;
}

// Define the interface for your data
export interface AlertsExplorerContextType {
  userAlertInstances: ExplorerAlerts[];
  users?: User[];
  total: number;
  isFetching: boolean;
  sort?: [string, "asc" | "desc"] | undefined;
  page: number;
  testUsersFilter?: boolean;
  resolvedFilter?: string;
  alertTypeFilter?: string[];
  userRolesFilter?: (StaffRoles | "Self")[];
  isPatientOrCaregiverFilter?: string;
  carePodFilter?: string;
  isPastDueFilter?: boolean;
  createdDateRangeFilter?: {before: string; after: string};

  setSort: (sort: [string, "asc" | "desc"] | undefined) => void;
  setPage: (page: number) => void;
  setTestUsersFilter: (testUsers: boolean) => void;
  setResolvedFilter: (resolved: string) => void;
  setAlertTypeFilter: (alertType: string[]) => void;
  setUserRolesFilter: (userRole: (StaffRoles | "Self")[]) => void;
  setIsPatientOrCaregiverFilter: (isPatientOrCaregiver: string) => void;
  setCarePodFilter: (id: string) => void;
  setIsPastDueFilter: (pastDue: boolean) => void;
  setCreatedDateRangeFilter: (createdDateRange: {before: string; after: string}) => void;
}

// Create a context for your data
export const AlertsExplorerContext = createContext<AlertsExplorerContextType>({
  users: [],
  userAlertInstances: [],
  isFetching: false,
  page: 1,
  total: 0,
  testUsersFilter: false,
  resolvedFilter: "",
  alertTypeFilter: [],
  userRolesFilter: [],
  isPatientOrCaregiverFilter: "",
  carePodFilter: "",
  isPastDueFilter: false,
  createdDateRangeFilter: {before: "", after: ""},
  setSort: () => {},
  setPage: () => {},
  setTestUsersFilter: () => {},
  setResolvedFilter: () => {},
  setAlertTypeFilter: () => {},
  setUserRolesFilter: () => {},
  setIsPatientOrCaregiverFilter: () => {},
  setCarePodFilter: () => {},
  setIsPastDueFilter: () => {},
  setCreatedDateRangeFilter: () => {},
});

export function useAlertsExplorerContext(): AlertsExplorerContextType {
  return useContext(AlertsExplorerContext);
}

export const AlertsExplorerController = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const toast = useToast();

  const [testUsersFilter, setTestUsersFilter] = useState(false);
  const [sort, setSort] = useState<[string, "asc" | "desc"] | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [resolvedFilter, setResolvedFilter] = useState<string>();
  const [alertTypeFilter, setAlertTypeFilter] = useState<string[]>([]);
  const [userRolesFilter, setUserRolesFilter] = useState<(StaffRoles | "Self")[]>([]);
  const [isPatientOrCaregiverFilter, setIsPatientOrCaregiverFilter] = useState<string | undefined>(
    undefined
  );
  const [carePodFilter, setCarePodFilter] = useState<string>("");
  const [isPastDueFilter, setIsPastDueFilter] = useState<boolean>(false);
  const [createdDateRangeFilter, setCreatedDateRangeFilter] = useState<
    {before: string; after: string} | undefined
  >(undefined);

  const alertsQuery = {
    page,
    limit: 50,
    ...(testUsersFilter && {testUserFilter: testUsersFilter}),
    ...(resolvedFilter !== undefined && {resolvedFilter}),
    ...(alertTypeFilter.length > 0 && {alertTypeFilter}),
    ...(isPatientOrCaregiverFilter && {isPatientOrCaregiverFilter}),
    ...(isPastDueFilter && {isPastDueFilter}),
    ...(createdDateRangeFilter && {createdDateRangeFilter}),
    ...(carePodFilter && {carePodFilter}),
    ...(userRolesFilter.length > 0 && {userRolesFilter}),
    ...(sort && {sort: {[sort[0]]: sort[1] === "asc" ? 1 : -1}}),
  };
  const {
    data: userAlertInstancesData,
    isFetching: alertFetching,
    refetch: refetchAlertsExplorerData,
    isUninitialized,
  } = useGetAlertsExplorerDataQuery(alertsQuery);

  // Refetch data when the component mounts to avoid stale data
  useEffect(() => {
    if (!isUninitialized) {
      refetchAlertsExplorerData().catch((error) =>
        toast.catch(error, "Error refetching alerts data")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized]);

  const userAlertInstances = userAlertInstancesData?.data || [];

  const isFetching = alertFetching;

  const total = (userAlertInstancesData as any)?.total || 0;

  // Using alert types found in the data to populate the alert type filter
  const alertTypes = (userAlertInstancesData as any)?.uniqueAlertTypes || [];

  const windowHeight = Dimensions.get("window").height;

  // Reset all filters
  const clearFilters = (): void => {
    setTestUsersFilter(false);
    setCarePodFilter("");
    setResolvedFilter(undefined);
    setAlertTypeFilter([]);
    setUserRolesFilter([]);
    setIsPatientOrCaregiverFilter("");
    setIsPastDueFilter(false);
    setCreatedDateRangeFilter(undefined);
    setPage(1);
  };

  return (
    <AlertsExplorerContext.Provider
      value={{
        resolvedFilter,
        alertTypeFilter,
        userRolesFilter,
        isPatientOrCaregiverFilter,
        carePodFilter,
        testUsersFilter,
        isPastDueFilter,
        sort,
        createdDateRangeFilter,
        page,
        isFetching,
        userAlertInstances,
        total,

        // Setters
        setTestUsersFilter,
        setSort,
        setCarePodFilter,
        setPage,
        setResolvedFilter,
        setAlertTypeFilter,
        setUserRolesFilter,
        setIsPatientOrCaregiverFilter,
        setIsPastDueFilter,
        setCreatedDateRangeFilter,
      }}
    >
      <Box direction="row" maxHeight="100%">
        <Box flex="grow" paddingX={2} width="25%">
          <Box marginBottom={7} width="50%">
            <Button text="Clear Filters" onClick={clearFilters} />
          </Box>

          <Box flex="grow" height={windowHeight} scroll>
            <Box marginBottom={2} padding={0}>
              <SelectField
                options={[
                  {label: "Resolved", value: "true"},
                  {label: "Unresolved", value: "false"},
                ]}
                placeholder="---"
                requireValue={false}
                title="Alert Resolved"
                value={resolvedFilter ?? ""}
                onChange={(value) => {
                  setResolvedFilter(value);
                  setPage(1);
                }}
              />
            </Box>
            <Box marginBottom={2} padding={0}>
              <SelectField
                options={[
                  {label: "Patient", value: "Patient"},
                  {label: "Caregiver", value: "FamilyMember"},
                ]}
                placeholder="---"
                requireValue={false}
                title="Patient / Caregiver"
                value={isPatientOrCaregiverFilter ?? ""}
                onChange={(value) => {
                  setIsPatientOrCaregiverFilter(value);
                  setPage(1);
                }}
              />
            </Box>
            <Box marginBottom={2} padding={0}>
              <CarePodDropdown
                title="Care Pod"
                value={carePodFilter}
                onChange={(id) => {
                  setCarePodFilter(id ?? "");
                  setPage(1);
                }}
              />
            </Box>
            <Box marginBottom={2} padding={0}>
              <MultiselectField
                options={["Self", ...Object.values(StaffRoles)].map((role) => ({
                  label: separateOnUpperCase(role),
                  value: role,
                }))}
                title="User Roles"
                value={userRolesFilter}
                onChange={(value) => {
                  setUserRolesFilter(value as (StaffRoles | "Self")[]);
                  setPage(1);
                }}
              />
            </Box>
            <Box marginBottom={2} padding={0}>
              <MultiselectField
                options={alertTypes.map((type: string) => ({
                  label: type,
                  value: type,
                }))}
                title="Alert Type"
                value={alertTypeFilter}
                onChange={(value: any) => {
                  setAlertTypeFilter(value);
                  setPage(1);
                }}
              />
            </Box>
            <Box marginBottom={2} padding={0}>
              <Text bold size="lg">
                Created Date Range
              </Text>
              <Box>
                <DateTimeField
                  title="Start Date"
                  type="date"
                  value={createdDateRangeFilter?.after}
                  onChange={(value: any) => {
                    setCreatedDateRangeFilter((prev: any) => ({
                      ...prev,
                      after: value,
                    }));
                    setPage(1);
                  }}
                />
                <DateTimeField
                  title="End Date"
                  type="date"
                  value={createdDateRangeFilter?.before}
                  onChange={(value: any) => {
                    setCreatedDateRangeFilter((prev: any) => ({...prev, before: value}));
                    setPage(1);
                  }}
                />
              </Box>
            </Box>
            <Box marginBottom={2} padding={0}>
              <BooleanField
                title="Past Due"
                value={isPastDueFilter}
                onChange={(value) => {
                  setIsPastDueFilter(value);
                  setPage(1);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="75%">
          {Boolean(isFetching) && (
            <Box alignSelf="start" padding={2}>
              <Spinner />
            </Box>
          )}

          <Box flex="grow" padding={2}>
            {children}
          </Box>
        </Box>
      </Box>
    </AlertsExplorerContext.Provider>
  );
};
