import {
  PreClinicUpdate,
  usePatchPreClinicUpdatesByIdMutation,
  usePostPreClinicUpdatesMutation,
  useSentryAndToast,
} from "@store";
import {IsMobileDevice} from "@utils";
import {Box, Button, DateTimeField, printDateAndTime, Text, TextArea} from "ferns-ui";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

const PRECLINIC_UPDATE_TEMPLATE_TEXT = `CLINIC SCHEDULE
Sample:
- 5.21.2024 Tuesday
  - 10:30am - 11:50am Patient1 Confirmed
    - 11:50am - 12:00pm break
  - 12:00pm - 12:25pm Patient2 urgent eval Confirmed
    - 12:25pm - 12:30pm break
  - 12:30pm - 12:55pm Patient3 Confirmed
    - 12:55pm - 1:00pm break
  - 1:00pm - 2:00pm Carepod Huddle
  - 2:00pm - 2:30pm Documentation Time

NEW INTAKES
Insert the one liner for new patients

RANKING OF PATIENTS TO DISCUSS DURING HUDDLE
Sample :
1. “Leandro”
  - Clinical status: High risk
  - Is he getting what he needs from us?
    - No because last time we interacted with him he was hearing voices, and he’s not responding to re-engagement effort
  - What to do for him this week?
    - See if he attends psychiatry appointment and how he is doing
    - Call the mother to express concerns for his safety
    - Try texting with emoji’s
`;

const styles = {
  mobile: {
    psychiatristSelectorWidth: "100%",
    datePickerWidth: "100%",
    createUpdateButtonWidth: "100%",
  },
  web: {psychiatristSelectorWidth: "33%", datePickerWidth: "33%", createUpdateButtonWidth: "25%"},
};

interface PreClinicUpdateEditorProps {
  preClinicUpdate?: PreClinicUpdate;
  psychiatristId?: string;
  onClose: () => void;
}

export const PreClinicUpdateEditor = ({
  preClinicUpdate,
  psychiatristId,
  onClose = (): void => {},
}: PreClinicUpdateEditorProps): ReactElement => {
  const [updatePreClinicUpdate] = usePatchPreClinicUpdatesByIdMutation();
  const [createPreClinicUpdate] = usePostPreClinicUpdatesMutation();

  const [clinicDate, setClinicDate] = useState<string | undefined>(
    preClinicUpdate?.clinicDate ?? undefined
  );
  const [updateText, setUpdateText] = useState(
    preClinicUpdate?.text ?? PRECLINIC_UPDATE_TEMPLATE_TEXT
  );
  const [currentPreClinicUpdate, setCurrentPreClinicUpdate] = useState<PreClinicUpdate | undefined>(
    preClinicUpdate || undefined
  );
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const autoSaveDelay = 3000;
  const sentryAndToast = useSentryAndToast();

  // Set the current preclinic update when the state changes,
  // specifically when the autosave triggers
  useEffect(() => {
    if (currentPreClinicUpdate) {
      setClinicDate(currentPreClinicUpdate.clinicDate);
      setUpdateText(currentPreClinicUpdate.text);
    }
  }, [currentPreClinicUpdate]);

  const savePreclinicUpdate = useCallback(
    async (
      value: {clinicDate: string; text: string},
      shouldUpdateLocalState: boolean = true
    ): Promise<void> => {
      if (currentPreClinicUpdate?._id) {
        const updatedPreClinicUpdate = await updatePreClinicUpdate({
          id: currentPreClinicUpdate._id,
          body: value,
        }).catch((error) => {
          sentryAndToast(`Error saving update. Please try again before continuing.`, error);
        });
        setCurrentPreClinicUpdate(updatedPreClinicUpdate?.data as PreClinicUpdate);
      } else {
        const newPreClinicUpdate = await createPreClinicUpdate({
          ...value,
          ownerId: psychiatristId,
        })
          .unwrap()
          .catch((error) => {
            sentryAndToast(`Error saving update. Please try again before continuing.`, error);
          });
        setCurrentPreClinicUpdate(newPreClinicUpdate as PreClinicUpdate);
      }
      // We clear the timeout if we decide to close the editor early.
      // Otherwise this is redundant but doesn't hurt
      clearTimeout(timeoutId);
      if (shouldUpdateLocalState) {
        setClinicDate(value.clinicDate);
        setUpdateText(value.text);
      }
    },
    [
      currentPreClinicUpdate,
      psychiatristId,
      sentryAndToast,
      updatePreClinicUpdate,
      createPreClinicUpdate,
      timeoutId,
    ]
  );

  const resetAutoSaveTimer = useCallback(
    (value: {clinicDate: string; text: string}): void => {
      // If a timeout already exists, we cancel it
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // We create a new timeout and keep track of the timeout id.
      // We want to know what the id is if we decide to save early and cancel the existing timeout
      try {
        const newTimeoutId = setTimeout(async () => {
          await savePreclinicUpdate(value, false);
        }, autoSaveDelay);
        setTimeoutId(newTimeoutId);
      } catch (error: any) {
        sentryAndToast(`Error saving update. Please try again before continuing.`, error);
      }
    },
    [timeoutId, sentryAndToast, savePreclinicUpdate]
  );

  return (
    <Box gap={4}>
      <Box width={IsMobileDevice ? styles.mobile.datePickerWidth : styles.web.datePickerWidth}>
        <DateTimeField
          helperText="Select a date to enable autosave."
          title="Clinic Date"
          type="date"
          value={clinicDate}
          onChange={async (date: string | undefined): Promise<void> => {
            setClinicDate(date);
            if (date && updateText !== PRECLINIC_UPDATE_TEMPLATE_TEXT) {
              resetAutoSaveTimer({clinicDate: date, text: updateText});
            }
          }}
        />
      </Box>
      <TextArea
        blurOnSubmit={false}
        grow
        rows={20}
        value={updateText}
        onChange={(value) => {
          setUpdateText(value);
          if (clinicDate && updateText !== PRECLINIC_UPDATE_TEMPLATE_TEXT) {
            resetAutoSaveTimer({clinicDate, text: value});
          }
        }}
      />
      <Box alignItems="center" direction="row" justifyContent="between" paddingX={1}>
        <Box>
          {currentPreClinicUpdate && (
            <Text color="secondaryLight" italic>
              Last saved on{IsMobileDevice ? "\n" : " "}
              {currentPreClinicUpdate?.updated
                ? printDateAndTime(currentPreClinicUpdate.updated)
                : printDateAndTime(currentPreClinicUpdate?.created)}
            </Text>
          )}
        </Box>
        <Button
          text="Done"
          onClick={async () => {
            onClose();
            if (clinicDate && updateText) {
              await savePreclinicUpdate({clinicDate, text: updateText});
            }
          }}
        />
      </Box>
    </Box>
  );
};
