import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {substituteDotPhrases, useGetDotPhrasesQuery} from "@store";
import {isStaff} from "@utils";
import {TextArea} from "ferns-ui";
import React, {ReactElement} from "react";

export const FreeformTextArea = ({
  answerRequiredErr,
  disabled = false,
  placeholder,
  value = [""],
  onChange,
  onBlur,
  onFocus,
}: {
  answerRequiredErr: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  value: string[];
  onChange: (value: string[]) => void | Promise<void>;
  onBlur?: (value: string[]) => Promise<void>;
  onFocus?: () => void;
}): ReactElement | null => {
  const profile = useReadProfile();
  // the isStaff check prevents patients from trying to request dot phrases (which the backend
  // prevents them from doing anyway)
  const {data: dotPhraseData} = useGetDotPhrasesQuery(isStaff(profile?.type) ? {} : skipToken);
  const dotPhrases = (dotPhraseData?.data ?? []).map((dp) => ({
    phrase: dp.phrase,
    replacement: dp.replacement,
  }));

  return (
    <TextArea
      blurOnSubmit={false}
      disabled={disabled}
      errorText={answerRequiredErr}
      grow
      placeholder={placeholder}
      value={value[0] ?? ""}
      onBlur={async (result): Promise<void> => {
        onBlur && (await onBlur([result]));
      }}
      onChange={async (result): Promise<void> => {
        await onChange([substituteDotPhrases(result, dotPhrases)]);
      }}
      onFocus={() => onFocus && onFocus()}
    />
  );
};
