export const SchedulingColors = {
  gray: "#8e8e8e",
  purple: "#b469eb",
  orange: "#e3780c",
  blue: "#4a90e2",
  green: "#1A7F36", // theme success color
};

export const OldColors = {
  blue: "#4a90e2",
  green: "#1A7F36",
  tertiaryLighter: "#f5f5f5",
  orange: "#e3780c",
  watermelon: "#e3780c",
  maroon: "#8e8e8e",
};

export const WorkflowColors = {
  neutralLight: "#D3D3D3",
  orange: "#FFA500",
  secondary: "#8d58bb",
  springGreen: "#00FF7F",
  neutral: "#808080",
};

export const Colors = {
  primary: "#5c58bb",
  secondary: "#8d58bb",
  accent: "#58b3bb",
  tertiary: "#b7956f",
};

// These colors come mostly from our old theme. We'll likely want to update them,
// but for now it keeps continuity.
export const BadgeColors = [
  {label: "blue", value: "#4a90e2"},
  {label: "darkGray", value: "#111111"},
  {label: "eggplant", value: "#5b2677"},
  {label: "gray", value: "#8e8e8e"},
  {label: "green", value: "#0fa573"},
  {label: "springGreen", value: "#008753"},
  {label: "neutralLight", value: "#D9D9D9"},
  {label: "maroon", value: "#6e0f3c"},
  {label: "midnight", value: "#133a5e"},
  {label: "navy", value: "#004b91"},
  {label: "olive", value: "#364a4c"},
  {label: "orange", value: "#e3780c"},
  {label: "orchid", value: "#8046a5"},
  {label: "pine", value: "#0a6955"},
  {label: "purple", value: "#b469eb"},
  {label: "red", value: "#bd081c"},
  {label: "watermelon", value: "#f13535"},
  {label: "primary", value: "#0E9DCD"},
  {label: "secondary", value: "#B6CDD5"},
  {label: "tertiary", value: "#000000"},
  {label: "accent", value: "#956A00"},
];

export const ThemeColors = {
  flourishHealth: {
    primary: "#5c58bb",
    secondary: "#8d58bb",
    accent: "#58b3bb",
    title: "Flourish Health",
  },
  helloBarbie: {
    primary: "#E0218A",
    secondary: "#ED5C9b",
    accent: "#F18DBC",
    title: "Hello Barbie",
  },
  retro: {
    primary: "#DD4111",
    secondary: "#2BAF90",
    accent: "#F1A512",
    title: "Retro",
  },
  hotDog: {
    primary: "#cc0000",
    secondary: "#fdb725",
    accent: "#44970C",
    title: "Hot Dog",
  },
  ocean: {
    primary: "#2150AE",
    secondary: "#21787D",
    accent: "#228EDB",
    title: "Ocean",
  },
  grapes: {
    primary: "#36289C",
    secondary: "#A62493",
    accent: "#B55FC8",
    title: "Grapes",
  },
  cottonCandy: {
    primary: "#FA019F",
    secondary: "#4409BB",
    accent: "#0A6AD7",
    title: "Cotton Candy",
  },
  celerySalt: {
    primary: "#251F47",
    secondary: "#425F9E",
    accent: "#B5C650",
    title: "Celery Salt",
  },
  sunsetCruise: {
    primary: "#f57a15",
    secondary: "#4f63ff",
    accent: "#c8230e",
    title: "Sunset Cruise",
  },
};
