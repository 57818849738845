import {useSelectCurrentUserId} from "@ferns-rtk";
import {usePatchUsersByIdMutation, User} from "@store";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useUpdateProfile(update: Partial<User>) {
  const currentUserId = useSelectCurrentUserId();
  const [updateUser] = usePatchUsersByIdMutation();
  if (!currentUserId) {
    return undefined;
  }
  return updateUser({id: currentUserId, body: {...update}});
}
