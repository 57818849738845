import {usePatchVoicemailsByIdMutation, Voicemail} from "@store";
import {Badge, Box, Button, Card, printDateAndTime, Text} from "ferns-ui";
import React from "react";

interface VoicemailCardProps {
  voicemail: Voicemail;
}

export const VoicemailCard = ({voicemail}: VoicemailCardProps): React.ReactElement => {
  const [updateVoicemail] = usePatchVoicemailsByIdMutation();

  const markVoicemailRead = async (): Promise<void> => {
    await updateVoicemail({id: voicemail._id, body: {read: true}});
  };

  const markVoicemailUnread = async (): Promise<void> => {
    await updateVoicemail({id: voicemail._id, body: {read: false}});
  };

  const archiveVoicemail = async (): Promise<void> => {
    await updateVoicemail({id: voicemail._id, body: {archived: true}});
  };

  const unarchiveVoicemail = async (): Promise<void> => {
    await updateVoicemail({id: voicemail._id, body: {archived: false}});
  };

  const fromUserString = (): string => {
    if (!voicemail.fromUser) {
      return "Unknown";
    }
    return `${voicemail.fromUser.name} (${voicemail.fromUser.type})`;
  };

  return (
    <Card key={voicemail._id} marginTop={4} padding={4}>
      <Box alignItems="center" direction="row" justifyContent="between" marginBottom={3}>
        <Box>{!voicemail.read && <Badge status="info" value="Unread" />}</Box>
        <Box alignItems="center" direction="row" justifyContent="around">
          <Box marginRight={3}>
            <Button
              text={voicemail.archived ? "Unarchive" : "Archive"}
              onClick={async () => {
                if (voicemail.archived) {
                  await unarchiveVoicemail();
                } else {
                  await archiveVoicemail();
                }
              }}
            />
          </Box>
          <Box>
            <Button
              iconName={voicemail.read ? undefined : "check"}
              text={voicemail.read ? "Mark Unread" : "Mark Read"}
              variant={voicemail.read ? "outline" : "primary"}
              onClick={async () => {
                if (voicemail.read) {
                  await markVoicemailUnread();
                } else {
                  await markVoicemailRead();
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box alignItems="center" direction="row" marginBottom={2}>
        <Text bold size="lg">
          From:{" "}
        </Text>
        <Text>{voicemail.fromPhoneNumber}</Text>
      </Box>
      <Box alignItems="center" direction="row" marginBottom={2}>
        <Text bold size="lg">
          User:{" "}
        </Text>
        <Text>{fromUserString()}</Text>
      </Box>
      <Box alignItems="center" direction="row" marginBottom={2} width="100%">
        <Text bold size="lg">
          Link:{" "}
        </Text>
        <Box flex="grow">
          <Text truncate>{voicemail.RecordingUrl}</Text>
        </Box>
      </Box>
      <Box marginTop={2}>
        <Text align="right">
          {printDateAndTime(voicemail.created, {
            showTimezone: true,
          })}
        </Text>
      </Box>
    </Card>
  );
};
