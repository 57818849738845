// Controller component that doesn't render anything but watches the socket for presence events and
// updates the Redux store with the presence state.
// Also manages clean up of the redux store when events expire.
// This helps manage the React lifecycle of the ManageFormScreen component by not re-rendering the
// entire tree every time presence changes, instead only the FormQuestionPresenceText component is
// re-rendered.
import {FORM_PRESENCE_BLUR_TIMEOUT_MS, logFormPresence} from "@ferns-rtk";
import {updateUserPresence, useSelectUserPresence} from "@store";
import {DateTime} from "luxon";
import React, {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";

import {useSocket} from "../SocketProvider";

interface FormPrescenceWatcherProps {
  formInstanceId: string;
}

export const FormPrescenceWatcher: React.FC<FormPrescenceWatcherProps> = ({formInstanceId}) => {
  const {socket} = useSocket();
  const dispatch = useDispatch();
  const userPresence = useSelectUserPresence();

  const handlePresence = useCallback(
    (data: {
      formInstanceId: string;
      userId: string;
      questionId: string;
      presence: "focus" | "blur";
    }): void => {
      if (data.formInstanceId !== formInstanceId) {
        return;
      }
      console.info(
        `${data.userId} ${data.presence === "focus" ? "is" : "is no longer"} editing question ${data.questionId}`
      );
      dispatch(
        updateUserPresence({
          instanceId: data.formInstanceId,
          userId: data.userId,
          questionId: data.questionId,
          timestamp: data.presence === "focus" ? Date.now() : null,
        })
      );
    },
    [dispatch, formInstanceId]
  );

  // UseEffect to emit join and leave events to the websocket for the given form instance.
  // Watch the websocket for presence events for the given form instance and update the
  // Redux store.
  useEffect(() => {
    if (!socket?.connected || !formInstanceId) {
      console.warn("Socket is not connected to emit formInstance-join or formInstance-leave");
      return;
    }
    logFormPresence(`Joining formInstance ${formInstanceId}`);
    socket.emit(`formInstance-join`, {formInstanceId});
    return (): void => {
      logFormPresence(`Leaving formInstance ${formInstanceId}`);
      socket.emit(`formInstance-leave`, {formInstanceId});
    };
  }, [formInstanceId, socket, socket?.connected]);

  // Watch socket for presence changes and update the Redux store.
  useEffect(() => {
    if (!socket?.connected) {
      console.warn("Socket is not connected to listen for formInstance-presence");
      return;
    }

    socket.on("formInstance-presence", handlePresence);

    return (): void => {
      socket.off("formInstance-presence", handlePresence);
    };
  }, [socket, dispatch, formInstanceId, handlePresence, socket?.connected]);

  // Clean up expired user presence timestamps
  useEffect(() => {
    if (!socket?.connected) {
      console.warn("Socket is not connected to clean up expired user presence timestamps");
      return;
    }

    // Clean up expired timestamps older than 15 seconds
    const cleanupInterval = setInterval(() => {
      const now = DateTime.now();
      const expirationTime = FORM_PRESENCE_BLUR_TIMEOUT_MS; // 15 seconds in milliseconds

      if (!userPresence) {
        return;
      }

      Object.entries(userPresence ?? {}).forEach(([userId, questions]) => {
        Object.entries(
          (questions ?? {}) as Record<string, {timestamp: number; formInstanceId: string}>
        ).forEach(([questionId, data]) => {
          const questionTimestamp = DateTime.fromMillis(data.timestamp);
          if (now.diff(questionTimestamp, "milliseconds").milliseconds > expirationTime) {
            logFormPresence(
              `Cleaning up expired timestamp for question ${questionId} in formInstance ${formInstanceId} `
            );
            dispatch(
              updateUserPresence({
                instanceId: formInstanceId,
                userId,
                questionId,
                timestamp: null,
              })
            );
          }
        });
      });
    }, 1000);

    socket.on("formInstance-presence", handlePresence);

    return (): void => {
      socket.off("formInstance-presence", handlePresence);
      clearInterval(cleanupInterval);
    };
  }, [socket, formInstanceId, dispatch, handlePresence, userPresence, socket?.connected]);

  return null;
};
