// A tool button that runs a migration as a dry run, shows the results in a modal, and provides
// a button for running a wet run, showing the results of that as well.
import {usePostScriptsCreateGoogleCalendarMutation} from "@store";
import {Box, Button, Modal, Text, TextField, Tooltip} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface MigrationResult {
  results: string[];
  wetRun: boolean;
  success: boolean;
}

export const CreateGoogleCalendar = (): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [createCalendar, {isLoading, data}] = usePostScriptsCreateGoogleCalendarMutation();
  const [calendarName, setCalendarName] = useState<string>("");

  const migrationResult = data as MigrationResult;

  return (
    //   Expanded to show the error message
    <Box>
      <Modal
        primaryButtonDisabled={isLoading}
        primaryButtonOnClick={async (): Promise<void> => {
          await createCalendar({body: {calendarName}});
        }}
        primaryButtonText="Create"
        secondaryButtonOnClick={(): void => {
          setShowModal(false);
        }}
        secondaryButtonText={migrationResult ? "Close" : "Cancel"}
        size="lg"
        title="Create Google Calendar"
        visible={showModal}
        onDismiss={(): void => setShowModal(false)}
      >
        <Box maxHeight={600} scroll width="100%">
          <TextField
            title="Calendar Name"
            type="text"
            value={calendarName}
            onChange={setCalendarName}
          />
          {isLoading && (
            <Box paddingY={2}>
              <Text>Creating calendar...</Text>
            </Box>
          )}
          {migrationResult && (
            <Box>
              <Text>
                Calendar{" "}
                {migrationResult.success ? "was successfully created!" : "failed to create."}
              </Text>
              <Box>
                <Text>Calendar ID: {migrationResult.results[0]}</Text>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      <Tooltip text="Used to create a Google Calendar ">
        <Box marginRight={5} paddingY={2} width={350}>
          <Button
            iconName="calendar"
            text="Create Google Calendar"
            onClick={async (): Promise<void> => {
              setShowModal(true);
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
