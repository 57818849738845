import {useAnalytics} from "@hooks";
import {Form, FormInstanceType, useDeleteFormsByIdMutation, useGetFormsQuery} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Button, Heading, IconButton, MultiselectField, Page, Text} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface FormAdminProps extends StaffStackScreenProps<"FormAdmin"> {}

interface FormFilterOption {
  value: FormInstanceType;
  label: string;
}

export const FormAdminPage = ({navigation}: FormAdminProps): ReactElement => {
  const logEvent = useAnalytics();
  const [removeForm] = useDeleteFormsByIdMutation();
  const {data: forms} = useGetFormsQuery({});
  const [formFilter, setFormFilter] = useState<string[]>([]);

  const formFilterOptions: FormFilterOption[] = [
    {label: "Assessment", value: "Assessment"},
    {label: "Note", value: "Note"},
  ];

  const renderForm = (form: Form): ReactElement => {
    return (
      <Box
        key={form._id + form.name}
        alignItems="center"
        border="default"
        color="base"
        direction="row"
        justifyContent="between"
        marginBottom={4}
        paddingX={4}
        paddingY={2}
        rounding="md"
        width="100%"
      >
        <Box direction="column" flex="grow">
          <Box paddingY={1}>
            <Text bold size="lg">
              {form.type}: {form.name}
            </Text>
          </Box>
          {Boolean(form.description) && (
            <Box paddingY={1}>
              <Text>{form.description}</Text>
            </Box>
          )}
          <Box paddingY={1}>
            <Text>
              {form.questions.length} question{form.questions.length === 1 ? "" : "s"}
            </Text>
          </Box>
          {Boolean(form.isDraft) && (
            <Box paddingY={1}>
              <Text italic>Draft</Text>
            </Box>
          )}
        </Box>
        <Box direction="row" justifyContent="between" width={70}>
          <IconButton
            accessibilityLabel="edit form"
            iconName="pencil"
            variant="muted"
            onClick={(): void => {
              navigation.navigate("CreateForm", {formId: form._id});
            }}
          />
          <IconButton
            accessibilityLabel="delete form"
            confirmationText="Are you sure you want to delete this form?"
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await removeForm(form._id);
              await logEvent({
                name: "RemoveForm",
                collectionModel: "forms",
                isActivityLogEvent: true,
                docId: form._id,
              });
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box>
        <Box>
          <Heading>Forms</Heading>
        </Box>
        <Box alignContent="center" paddingY={3}>
          <Button
            iconName="plus"
            text="Create Form"
            onClick={(): void => {
              navigation.navigate("CreateForm", {});
            }}
          />
        </Box>
        <Box maxWidth={200} paddingY={3}>
          <MultiselectField
            options={formFilterOptions}
            title="Filter Forms"
            value={formFilter}
            onChange={setFormFilter}
          />
        </Box>
      </Box>
      <Box>
        {(forms?.data ?? [])
          .filter((c: Form) => formFilter.includes(c.type) || !formFilter.length)
          .map((c: Form) => renderForm(c))}
      </Box>
    </Page>
  );
};
