import {Box, Text} from "ferns-ui";
import React, {FC} from "react";

import {versionInfo} from "../store";

export const VersionString: FC<{}> = () => {
  const {version, environment, dev} = versionInfo();
  const envString = dev ? "(DEV)" : `(${environment})`;
  const versionString = `${version} ${envString}`;

  return (
    <Box marginBottom={6} marginTop={6}>
      <Text align="center" color="secondaryDark">
        Version: {versionString}
      </Text>
    </Box>
  );
};
