import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteCompanyOrganizationsByIdMutation,
  useGetCompanyOrganizationsQuery,
  usePatchCompanyOrganizationsByIdMutation,
  usePostCompanyOrganizationsMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"CompanyOrganizationAdmin"> {}

export const CompanyOrganizationAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this company organization from all users before deleting, or the app will behave weirdly."
      description="Flourish Organizations are the Company a member is associated with."
      display={(companyOrganization): {title: string; subtitle?: string} => ({
        title: companyOrganization?.name,
      })}
      fields={[{fieldKey: "name", type: "text", title: "Name"}] as FieldConfig[]}
      navigation={navigation}
      useCreate={usePostCompanyOrganizationsMutation}
      useList={useGetCompanyOrganizationsQuery}
      useRemove={useDeleteCompanyOrganizationsByIdMutation}
      useUpdate={usePatchCompanyOrganizationsByIdMutation}
    />
  );
};
