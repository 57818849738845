import {ListResponse} from "@ferns-rtk";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  CarePod,
  EngagementData,
  ExternalClinician,
  InsurancePlan,
  ReferralMethod,
  ReferralSource,
  ScheduleItem,
  useGetEngagementDataQuery,
  useGetExplorerDataQuery,
  useGetUsersQuery,
  useGetUserStatusesQuery,
  User,
  UserStatus,
} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  DateTimeField,
  SegmentedControl,
  Spinner,
  TextField,
  useStoredState,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {createContext, useContext, useMemo, useState} from "react";

import {CarePodDropdown} from "./CarePodDropdown";
import {UserStatusDropdown} from "./UserStatusDropdown";

export type ExplorerNav = NativeStackNavigationProp<StaffStackParamList, "UserExplorer", undefined>;

export interface UserExplorerColumn {
  title: string;
  width: number;
  sort?: string;
  Component: (user: User, navigation: ExplorerNav) => React.ReactElement | null;
}

export interface ColumnValueData {
  carePods?: ListResponse<CarePod>;
  engagement?: ListResponse<EngagementData>;
  externalClinicians?: ListResponse<ExternalClinician>;
  insurancePlans?: ListResponse<InsurancePlan>;
  referralMethods?: ListResponse<ReferralMethod>;
  referralSources?: ListResponse<ReferralSource>;
  scheduleItems?: ListResponse<ScheduleItem>;
  userStatuses?: ListResponse<UserStatus>;
}

// Define the interface for your data
export interface ExplorerContextType {
  billingDate?: string;
  carePodId?: string;
  engagement?: ListResponse<EngagementData>;
  userData?: ListResponse<User>;
  testUsers: boolean;
  sort?: [string, "asc" | "desc"] | undefined;
  carePodFilter?: string;
  searchFilter?: string;
  dateFilter?: string | undefined;
  view?: number | null;
  page: number;
  total: number;
  users: User[];
  isFetching?: boolean;
  more?: boolean;

  setBillingDate: (date: string | undefined) => void;
  setCarePodId: (id: string) => void;
  setPage: (page: number) => void;
  setSort: (sort: [string, "asc" | "desc"] | undefined) => void;
  setTestUsers: (testUsers: boolean) => void;
  setView: (view: number) => void;
  setSearchFilter: (searchFilter: string) => void;
}

interface Props extends ExplorerContextType {
  children: React.ReactElement;
}

// Create a context for your data
export const UserExplorerContext = createContext<ExplorerContextType>({
  setBillingDate: () => {},
  setCarePodId: () => {},
  setPage: () => {},
  setSort: () => {},
  setTestUsers: () => {},
  setView: () => {},
  setSearchFilter: () => {},
  users: [],
  page: 1,
  total: 0,
  more: false,
  testUsers: false,
  isFetching: false,
  searchFilter: "",
});

const {Provider} = UserExplorerContext;

export const UserExplorerContextProvider = (props: Props): React.ReactElement<typeof Provider> => {
  const {children, ...value} = props;
  return <Provider value={value}>{children}</Provider>;
};

export function useExplorerContext(): ExplorerContextType {
  return useContext(UserExplorerContext);
}

// Create a custom hook to access the user data
export const useUserExplorerContext = (): any => {
  const context = useContext(UserExplorerContext);
  if (context === undefined) {
    throw new Error("useUserExplorerContext must be used within a UserExplorerContextProvider");
  }
  return context;
};

export const UserExplorerController = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [view, setView] = useStoredState<number>("UserExplorerScreen.view", 0);
  const tabs = ["Patients", "My Panel", "Billing", "Staff", "Features", "Enrollment"];
  const isPatients = view === 0;
  const isPanel = view === 1;
  const isBilling = view === 2;
  // const isStaff = view === 3;
  const isFeatures = view === 4;
  const isEnrollment = view === 5;

  let defaultSort: [string, "asc" | "desc"] | undefined;
  if (isPatients || isPanel || isEnrollment) {
    defaultSort = ["status", "asc"];
  }

  const [testUsers, setTestUsers] = useState(false);
  const [sort, setSort] = useState<[string, "asc" | "desc"] | undefined>(defaultSort);
  const [page, setPage] = useState<number>(1);

  const [carePodId, setCarePodId] = useStoredState<string>("UserExplorerScreen.carePodId", "");
  const [billingDate, setBillingDate] = useState<string | undefined>(DateTime.now().toISO());
  const [userStatusId, setUserStatusId] = useStoredState<string>(
    "UserExplorerScreen.userStatusId",
    ""
  );
  const [searchFilter, setSearchFilter] = useStoredState<string>(
    "UserExplorerScreen.searchFilter",
    ""
  );

  const {data: userStatusData} = useGetUserStatusesQuery({});

  const userQuery: {
    page: number;
    limit: number;
    testUser?: boolean;
    type?: UserTypes;
    carePodId?: string;
    userStatusId?: string;
    myPanel?: boolean;
    carePod?: string;
    sort?: any;
    searchFilter?: string;
  } = {page, limit: 50};
  userQuery.testUser = testUsers;
  if (isPatients || isPanel || isEnrollment) {
    if (carePodId) {
      userQuery.carePodId = carePodId;
    }
    if (userStatusId) {
      userQuery.userStatusId = userStatusId;
    }
    if (isPanel) {
      userQuery.myPanel = true;
    }
  } else if (isBilling && carePodId) {
    userQuery.carePod = carePodId;
  }
  // add sort if it exists to the userQuery
  if (sort) {
    // We sort the Billing and Features tables with mongoose syntax and the other tables with
    // mongodb's aggregation syntax. This handles both cases.
    if (!isFeatures && !isBilling) {
      userQuery.sort = {[sort[0]]: sort[1] === "asc" ? 1 : -1};
    } else {
      userQuery.sort = `${sort[1] === "desc" ? "-" : ""}${sort[0]}`;
    }
  }

  if (searchFilter) {
    userQuery.searchFilter = searchFilter;
  }

  userQuery.type = isFeatures ? UserTypes.Staff : UserTypes.Patient;

  // Different user query for different views
  const {data: userData, isFetching: usersFetching} = useGetUsersQuery(
    isFeatures || isBilling ? userQuery : skipToken
  );
  const {data: explorerData, isFetching: explorerFetching} = useGetExplorerDataQuery(
    isPatients || isPanel || isEnrollment ? userQuery : skipToken
  );

  const {data: engagementData, isFetching: engagementFetching} = useGetEngagementDataQuery(
    carePodId && isBilling
      ? {
          carePodId,
          date: billingDate,
        }
      : skipToken
  );

  // Sort users by finding the userStatusId that matches their user.statusId,
  // then sorting by the index of that userStatus.
  // TODO: Move sorting users and getting user info for engagement to the backend.
  const users = useMemo(() => {
    const initialUsers = (isBilling || isFeatures ? userData?.data : explorerData?.data) ?? [];
    // Only billing is sorted on the frontend. We only fetch a few users at a time for billing.
    if (!isBilling) {
      return initialUsers;
    }
    // Don't show users until a care pod is selected.
    if (isBilling && !carePodId) {
      return [];
    }
    // Create a shallow copy of initialUsers before sorting
    const usersToSort = [...initialUsers];
    return usersToSort.sort((a: User, b: User) => {
      const aStatus = userStatusData?.data?.find((s) => s._id === a.statusId);
      const bStatus = userStatusData?.data?.find((s) => s._id === b.statusId);
      return (aStatus?.index ?? 0) - (bStatus?.index ?? 0);
    });
  }, [carePodId, explorerData?.data, isBilling, isFeatures, userData?.data, userStatusData?.data]);

  const total = useMemo(() => {
    if (isBilling || isFeatures) {
      return (userData as any)?.total ?? 0;
    }
    return (explorerData as any)?.total ?? 0;
  }, [explorerData, isBilling, isFeatures, userData]);

  // Use more for billing and features tables
  const more = userData?.more ?? false;

  const isFetching = usersFetching || explorerFetching || engagementFetching;

  return (
    <UserExplorerContext.Provider
      value={{
        billingDate,
        carePodId: carePodId as string | undefined,
        engagement: engagementData,
        testUsers,
        page,
        sort,
        total,
        more,
        users,
        view,
        isFetching,
        searchFilter,

        // Setters
        setBillingDate,
        setCarePodId,
        setPage,
        setSort,
        setTestUsers,
        setView,
        setSearchFilter,
      }}
    >
      <Box direction="row" justifyContent="between" width="100%">
        <Box direction="column" maxWidth={620} paddingY={2} width="100%">
          <SegmentedControl
            items={tabs}
            selectedIndex={view}
            onChange={async (activeIndex): Promise<void> => {
              setTestUsers((activeIndex as number) === 4);
              await setView(activeIndex as number);
            }}
          />
        </Box>
        {Boolean(isFetching) && (
          <Box alignSelf="start" padding={2}>
            <Spinner />
          </Box>
        )}
        <Box alignSelf="end" direction="row" marginBottom={4} paddingX={2}>
          {Boolean(!isFeatures) && (
            <Box justifyContent="center" marginBottom={4} marginRight={4} paddingY={2} width={200}>
              <TextField
                placeholder="Type a name or full ID..."
                title="Search By Name or ID"
                value={searchFilter}
                onChange={(input: string) => {
                  void setSearchFilter(input);
                  setPage(1);
                }}
              />
            </Box>
          )}
          {isBilling && (
            <Box direction="column" marginRight={4} marginTop={4} width={200}>
              <DateTimeField
                helperText="Date to use for calculating engagement data."
                title="Billing Date"
                type="date"
                value={billingDate}
                onChange={setBillingDate}
              />
            </Box>
          )}
          {Boolean(!isFeatures && !isPanel) && (
            <Box justifyContent="center" marginBottom={4} marginRight={4} paddingY={2} width={200}>
              <CarePodDropdown
                errorText={isBilling && !carePodId ? "Care Pod is required" : undefined}
                title="Care Pod Filter"
                value={carePodId}
                onChange={(id) => {
                  void setCarePodId(id);
                  setPage(1);
                }}
              />
            </Box>
          )}
          {Boolean(!isFeatures && !isPanel && !isBilling) && (
            <Box justifyContent="center" marginBottom={4} marginRight={4} paddingY={2} width={200}>
              <UserStatusDropdown
                title="User Status Filter"
                value={userStatusId}
                onChange={(id) => {
                  void setUserStatusId(id);
                  setPage(1);
                }}
              />
            </Box>
          )}
          <Box justifyContent="center" marginBottom={4} marginRight={4} paddingY={2} width={200}>
            <BooleanField title="Show Test Users" value={testUsers} onChange={setTestUsers} />
          </Box>
        </Box>
      </Box>
      <Box flex="grow" width="100%">
        {children}
      </Box>
    </UserExplorerContext.Provider>
  );
};
