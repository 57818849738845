import {FormInstanceList, SectionDivider} from "@components";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  isEmptyNestedObject,
  LimitedUser,
  splitOnUpperCase,
  StaffRoles,
  useGetFamilyUnitsQuery,
  userName,
} from "@store";
import {PatientStackParamList, PatientTabScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Heading, Icon, Page, Spinner, Text} from "ferns-ui";
import React, {ReactElement} from "react";

interface ItemPickerRowProps {
  onClick: () => void;
  children: ReactElement;
}

const ItemPickerRow = (props: ItemPickerRowProps): ReactElement => {
  return (
    <Box
      accessibilityHint="Select to view more details"
      accessibilityLabel="Select"
      borderBottom="default"
      direction="row"
      paddingY={2}
      width="100%"
      onClick={props.onClick}
    >
      <Box flex="grow">{props.children}</Box>
      <Box alignItems="center" justifyContent="center" width={20}>
        <Icon iconName="chevron-right" />
      </Box>
    </Box>
  );
};

const CareTeamMemberRow = ({
  role,
  careTeamMember,
}: {
  careTeamMember?: LimitedUser;
  role: StaffRoles;
}): ReactElement | null => {
  const navigation = useNavigation<NativeStackNavigationProp<PatientStackParamList, "UserBio">>();
  if (!careTeamMember?._id) {
    return null;
  }
  return (
    <ItemPickerRow
      key={role}
      onClick={(): void => navigation.navigate("UserBio", {userId: careTeamMember._id})}
    >
      <Box direction="column">
        <Text bold>{`${role}: `}</Text>
        <Text>{careTeamMember?.name}</Text>
      </Box>
    </ItemPickerRow>
  );
};

interface HomeScreenProps extends PatientTabScreenProps<"CarePlan"> {}

export const CarePlanScreen = ({navigation}: HomeScreenProps): ReactElement => {
  const user = useReadProfile();
  const careTeam = user?.careTeam ?? {};
  const {data: familyUnitsData} = useGetFamilyUnitsQuery({});

  const isCareTeamEmpty = isEmptyNestedObject(careTeam);

  if (!user) {
    return <Spinner />;
  }

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box alignItems="center" gap={4} width="100%">
        <Box>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Heading>Care Plan</Heading>
            </Box>
            <Text>
              {user?.carePlan ||
                "Once your care team creates your family's care plan, you can see it here."}
            </Text>
          </Box>
          {Boolean(user?.safetyPlan) && (
            <Box paddingY={4}>
              <Box marginBottom={2}>
                <Heading>Safety Plan</Heading>
              </Box>
              <Box>
                <Text>{user?.safetyPlan}</Text>
              </Box>
            </Box>
          )}
        </Box>
        {user?.thrivePlan && (
          <Box paddingX={3}>
            <Box marginBottom={2}>
              <Heading>Thrive Plan</Heading>
            </Box>
            <Text>{user.thrivePlan}</Text>
          </Box>
        )}
        <SectionDivider />

        <FormInstanceList formTypes="Assessment" userId={user._id} />
        <SectionDivider />
        <FormInstanceList formTypes="Note" userId={user._id} />
        <SectionDivider />

        <Box width="100%">
          <Box marginBottom={2}>
            <Heading>Family</Heading>
          </Box>
          <Box>
            {!Boolean(familyUnitsData?.data?.length) && (
              <Box paddingY={2}>
                <Text>Your family members will be listed here.</Text>
              </Box>
            )}
            {familyUnitsData?.data?.map((f) => {
              return (
                <Box
                  key={f._id}
                  alignItems="center"
                  borderBottom="default"
                  direction="row"
                  justifyContent="between"
                  paddingX={3}
                  paddingY={2}
                >
                  <Box>
                    <Text bold>{f.name || "Untitled"} Family</Text>
                    <Box>
                      {(f?.familyUsers).map((familyUser) => {
                        return (
                          Boolean(familyUser._id !== user?._id) && (
                            <Text key={familyUser._id}>{userName(familyUser)}</Text>
                          )
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <SectionDivider />

        <Box width="100%">
          <Box marginBottom={2}>
            <Heading>Care Team</Heading>
          </Box>
          <Box>
            {Boolean(isCareTeamEmpty) && (
              <Text>Once your care team has been assigned, you can see it here.</Text>
            )}
            {Boolean(!isCareTeamEmpty) &&
              [
                StaffRoles.PatientGuide,
                StaffRoles.Therapist,
                StaffRoles.Psychiatrist,
                StaffRoles.FamilyGuide,
              ].map((role) => (
                <CareTeamMemberRow
                  key={role}
                  careTeamMember={
                    user.careTeam[
                      role as "Therapist" | "PatientGuide" | "FamilyGuide" | "Psychiatrist"
                    ]
                  }
                  role={splitOnUpperCase(role) as StaffRoles}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};
