import {humanDateAndTime, Text, useTheme} from "ferns-ui";
import * as React from "react";
import {StyleSheet, View} from "react-native";

import {IMessage, TimeProps} from "./Models";

export const Time = <TMessage extends IMessage = IMessage>({
  position = "left",
  containerStyle,
  currentMessage,
}: TimeProps<TMessage>): React.ReactElement | null => {
  const {theme} = useTheme();
  const styles = {
    left: StyleSheet.create({
      container: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 5,
      },
      text: {
        color: theme.text.primary,
        fontSize: 10,
        backgroundColor: "transparent",
        textAlign: "right" as const,
      },
    }),
    right: StyleSheet.create({
      // eslint-disable-next-line react-native/no-unused-styles
      container: {
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 5,
      },
      // eslint-disable-next-line react-native/no-unused-styles
      text: {
        color: theme.surface.base,
        fontSize: 10,
        backgroundColor: "transparent",
        textAlign: "right" as const,
      },
    }),
  };

  if (!currentMessage?.createdAt) {
    return null;
  }

  return (
    <View style={[styles[position].container, containerStyle && containerStyle[position]]}>
      <Text color={position === "left" ? "primary" : "inverted"} size="sm">
        {currentMessage.sentAsSms ? "SMS - " : "Push - "}
        {humanDateAndTime(currentMessage.createdAt)}
      </Text>
    </View>
  );
};
