import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {isStaff} from "@utils";
import {Box, Button, printDate, SelectField, Text} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {clinicalUpdateFieldOptions} from "../store/modelTypes";

const clinicalInfoSelectFields = {
  clinicalStatus: "clinicalStatus",
  therapyCadence: "therapyCadence",
};
interface UserSelectOneFieldProps {
  userId: string;
  field: keyof typeof clinicalInfoSelectFields;
  showUpdateDate?: boolean;
}
export const ClinicalInfoSelectField = ({
  userId,
  field,
  showUpdateDate = false,
}: UserSelectOneFieldProps): React.ReactElement | null => {
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId);
  const [editSelect, setEditSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState<undefined | string>("");
  const [displayValue, setDisplayValue] = useState("");
  const [displayUpdateDate, setDisplayUpdateDate] = useState<string | undefined>(undefined);

  const setValues = (value: string): void => {
    setSelectedValue(value);
    setDisplayValue(value);
  };

  // Update initial value and selectedValue value when the user or field changes
  useEffect(() => {
    const value = [
      clinicalInfoSelectFields.clinicalStatus,
      clinicalInfoSelectFields.therapyCadence,
    ].includes(field)
      ? (user as any)[field]?.status || ""
      : (user as any)[field] || "";
    const date = (user as any)[field]?.updated || undefined;
    setDisplayUpdateDate(date);
    setValues(value);
  }, [field, user]);

  if (!user || isStaff(user?.type)) {
    return null;
  }
  const findLabelByValue = (value: string): string => {
    const option = (clinicalUpdateFieldOptions as any)[field].find(
      (opt: any) => opt.value === value
    );
    return option ? option.label : "Not selected"; // Default or fallback text
  };

  const onSave = async ({id, value}: {id: string; value: any}): Promise<void> => {
    if (
      [clinicalInfoSelectFields.clinicalStatus, clinicalInfoSelectFields.therapyCadence].includes(
        field
      )
    ) {
      await updateUser({id, body: {[field]: {status: value}}});
    }
    setValues(value);
    setDisplayUpdateDate(new Date().toISOString());
    setEditSelect(false);
  };

  if (editSelect) {
    return (
      <Box>
        <SelectField
          options={
            [...clinicalUpdateFieldOptions[field]] as any // only show the default option if the value is empty
          }
          placeholder="---"
          requireValue={false}
          value={selectedValue}
          onChange={setSelectedValue}
        />
        <Box direction="row" paddingY={2}>
          <Box marginRight={4}>
            <Button
              disabled={isLoading}
              text="Save"
              onClick={async (): Promise<void> => {
                await onSave({id: user._id, value: selectedValue});
              }}
            />
          </Box>
          <Button
            text="Cancel"
            onClick={(): void => {
              setValues(displayValue);
              setEditSelect(false);
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box marginBottom={1}>
          <Text bold size="lg">
            {findLabelByValue(displayValue)}
          </Text>
        </Box>
        <Box alignItems="baseline" direction="row" justifyContent="between" paddingY={2}>
          <Button
            iconName="pencil"
            text="Edit"
            onClick={(): void => {
              setEditSelect(true);
            }}
          />
          {showUpdateDate && displayUpdateDate && (
            <Box alignSelf="baseline" marginRight={2}>
              <Text color="secondaryLight">{`Last Updated: ${printDate(displayUpdateDate)}`}</Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
};
