import React from "react";
import {ActivityIndicator, Platform, Pressable, StyleSheet, Text, View} from "react-native";

import Color from "./Color";
import {LoadEarlierProps} from "./Models";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  wrapper: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Color.defaultColor,
    borderRadius: 15,
    height: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  text: {
    backgroundColor: Color.backgroundTransparent,
    color: Color.white,
    fontSize: 12,
  },
  activityIndicator: {
    marginTop: Platform.select({
      ios: -14,
      android: -16,
      default: -15,
    }),
  },
});

export const LoadEarlier = ({
  isLoadingEarlier = false,
  onLoadEarlier = (): void => {},
  label = "Load earlier messages",
  containerStyle,
  wrapperStyle,
  textStyle,
  activityIndicatorColor = "white",
  activityIndicatorSize = "small",
  activityIndicatorStyle,
}: LoadEarlierProps): React.ReactElement => {
  return (
    <Pressable
      disabled={isLoadingEarlier}
      role="button"
      style={[styles.container, containerStyle]}
      onPress={onLoadEarlier}
    >
      <View style={[styles.wrapper, wrapperStyle]}>
        {isLoadingEarlier ? (
          <View>
            <Text style={[styles.text, textStyle, {opacity: 0}]}>{label}</Text>
            <ActivityIndicator
              color={activityIndicatorColor!}
              size={activityIndicatorSize!}
              style={[styles.activityIndicator, activityIndicatorStyle]}
            />
          </View>
        ) : (
          <Text style={[styles.text, textStyle]}>{label}</Text>
        )}
      </View>
    </Pressable>
  );
};
