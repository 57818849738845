export const SignUpScreen = (): null => {
  return null;
};
// import {emailSignup, RootState, selectProfile, updateProfile} from "@store";
// import {Box, Button, Page, BooleanField, Text, TextField, Unifier} from "ferns-ui";
// import React, {Component} from "react";
// import {connect, ConnectedProps} from "react-redux";
//
// interface Props extends PropsFromRedux {
//   navigation: any;
// }
//
// interface State {
//   errorMessage?: string;
//   email: string;
//   password: string;
//   displayName: string;
//   patient: boolean;
// }
//
// class SignUpPageBare extends Component<Props, State> {
//   static options = (): any => ({
//     topBar: {
//       title: {
//         text: "Sign Up",
//         color: Unifier.theme.white,
//         fontFamily: Unifier.theme.titleFont,
//         fontSize: 18,
//       },
//       background: {
//         color: Unifier.theme.primaryDark,
//       },
//       backButton: {
//         color: Unifier.theme.white,
//       },
//       // Visible: false,
//       // animate: false,
//     },
//     bottomBar: {
//       visible: false,
//     },
//   });
//
//   displayNameRef: any;
//
//   emailRef: any;
//
//   passwordRef: any;
//
//   constructor(props: Props) {
//     super(props);
//     this.state = {
//       email: "",
//       password: "",
//       displayName: "",
//       patient: true,
//     };
//   }
//
//   signUp = (): void => {
//     this.setState({errorMessage: undefined}, async () => {
//       Unifier.tracking.log(`[signup] Creating user with email: ${this.state.email}`);
//       try {
//         await this.props.emailSignup({
//           email: this.state.email,
//           password: this.state.password,
//           type: this.state.patient ? "Patient" : "FamilyMember",
//           name: this.state.displayName,
//         });
//       } catch (error) {
//        // If we uncomment this out, we would need to update this to use .unwrap() and .catch
//         this.setState({errorMessage: (error as any).message});
//         return;
//       }
//     });
//   };
//
//   render(): React.ReactElement {
//     // TODO: support taking from props so we can generalize this.
//     const image = null;
//     const explainer = null;
//     const title = "Sign Up With Email";
//     const disableSignUp = !(
//       this.state.displayName &&
//       this.state.email &&
//       this.state.password &&
//       this.state.password.length >= 6
//     );
//     return (
//       <Page onError={pageOnError} navigation={this.props.navigation} scroll>
//         <Box
//           alignItems="center"
//           alignSelf="center"
//           display="flex"
//           height="100%"
//           justifyContent="center"
//           maxWidth={400}
//           paddingX={4}
//           width="100%"
//         >
//           <Box width="100%">
//             {/* {Platform.OS === "ios" && (
//               <Box paddingY={2}>
//                 <Button
//                   text="Sign Up With Apple"
//                   onClick={() => this.props.appleSignUp()}
//                   color="black"
//                   iconName="apple"
//                   iconPrefix="fab"
//                 />
//               </Box>
//             )}
//             <Box paddingY={2}>
//               <Button
//                 text="Sign Up With Google"
//                 onClick={() => this.props.googleSignUp()}
//                 color="blue"
//                 iconName="google"
//                 iconPrefix="fab"
//               />
//             </Box> */}
//             {/* <Box
//               paddingY={4}
//               width="100%"
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//             >
//               <Heading size="sm">Or</Heading>
//             </Box> */}
//           </Box>
//           <Box width="100%">
//             {Boolean(image) && (
//               <Box alignItems="center" paddingY={4} width="100%">
//                 {image}
//               </Box>
//             )}
//             {Boolean(title) && (
//               <Box paddingY={4} width="100%">
//                 <Text align="center" size="lg" bold>
//                   {title}
//                 </Text>
//               </Box>
//             )}
//             {Boolean(explainer) && (
//               <Box paddingY={2} width="100%">
//                 <Text align="center" bold>
//                   {explainer}
//                 </Text>
//               </Box>
//             )}
//             <Box paddingY={2} width="100%">
//               <Box paddingY={2}>
//                 <TextField
//                   inputRef={(ref: any): void => (this.displayNameRef = ref)}
//                   title="Your Name"
//                   placeholder="Amy"
//                   returnKeyType="next"
//                   value={this.state.displayName}
//                   onChange={(result): void => this.setState({displayName: result})}
//                   onEnter={(): void => this.emailRef && this.emailRef.focus()}
//                 />
//               </Box>
//               <Box paddingY={2}>
//                 <TextField
//                    errorText={this.state.errorMessage}
//                   inputRef={(ref: any): void => (this.emailRef = ref)}
//                   title="Email"
//                   placeholder="amy@totum.health"
//                   returnKeyType="next"
//                   type="email"
//                   value={this.state.email}
//                   onChange={(result): void => this.setState({email: result})}
//                   onEnter={(): void => this.passwordRef && this.passwordRef.focus()}
//                 />
//               </Box>
//               <Box paddingY={2}>
//                 <TextField
//                   inputRef={(ref: any): void => (this.passwordRef = ref)}
//                   title="Password"
//                   placeholder="Secure123"
//                   returnKeyType="done"
//                   type="password"
//                   value={this.state.password}
//                   onChange={(result): void => this.setState({password: result})}
//                   onEnter={(): void => this.signUp()}
//                 />
//               </Box>
//
//               <Box maxWidth={130} paddingY={2}>
//                 <BooleanField
//                   title={this.state.patient ? "Patient" : "Family Member"}
//                   value={this.state.patient}
//                   onChange={(): void => this.setState({patient: !this.state.patient})}
//                 />
//               </Box>
//
//               <Box
//                 direction="row"
//                 justifyContent="between"
//                 paddingY={2}
//                 smDirection="column"
//                 width="100%"
//               >
//                 <Box flex="grow" padding={2}>
//                   <Button
//
//                     disabled={disableSignUp}
//                     text="Sign Up"
//                     onClick={this.signUp}
//                   />
//                 </Box>
//                 <Box flex="grow" padding={2}>
//                   <Button
//                     variant="secondary"
//                     text="Log In"
//                     type="ghost"
//                     onClick={(): void => {
//                       this.props.navigation.navigate({name: "LogInScreen", params: {}});
//                     }}
//                   />
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Page>
//     );
//   }
// }
//
// const connector = connect((state: RootState) => ({profile: selectProfile(state)}), {
//   updateProfile,
//   emailSignup,
// });
// type PropsFromRedux = ConnectedProps<typeof connector>;
// export const SignUpScreen = connector(SignUpScreenBare);
