import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Box, Button, formatAddress, IconButton, Text, useToast} from "ferns-ui";
import React from "react";

interface Props {
  userId: string;
}

export const ContactsPane = ({userId}: Props): React.ReactElement | null => {
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const toast = useToast();
  const user = useGetUsersByIdQuery(userId).data;
  const [updateUser] = usePatchUsersByIdMutation();

  if (!user) {
    return null;
  }

  const deleteContact = async (index: number): Promise<void> => {
    const contacts = [...user.contacts];
    contacts.splice(index, 1);
    try {
      await updateUser({
        id: userId,
        body: {contacts},
      }).unwrap();
    } catch (error: any) {
      toast.error(`Failed to delete contact: ${error}`);
    }
  };

  return (
    <>
      {(user.contacts ?? []).map((contact, index) => (
        <Box
          key={index}
          borderBottom="default"
          direction="row"
          justifyContent="between"
          paddingY={2}
          width="100%"
        >
          <Box flex="grow">
            <Text bold>{contact.name}</Text>
            <Text>{contact.types.join(", ")}</Text>

            {Boolean(contact?.phoneNumber) && (
              <Box paddingY={1}>
                <Text>Phone: {contact.phoneNumber}</Text>
              </Box>
            )}

            {Boolean(contact?.email) && (
              <Box paddingY={1}>
                <Text>Email: {contact.email}</Text>
              </Box>
            )}

            {Boolean(contact?.address?.city) && (
              <Box paddingY={1}>
                <Text>Address: {formatAddress(contact.address as any)}</Text>
              </Box>
            )}

            {Boolean(contact?.notes) && (
              <Box paddingY={1}>
                <Text>Notes: {contact.notes}</Text>
              </Box>
            )}
          </Box>

          <IconButton
            accessibilityLabel="Edit Contact"
            iconName="pencil"
            onClick={(): void => {
              navigation.push("Contacts", {userId, contactIndex: index});
            }}
          />
          <IconButton
            accessibilityLabel="Delete Contact"
            confirmationText="Are you sure you want to delete this contact?"
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await deleteContact(index);
            }}
          />
        </Box>
      ))}
      <Box marginLeft={2} paddingY={2}>
        <Button
          iconName="plus"
          text="Add Contact"
          onClick={(): void => {
            navigation.push("Contacts", {userId});
          }}
        />
      </Box>
    </>
  );
};
