import {useSelectCurrentUserId} from "@ferns-rtk";
import {ConversationSplitPageItem, otherConversationUsers, userName} from "@store";
import {
  Avatar,
  AvatarStatus,
  Badge,
  Box,
  humanDate,
  Icon,
  IconName,
  Text,
  useTheme,
} from "ferns-ui";
import React, {ReactElement} from "react";
import {ListRenderItemInfo, View} from "react-native";

import {IsMobileDevice} from "../utils";

interface InternalChatConversationItemProps {
  onClick?: () => void;
  selectedConversation?: string;
  itemInfo: ListRenderItemInfo<ConversationSplitPageItem>;
}

export const InternalChatConversationItem = ({
  itemInfo,
  selectedConversation,
}: InternalChatConversationItemProps): ReactElement | null => {
  const {theme} = useTheme();
  const currentUserId = useSelectCurrentUserId();

  const {conversation, webPresence, avatar} = itemInfo.item.item;

  const unreadCount = conversation.unreadCount ?? 0;
  const tagCount = conversation.tagCount ?? 0;
  const muted = conversation.muted ?? false;

  const showUnread = Boolean(unreadCount);

  if (!conversation || !currentUserId) {
    return null;
  }

  const otherUsers =
    conversation && currentUserId ? otherConversationUsers(conversation, currentUserId) : null;

  let conversationName = "";
  if (conversation?.name) {
    conversationName = conversation?.name;
  } else if (conversation?.type === "Multi") {
    conversationName = "Group Chat";
  } else if (conversation?.type === "AllStaff") {
    conversationName = "Flourish Health Internal";
  } else if (otherUsers?.[0]) {
    conversationName = userName(otherUsers[0]);
  } else {
    conversationName = "Flourish Health Staff";
  }

  let status: AvatarStatus = "offline";
  if (webPresence?.showOnlineMobile) {
    status = "activeMobile";
  } else if (webPresence?.showOnlineWeb) {
    status = "online";
  }

  let unreadCountText = "";
  if (unreadCount > 0) {
    unreadCountText = `${unreadCount > 10 ? "10+" : unreadCount} Unread - `;
  }

  const renderIcon = (): React.ReactElement | null => {
    if (conversation.type === "Staff") {
      return (
        <Avatar
          doNotDisturb={webPresence?.doNotDisturb}
          name={userName(otherUsers?.[0])}
          size="sm"
          src={avatar?.imageMediaLink}
          status={status}
        />
      );
    } else {
      let icon: IconName;
      if (conversation?.type === "Multi" && conversation.referencedUsers?.length > 0) {
        icon = "child";
      } else if (conversation?.type === "Multi") {
        icon = "users";
      } else if (conversation?.type === "AllStaff") {
        // Flourish Icon
        icon = "hand-holding-heart";
      } else {
        console.warn("Unknown conversation type", conversation);
        return null;
      }
      return (
        <Box alignItems="center" justifyContent="center" width={32}>
          <Icon iconName={icon} />
        </Box>
      );
    }
  };

  const isSelected = conversation?._id === selectedConversation;

  return (
    <Box
      border={isSelected && !IsMobileDevice ? "activeAccent" : undefined}
      color="base"
      dangerouslySetInlineStyle={{__style: {borderWidth: isSelected && !IsMobileDevice ? 2 : 0}}}
      direction="row"
      paddingX={2}
      paddingY={2}
      width="100%"
    >
      <View
        style={{
          backgroundColor: unreadCount > 0 ? theme.surface.primary : theme.surface.neutralLight,
          borderRadius: 2,
        }}
      />
      <Box justifyContent="center" marginRight={2} width={48}>
        {renderIcon()}
      </Box>

      <Box direction="column" flex="grow" justifyContent="center" width="100%">
        <Box direction="row">
          <Box alignItems="center" direction="row" marginBottom={1} paddingX={1}>
            <Text
              bold={showUnread && unreadCount > 0}
              color={muted ? "extraLight" : "primary"}
              size="lg"
            >
              {conversationName}
            </Text>
            {Boolean(muted) && (
              <Box paddingX={1} width="100%">
                <Icon color="extraLight" iconName="bell-slash" type="regular" />
              </Box>
            )}
          </Box>
          {Boolean(tagCount) && (
            <Badge status="warning" value={`${tagCount > 10 ? "10+" : tagCount}`} />
          )}
        </Box>
        {Boolean(conversation.lastMessageSentDate) && (
          <Box paddingX={1}>
            <Text bold={unreadCount > 0} color="secondaryLight" size="sm">
              {unreadCountText}
              {humanDate(conversation.lastMessageSentDate!)}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
