import {
  useCurrentWorkflowMapping,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {isOutOfOffice, useSelectWorkflowStaffId} from "@store";
import {Banner, Box} from "ferns-ui";
import React from "react";

export const ChatBanner = (): React.ReactElement | null => {
  const profile = useReadProfile();
  const currentWorkflowMapping = useCurrentWorkflowMapping();

  const staffId = useSelectWorkflowStaffId();
  const {conversation, isLoading: isLoadingConversation} = useGetConversationForWorkflowMapping(
    staffId ? currentWorkflowMapping : undefined
  );

  if (isLoadingConversation || !currentWorkflowMapping || !profile?._id) {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);

  if (conversation && isMyWorkflow && isOutOfOffice(profile)) {
    return (
      <Box direction="column" display="flex" marginBottom={2} width="100%">
        <Banner
          dismissible={false}
          id="offlineWarning"
          status="warning"
          text={`You are currently set to Out of Office. You must turn off your "Out of Office" in your settings before sending messages.`}
        />
      </Box>
    );
  } else {
    return null;
  }
};
