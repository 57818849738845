import {ThemeColors} from "@constants";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {usePatchUsersByIdMutation} from "@store";
import {StaffStackParamList} from "@types";
import {Box, Heading, Page, Radio, useToast} from "ferns-ui";
import React from "react";

export const ColorThemesScreen = (): React.ReactElement | null => {
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Staff">>();
  const user = useReadProfile();
  const [updateUser] = usePatchUsersByIdMutation();
  const toast = useToast();

  // const {theme, setTheme} = useContext(ThemeContext);
  const [selectedTheme, setSelectedTheme] = React.useState<string>(
    user?.settings.colorTheme || "flourishHealth"
  );

  if (!user) {
    return null;
  }

  return (
    <Page color="base" navigation={navigation} scroll>
      {Object.entries(ThemeColors).map(([name, themeColor]) => (
        <Box key={name}>
          <Heading size="sm">{themeColor.title}</Heading>
          <Box direction="row">
            <Box
              dangerouslySetInlineStyle={{
                __style: {backgroundColor: themeColor.primary},
              }}
              height={25}
              width={25}
            />
            <Box
              dangerouslySetInlineStyle={{
                __style: {backgroundColor: themeColor.secondary},
              }}
              height={25}
              marginLeft={3}
              marginRight={3}
              width={25}
            />
            <Box
              dangerouslySetInlineStyle={{
                __style: {backgroundColor: themeColor.accent},
              }}
              height={25}
              width={25}
            />
            <Box
              accessibilityHint="Select theme"
              accessibilityLabel="Radio"
              paddingX={3}
              onClick={async (): Promise<void> => {
                setSelectedTheme(name);
                console.warn("Theme isn't supported yet");
                // setTheme({
                //   ...theme,
                //   primary: themeColor.primary,
                //   secondary: themeColor.secondary,
                //   accent: themeColor.accent,
                // });
                await updateUser({
                  id: user._id,
                  body: {
                    settings: {...user.settings, colorTheme: name},
                  },
                });
                toast.show("Color settings updated successfully");
              }}
            >
              <Radio selected={selectedTheme === name} />
            </Box>
          </Box>
        </Box>
      ))}
    </Page>
  );
};
