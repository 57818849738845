import {DEFAULT_USER_PASSWORD} from "@constants";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {
  passwordHasErrors,
  PostUsersArgs,
  removeEmptyKeys,
  useGetCarePodsQuery,
  useGetCompanyOrganizationsQuery,
  useGetExternalCliniciansQuery,
  useGetInsurancePlansQuery,
  useGetReferralSourcesQuery,
  useGetUserStatusesQuery,
  usePostUsersMutation,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {getAllCarePodStates, UserTypeLabels, UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  DateTimeField,
  EmailField,
  Page,
  PhoneNumberField,
  SelectField,
  Text,
  TextField,
  useToast,
} from "ferns-ui";
import parsePhoneNumber from "libphonenumber-js";
import React, {ReactElement, useEffect, useState} from "react";

interface CreateUserScreenProps {}

export const CreateUserScreen = ({}: CreateUserScreenProps): ReactElement => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const [createUser, {isLoading}] = usePostUsersMutation();
  const {data: referralSources} = useGetReferralSourcesQuery({});
  const {data: externalClinicians} = useGetExternalCliniciansQuery({});
  const {data: insurancePlans} = useGetInsurancePlansQuery({});
  const {data: userStatuses} = useGetUserStatusesQuery({});
  const {data: companyOrganizations} = useGetCompanyOrganizationsQuery({});
  const {data: carePods} = useGetCarePodsQuery({});

  const [userType, setUserType] = useState(UserTypes.Patient);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthday, setBirthday] = useState<string | undefined>(undefined);
  const [referralDate, setReferralDate] = useState<string | undefined>(undefined);
  const [referralSource, setReferralSource] = useState<string | undefined>("");
  const [referringClinician, setReferringClinician] = useState("");
  const [referringPsychiatrist, setReferringPsychiatrist] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [userStatus, setUserStatus] = useState<string | undefined>("");
  const [companyOrganization, setCompanyOrganization] = useState<string | undefined>("");
  const [insuranceState, setInsuranceState] = useState<string | undefined>("");

  const [password, setPassword] = useState(DEFAULT_USER_PASSWORD);
  const [testUser, setTestUser] = useState<boolean>(false);
  const [smsMessaging, setSmsMessaging] = useState<boolean>(false);
  const [smsNotifications, setSmsNotifications] = useState<boolean>(false);

  const insuranceStates = getAllCarePodStates(carePods?.data ?? []).map((state) => ({
    label: state,
    value: state,
  }));

  const toast = useToast();
  const canSubmit: boolean = Boolean(name);

  // Default to Referred user status
  useEffect(() => {
    if (!userStatuses?.data?.length) {
      return;
    }
    setUserStatus(userStatuses?.data?.find((us) => us.name === "Referred")?._id);
  }, [userStatuses]);

  // Check password errors in a use memo
  const passwordError = React.useMemo(() => {
    if (password !== "") {
      return passwordHasErrors(UserTypes.Patient as string, password);
    }
    return undefined;
  }, [password]);

  const submit = async (): Promise<void> => {
    if (password !== "") {
      const validationError = passwordHasErrors(UserTypes.Patient as string, password);

      if (validationError) {
        toast.error(validationError);
        return;
      }
    }
    const parsedPhoneNumber = phoneNumber ? parsePhoneNumber(phoneNumber, "US")?.number : undefined;
    const args: PostUsersArgs = {
      email,
      type: userType,
      phoneNumber: parsedPhoneNumber,
      name,
      testUser,
      birthday,
      statusId: userStatus,
      growth: {
        referralDate,
        referralSource,
        referringClinician,
        referringPsychiatrist,
      },
      billingInfo: {
        insurancePlan,
        address: {state: insuranceState as User["address"]["state"]},
      },
      smsMessaging,
      smsNotifications,
    };
    await createUser({
      ...removeEmptyKeys(args),
      // Password isn't part of the user type, so we have to add it here and cast it to any
      password: password !== "" ? password : undefined,
    } as any)
      .unwrap()
      .then(() => {
        toast.show("User created successfully!");
        navigation.pop();
      })
      .catch((error: any) => {
        toast.catch(error, "Error creating user.");
      });
  };

  return (
    <Page navigation={navigation}>
      <Box color="base" direction="column" gap={4} padding={2} rounding="md" width="100%">
        <Box maxWidth={200}>
          <SelectField
            options={[UserTypeLabels[UserTypes.Patient], UserTypeLabels[UserTypes.FamilyMember]]}
            requireValue
            value={userType}
            onChange={(value: string): void => setUserType(value as UserTypes)}
          />
        </Box>
        <TextField title="Name" value={name} onChange={(value): void => setName(value)} />
        <Box>
          <Text bold size="md">
            Note: email and password are not required to create a user.
          </Text>
          <Text>
            Both will need to be added in the User Info section in order to enable new user login if
            not added now.
          </Text>
        </Box>
        <EmailField
          title="Email"
          value={email}
          onChange={(value: string): void => setEmail(value)}
        />
        <PhoneNumberField title="Phone Number" value={phoneNumber} onChange={setPhoneNumber} />
        <TextField
          errorText={passwordError}
          title="Password"
          type="text"
          value={password}
          onChange={setPassword}
        />
        <DateTimeField title="Birthday" type="date" value={birthday} onChange={setBirthday} />
        <SelectField
          options={[
            ...(userStatuses?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="User Status"
          value={userStatus}
          onChange={setUserStatus}
        />
        <DateTimeField
          title="Referral Date"
          type="date"
          value={referralDate}
          onChange={setReferralDate}
        />
        <SelectField
          options={[
            ...(referralSources?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="Referral Source"
          value={referralSource}
          onChange={setReferralSource}
        />
        <SelectField
          options={[
            ...(externalClinicians?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="Referring Clinician"
          value={referringClinician}
          onChange={(result: any): void => {
            setReferringClinician(result);
          }}
        />
        <SelectField
          options={[
            ...(externalClinicians?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="Referring Psychiatrist"
          value={referringPsychiatrist}
          onChange={(result: any): void => {
            setReferringPsychiatrist(result);
          }}
        />
        <SelectField
          options={[
            ...(insurancePlans?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="Insurance Plan"
          value={insurancePlan}
          onChange={(result: any): void => {
            setInsurancePlan(result);
          }}
        />
        <SelectField
          options={[
            ...(companyOrganizations?.data?.map((d: any) => ({label: d.name, value: d._id})) ?? []),
          ]}
          requireValue={false}
          title="Flourish Organization"
          value={companyOrganization}
          onChange={(result: any): void => {
            setCompanyOrganization(result);
          }}
        />
        <SelectField
          helperText="This is the state the patient will be in for licensing purposes."
          options={insuranceStates}
          requireValue={false}
          title="State (Licensing)"
          value={insuranceState}
          onChange={(result: any): void => {
            setInsuranceState(result);
          }}
        />
        <Box maxWidth={250}>
          <BooleanField
            title="Consent to SMS Messaging"
            value={smsMessaging}
            onChange={(value): void => setSmsMessaging(value)}
          />
        </Box>
        <Box maxWidth={250}>
          <BooleanField
            title="Consent to SMS Notifications"
            value={smsNotifications}
            onChange={(value): void => setSmsNotifications(value)}
          />
        </Box>
        <Box maxWidth={250}>
          <BooleanField
            title="Test User"
            value={testUser}
            onChange={(value): void => setTestUser(value)}
          />
        </Box>
        <Box paddingY={2}>
          <Button disabled={!canSubmit || isLoading} text="Create User" onClick={submit} />
        </Box>
      </Box>
    </Page>
  );
};
