import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {Box, Button, TextArea, useToast} from "ferns-ui";
import React, {ReactElement, useState} from "react";

import {FilterItem} from "./FilterItems";

const SafetyConcernEditor = ({
  concern,
  index,
  onSave,
  onCancel,
}: {
  concern?: string;
  index?: number;
  onSave: (concern: string, index?: number) => void;
  onCancel: () => void;
}): ReactElement => {
  const [newConcern, setNewConcern] = useState(concern ?? "");
  return (
    <Box gap={4}>
      <TextArea
        title="Concern"
        value={newConcern}
        onChange={(value: string): void => setNewConcern(value)}
      />
      <Box direction="row" width="100%">
        <Box marginLeft={2}>
          <Button
            text="Save Concern"
            variant="secondary"
            onClick={() => {
              onSave(newConcern, index);
              setNewConcern("");
            }}
          />
        </Box>
        <Box marginLeft={2}>
          <Button text="Cancel" variant="secondary" onClick={onCancel} />
        </Box>
      </Box>
    </Box>
  );
};

export const PatientSafetyConcerns = ({userId}: {userId: string}): ReactElement => {
  const toast = useToast();
  const [showEditor, setShowEditor] = useState(false);
  const [updateUser] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [concernToEdit, setConcernToEdit] = useState<string | undefined>(undefined);
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);

  const onDelete = async (index: number): Promise<void> => {
    try {
      const updatedConcerns = user?.safetyConcerns ? [...user?.safetyConcerns] : [];
      updatedConcerns.splice(index, 1);
      await updateUser({id: userId, body: {safetyConcerns: updatedConcerns}});
    } catch (error: any) {
      const deletionError = error?.data?.errors?.title;
      toast.catch(error, deletionError ?? "Error deleting safetyConcerns");
    }
  };

  const onSave = async (concern: string, index?: number): Promise<void> => {
    if (index === undefined) {
      index = user?.safetyConcerns?.length;
    }
    try {
      const updatedConcerns = user?.safetyConcerns ? [...user?.safetyConcerns] : [];
      updatedConcerns[index as number] = concern;
      await updateUser({id: userId, body: {safetyConcerns: updatedConcerns}});
      setShowEditor(false);
    } catch (error: any) {
      const updateError = error?.data?.errors?.title;
      toast.catch(error, updateError ?? "Error updating safetyConcerns");
    }
  };

  return (
    <Box gap={4}>
      {user?.safetyConcerns &&
        user?.safetyConcerns.map((c: string, index: number) => (
          <FilterItem
            key={`concern-${index}`}
            confirmationText="Are you sure you want to delete this?"
            dismissable
            editable
            showDelete
            text={c}
            withConfirmation
            onDismiss={async (): Promise<void> => {
              await onDelete(index);
            }}
            onEdit={(): void => {
              setShowEditor(true);
              setEditIndex(index);
              setConcernToEdit(c);
            }}
          />
        ))}

      {showEditor ? (
        <Box marginTop={4}>
          <SafetyConcernEditor
            concern={concernToEdit}
            index={editIndex}
            onCancel={() => setShowEditor(false)}
            onSave={onSave}
          />
        </Box>
      ) : (
        <Box direction="row" justifyContent="between" paddingY={3}>
          <Button
            iconName="plus"
            text="Add Concern"
            variant="secondary"
            onClick={async (): Promise<void> => {
              setShowEditor(true);
              setEditIndex(undefined);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
