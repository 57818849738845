import {BaseTapToEditProps, Box, FieldOption, TapToEdit, useTheme} from "ferns-ui";
import React from "react";

interface TapToEditRowProps extends BaseTapToEditProps {
  options?: FieldOption[];
  placeholder?: string;
  googleMapsApiKey?: string;
  includeCounty?: boolean;
}

export const TapToEditRow: React.FC<TapToEditRowProps> = (props) => {
  const {theme} = useTheme();
  return (
    <Box
      dangerouslySetInlineStyle={{
        __style: {borderBottomWidth: 1, borderBottomColor: theme.border.default},
      }}
      paddingY={2}
      width="100%"
    >
      <TapToEdit {...(props as any)} />
    </Box>
  );
};
