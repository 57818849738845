import {DateTime} from "luxon";

import {convertNullToUndefined} from "./typeHelpers";

export function getDateOnly(date: string | undefined): string | undefined {
  if (!date) {
    return undefined;
  }
  return convertNullToUndefined(DateTime.fromISO(date).toUTC().toISO());
}
