import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {useOutboundPhoneCallMutation} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Box, IconButton, Page, TextField, useToast} from "ferns-ui";
import React from "react";

export const DialerScreen = (): React.ReactElement => {
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [outboundPhoneCall] = useOutboundPhoneCallMutation();
  const toast = useToast();
  // TODO: Add call log
  return (
    <Page navigation={navigation} scroll>
      <Box justifyContent="center" width="100%">
        <TextField
          placeholder="Enter phone number to call"
          type="phoneNumber"
          value={phoneNumber}
          onChange={(value): void => setPhoneNumber(value)}
        />
      </Box>
      <Box alignItems="center" justifyContent="center" marginTop={2} width="100%">
        <IconButton
          accessibilityLabel="Place Phone Call"
          iconName="phone"
          onClick={async (): Promise<void> => {
            try {
              toast.show(`Calling phone number: "${phoneNumber}"`);
              await outboundPhoneCall({callRecipient: {phoneNumber}}).unwrap();
            } catch (error: any) {
              toast.show(`${error?.data?.title ?? "Unknown error calling user"}}`);
            }
          }}
        />
      </Box>
    </Page>
  );
};
