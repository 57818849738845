import {MarkdownView} from "@components";
import {useAnalytics, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  ConsentFormType,
  latestConsentFormOfType,
  setShowConsent,
  shouldShowConsentForm,
  useAppDispatch,
  usePatchUsersByIdMutation,
} from "@store";
import {ConsentStackParamList} from "@types";
import {BooleanField, Box, Button, Icon, Spinner, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

const patientAgreementContents = latestConsentFormOfType("patientAgreement");
const familyMemberAgreementContents = latestConsentFormOfType("familyMemberAgreement");
const consentFormContents = latestConsentFormOfType("consent");
const researchFormContents = latestConsentFormOfType("research");
const transportationFormContents = latestConsentFormOfType("transportation");
const virginiaRightsContents = latestConsentFormOfType("virginiaRights");

export const ConsentFormScreen = (): ReactElement | null => {
  const logEvent = useAnalytics();

  const user = useReadProfile();
  const [updateUser] = usePatchUsersByIdMutation();
  const navigation =
    useNavigation<NativeStackNavigationProp<ConsentStackParamList, "ConsentFormScreen">>();
  const dispatch = useAppDispatch();

  const [privacy, setPrivacy] = useState(true);
  const [hipaa, setHipaa] = useState(true);
  const [smsNotifications, setSmsNotifications] = useState(true);
  const [smsMessaging, setSmsMessaging] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(true);

  const shouldShowPatientAgreement = shouldShowConsentForm("patientAgreement", user);
  const shouldShowFamilyAgreement = shouldShowConsentForm("familyMemberAgreement", user);
  const shouldShowAgreement = shouldShowPatientAgreement || shouldShowFamilyAgreement;
  const shouldShowConsent = shouldShowConsentForm("consent", user);
  const shouldShowPrivacy = shouldShowConsentForm("privacy", user);
  const shouldShowHipaa = shouldShowConsentForm("hipaa", user);
  const shouldShowResearch = shouldShowConsentForm("research", user);
  const shouldShowTransportation = shouldShowConsentForm("transportation", user);
  const shouldShowVirginiaRights = shouldShowConsentForm("virginiaRights", user);

  if (!user) {
    return (
      <Box alignItems="center" height="100%" justifyContent="center" width="100%">
        <Spinner />
      </Box>
    );
  }

  // If user has already agreed, navigate to home.
  console.debug(user.consentFormAgreements, {
    shouldShowAgreement,
    shouldShowConsent,
    shouldShowHipaa,
    shouldShowPrivacy,
    shouldShowResearch,
    shouldShowTransportation,
    shouldShowVirginiaRights,
  });
  if (
    !shouldShowAgreement &&
    !shouldShowConsent &&
    !shouldShowHipaa &&
    !shouldShowPrivacy &&
    !shouldShowResearch &&
    !shouldShowTransportation &&
    !shouldShowVirginiaRights
  ) {
    dispatch(setShowConsent(false));
    return null;
  }

  async function signConsent(consentFormTypes: {
    [type in ConsentFormType]?: boolean;
  }): Promise<void> {
    if (!user) {
      return;
    }
    const consents = [...(user.consentFormAgreements ?? [])];
    for (const [type, agreed] of Object.entries(consentFormTypes)) {
      const consentFormType = type as ConsentFormType;
      const consentFormId = latestConsentFormOfType(
        consentFormType as ConsentFormType
      )?.consentFormId!;
      console.debug(`Signing consent for ${consentFormType} with id ${consentFormId}`);
      consents.push({
        agreedDate: new Date().toISOString(),
        consentFormId,
        consentFormType,
        isAgreed: agreed,
      });
    }
    const prevValue = user.consentFormAgreements
      .map((cf) => `${cf.consentFormType}: ${cf.isAgreed}`)
      .join("\n");
    const newValue = consents.map((cf) => `${cf.consentFormType}: ${cf.isAgreed}`).join("\n");
    await updateUser({
      id: user._id,
      body: {consentFormAgreements: consents},
    }).unwrap();

    await logEvent({
      name: "UpdateUserConsent",
      // type is reserved by mongo and cannot be a property
      userType: user.type,
      collectionModel: "users",
      isActivityLogEvent: true,
      appliedUserId: user._id,
      docId: user._id,
      payload: {
        prevValue,
        newValue,
      },
    });
  }

  if (shouldShowPatientAgreement) {
    return (
      <AgreementPage
        text={patientAgreementContents?.text}
        title="Patient Agreement"
        onAgree={async (): Promise<void> => {
          await signConsent({patientAgreement: true});
        }}
      />
    );
  } else if (shouldShowFamilyAgreement) {
    return (
      <AgreementPage
        text={familyMemberAgreementContents?.text}
        title="Family Member/Caregiver Agreement"
        onAgree={async (): Promise<void> => {
          await signConsent({familyMemberAgreement: true});
        }}
      />
    );
  } else if (shouldShowConsent) {
    return (
      <AgreementPage
        text={consentFormContents?.text}
        title="Consent Form"
        onAgree={async (): Promise<void> => {
          await signConsent({consent: true});
        }}
      />
    );
  } else if (shouldShowHipaa || shouldShowPrivacy) {
    return (
      <AgreementPage
        agreeButtonText="Continue"
        agreeDisabled={!(hipaa && privacy)}
        title="Permissions"
        onAgree={async (): Promise<void> => {
          await signConsent({hipaa, privacy});
          await updateUser({
            id: user._id,
            body: {smsNotifications, smsMessaging, pushNotifications},
          });
        }}
      >
        <>
          <CheckboxAgreement
            explanation="Flourish Health uses push notifications to alert you to chat messages, reminders, and other important information."
            title="Push Notifications"
            value={pushNotifications}
            onChange={(value): void => setPushNotifications(value)}
          />
          <CheckboxAgreement
            explanation="Allow Flourish Health to text you notifications about appointments and other important information."
            title="SMS Notifications"
            value={smsNotifications}
            onChange={(value): void => setSmsNotifications(value)}
          />
          <CheckboxAgreement
            explanation="Allow Flourish Health to text you to communicate with you about your care team. Note: SMS is not secure and others may be able to see your messages with your care team."
            title="SMS Messaging"
            value={smsMessaging}
            onChange={(value): void => setSmsMessaging(value)}
          />

          <CheckboxAgreement
            explanation="View Privacy Policy"
            title="I have read the Privacy Policy"
            value={privacy}
            onChange={(value): void => setPrivacy(value)}
            onTapExplanation={(): void => {
              navigation.push("Privacy");
            }}
          />
          <CheckboxAgreement
            explanation="View HIPAA Policy"
            title="I have read the HIPAA Notice"
            value={hipaa}
            onChange={(value): void => setHipaa(value)}
            onTapExplanation={(): void => {
              navigation.push("HIPAA");
            }}
          />
        </>
      </AgreementPage>
    );
  } else if (shouldShowResearch) {
    return (
      <AgreementPage
        text={researchFormContents?.text}
        title="Research Consent"
        onAgree={async (): Promise<void> => {
          await signConsent({research: true});
        }}
        onDisagree={async (): Promise<void> => {
          await signConsent({research: false});
        }}
      />
    );
  } else if (shouldShowTransportation) {
    return (
      <AgreementPage
        text={transportationFormContents?.text}
        title="Transportation Release"
        onAgree={async (): Promise<void> => {
          await signConsent({transportation: true});
        }}
        onDisagree={async (): Promise<void> => {
          await signConsent({transportation: false});
        }}
      />
    );
  } else if (shouldShowVirginiaRights) {
    return (
      <AgreementPage
        text={virginiaRightsContents?.text}
        title={virginiaRightsContents?.title ?? ""}
        onAgree={async (): Promise<void> => {
          await signConsent({virginiaRights: true});
        }}
        onDisagree={async (): Promise<void> => {
          await signConsent({virginiaRights: false});
        }}
      />
    );
  }
  return null;
};

interface AgreementPageProps {
  onAgree: () => void;
  agreeDisabled?: boolean;
  onDisagree?: () => void;
  text?: string;
  children?: React.ReactElement;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  title: string;
}

// A full screen page that displays either the markdown of an agreement or a custom component,
// and displays buttons to agree and optionally disagree.
const AgreementPage = ({
  onAgree,
  agreeButtonText,
  agreeDisabled,
  onDisagree,
  disagreeButtonText,
  text,
  children,
  title,
}: AgreementPageProps): React.ReactElement => {
  const navigation = useNavigation();

  // Set the title of the screen to the title of the consent form.
  useEffect(() => {
    navigation.setOptions({title});
  }, [navigation, title]);

  if (!text && !children) {
    throw new Error("Must provide either text or children to AgreementPage.");
  }

  return (
    <Box color="base" flex="grow" padding={4}>
      <Box alignSelf="center" avoidKeyboard maxWidth={800} padding={2} scroll width="100%">
        {Boolean(text) && <MarkdownView>{text}</MarkdownView>}
        {children}
      </Box>
      <Box
        alignSelf="center"
        direction="row"
        display="flex"
        flex="shrink"
        marginBottom={4}
        maxWidth={800}
        paddingX={5}
        paddingY={2}
        width="100%"
      >
        {Boolean(onDisagree) && (
          <Box flex="grow" marginRight={2}>
            <Button
              text={disagreeButtonText ?? "I Don't Agree"}
              variant="muted"
              onClick={onDisagree!}
            />
          </Box>
        )}
        <Box flex="grow" marginLeft={onDisagree ? 2 : 0}>
          <Button disabled={agreeDisabled} text={agreeButtonText ?? "I Agree"} onClick={onAgree} />
        </Box>
      </Box>
    </Box>
  );
};

interface CheckboxAgreementProps {
  value: boolean;
  title: string;
  explanation: string;
  onChange: (value: boolean) => void;
  onTapExplanation?: () => void;
}

export const CheckboxAgreement = ({
  value,
  title,
  explanation,
  onChange,
  onTapExplanation,
}: CheckboxAgreementProps): ReactElement => {
  return (
    <Box marginBottom={4}>
      <Box width="100%">
        <BooleanField title={title} value={value} onChange={onChange} />
      </Box>
      <Box
        accessibilityHint="Show explanation"
        accessibilityLabel="Tap"
        direction={onTapExplanation ? "row" : "column"}
        paddingY={2}
        onClick={onTapExplanation!}
      >
        <Text
          bold={Boolean(onTapExplanation)}
          color={onTapExplanation ? "secondaryDark" : "primary"}
        >
          {explanation}
        </Text>
        {Boolean(onTapExplanation) && (
          <Box alignItems="center" justifyContent="center" marginLeft={2}>
            <Icon color="secondaryDark" iconName="chevron-right" size="md" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
