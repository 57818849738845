import {FitbitSteps, User} from "@store";
import {useTheme} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React, {ReactElement} from "react";
import {VictoryBar, VictoryChart, VictoryLabel} from "victory-native";

import {VictoryTitle} from "./graphs/VictoryTitle";

interface UserStepsChartProps {
  user: User;
  fitbitSteps: FitbitSteps[];
}

export const UserStepsChart = ({fitbitSteps}: UserStepsChartProps): ReactElement | null => {
  const {theme} = useTheme();
  const steps = fitbitSteps.map((step) => {
    return {
      ...step,
      datetime: new Date(step.datetime),
    };
  });

  if (steps.length === 0) {
    return null;
  }
  return (
    <VictoryChart domainPadding={15} scale={{x: "time", y: "linear"}}>
      <VictoryTitle title="User Steps" />
      <VictoryBar
        data={sortBy(steps, "datetime").map((a) => ({
          x: a.datetime,
          y: a.value,
        }))}
        labelComponent={<VictoryLabel dy={30} />}
        style={{data: {fill: theme.surface.error}, labels: {fill: theme.surface.base}}}
      />
    </VictoryChart>
  );
};
