// A read-only cell that displays a checkmark if the user has completed a task or not
import {Box, Icon} from "ferns-ui";
import React from "react";

export const CheckedCell = ({checked}: {checked: boolean}): React.ReactElement => {
  return (
    <Box flex="grow" justifyContent="center" width="100%">
      <Icon color={checked ? "success" : "secondaryDark"} iconName={checked ? "check" : "x"} />
    </Box>
  );
};
