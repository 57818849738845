import {RootState} from "@ferns-rtk";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

import {AppState, UserSettings} from "./modelTypes";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const initialState: AppState = {
  // The current selected workflow
  workflowStaffId: undefined,
  // The userId who's workflow items are selected
  workflowMappingId: undefined,
  showConsentScreen: false,
  lastBiometricsSuccess: undefined,
  unreadUserUpdates: false,
  userSettings: undefined,
  collapseAllRightBar: false,
  infoModalDataKey: "",
  showSideDrawer: false,
  sideDrawerComponentName: undefined,
  internalChatConversationId: undefined,
  internalChatIsFocused: false,
};

// State that is local to the app and not associated with a fetched API document.
// In the future we may want to sync this between apps but for now persisting it locally is
// sufficient.
export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setWorkflowStaffId: (state, action: PayloadAction<string | undefined>) => {
      state.workflowStaffId = action.payload;
    },
    setWorkflowMappingId: (state, action: PayloadAction<string | undefined>) => {
      state.workflowMappingId = action.payload;
    },
    setShowConsent: (state, action: PayloadAction<boolean | undefined>) => {
      state.showConsentScreen = action.payload;
    },
    setLastBiometricsSuccess: (state, action: PayloadAction<string | undefined>) => {
      state.lastBiometricsSuccess = action.payload;
    },
    setUnreadUserUpdates: (state, action: PayloadAction<boolean>) => {
      state.unreadUserUpdates = action.payload;
    },
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.userSettings = action.payload;
    },
    setCollapseAllRightBar: (state, action: PayloadAction<boolean>) => {
      state.collapseAllRightBar = action.payload;
    },
    setInfoModalDataKey: (state, action: PayloadAction<string>) => {
      state.infoModalDataKey = action.payload;
    },
    setShowSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.showSideDrawer = action.payload;
    },
    setSideDrawerComponentName: (
      state,
      action: PayloadAction<AppState["sideDrawerComponentName"]>
    ) => {
      state.sideDrawerComponentName = action.payload;
    },
    setInternalChatConversationId: (state, action: PayloadAction<string | undefined>) => {
      state.internalChatConversationId = action.payload;
    },
    setInternalChatIsFocused: (state, action: PayloadAction<boolean>) => {
      state.internalChatIsFocused = action.payload;
    },
    resetAppState: () => initialState,
  },
});

export const {
  setWorkflowStaffId,
  setWorkflowMappingId,
  setShowConsent,
  setLastBiometricsSuccess,
  setUnreadUserUpdates,
  setUserSettings,
  setCollapseAllRightBar,
  setInfoModalDataKey,
  resetAppState,
  setShowSideDrawer,
  setSideDrawerComponentName,
  setInternalChatConversationId,
  setInternalChatIsFocused,
} = appStateSlice.actions;

export const useSelectInternalChatConversationId = (): string | undefined => {
  return useAppSelector((state: RootState): string | undefined => {
    return state.appState.internalChatConversationId;
  });
};

export const useSelectInternalChatIsFocused = (): boolean | undefined => {
  return useAppSelector((state: RootState): boolean | undefined => {
    return state.appState.internalChatIsFocused;
  });
};

export const useSelectWorkflowMappingId = (): string | undefined => {
  return useAppSelector((state: RootState): string | undefined => {
    return state.appState.workflowMappingId;
  });
};
export const useSelectWorkflowStaffId = (): string | undefined => {
  return useAppSelector((state: RootState): string | undefined => {
    return state.appState.workflowStaffId;
  });
};
export const useSelectShowConsent = (): boolean | undefined => {
  return useAppSelector((state: RootState): boolean | undefined => {
    return state.appState.showConsentScreen;
  });
};
export const useSelectLastBiometricsSuccess = (): string | undefined => {
  return useAppSelector((state: RootState): string | undefined => {
    return state.appState.lastBiometricsSuccess;
  });
};

export const useSelectUnreadUserUpdates = (): boolean => {
  return useAppSelector((state: RootState): boolean => {
    return state.appState.unreadUserUpdates;
  });
};

export const useSelectUserSettings = (): UserSettings => {
  return useAppSelector((state: RootState): UserSettings => {
    return state.appState.userSettings;
  });
};
export const useCollapseAllRightBar = (): boolean => {
  return useAppSelector((state: RootState): boolean => {
    return state.appState.collapseAllRightBar;
  });
};

export const useSetCollapseAllRightBar = (): ((collapsed: boolean) => void) => {
  const dispatch = useDispatch();
  return (collapsed: boolean) => dispatch(setCollapseAllRightBar(collapsed));
};

export const useSelectShowSideDrawer = (): boolean => {
  return useAppSelector((state: RootState): boolean => {
    return state.appState.showSideDrawer;
  });
};

export const useSetShowSideDrawer = (): ((show: boolean) => void) => {
  const dispatch = useDispatch();
  return (show: boolean) => dispatch(setShowSideDrawer(show));
};

export const useSelectSideDrawerComponentName = (): AppState["sideDrawerComponentName"] => {
  return useAppSelector((state: RootState): AppState["sideDrawerComponentName"] => {
    return state.appState.sideDrawerComponentName;
  });
};

export const useSetSideDrawerComponentName = (): ((
  name: AppState["sideDrawerComponentName"]
) => void) => {
  const dispatch = useDispatch();
  return (name: AppState["sideDrawerComponentName"]) => dispatch(setSideDrawerComponentName(name));
};

// eslint-disable-next-line import/no-default-export
export default appStateSlice.reducer;
