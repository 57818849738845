import {ChatBox, OfflineBanner} from "@components";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  useGetConversationsByIdQuery,
  useGetUsersByIdQuery,
  userName,
  useUpdateLastReadMutation,
} from "@store";
import {PatientStackScreenProps} from "@types";
import {IsIos, KEYBOARD_VERTICAL_OFFSET} from "@utils";
import {Box, Spinner} from "ferns-ui";
import React, {ReactElement, useEffect} from "react";
import {KeyboardAvoidingView, SafeAreaView} from "react-native";

interface ChatScreenProps extends PatientStackScreenProps<"Chat"> {}

export const ChatScreen = ({route, navigation}: ChatScreenProps): ReactElement => {
  const {data: conversation} = useGetConversationsByIdQuery(route.params.conversationId);
  const [updateLastRead] = useUpdateLastReadMutation();
  const user = useReadProfile();
  const otherConversationMemberId = conversation?.users.find((u) => u.userId?._id !== user?._id)
    ?.userId?._id;
  const {data: otherConversationMember} = useGetUsersByIdQuery(
    otherConversationMemberId ?? skipToken
  );

  // Set the title of the screen to the name and pronouns of the other converstation member.
  useEffect(() => {
    if (!conversation) {
      return;
    }

    // For simplicity right now, just grab the first conversation member that isn't the user.

    const pronouns =
      otherConversationMember?.pronouns && otherConversationMember.pronouns !== "Not set"
        ? ` (${otherConversationMember?.pronouns})`
        : "";

    navigation.setOptions({
      title: otherConversationMember ? `${userName(otherConversationMember)}${pronouns}` : "Chat",
    });
  });

  // Mark the conversation as read when the user opens the chat.
  useEffect(() => {
    if (!conversation?._id || !user?._id) {
      return;
    }

    void updateLastRead({
      conversationId: conversation._id,
      lastReadDateTime: new Date(),
    });
  }, [conversation?._id, updateLastRead, user?._id]);

  if (!conversation) {
    return (
      <Box alignItems="center" height="100%" justifyContent="center" width="100%">
        <Spinner />
      </Box>
    );
  }
  // used to disable composer if other party (which would be staff when a patient is logged in)
  // in the conversation is out of office
  const staffOutOfOffice = otherConversationMember?.outOfOffice ?? false;

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: "white"}}>
      <KeyboardAvoidingView
        behavior={IsIos ? "padding" : "height"}
        keyboardVerticalOffset={KEYBOARD_VERTICAL_OFFSET}
        style={{flex: 1}}
      >
        <Box color="base" height="100%" width="100%">
          <OfflineBanner staffId={otherConversationMember?._id} />
          <ChatBox
            conversationId={route.params.conversationId}
            disableComposer={staffOutOfOffice}
          />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
