import {humanDate} from "ferns-ui";
import {DateTime} from "luxon";
import * as React from "react";
import {Text, View} from "react-native";

import Color from "./Color";
import {DayProps, IMessage} from "./Models";
import {isSameDay} from "./utils";

export const Day = <TMessage extends IMessage = IMessage>({
  dateFormat,
  currentMessage,
  previousMessage,
  containerStyle,
  wrapperStyle,
  textStyle,
  position = "left",
}: DayProps<TMessage>): React.ReactElement | null => {
  if (currentMessage == null || isSameDay(currentMessage, previousMessage)) {
    return null;
  }

  let date = humanDate(currentMessage.createdAt, {dontShowTime: true});
  if (dateFormat) {
    date = DateTime.fromISO(currentMessage.createdAt).toFormat(dateFormat);
  }
  return (
    <View
      style={[
        {
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 10,
        },
        containerStyle?.[position],
      ]}
    >
      <View style={wrapperStyle?.[position]}>
        <Text
          style={[
            {
              backgroundColor: Color.backgroundTransparent,
              color: Color.defaultColor,
              fontSize: 12,
              fontWeight: "600",
            },
            textStyle?.[position],
          ]}
        >
          {date}
        </Text>
      </View>
    </View>
  );
};
