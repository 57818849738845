// TODO: open source in ferns-ui
import {Box, IconButton, Text} from "ferns-ui";
import React, {useCallback} from "react";

import {Day, DayDropdown} from "./DayDropdown";
import {TimeDropdown, TimeResolution} from "./TimeDropdown";

export type DayTimeRange = {
  day: Day;
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
};

interface DayTimeRangeProps {
  dayTimeRange: DayTimeRange;
  onChange: (value: DayTimeRange) => void;
  onDelete?: () => void;
  // Whether to show a day picker or just show the day as text.
  disablePickDay?: boolean;
  resolution?: TimeResolution;
  showDelete?: boolean;
}

export const DayTimeRange = ({
  dayTimeRange,
  onChange,
  disablePickDay = false,
  resolution = "30",
  showDelete = false,
  onDelete,
}: DayTimeRangeProps): React.ReactElement => {
  let error = "";
  if (
    dayTimeRange.endHour * 60 + dayTimeRange.endMin <=
    dayTimeRange.startHour * 60 + dayTimeRange.startMin
  ) {
    error = "End time must be after start time";
  }

  const updateStart = useCallback(
    ({minute, hour}: {minute: number; hour: number}): void => {
      const startMinutes = hour * 60 + minute;
      const endMinutes = dayTimeRange.endHour * 60 + dayTimeRange.endMin;

      if (startMinutes >= endMinutes) {
        // If start time would push end time past midnight, just set error
        const newEndMinutes = startMinutes + 60;
        if (newEndMinutes >= 24 * 60) {
          onChange({
            ...dayTimeRange,
            startHour: hour,
            startMin: minute,
          });
        } else {
          const newEndHour = Math.floor(newEndMinutes / 60);
          const newEndMin = newEndMinutes % 60;
          onChange({
            ...dayTimeRange,
            startHour: hour,
            startMin: minute,
            endHour: newEndHour,
            endMin: newEndMin,
          });
        }
      } else {
        onChange({...dayTimeRange, startHour: hour, startMin: minute});
      }
    },
    [dayTimeRange, onChange]
  );

  const updateEnd = useCallback(
    ({minute, hour}: {minute: number; hour: number}): void => {
      const endMinutes = hour * 60 + minute;
      const startMinutes = dayTimeRange.startHour * 60 + dayTimeRange.startMin;

      if (endMinutes <= startMinutes) {
        // If moving start time back would go before midnight, just set error
        const newStartMinutes = endMinutes - 60;
        if (newStartMinutes < 0) {
          onChange({
            ...dayTimeRange,
            endHour: hour,
            endMin: minute,
          });
        } else {
          const newStartHour = Math.floor(newStartMinutes / 60);
          const newStartMin = newStartMinutes % 60;
          onChange({
            ...dayTimeRange,
            endHour: hour,
            endMin: minute,
            startHour: newStartHour,
            startMin: newStartMin,
          });
        }
      } else {
        onChange({...dayTimeRange, endHour: hour, endMin: minute});
      }
    },
    [dayTimeRange, onChange]
  );

  return (
    <Box direction="column" width="100%">
      <Box alignItems="center" direction="row" width="100%">
        {Boolean(disablePickDay) ? (
          <Box height="100%" marginRight={2} marginTop={4} width={80}>
            <Text bold>{dayTimeRange?.day ?? "Unknown"}</Text>
          </Box>
        ) : (
          <Box marginRight={4} width={120}>
            <DayDropdown
              day={dayTimeRange?.day}
              onChange={(d): void => onChange({...dayTimeRange, day: d})}
            />
          </Box>
        )}
        <Box marginRight={2}>
          <TimeDropdown
            hour={dayTimeRange.startHour ?? 0}
            minute={dayTimeRange.startMin ?? 0}
            resolution={resolution}
            onChange={updateStart}
          />
        </Box>
        <Box marginRight={2}>
          <Text>to</Text>
        </Box>
        <TimeDropdown
          hour={dayTimeRange.endHour ?? 23}
          minute={dayTimeRange.endMin ?? 59}
          resolution={resolution}
          onChange={updateEnd}
        />
        {Boolean(showDelete) && (
          <Box marginLeft={4}>
            <IconButton
              accessibilityLabel="delete schedule"
              iconName="trash"
              variant="destructive"
              onClick={(): void => onDelete?.()}
            />
          </Box>
        )}
      </Box>
      {Boolean(error) && (
        <Box marginLeft={2}>
          <Text color="error">{error}</Text>
        </Box>
      )}
    </Box>
  );
};
