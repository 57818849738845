import {Box, Button, IconButton, Text, TextField} from "ferns-ui";
import React from "react";

interface StatusReasonsEditorProps {
  instanceData: {
    reasons?: string[];
    captureReasons?: boolean;
    _id?: string;
  };
  setInstanceData: (val: any) => void;
}

export const StatusReasonsEditor = ({
  instanceData,
  setInstanceData,
}: StatusReasonsEditorProps): React.ReactElement | null => {
  const reasons = instanceData.reasons ?? [];

  const updateReason = (index: number, newReason: string): void => {
    const updatedReasons = [...reasons];
    updatedReasons[index] = newReason;
    setInstanceData({...instanceData, reasons: updatedReasons});
  };

  const addNewReason = (): void => {
    setInstanceData({
      ...instanceData,
      reasons: [...reasons, ""],
    });
  };

  const deleteReason = (index: number): void => {
    const updatedReasons = reasons.filter((_, i) => i !== index);
    setInstanceData({...instanceData, reasons: updatedReasons});
  };

  if (!instanceData.captureReasons && !instanceData.reasons?.length) {
    return null;
  }

  return (
    <Box gap={2} width="100%">
      {Boolean(reasons.length > 0) ? (
        <>
          {Boolean(instanceData._id) && (
            <Box paddingY={2}>
              <Text italic>
                Please note that editing a reason below will not update users who have already been
                assigned that reason. It will only update the reason in the list of available
                reasons moving forward.
              </Text>
            </Box>
          )}
          {reasons.map((reason, index) => (
            <Box key={index} alignItems="center" direction="row" paddingY={2} width="100%">
              <Box marginRight={2} width="75%">
                <TextField
                  placeholder="Please enter a possible reason for this status or delete this reason"
                  value={reason}
                  onChange={(text) => updateReason(index, text)}
                />
              </Box>
              <IconButton
                accessibilityLabel="Delete reason"
                confirmationText="Are you sure you want to delete this reason? This will not remove this reason from users to who have already been assigned this reason. Deleting will only remove this from the list of available reasons moving forward."
                iconName="trash"
                // Only show confirmation if the reason is not empty
                withConfirmation={Boolean(reason[index])}
                onClick={() => deleteReason(index)}
              />
            </Box>
          ))}
        </>
      ) : (
        <Text>No status reasons available. Add a new one below.</Text>
      )}
      <Box width={200}>
        <Button text="Add New Reason" variant="secondary" onClick={addNewReason} />
      </Box>
      {Boolean(!instanceData.captureReasons && reasons.length > 0) && (
        <Box>
          <Text color="warning">
            Warning: Capture reasons is set to false, but there are reasons provided. Please toggle
            capture reasons to true or delete existing reasons.
          </Text>
        </Box>
      )}
      {Boolean(instanceData.captureReasons && reasons.length === 0) && (
        <Box>
          <Text color="warning">
            Warning: Capture reasons is set to true, but no valid reasons are defined. Please add at
            least one reason, or toggle capture reasons to false.
          </Text>
        </Box>
      )}
    </Box>
  );
};
