import {useInactivityTimer, useOnlinePresenceDetection} from "@hooks";
import {BottomTabView} from "@react-navigation/bottom-tabs";
import {createNavigatorFactory, TabRouter, useNavigationBuilder} from "@react-navigation/native";
import React from "react";
import {View} from "react-native";

interface Props {
  initialRouteName: string;
  children: any;
  screenOptions: any;
  backBehavior?: any;
}

const BottomTabNavigator = ({
  initialRouteName,
  backBehavior,
  children,
  screenOptions,
  ...rest
}: Props): React.ReactElement => {
  const {state, descriptors, navigation, NavigationContent} = useNavigationBuilder(TabRouter, {
    initialRouteName,
    backBehavior,
    children,
    screenOptions,
  });

  useInactivityTimer();
  useOnlinePresenceDetection();

  return (
    <NavigationContent>
      <View style={{flex: 1, overflow: "hidden"}}>
        <BottomTabView
          {...rest}
          descriptors={descriptors as any}
          navigation={navigation as any}
          state={state}
        />
      </View>
    </NavigationContent>
  );
};

export const createInactivityBottomTabNavigator = createNavigatorFactory(BottomTabNavigator);
