import {skipToken} from "@reduxjs/toolkit/query/react";
import {useSelector} from "react-redux";

import {RootState} from "../ferns-rtk/constants";
import {User} from "../store/modelTypes";
import {useGetUsersByIdQuery} from "../store/sdk";

export function useReadProfile(): User | undefined {
  const currentUserId = useSelector((state: RootState): string | undefined => {
    return state.auth?.userId;
  });
  const {data: userData} = useGetUsersByIdQuery(currentUserId ?? skipToken);
  if (!currentUserId) {
    return undefined;
  }
  return userData as User;
}
