import {useEmailLoginMutation} from "@store";
import {pageOnError} from "@utils";
import {Box, Button, EmailField, Heading, Page, Text, TextField, useToast} from "ferns-ui";
import React, {ReactElement} from "react";
import {Linking, Platform, View} from "react-native";

const explainer =
  "We're so happy you're here!\n\nYour guide has already created your account. You can use the username and password they provided you.";
const title = "Welcome to Flourish Health";
const forgotLoginInstructions = "No problem! Please contact your guide and we can help.";

export const LogInScreen: React.FC<{navigation: any}> = ({navigation}): ReactElement => {
  const [emailLogin, {isLoading}] = useEmailLoginMutation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showForgotLoginInfo, setShowForgotLoginInfo] = React.useState(false);
  const toast = useToast();
  const canSubmit = Boolean(email && password && !isLoading);

  const handleLogin = async (): Promise<void> => {
    if (!canSubmit) {
      return;
    }

    try {
      await emailLogin({email, password}).unwrap();
      const initialUrl = await Linking.getInitialURL();

      // On web, we want to redirect to the initial URL after login.
      // This is fragile and doesn't work on mobile. But since we'll upgrade to Expo Router,
      // we don't want to sink time into this.
      if (initialUrl && !initialUrl.includes("LogInScreen") && Platform.OS === "web") {
        (window as any).location.href = initialUrl;
        return;
      }
    } catch (error: any) {
      toast.catch(error.data?.message ?? error.error);
    }
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box
        alignItems="center"
        alignSelf="center"
        height="100%"
        justifyContent="center"
        marginTop={8}
        maxWidth={400}
        paddingX={4}
        width="100%"
      >
        <Box width="100%">
          <Box paddingY={4} width="100%">
            <Heading align="center">{title}</Heading>
          </Box>
          <Box paddingY={2} width="100%">
            <Text align="center" bold>
              {explainer}
            </Text>
          </Box>

          <View aria-label="Log In Form" role="form">
            <Box paddingY={4}>
              <Box marginBottom={2}>
                <EmailField
                  placeholder="hello@example.com"
                  // returnKeyType="next"
                  testID="email"
                  title="Email"
                  value={email}
                  onChange={(result: string): void => setEmail(result)}
                />
              </Box>
              <Box>
                <TextField
                  placeholder="SuperSecurePassword123"
                  returnKeyType="done"
                  testID="password"
                  title="Password"
                  type="password"
                  value={password}
                  onChange={(result): void => setPassword(result)}
                  onEnter={handleLogin}
                />
              </Box>
              <Box
                direction="row"
                justifyContent="between"
                paddingY={2}
                smDirection="column"
                width="100%"
              >
                <Box flex="grow" padding={2}>
                  <Button
                    disabled={!canSubmit}
                    testID="login"
                    text="Log In"
                    onClick={handleLogin}
                  />
                </Box>
              </Box>
              {Boolean(showForgotLoginInfo) && (
                <Box marginTop={2} paddingX={2} paddingY={2}>
                  <Text>{forgotLoginInstructions}</Text>
                </Box>
              )}
              <Box direction="row" justifyContent="end">
                <Box paddingX={2} paddingY={2}>
                  <Button
                    text="Forgot Login Info?"
                    variant="muted"
                    onClick={(): void => setShowForgotLoginInfo(!showForgotLoginInfo)}
                  />
                </Box>
              </Box>
            </Box>
          </View>
        </Box>
      </Box>
    </Page>
  );
};
