import {User} from "@store";
import {Box, SelectField} from "ferns-ui";
import get from "lodash/get";
import React, {useState} from "react";

import {TextCell} from "./TextCell";

export const DropdownCell = ({
  user,
  options,
  field,
  onSave,
  editing,
  text,
}: {
  user: User;
  field: string;
  options: {value: string; label: string}[];
  onSave: (value: string) => void;
  editing: boolean;
  text?: string;
}): React.ReactElement => {
  const [value, setValue] = useState(get(user, field));
  return (
    <Box flex="grow" justifyContent="center" width="100%">
      {editing ? (
        <Box height={50}>
          <SelectField
            options={options}
            requireValue={false}
            value={value}
            onChange={(result: any): void => {
              setValue(result);
              onSave(result);
            }}
          />
        </Box>
      ) : (
        <TextCell text={text ?? value} />
      )}
    </Box>
  );
};
