import {timezoneOptions} from "@constants";
import {SelectField, SelectFieldPropsBase} from "ferns-ui";
import {DateTime} from "luxon";
import React from "react";

interface TimezoneDropdownProps extends Omit<SelectFieldPropsBase, "options"> {
  value: string;
  onChange: (value: string) => void;
  location?: "USA" | "Worldwide";
}

export const TimezoneDropdown = ({
  value,
  onChange,
  location = "USA",
  ...fieldProps
}: TimezoneDropdownProps): React.ReactElement => {
  // eslint-disable-next-line react/display-name
  const tzOptions: {label: string; value: string}[] = React.useMemo(() => {
    let timezones: [string, string][];
    if (location === "USA") {
      timezones = timezoneOptions.map((tz) => [tz.label, tz.value]);
    } else {
      timezones = (Intl as any).supportedValuesOf("timeZone").map((tz: any) => {
        return [tz, tz];
      });
    }
    return timezones.map(([name, tz]) => ({
      label: `${name} - ${DateTime.now().setZone(tz).toFormat("h:mm a")}`,
      value: tz,
    }));
  }, [location]);

  // Check that value is in the list of options
  const valueIndex = tzOptions.findIndex((t) => t.value === value);
  if (valueIndex === -1) {
    console.warn(`${value} is not a valid timezone`);
  }

  return (
    <SelectField
      requireValue
      title="Timezone"
      {...fieldProps}
      options={tzOptions}
      value={value}
      onChange={onChange}
    />
  );
};
