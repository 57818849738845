import React from "react";
import {Image, StyleSheet, View} from "react-native";
// TODO: support web
import Lightbox from "react-native-lightbox-v2";

import {IMessage, MessageImageProps} from "./Models";

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
    resizeMode: "cover",
  },
  imageActive: {
    flex: 1,
    resizeMode: "contain",
  },
});

export const MessageImage = <TMessage extends IMessage = IMessage>({
  containerStyle,
  lightboxProps = {},
  imageProps = {},
  imageStyle,
  currentMessage,
}: MessageImageProps<TMessage>): React.ReactElement | null => {
  if (currentMessage == null) {
    return null;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      {/*
       // ignoring this for now until this issue is resolved: https://github.com/cbbfcd/react-native-lightbox/issues/11
       // @ts-ignore */}
      <Lightbox
        activeProps={{
          style: styles.imageActive,
        }}
        {...lightboxProps}
      >
        <Image
          {...imageProps}
          source={{uri: currentMessage.image}}
          style={[styles.image, imageStyle]}
        />
      </Lightbox>
    </View>
  );
};
