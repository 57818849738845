import React from "react";

import {IGiftedChatContext, IMessage} from "./Models";

export const GiftedChatContext = React.createContext<IGiftedChatContext>({
  actionSheet: (): any => ({
    showActionSheetWithOptions: (): void => {},
  }),
  setSeletectedMessage: () => {},
  selectedMessage: undefined,
  deleteModalVisible: false,
  getLocale: (): string => "en",
  removeMessage: (): void => {
    console.warn("removeMessage not implemented");
  },
  removeMessageError: undefined,
  removeMessageLoading: false,
  sendAsSms: false,
  setDeleteModalVisible: () => {},
  setSendAsSmsForConversation: (): void => {},
  shouldShowDeliveryIcons: (): boolean => false,
  showSendAsSms: false,
  conversationId: "",
});

interface Props {
  children: React.ReactNode;
  value: Partial<IGiftedChatContext>;
}

export const GiftedChatProvider: React.FC<Props> = ({children, value}) => {
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [selectedMessage, setSeletectedMessage] = React.useState<IMessage | undefined>(undefined);

  const computedValue = {
    // External to Gifted
    getLocale: (): string => "en",
    setSendAsSmsForConversation: (): void => {},
    sendAsSms: false,
    showSendAsSms: false,
    shouldShowDeliveryIcons: (): boolean => false,

    removeMessage: (): void => {
      console.warn("removeMessage not implemented");
    },
    removeMessageLoading: false,
    removeMessageError: undefined,

    actionSheet: (): any => ({
      showActionSheetWithOptions: (): void => {
        console.warn("Action sheet not implemented");
      },
    }),

    ...value,

    // Internal to Gifted
    deleteModalVisible,
    setDeleteModalVisible,
    setSeletectedMessage,
    selectedMessage,
  };

  return <GiftedChatContext.Provider value={computedValue}>{children}</GiftedChatContext.Provider>;
};

export const useChatContext = (): IGiftedChatContext => React.useContext(GiftedChatContext);
