import {usePatchUsersByIdMutation, User} from "@store";
import {Box, TextArea} from "ferns-ui";
import get from "lodash/get";
import React, {useState} from "react";

import {TextCell} from "./TextCell";

interface Props {
  user: User;
  editing: boolean;
  field: string;
  getUserBody: (value: string) => Partial<User>;
}

// TODO: Support passing field as a dot notation path to save embedded fields on the user once
// ferns-api supports it.

export const TextAreaCell = ({user, getUserBody, editing, field}: Props): React.ReactElement => {
  const [updateUser] = usePatchUsersByIdMutation();
  const [text, setText] = useState(get(user, field));

  return (
    <Box flex="grow" justifyContent="center" width="100%">
      {editing ? (
        <TextArea
          grow
          value={text}
          onBlur={async (): Promise<void> => {
            await updateUser({
              id: user._id,
              body: getUserBody(text),
            });
          }}
          onChange={(result): void => setText(result)}
        />
      ) : (
        <TextCell text={text ?? ""} />
      )}
    </Box>
  );
};
