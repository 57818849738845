import {useAnalytics, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  getStaffRole,
  ScheduleItem as ScheduleItemType,
  useDeleteScheduleItemsByIdMutation,
  useGetRecurringScheduleItemsQuery,
  useGetScheduleItemsQuery,
  usePostSyncScheduleGoogleCalendarMutation,
  userName,
} from "@store";
import {StaffStackParamList} from "@types";
import {isStaff, UserTypes} from "@utils";
import {Box, Button, IconButton, printDate, printDateRange, Text, useToast} from "ferns-ui";
import uniq from "lodash/uniq";
import React, {ReactElement} from "react";
import {Linking} from "react-native";

export const FallbackScheduleItem = (): ReactElement => (
  <Box
    alignItems="center"
    border="dark"
    direction="column"
    justifyContent="between"
    marginBottom={4}
    maxWidth={450}
    paddingY={2}
    rounding="md"
  >
    <Text>An error has occurred.</Text>
    <Text>We have notified the dev team of the issue.</Text>
  </Box>
);

export const ScheduleItem = ({
  scheduleItem,
  userId,
  type,
  isPast,
}: {
  scheduleItem: ScheduleItemType;
  userId: string;
  type: UserTypes.Patient | UserTypes.Staff;
  isPast?: boolean;
}): ReactElement => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const logEvent = useAnalytics();
  const toast = useToast();
  const profile = useReadProfile();
  const [removeScheduleItem] = useDeleteScheduleItemsByIdMutation();
  let syncedText = "No Google Calendar event created";
  const [syncCalendar] = usePostSyncScheduleGoogleCalendarMutation();
  const {data: scheduleItems} = useGetScheduleItemsQuery({
    ...(isStaff(type) ? {"staff.userId": userId} : {"users.userId": userId}),
  });
  const recurringIds = uniq(scheduleItems?.data?.filter((s) => s.recurringId) ?? []).map(
    (s) => s.recurringId
  ) as string[];
  const {data: recurringItems} = useGetRecurringScheduleItemsQuery(
    isStaff(profile?.type) && recurringIds.length
      ? {
          _id: {$in: recurringIds},
        }
      : skipToken
  );

  function isEventSynced(item: ScheduleItemType): boolean {
    if (item.googleCalendarEventId) {
      return true;
    }
    if (item.recurringId) {
      const recurring = recurringItems?.data?.find((r) => r._id === item.recurringId);
      return Boolean(recurring?.googleCalendarEventId);
    }
    return false;
  }

  if (isEventSynced(scheduleItem)) {
    syncedText = "Synced to Google Calendar";
  } else if (
    scheduleItem.recurringId &&
    !recurringItems?.data?.find((r) => r._id === scheduleItem.recurringId)
  ) {
    syncedText = "Could not find recurring schedule item";
  }
  const isVideoCall =
    scheduleItem.location?.includes("zoom.us") || scheduleItem.location?.includes("doxy.me");
  // TODO: Store timezone on the schedule item.
  const timezone =
    scheduleItem.users.find((u) => u.userId?.timezone)?.userId?.timezone ?? "America/New_York";

  return (
    <Box
      key={scheduleItem._id}
      alignItems="center"
      border="default"
      direction="row"
      justifyContent="between"
      marginBottom={4}
      maxWidth={450}
      paddingY={2}
      rounding="md"
    >
      <Box direction="column" width="100%">
        <Box direction="row" justifyContent="between" paddingX={2} paddingY={1} width="100%">
          <Box direction="column" flex="grow">
            <Text bold size="lg">
              {Boolean(scheduleItem.title?.length !== 0) ? scheduleItem.title : scheduleItem.type}
            </Text>
            <Box marginTop={1}>
              <Text bold>
                {printDateRange(scheduleItem.startDatetime, scheduleItem.endDatetime, {
                  timezone,
                  showTimezone: true,
                })}
              </Text>
            </Box>
          </Box>
          {isStaff(profile?.type) && !isPast && (
            <Box direction="row" justifyContent="between" width={100}>
              <IconButton
                accessibilityLabel="sync calendar"
                iconName={isEventSynced(scheduleItem) ? "calendar" : "calendar-xmark"}
                tooltipText={syncedText}
                variant={isEventSynced(scheduleItem) ? "muted" : "destructive"}
                onClick={async (): Promise<void> => {
                  try {
                    await syncCalendar({
                      // If it's a recurring item, we want to sync the entire
                      // series, otherwise just this event.
                      id: scheduleItem.recurringId ?? scheduleItem._id,
                      isRecurring: Boolean(scheduleItem.recurringId),
                    }).unwrap();
                    toast.show("Calendar event synced");
                  } catch (error) {
                    toast.catch(error, "Error syncing calendar");
                  }
                }}
              />
              <IconButton
                accessibilityLabel="edit schedule item"
                iconName="pencil"
                variant="muted"
                onClick={(): void => {
                  navigation.navigate("CreateScheduleItem", {
                    scheduleItemId: scheduleItem._id,
                  });
                }}
              />
              <IconButton
                accessibilityLabel="remove schedule item"
                confirmationText={
                  scheduleItem.recurringId
                    ? "This action will remove this single event from the recurring series, but leave the rest in place. Do you want to continue?"
                    : "Are you sure you want to remove this schedule item?"
                }
                iconName="trash"
                variant="destructive"
                withConfirmation
                onClick={async (): Promise<void> => {
                  await removeScheduleItem(scheduleItem._id);
                  await logEvent({
                    name: "RemoveScheduleItem",
                    collectionModel: "scheduleItems",
                    isActivityLogEvent: true,
                    appliedUserId: profile?._id,
                    docId: scheduleItem._id,
                    payload: {
                      prevValue: scheduleItem.startDatetime,
                      newValue: "",
                    },
                  });
                  toast.show(`Schedule item for ${printDate(scheduleItem.startDatetime)} removed`);
                }}
              />
            </Box>
          )}
        </Box>

        <Box paddingX={2} width="100%">
          {Boolean(isVideoCall) && (
            <Box alignSelf="start" paddingY={2}>
              <Button
                iconName="video"
                text="Join Video Call"
                variant="secondary"
                onClick={async (): Promise<void> => {
                  if (!scheduleItem.location) {
                    return;
                  }
                  await Linking.openURL(scheduleItem.location);
                }}
              />
            </Box>
          )}
          {Boolean(scheduleItem.location && !isVideoCall) && (
            <Box direction="row" justifyContent="between" paddingY={1} width="100%">
              <Text bold>Location:</Text>
              <Text> {scheduleItem.location}</Text>
            </Box>
          )}

          {isStaff(profile?.type) && (
            <Box direction="column" justifyContent="between" paddingY={1} width="100%">
              <Box direction="row" justifyContent="between" paddingY={1} width="100%">
                <Text bold>Users:</Text>
                <Box>
                  {scheduleItem.users.map((u) => {
                    return (
                      <Text
                        key={`${u.userId?._id}${u.userId?.type}`}
                        align="right"
                      >{`${userName(u.userId)} (${u.userId?.type})`}</Text>
                    );
                  })}
                </Box>
              </Box>

              <Box direction="row" justifyContent="between" paddingY={1} width="100%">
                <Text bold>Staff:</Text>
                <Box>
                  {scheduleItem.staff.map((u) => {
                    return (
                      <Text
                        key={`${u.userId?._id}${u.userId?.type}`}
                        align="right"
                      >{`${userName(u.userId)} (${getStaffRole(u.userId)})`}</Text>
                    );
                  })}
                </Box>
              </Box>
              {Boolean(scheduleItem.userNotes) && (
                <Box paddingY={2}>
                  <Text bold>User-Facing Notes</Text>
                  <Text>{scheduleItem.userNotes}</Text>
                </Box>
              )}
              {Boolean(scheduleItem.staffNotes) && (
                <Box paddingY={2}>
                  <Text bold>Staff-Facing Notes</Text>
                  <Text>{scheduleItem.staffNotes}</Text>
                </Box>
              )}
            </Box>
          )}
          {!isStaff(profile?.type) && scheduleItem.userNotes && (
            <Box direction="column" justifyContent="between" paddingY={1} width="100%">
              <Text bold>Notes:</Text>
              <Text>{scheduleItem.userNotes}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
