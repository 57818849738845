import {Box, IconButton, Modal, printDate, TapToEdit, useTheme} from "ferns-ui";
import React, {useState} from "react";

import {ConsentFormType, latestConsentFormOfType, latestSignedConsentForm, User} from "../store";
import {MarkdownView} from "./MarkdownView";

interface TapToEditConsentProps {
  currentUser: User;
  consentType: ConsentFormType;
}

const TITLE_MAP = {
  familyMemberAgreement: "Family Agreement",
  patientAgreement: "Patient Agreement",
  consent: "Informed Consent",
  research: "Research Consent",
  transportation: "Transportation Consent",
  privacy: "Privacy",
  hipaa: "HIPAA",
  virginiaRights: "Virginia Rights Consent",
};

export const TapToEditConsent: React.FC<TapToEditConsentProps> = ({consentType, currentUser}) => {
  const {theme} = useTheme();
  const [showModal, setShowModal] = useState(false);
  const agreement = latestSignedConsentForm(currentUser, consentType);
  const value = agreement
    ? `${agreement.isAgreed ? "Yes" : "No"} - ${
        agreement.agreedDate ? printDate(agreement.agreedDate) : "---"
      } (${agreement.consentFormId})`
    : "Unsigned";
  const title = TITLE_MAP[consentType];
  const consentFormContents = latestConsentFormOfType(consentType);
  return (
    <>
      <Modal
        primaryButtonOnClick={() => setShowModal(false)}
        primaryButtonText="Close"
        size="lg"
        title={title}
        visible={showModal}
        onDismiss={() => setShowModal(false)}
      >
        <Box maxHeight={600} scroll width="100%">
          <MarkdownView>{consentFormContents?.text}</MarkdownView>
        </Box>
      </Modal>
      <Box
        alignItems="center"
        dangerouslySetInlineStyle={{
          __style: {borderBottomWidth: 1, borderBottomColor: theme.border.default},
        }}
        direction="row"
        gap={2}
        paddingY={2}
        width="100%"
      >
        <IconButton
          accessibilityLabel="show consent"
          iconName="circle-info"
          variant="muted"
          onClick={() => setShowModal(true)}
        />
        <Box flex="grow">
          <TapToEdit
            key={agreement?.consentFormId + title}
            editable={false}
            title={title}
            transform={(): string => value}
            type="boolean"
            value={agreement?.isAgreed}
          />
        </Box>
      </Box>
    </>
  );
};
