import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  ScheduleItem,
  useGetDiagnosesQuery,
  useGetScheduleItemsQuery,
  useGetUsersByIdQuery,
  useGetUserStatusesQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {
  Box,
  printOnlyDate,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TapToEdit,
  Text,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {FC, useContext, useEffect, useState} from "react";

import {GOOGLE_MAPS_API_KEY} from "../constants";
import {AutoTapToEdit} from "./AutoTapToEdit";
import {AutoTapToEditInsurance} from "./AutoTapToEditInsurance";
import {
  carePodColumn,
  dobColumn,
  healthPlanColumn,
  memberIdColumn,
  nameColumn,
  userStatusColumn,
} from "./ExplorerColumns";
import {TapToEditSchedule} from "./TapToEditSchedule";
import {
  engagementDaysColumn,
  engagementMonthEndColumn,
  engagementMonthStartColumn,
  engagementPrimaryCaregiverColumn,
  familyAttendedColumn,
  familyAttendedNonClinicalColumn,
  familyMessagesColumn,
  familyOpenNotesEngagement,
  patientAttendedColumn,
  patientAttendedNonClinicalColumn,
  patientMessagesColumn,
  patientOpenNotesEngagement,
} from "./UserExplorerBillingView";
import {UserExplorerColumn, UserExplorerContext} from "./UserExplorerContext";

// appointments attended, days of engagement for patient, numbers of message sent by patient,
// days of family member engagement, and number of messages sent by family member
const columns: UserExplorerColumn[] = [
  userStatusColumn,
  nameColumn,
  carePodColumn,
  dobColumn,
  memberIdColumn,
  healthPlanColumn,
  engagementPrimaryCaregiverColumn,
  engagementDaysColumn,
  patientMessagesColumn,
  patientAttendedColumn,
  patientAttendedNonClinicalColumn,
  patientOpenNotesEngagement,
  familyMessagesColumn,
  familyAttendedColumn,
  familyAttendedNonClinicalColumn,
  familyOpenNotesEngagement,
  engagementMonthStartColumn,
  engagementMonthEndColumn,
];

const DataRow = ({title, data}: {title: string; data?: string}): React.ReactElement => {
  return (
    <Box direction="row" paddingY={1} width="100%">
      <Box width={140}>
        <Text bold>{title}:</Text>
      </Box>
      <Box width={460}>
        <Text>{data ?? ""}</Text>
      </Box>
    </Box>
  );
};

const BillingDrawerContents: FC<{user: User}> = ({user}) => {
  const {data: diagnoses} = useGetDiagnosesQuery(
    user.diagnosis.diagnoses ? {_id: {$in: user.diagnosis.diagnoses ?? []}} : skipToken
  );
  const {engagement} = useContext(UserExplorerContext)!;
  const [updateUser] = usePatchUsersByIdMutation();

  const {data: userData} = useGetUsersByIdQuery(user._id);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const {data: userStatusData} = useGetUserStatusesQuery({});

  const engagementData = engagement?.data?.find((error) => error.userId === currentUser?._id);

  const filter = engagementData
    ? {
        startDatetime: {
          $gte: engagementData.selectedEngagementMonthStart,
          $lte: engagementData.selectedEngagementMonthEnd,
        },
      }
    : {};

  const {data: userScheduleItemData} = useGetScheduleItemsQuery(
    engagementData?.userId ? {"users.userId": engagementData.userId, ...filter} : skipToken
  );

  // fetch schedule items for the user and the primary care giver, if any.
  const {data: primaryCaregiverScheduleItemData} = useGetScheduleItemsQuery(
    engagementData?.primaryCaregiverId
      ? {"users.userId": engagementData.primaryCaregiverId, ...filter}
      : skipToken
  );

  const printScheduleItems = (item: ScheduleItem): string => {
    return `${printOnlyDate(item.startDatetime, {defaultValue: ""})} - ${item.title} (${item.attendanceStatus})`;
  };

  const userScheduleItems =
    userScheduleItemData?.data?.map(printScheduleItems).join("\n") ?? "None";
  const caregiverScheduleItems =
    primaryCaregiverScheduleItemData?.data?.map(printScheduleItems).join("\n") ?? "None";

  // Set the user data once we finish fetching
  useEffect(() => {
    if (userData) {
      setCurrentUser(userData);
    }
  }, [userData]);

  if (!currentUser) {
    return null;
  }

  const props = {
    border: true,
    currentObj: currentUser,
    setValue: setCurrentUser,
    model: "users",
    instanceId: currentUser._id,
  };

  const statusName =
    userStatusData?.data?.find((us) => us._id === currentUser.statusId)?.name ?? "No Status";
  let status = `${statusName}`;
  // Check if discharge date is within the next 30 days using luxon, if so, add to status
  if (currentUser.growth.dischargeDate) {
    const dischargeDate = DateTime.fromISO(currentUser.growth.dischargeDate);
    const now = DateTime.now();
    const daysUntilDischarge = dischargeDate.diff(now, "days").days;
    if (daysUntilDischarge < 60) {
      status = `${status} - Discharge in ${daysUntilDischarge} days`;
    }
  }

  return (
    <Box padding={4} width={600}>
      <AutoTapToEdit field="name" title="Name" {...props} />
      <Text>Status: {status}</Text>
      <AutoTapToEdit field="billingInfo.firstName" title="First Name (billing)" {...props} />
      <AutoTapToEdit field="billingInfo.lastName" title="Last Name (billing)" {...props} />
      <AutoTapToEdit field="birthday" title="Date of Birth" type="date" {...props} />
      <Box borderBottom="default" paddingY={1}>
        <TapToEdit
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          includeCounty
          setValue={(value): void => {
            setCurrentUser({...currentUser, address: value});
          }}
          title="Address"
          type="address"
          value={currentUser?.address}
          onSave={async (address): Promise<void> => {
            await updateUser({
              id: currentUser._id,
              body: {address},
            });
          }}
        />
      </Box>
      <AutoTapToEdit field="billingInfo.gender" title="Billing Gender" {...props} />
      <AutoTapToEdit field="phoneNumber" title="Phone Number" {...props} />
      <AutoTapToEditInsurance
        currentUser={currentUser}
        title="Insurance"
        value={currentUser.billingInfo?.insurancePlan}
        {...props}
      />
      <AutoTapToEdit field="billingInfo.memberId" {...props} />
      <AutoTapToEdit field="billingInfo.groupId" {...props} />
      <AutoTapToEdit field="billingInfo.healthPlanId" {...props} />
      <AutoTapToEdit field="billingInfo.authorizationCode" {...props} />
      <AutoTapToEdit field="billingInfo.medicaidNumber" {...props} />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.therapyIntake?.scheduleItemId}
        type="Therapy Intake"
      />
      <TapToEditSchedule
        border
        currentUser={currentUser}
        scheduleItemId={currentUser.growth.clinicalIntake?.scheduleItemId}
        type="Clinical Intake"
      />

      <AutoTapToEdit field="growth.enrolledDate" {...props} />
      {/* TODO: invalidate engagement data when changing this */}
      <AutoTapToEdit field="growth.serviceStartDate" {...props} />
      <AutoTapToEdit field="growth.dischargeDate" {...props} />
      <AutoTapToEdit field="growth.documentFolder" title="Google Drive" {...props} />
      <DataRow
        data={diagnoses?.data?.map((d: any) => `${d.icd10cm}: ${d.description} `).join("\n")}
        title="Diagnoses"
      />
      <DataRow data={userScheduleItems} title="User Schedule Items" />
      <DataRow data={caregiverScheduleItems} title="Family Schedule Items" />
      <DataRow
        data={engagementData?.engagementMonths
          ?.map(
            (m, i) =>
              `Month ${i + 1}: ${printOnlyDate(m.start, {defaultValue: ""})} - ${printOnlyDate(
                m.end,
                {
                  defaultValue: "---",
                }
              )}`
          )
          .join("\n")}
        title="Engagement Months"
      />
    </Box>
  );
};

export const BillingTable = (): React.ReactElement => {
  const context = useContext(UserExplorerContext)!;
  const {setSort, users, page, setPage, more, total} = context;
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  const totalPages = Math.ceil((total ?? 0) / 50);

  return (
    <Table
      columns={columns.map((c) => c.width)}
      more={more}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
    >
      <TableHeader>
        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.title}
            index={index}
            sortable={Boolean(column.sort)}
            onSortChange={(direction): void => {
              if (!column.sort) {
                setSort(undefined);
              } else {
                setSort(direction ? [column.sort, direction] : undefined);
              }
            }}
          >
            <Box flex="grow" justifyContent="center" width="100%" wrap>
              <Text bold size="sm">
                {column.title}
              </Text>
            </Box>
          </TableHeaderCell>
        ))}
      </TableHeader>
      {users.map((user) => (
        <TableRow key={user._id} drawerContents={<BillingDrawerContents user={user} />}>
          {columns.map((column) => (
            <React.Fragment key={column.title}>
              {column.Component(user, navigation as any)}
            </React.Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};
