import {DownloadFileButtonProps} from "@store";
import axios from "axios";
import {Button} from "ferns-ui";
import React from "react";

export const DownloadFileButton = (props: DownloadFileButtonProps): React.ReactElement => {
  const {url, filename, ...rest} = props;

  const downloadPDF = async (): Promise<void> => {
    try {
      const response = await axios.post(url, null, {
        responseType: "blob", // Ensure the response is treated as binary data
      });

      // Check if the request was successful
      if (response.status === 200) {
        // Create a URL for the blob data
        const blob = new Blob([response.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        // Create a temporary link and trigger a download
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = filename;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up the temporary link and URL object
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Failed to download PDF");
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return <Button {...rest} iconName="print" variant="muted" onClick={downloadPDF} />;
};
