import {UserList, UserRowProps} from "@components";
import {useInvalidateTags} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  FamilyUnitRelationship,
  guessUserLastName,
  LimitedUser,
  useGetFamilyUnitsByIdQuery,
  useGetUsersByIdQuery,
  usePatchFamilyUnitsByIdMutation,
  usePostFamilyUnitsMutation,
  User,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {isFamilyMember, isPatient, pageOnError} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  Heading,
  IconButton,
  Page,
  Text,
  TextField,
  useToast,
} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";
interface CreateFamilyUnitProps extends StaffStackScreenProps<"CreateFamilyUnit"> {}

const FamilyUnitUserRow = ({
  user,
  users,
  onChangeUsers,
  extraProps,
}: UserRowProps): React.ReactElement => {
  const {relationships, setRelationships, primaryCaregiver, setPrimaryCaregiver} = extraProps;
  const relationshipIndex = relationships.findIndex(
    (r: FamilyUnitRelationship) => r.familyUserId === user._id
  );
  const relationship = relationships[relationshipIndex];
  return (
    <Box
      key={user?._id}
      alignItems="center"
      color="base"
      direction="row"
      justifyContent="between"
      marginBottom={2}
      paddingX={4}
      paddingY={2}
      rounding="md"
      width="100%"
    >
      <Box direction="column">
        <Heading size="sm">
          {userName(user)} {user?.testUser ? "- Test User" : ""}
        </Heading>
        <Box marginTop={2} width={300}>
          <TextField
            placeholder="Example: Mother, Friend, etc."
            title="Relationship"
            value={relationship?.relationship ?? ""}
            onChange={(value: string): void => {
              if (value.trim() === "") {
                // Remove the relationship if value is empty
                setRelationships(
                  relationships.filter((r: FamilyUnitRelationship) => r.familyUserId !== user._id)
                );
              } else {
                if (relationshipIndex === -1) {
                  setRelationships([
                    ...relationships,
                    {familyUserId: user._id, relationship: value},
                  ]);
                } else {
                  const newRelationships = [...relationships];
                  newRelationships[relationshipIndex] = {
                    ...newRelationships[relationshipIndex],
                    relationship: value,
                  };
                  setRelationships(newRelationships);
                }
              }
            }}
          />
        </Box>
        {Boolean(setPrimaryCaregiver) && (
          <Box>
            <BooleanField
              title="Primary Caregiver"
              value={primaryCaregiver === user._id}
              onChange={(value: boolean): void => {
                if (value) {
                  setPrimaryCaregiver(user!._id);
                } else {
                  setPrimaryCaregiver(undefined);
                }
              }}
            />
          </Box>
        )}
      </Box>
      <Box direction="row" justifyContent="end" width={70}>
        <IconButton
          accessibilityLabel="remove user"
          iconName="trash"
          variant="destructive"
          onClick={(): void => {
            onChangeUsers(users.filter((u) => u._id !== user._id));
          }}
        />
      </Box>
    </Box>
  );
};

export const CreateFamilyUnitScreen = ({
  navigation,
  route,
}: CreateFamilyUnitProps): ReactElement => {
  const invalidateTags = useInvalidateTags();
  const toast = useToast();

  const {data: familyUnit} = useGetFamilyUnitsByIdQuery(route?.params?.familyUnitId ?? skipToken);
  const {data: selectedUser} = useGetUsersByIdQuery(route?.params?.selectedUserId ?? skipToken);

  const [updateFamilyUnit, {isLoading: updateIsLoading}] = usePatchFamilyUnitsByIdMutation();
  const [createFamilyUnit, {isLoading: createIsLoading}] = usePostFamilyUnitsMutation();

  const isLoading = Boolean(updateIsLoading || createIsLoading);

  const [familyName, setFamilyName] = useState("");
  const [relationships, setRelationships] = useState<FamilyUnitRelationship[]>([]);
  const [primaryCaregiver, setPrimaryCaregiver] = useState<string | undefined>(undefined);
  const [patients, setPatients] = useState<LimitedUser[]>([]);
  const [familyMembers, setFamilyMembers] = useState<LimitedUser[]>([]);

  // set family unit values if passed in
  useEffect(() => {
    if (familyUnit) {
      setFamilyName(familyUnit.name || "");
      setFamilyMembers(familyUnit.familyUsers.filter((u) => isFamilyMember(u.type)));
      setPatients(familyUnit.familyUsers.filter((u) => isPatient(u.type)));
      setRelationships(familyUnit.relationships || []);
      setPrimaryCaregiver(familyUnit.primaryCaregiver);
    }
  }, [familyUnit]);

  // set currently selected user if passed in and no existing family unit passed in
  useEffect(() => {
    if (!route?.params?.familyUnitId && route?.params?.selectedUserId) {
      if (selectedUser && isFamilyMember(selectedUser?.type)) {
        setFamilyMembers([selectedUser]);
        setPrimaryCaregiver(selectedUser._id);
      } else if (selectedUser && isPatient(selectedUser?.type)) {
        setPatients([selectedUser]);
      }
    }
  }, [route?.params?.familyUnitId, route?.params?.selectedUserId, selectedUser]);

  const submit = async (): Promise<void> => {
    let err;
    let placeholderFamilyName = "";
    if (!patients.length || !familyMembers.length) {
      toast.warn("Family Unit must include at least one patient and at least one familyMember");
      return;
    }
    if (!familyName) {
      // Add a family name by concatenating unique last names each user
      const familyLastNames: string[] = [];
      [...familyMembers, ...patients].forEach((user) => {
        const lastName = guessUserLastName(user);
        if (!familyLastNames.includes(lastName)) {
          familyLastNames.push(lastName);
        }
      });
      placeholderFamilyName = familyLastNames.join("/");
    }
    if (familyUnit) {
      await updateFamilyUnit({
        id: familyUnit?._id,
        body: {
          name: familyName || placeholderFamilyName,
          familyUsers: [...familyMembers, ...patients].map((u) => u._id) as any,
          relationships,
          primaryCaregiver,
        },
      })
        .unwrap()
        .catch((error: any) => {
          toast.catch(error, error?.data?.title ?? "Error updating family unit");
          return;
        });
      invalidateTags(["alertinstances"]);
    } else {
      await createFamilyUnit({
        name: familyName || placeholderFamilyName,
        familyUsers: [...familyMembers, ...patients].map((u) => u._id) as any,
        relationships,
        primaryCaregiver,
      })
        .unwrap()
        .catch((error: any) => {
          toast.catch(error, error?.data?.title ?? "Error creating family unit");
          return;
        });
      invalidateTags(["alertinstances"]);
    }

    if (!err) {
      navigation.pop();
    }
  };

  const usersNotSelected = Boolean(!familyMembers.length || !patients.length);

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Box marginBottom={6}>
        <Box width="60%">
          <TextField
            title="Family Name"
            value={familyName}
            onChange={(value: any): void => {
              setFamilyName(value);
            }}
          />
        </Box>
      </Box>

      <Box paddingY={2}>
        <Box marginBottom={2}>
          <Heading>Patients</Heading>
        </Box>
        <UserList
          UserRowComponent={FamilyUnitUserRow}
          buttonText="Add  Patient"
          extraProps={{relationships, setRelationships}}
          patient
          userIds={patients.map((u) => u._id)}
          onChangeUsers={setPatients}
        />
      </Box>
      <Box paddingY={2}>
        <Box marginBottom={2}>
          <Heading>Family Members</Heading>
        </Box>
        <UserList
          UserRowComponent={FamilyUnitUserRow}
          buttonText="Add Family Member"
          extraProps={{relationships, setRelationships, primaryCaregiver, setPrimaryCaregiver}}
          familyMember
          userIds={familyMembers.map((u) => u._id)}
          onChangeUsers={(users: User[]): void => {
            setFamilyMembers(users);
            if (users.length === 1) {
              setPrimaryCaregiver(users[0]._id);
            }
          }}
        />
      </Box>

      <Box marginTop={6}>
        <Box height={100} width="40%">
          <Button
            disabled={usersNotSelected || isLoading || !primaryCaregiver}
            loading={isLoading}
            text={`${familyUnit ? "Update" : "Create"} Family Unit`}
            onClick={submit}
          />
        </Box>
        {!primaryCaregiver && (
          <Box paddingY={2}>
            <Text>Must select a primary caregiver</Text>
          </Box>
        )}
      </Box>
    </Page>
  );
};
