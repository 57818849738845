import Constants from "expo-constants";
import {mediaQuerySmallerThan} from "ferns-ui";
import {isAndroid, isIOS} from "react-device-detect";
import {Platform, StatusBar} from "react-native";
export const IsAndroid = Platform.OS === "android" || isAndroid;

export const IsNative = ["android", "ios"].includes(Platform.OS);

export const IsIos = Platform.OS === "ios" || isIOS;

export const IsMacOs = Platform.OS === "macos";

export const IsMobileDevice = Platform.OS !== "web" || mediaQuerySmallerThan("sm");

// Approximate height of the header on both platforms.
const HEADER_HEIGHT = 88;
// On Android, we need to account for the status bar height. iOS weirdly doesn't, probably because
// of the safe area view we use.
export const KEYBOARD_VERTICAL_OFFSET = IsIos
  ? HEADER_HEIGHT
  : HEADER_HEIGHT + (StatusBar.currentHeight || 0);

const emulatorModelNames = ["sdk_gphone_x86", "sdk_gphone_x86_arm"];
const getIsEmulator = (): boolean => {
  if (Platform.OS === "android") {
    return Boolean(
      !Constants.isDevice ||
        (Constants.deviceName && emulatorModelNames.includes(Constants.deviceName))
    );
  } else if (Platform.OS === "ios") {
    return !Constants.isDevice;
  } else {
    return false; // Default to false if not iOS or Android
  }
};

export const IsEmulator = getIsEmulator();

export const IsWeb = Platform.OS === "web";
