import {baseWebsocketsUrl, getAuthToken, useSelectCurrentUserId} from "@ferns-rtk";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  flourishApi,
  TagType,
  useAppDispatch,
  useGetUsersByIdQuery,
  useSelectInternalChatConversationId,
  useSelectInternalChatIsFocused,
} from "@store";
import {useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from "react";

interface SocketConnection {
  isConnected: boolean;
  lastDisconnectedAt: string | null;
}

import {io, Socket} from "socket.io-client";

import {captureEvent, playSound, unloadSounds} from "../utils";

// Create a Context

interface SocketProviderProps {
  children: React.ReactElement;
}

// Provider Component
export const SocketProvider = ({children}: SocketProviderProps): React.ReactElement => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const selectedInternalChatConversation = useSelectInternalChatConversationId();
  const internalChatIsFocused = useSelectInternalChatIsFocused();

  // Only connect to the socket once
  useEffect(() => {
    // Initialize socket connection

    const socketIo = io(baseWebsocketsUrl, {
      transports: ["websocket"], // you need to explicitly tell it to use websockets
      autoConnect: false,
    });

    setSocket(socketIo);

    return (): void => {
      socketIo.disconnect();
    };
  }, []);

  const toast = useToast();
  const isConnectedRef = useRef<SocketConnection>();
  const [isSocketConnected, setIsSocketConnected] = useState<SocketConnection>({
    isConnected: socket?.connected ?? false,
    lastDisconnectedAt: null,
  });
  const dispatch = useAppDispatch();
  const currentUserId = useSelectCurrentUserId();
  const {data: user} = useGetUsersByIdQuery(currentUserId ?? skipToken);
  const disconnectedToastId = useRef<string | null>(null);
  // Keep ref updated with latest socket connection state
  useEffect(() => {
    // need this to keep ref updated so we can use it in the timeout
    isConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  // Connect/disconnect socket when user logs in/out
  useEffect(() => {
    const connectSocket = async (): Promise<void> => {
      const token = await getAuthToken();
      if (socket) {
        console.debug(`Socket connecting ${Boolean(token) ? "with" : "without"} token`);
        socket.auth = {token: `Bearer ${token}`};
        socket.connect();
      }
    };

    if (currentUserId && user?._id && !isSocketConnected.isConnected) {
      console.debug("Connecting socket");
      void connectSocket();
    }
    if ((!currentUserId || !user?._id) && isSocketConnected.isConnected) {
      console.debug("Disconnecting socket due to no logged in user");
      socket?.disconnect();
      setIsSocketConnected({
        isConnected: false,
        lastDisconnectedAt: null, // we want null because this was intentional, not due to an error condition
      });
    }
  }, [currentUserId, isSocketConnected, socket, user?._id]);

  const hideDisconnectedToast = useCallback((): void => {
    if (disconnectedToastId.current) {
      toast.hide(disconnectedToastId.current);
    }
    disconnectedToastId.current = null;
  }, [disconnectedToastId, toast]);

  // Show toast when disconnected
  useEffect(() => {
    const checkShowToast = async (): Promise<void> => {
      // if was disconnected and now connected, don't show toast since it resolved itself within 10
      // seconds otherwise, show toast for disconnect using 9 seconds to avoid race between
      // calculation and timeout making this request
      if (
        isConnectedRef.current &&
        !isConnectedRef.current.isConnected &&
        !disconnectedToastId.current &&
        isSocketConnected.lastDisconnectedAt &&
        DateTime.now().diff(DateTime.fromISO(isSocketConnected.lastDisconnectedAt), "seconds")
          .seconds > 9 &&
        currentUserId
      ) {
        disconnectedToastId.current = toast.show(
          "You have been disconnected. Attempting to reconnect...",
          {
            persistent: true,
            onDismiss: (): void => hideDisconnectedToast(),
          }
        );
      }
    };

    let intervalId: NodeJS.Timeout | null = null;

    // Check every second if we've reconnected
    const startCheckingConnection = (): void => {
      if (!isSocketConnected.isConnected && !intervalId) {
        intervalId = setInterval(async () => {
          await checkShowToast();
          if (isSocketConnected.isConnected && intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 1000);
      }
    };

    startCheckingConnection();

    return (): void => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isSocketConnected, hideDisconnectedToast, toast, currentUserId]);

  // Set up basic socket event listeners (connect, disconnect, changeEvent)
  useEffect(() => {
    if (!socket) return;

    const onConnect = (): void => {
      console.debug("Socket connected");
      if (disconnectedToastId.current) {
        hideDisconnectedToast();
      }
      // don't show toast if was disconnected and now connected within 10 seconds
      if (
        isSocketConnected.lastDisconnectedAt &&
        DateTime.now().diff(DateTime.fromISO(isSocketConnected.lastDisconnectedAt), "seconds")
          .seconds > 10
      ) {
        toast.show("You have been reconnected.");
      }
      setIsSocketConnected({
        isConnected: true,
        lastDisconnectedAt: null,
      });
    };

    const onDisconnect = async (): Promise<void> => {
      console.debug("Socket disconnected");
      await unloadSounds();
      setIsSocketConnected({
        isConnected: false,
        lastDisconnectedAt: DateTime.now().toISO(),
      });
      captureEvent("WebSocket Disconnection", {
        userType: user?.type ?? "unknown",
        time: new Date().toISOString(),
      });
    };

    const onSocketEvent = (data: any): void => {
      // Possible operation types include: "delete" | "invalidate" | "update" | "replace" |
      // "insert" | "drop" | "dropDatabase" | "rename"
      // Currently only invalidating in response to "insert" events
      const collection: any = data.collection;
      if (data.type === "insert") {
        dispatch(flourishApi.util.invalidateTags([collection]));
      }
      if (data.type === "update") {
        if (collection === "conversationstatuses") {
          // We need to invalidate this because we need conversations to refresh the unread count
          // when conversationStatus documents are updated on the backend to fix a bug where a
          // user's unread counts were out of sync across devices
          // TODO: This is a hacky bug fix for now - we should try to find a way to do this without
          // invalidating all conversations for all users
          dispatch(flourishApi.util.invalidateTags(["conversations"]));
        } else if (collection === "conversations") {
          // We need to invalidate the whole conversations collection,
          // otherwise users added during an update will not see new conversations without
          // refreshing
          dispatch(flourishApi.util.invalidateTags([collection]));
        } else if (collection === "users") {
          dispatch(flourishApi.util.invalidateTags([{type: collection, id: data._id}]));
          // We need to invalidate conversations when updating a user so that changes (especially
          // online status) are reflected in the UI
          dispatch(flourishApi.util.invalidateTags(["conversations"]));
          // invalidate alertInstances when updating a user so that changes are reflected in the UI
          dispatch(flourishApi.util.invalidateTags(["alertinstances"]));
        } else if (collection === "notifications") {
          // TODO: Revisit to see if we can invalidate messages by ID instead of the whole
          // collection
          dispatch(flourishApi.util.invalidateTags([{type: "messages"}]));
        } else if (collection === "usersessions") {
          // Required so that the user's online status (dnd, last online,
          // etc) is updated in the UI across users across browser sessions
          dispatch(
            flourishApi.util.updateQueryData("getUserSessions", {page: 1}, (draft) => {
              // Update the local RTK query cache with the updated user session data
              draft.data = draft.data?.map((doc) =>
                doc._id === data._id ? Object.assign(doc, data.updatedFields) : doc
              );
            })
          );
        } else {
          dispatch(flourishApi.util.invalidateTags([{type: collection, id: data._id}]));
        }
      }
    };

    const onInvalidateTagEvent = async (data: {collection: TagType}): Promise<void> => {
      dispatch(flourishApi.util.invalidateTags([data.collection]));
    };

    // Attach basic event listeners
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("changeEvent", onSocketEvent);
    socket.on(`invalidateTagEvent`, onInvalidateTagEvent);

    // Cleanup function to remove event listeners
    return (): void => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("changeEvent", onSocketEvent);
      socket.off(`invalidateTagEvent`, onInvalidateTagEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // Handle notification events separately based on internal chat state
  useEffect(() => {
    if (!socket || !currentUserId) return;

    const onNotificationEvent = async (data: {
      conversationId?: string;
      sound: string;
    }): Promise<void> => {
      if (
        data.conversationId &&
        data.conversationId === selectedInternalChatConversation &&
        internalChatIsFocused
      ) {
        // Don't play a sound if the internal chat is focused and the notification is for the
        // conversation that is selected
        return;
      }
      await playSound(data.sound);
    };

    // Attach notification event listener
    socket.on(`notificationEvent${currentUserId}`, onNotificationEvent);

    // Cleanup to avoid multiple listeners
    return (): void => {
      socket.off(`notificationEvent${currentUserId}`, onNotificationEvent);
    };
  }, [socket, selectedInternalChatConversation, internalChatIsFocused, currentUserId]);

  return <SocketContext.Provider value={{socket}}>{children}</SocketContext.Provider>;
};

interface ISocketContext {
  socket: Socket | null;
}

// Create a context with a default value of null for the socket
export const SocketContext = createContext<ISocketContext>({socket: null});

// Custom hook to use the socket context
export const useSocket = (): ISocketContext => useContext(SocketContext);
