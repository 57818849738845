import {Text} from "ferns-ui";
import React, {ReactElement} from "react";

export const QuestionPromptText = ({
  index,
  prompt,
}: {
  index?: number;
  prompt: string;
}): ReactElement | null => {
  return (
    <Text bold>
      {typeof index === "number" ? `${index + 1}: ` : null}
      {prompt}
    </Text>
  );
};
