const styleString = (color: string): string => `color: ${color}; font-weight: bold`;
const headerLog = "%c[react-native-gifted-chat]";

export const warning = (...args: any): void => {
  // eslint-disable-next-line no-console
  console.log(headerLog, styleString("orange"), ...args);
};

export const error = (...args: any): void => {
  // eslint-disable-next-line no-console
  console.log(headerLog, styleString("red"), ...args);
};
