import {Box, Icon, Text} from "ferns-ui";
import React from "react";

export const InfoLine = ({
  type,
  text,
}: {
  type: "info" | "error" | "warn";
  text: string;
}): React.ReactElement => {
  const color = type === "error" ? "error" : type === "warn" ? "warning" : "primary";
  const iconName =
    type === "error"
      ? "circle-exclamation"
      : type === "warn"
        ? "triangle-exclamation"
        : "circle-info";
  return (
    <Box direction="row" marginBottom={2} width="100%">
      <Box marginRight={4}>
        <Icon color={color} iconName={iconName} size="lg" />
      </Box>
      <Box flex="grow">
        <Text bold size="lg">
          {text}
        </Text>
      </Box>
    </Box>
  );
};
