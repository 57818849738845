import {ActionSheetProvider, ActionSheetProviderRef} from "@expo/react-native-action-sheet";
import {usePrevious} from "@hooks";
import {IsAndroid} from "@utils";
import {Spinner} from "ferns-ui";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Animated, Keyboard, KeyboardAvoidingView, LayoutChangeEvent, View} from "react-native";
import {v4} from "uuid";

import {
  DEFAULT_PLACEHOLDER,
  MAX_COMPOSER_HEIGHT,
  MIN_COMPOSER_HEIGHT,
  TEST_ID,
  TIME_FORMAT,
} from "./Constant";
import {GiftedChatProvider} from "./GiftedChatContext";
import {DeleteModal} from "./GiftedDeleteModal";
import {InputToolbar} from "./InputToolbar";
import {MessageContainer, MessageContainerHandles} from "./MessageContainer";
import {GiftedChatProps, IMessage, SendOptions} from "./Models";

interface GiftedChatHandles {
  scrollToBottom(animated?: boolean): void;
  focusTextInput(): void;
}

export const GiftedChat = React.forwardRef<GiftedChatHandles, GiftedChatProps>(
  <TMessage extends IMessage = IMessage>(
    {
      allowMentions,
      bottomOffset: propsBottomOffset,
      dateFormat,
      disableComposer = false,
      forceGetKeyboardHeight = false,
      infiniteScroll,
      initialText,
      inverted = true,
      isKeyboardInternallyHandled = true,
      isLoadingEarlier = false,
      isTyping = false,
      keyboardAppearance,
      // keyboardShouldPersistTaps = Platform.select({
      //   ios: "never",
      //   android: "always",
      //   default: "never",
      // }),
      lightboxProps = {},
      loadEarlier = false,
      locale = "en",
      maxComposerHeight = MAX_COMPOSER_HEIGHT,
      maxInputLength,
      messageIdGenerator = (): string => v4(),
      messages = [],
      messagesContainerStyle,
      minComposerHeight = MIN_COMPOSER_HEIGHT,
      minInputToolbarHeight = 44,
      multiline,
      onInputTextChanged,
      onLoadEarlier = (): void => {},
      onLongPressAvatar,
      onMentionSelect,
      onPressActionButton,
      onPressAvatar,
      onSelectMessageIds,
      onSend: propsSend = (): void => {},
      onTaggedUserChange,
      placeholder = DEFAULT_PLACEHOLDER,
      placeholderColor,
      renderAccessory,
      renderActions,
      renderAvatar,
      renderAvatarOnTop = false,
      renderBubble,
      renderChatEmpty,
      renderChatFooter,
      renderComposer,
      renderDay,
      renderFooter,
      renderInputToolbar,
      renderLoadEarlier,
      renderLoading,
      renderMessage,
      renderMessageAudio,
      renderMessageImage,
      renderMessageText,
      renderMessageVideo,
      renderSend,
      renderSystemMessage,
      renderTime,
      selectedMessageIds,
      sendAsSms,
      setSendAsSmsForConversation,
      showSendAsSms,
      shouldShowDeliveryIcons,
      shouldUpdateMessage,
      showEmojiPicker,
      showMessageSelector,
      showUserAvatar = false,
      taggableUsers,
      textInputAutoFocus,
      textInputProps = {},
      textInputStyle,
      timeFormat = TIME_FORMAT,
      user,
      removeMessage,
      removeMessageLoading,
      removeMessageError,
      enableRemoveMessage,
      enableMarkUnread,
      onMarkUnread,
      enableMessageDebugging,
      conversationId,
    }: GiftedChatProps<TMessage>,
    ref: React.ForwardedRef<any>
  ): React.ReactElement => {
    const messageContainerRef = useRef<MessageContainerHandles>(null);
    const actionSheetRef = useRef<ActionSheetProviderRef>(null);

    const scrollToBottom = useCallback(
      (animated = true) => {
        if (messageContainerRef?.current) {
          if (inverted) {
            messageContainerRef.current.scrollToBottom(animated);
          } else {
            messageContainerRef.current.scrollTo({animated, offset: 0});
          }
        }
      },
      [inverted, messageContainerRef]
    );

    useImperativeHandle(ref, () => ({
      scrollToBottom,
      focusTextInput,
    }));

    const isMounted = useRef(false);
    const keyboardHeight = useRef(0);
    const bottomOffset = useRef(propsBottomOffset || 0);
    const maxHeight = useRef<number | undefined>(undefined);
    const isFirstLayout = useRef(true);

    const prevMessages = usePrevious(messages);

    let isTextInputWasFocused: boolean = false;
    let textInput: any;
    const [isInitialized, setIsInitialized] = useState(false);
    const [composerHeight, setComposerHeight] = useState(minComposerHeight);
    const [messagesContainerHeight, setMessagesContainerHeight] = useState<
      number | Animated.Value
    >();
    const [isTypingDisabled, setIsTypingDisabled] = useState(false);

    // Initialization when the component is mounted.
    useEffect((): (() => void) => {
      isMounted.current = true;
      // When we open the keyboard, scroll to the bottom
      const didShowListener = Keyboard.addListener?.("keyboardDidShow", (error): void => {
        messageContainerRef?.current?.scrollToBottom(true);
        onKeyboardDidShow(error);
      });

      const didHideListener = Keyboard.addListener?.("keyboardDidHide", (error): void => {
        messageContainerRef?.current?.scrollToBottom(true);
        onKeyboardDidHide(error);
      });

      return () => {
        isMounted.current = false;
        didShowListener.remove();
        didHideListener.remove();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // When receiving a new message, scroll to the bottom.
    useEffect(() => {
      if (messages?.slice(-1)?.[0]?._id !== prevMessages?.slice(-1)?.[0]?._id) {
        setTimeout(() => scrollToBottom(false), 200);
      }
    }, [inverted, messages, prevMessages, scrollToBottom]);

    const getKeyboardHeight = (): number => {
      if (IsAndroid && !forceGetKeyboardHeight) {
        // For android: on-screen keyboard resized main container and has own height.
        // @see https://developer.android.com/training/keyboard-input/visibility.html
        // So for calculate the messages container height ignore keyboard height.
        return 0;
      }

      return keyboardHeight.current;
    };

    /**
     * Returns the height, based on current window size, without taking the keyboard into account.
     */
    const getBasicMessagesContainerHeight = (ch = composerHeight): number => {
      let getMinInputToolbarHeight = minInputToolbarHeight;
      if (renderAccessory) {
        getMinInputToolbarHeight = minInputToolbarHeight! * 2;
      }
      if (showEmojiPicker || showSendAsSms || allowMentions) {
        getMinInputToolbarHeight += 48;
      }

      const inputToolbarHeight = (ch ?? 0) + (getMinInputToolbarHeight! - minComposerHeight!);
      return maxHeight.current! - inputToolbarHeight;
    };

    /**
     * Returns the height, based on current window size, taking the keyboard into account.
     */
    const getMessagesContainerHeightWithKeyboard = (ch = composerHeight): number => {
      return getBasicMessagesContainerHeight(ch) - getKeyboardHeight() + bottomOffset.current;
    };

    /**
     * Store text input focus status when keyboard hide to retrieve
     * it after wards if needed.
     * `onKeyboardWillHide` may be called twice in sequence so we
     * make a guard condition (eg. showing image picker)
     */
    const handleTextInputFocusWhenKeyboardHide = (): void => {
      if (!isTextInputWasFocused) {
        isTextInputWasFocused = textInput?.isFocused() || false;
      }
    };

    /**
     * Refocus the text input only if it was focused before showing keyboard.
     * This is needed in some cases (eg. showing image picker).
     */
    const handleTextInputFocusWhenKeyboardShow = (): void => {
      if (textInput && isTextInputWasFocused && !textInput.isFocused()) {
        textInput.focus();
      }

      // Reset the indicator since the keyboard is shown
      isTextInputWasFocused = false;
    };

    const onKeyboardWillShow = (e: any): void => {
      handleTextInputFocusWhenKeyboardShow();

      if (isKeyboardInternallyHandled) {
        setIsTypingDisabled(true);
        keyboardHeight.current = e.endCoordinates?.height || e.end.height;
        bottomOffset.current = bottomOffset.current ?? 1;
        const newMessagesContainerHeight = getMessagesContainerHeightWithKeyboard();

        setMessagesContainerHeight(newMessagesContainerHeight);
      }
    };

    const onKeyboardWillHide = (_e: any): void => {
      handleTextInputFocusWhenKeyboardHide();

      if (isKeyboardInternallyHandled) {
        setIsTypingDisabled(true);
        keyboardHeight.current = 0;
        bottomOffset.current = 0;
        const newMessagesContainerHeight = getBasicMessagesContainerHeight();
        setMessagesContainerHeight(newMessagesContainerHeight);
      }
    };

    const onKeyboardDidShow = (error: any): void => {
      if (IsAndroid) {
        onKeyboardWillShow(error);
      }
      setIsTypingDisabled(false);
    };

    const onKeyboardDidHide = (error: any): void => {
      if (IsAndroid) {
        onKeyboardWillHide(error);
      }
      setIsTypingDisabled(false);
    };

    const renderMessages = (): React.ReactElement | null => {
      const fragment = (
        <>
          <DeleteModal />
          <View
            style={[
              {
                height: messagesContainerHeight,
              },
              messagesContainerStyle,
            ]}
          >
            <MessageContainer
              ref={messageContainerRef}
              dateFormat={dateFormat}
              infiniteScroll={infiniteScroll}
              inverted={inverted}
              isLoadingEarlier={isLoadingEarlier}
              isTyping={isTyping}
              lightboxProps={lightboxProps}
              loadEarlier={loadEarlier}
              messages={messages}
              renderAvatar={renderAvatar}
              renderAvatarOnTop={renderAvatarOnTop}
              renderBubble={renderBubble}
              renderChatEmpty={renderChatEmpty}
              renderDay={renderDay}
              renderFooter={renderFooter}
              renderLoadEarlier={renderLoadEarlier}
              renderMessage={renderMessage}
              renderMessageAudio={renderMessageAudio}
              renderMessageImage={renderMessageImage}
              renderMessageText={renderMessageText}
              renderMessageVideo={renderMessageVideo}
              renderSystemMessage={renderSystemMessage}
              renderTime={renderTime}
              selectedMessageIds={selectedMessageIds}
              shouldUpdateMessage={shouldUpdateMessage}
              showMessageSelector={showMessageSelector}
              showScrollToBottom
              showUserAvatar={showUserAvatar}
              timeFormat={timeFormat}
              user={user}
              onLoadEarlier={onLoadEarlier}
              onLongPressAvatar={onLongPressAvatar}
              onPressAvatar={onPressAvatar}
              onSelectMessageIds={onSelectMessageIds}
            />
            {renderChatFooter?.()}
          </View>
        </>
      );

      return isKeyboardInternallyHandled ? (
        <KeyboardAvoidingView
          // behavior={Platform.OS === "ios" ? "position" : undefined}
          enabled
          // keyboardVerticalOffset={Platform.OS === "ios" ? 64 : 0}
        >
          {fragment}
        </KeyboardAvoidingView>
      ) : (
        fragment
      );
    };

    const onSend = async (msgs: TMessage[] = [], options: SendOptions): Promise<void> => {
      if (!Array.isArray(msgs)) {
        msgs = [msgs];
      }
      const newMessages: TMessage[] = msgs.map((msg) => {
        return {
          ...msg,
          user: user!,
          createdAt: new Date(),
          _id: messageIdGenerator?.(),
        };
      });

      if (propsSend) {
        await propsSend(newMessages, options);
      }
      resetInputToolbar();
    };

    const resetInputToolbar = (): void => {
      if (textInput) {
        textInput.clear();
      }
      onInputTextChanged?.("");
      const newMessagesContainerHeight = getMessagesContainerHeightWithKeyboard(minComposerHeight);

      setComposerHeight(minComposerHeight);
      setMessagesContainerHeight(newMessagesContainerHeight);
    };

    const focusTextInput = (): void => {
      textInput?.focus();
    };

    const onInputSizeChanged = (size: {height: number}): void => {
      const newComposerHeight = Math.max(
        minComposerHeight!,
        Math.min(maxComposerHeight!, size.height)
      );
      const newMessagesContainerHeight = getMessagesContainerHeightWithKeyboard(newComposerHeight);
      if (newComposerHeight !== composerHeight) {
        setComposerHeight(newComposerHeight);
        setMessagesContainerHeight(newMessagesContainerHeight);
      }
    };

    const doOnInputTextChanged = (t: string): void => {
      if (isTypingDisabled && !doOnInputTextChanged) {
        return;
      }

      if (onInputTextChanged) {
        onInputTextChanged(t);
      }
    };

    const onInitialLayoutViewLayout = (e: any): void => {
      const {layout} = e.nativeEvent;
      if (layout.height <= 0) {
        return;
      }
      // This triggers an inputTextChange and resets any cache on initial render.
      // Commenting out for now because this will clear our drafted message
      // whenever we switch between conversations
      // onInputTextChanged?.("");
      maxHeight.current = layout.height;
      const newComposerHeight = minComposerHeight;
      const newMessagesContainerHeight = getMessagesContainerHeightWithKeyboard(newComposerHeight);
      setIsInitialized(true);
      setComposerHeight(newComposerHeight);
      setMessagesContainerHeight(newMessagesContainerHeight);
    };

    const onMainViewLayout = (e: LayoutChangeEvent): void => {
      // TODO: fix an issue when keyboard is dismissing during the initialization
      const {layout} = e.nativeEvent;
      if (maxHeight.current !== layout.height || isFirstLayout) {
        maxHeight.current = layout.height;
        setMessagesContainerHeight(
          keyboardHeight.current > 0
            ? getMessagesContainerHeightWithKeyboard()
            : getBasicMessagesContainerHeight()
        );
      }
      if (isFirstLayout.current) {
        isFirstLayout.current = false;
      }
    };

    const internalRenderInputToolbar = (): React.ReactElement | null => {
      const inputToolbarProps = {
        allowMentions,
        composerHeight: Math.max(minComposerHeight!, composerHeight!),
        disableComposer,
        keyboardAppearance,
        multiline,
        onInputSizeChanged,
        onMentionSelect,
        onPressActionButton,
        onSend,
        onTaggedUserChange,
        onTextChanged: doOnInputTextChanged,
        placeholder,
        placeholderColor,
        renderAccessory,
        renderActions,
        renderComposer,
        renderSend,
        showEmojiPicker,
        taggableUsers,
        textInputAutoFocus,
        textInputProps: {
          ...textInputProps,
          ref: (t: any): any => (textInput = t),
          maxLength: isTypingDisabled ? 0 : maxInputLength,
        },
        textInputStyle,
      };

      if (renderInputToolbar) {
        return renderInputToolbar(inputToolbarProps);
      }
      return <InputToolbar {...inputToolbarProps} />;
    };

    if (isInitialized) {
      return (
        <GiftedChatProvider
          value={{
            // TODO: use ferns-ui action sheet
            actionSheet: () => actionSheetRef.current?.getContext()! as any,
            getLocale: () => locale,
            sendAsSms,
            setSendAsSmsForConversation,
            showSendAsSms,
            shouldShowDeliveryIcons,
            removeMessage,
            removeMessageLoading,
            removeMessageError,
            enableRemoveMessage,
            enableMarkUnread,
            onMarkUnread,
            enableMessageDebugging,
            initialText,
            conversationId,
          }}
        >
          <ActionSheetProvider ref={actionSheetRef}>
            <View style={{flex: 1}} onLayout={onMainViewLayout}>
              {renderMessages()}
              {internalRenderInputToolbar()}
            </View>
          </ActionSheetProvider>
        </GiftedChatProvider>
      );
    }

    return (
      <View style={{flex: 1}} testID={TEST_ID.LOADING_WRAPPER} onLayout={onInitialLayoutViewLayout}>
        {renderLoading ? renderLoading() : <Spinner />}
      </View>
    );
  }
);

GiftedChat.displayName = "GiftedChat";
