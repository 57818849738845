import {populateId} from "@ferns-rtk";
import {useAnalytics} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {
  getObjectDifferences,
  splitOnUpperCase,
  StaffRoles,
  useGetCarePodsQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  User,
} from "@store";
import {StaffStackParamList} from "@types";
import {isGuideOnly, isStaff, UserTypes} from "@utils";
import {BorderColor, Box, Icon, Text, useToast} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {TapToEditRow} from "./TapToEditRow";
import {UserInfoCard} from "./UserInfoCard";

const rowBorder = {borderBottom: "default" as BorderColor};

interface ClinicalInfoProps {
  userId: string;
  collapseAll?: boolean;
}

export const ClinicalInfo = ({
  userId,
  collapseAll,
}: ClinicalInfoProps): React.ReactElement | null => {
  const logEvent = useAnalytics();
  const toast = useToast();
  const [updateUser] = usePatchUsersByIdMutation();
  // This should always hit the cache.
  const {data: userData} = useGetUsersByIdQuery(userId);
  const {data: carePodData} = useGetCarePodsQuery({});

  const [user, setUser] = useState<User | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  // This is a hack to get the user data to load in the UI. It's not ideal, but it works.
  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  return (
    <Box color="base">
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        enableEditButton
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        title="Clinical Info"
        onCancel={(): void => setUser(userData)}
        onSave={async (): Promise<void> => {
          await updateUser({id: user._id, ...getObjectDifferences(user, userData)});
        }}
      >
        <TapToEditRow
          isEditing={isEditing}
          setValue={(value: string): void => {
            setUser({...user, growth: {...user.growth, documentFolder: value.trim()}});
          }}
          title="Google Drive"
          type="url"
          value={user.growth?.documentFolder ?? ""}
          onSave={async (value): Promise<void> => {
            if (!value.startsWith("https://drive.google.com/drive/folders/")) {
              toast.error("Document Folder must be a Google Drive folder link");
              return;
            }
            await updateUser({
              id: user._id,
              body: {
                growth: {...user.growth, documentFolder: value.trim()},
              },
            });
          }}
        />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Care Team">
        <>
          <TapToEditRow
            confirmationText={`Warning: Please remove ALL existing schedule items before changing a user's care pod, or the calendar events will get stuck on google calendar, even when deleted from the app. \n\nAre you sure the user has all schedule items deleted, and you'd like to change their care pod?`}
            isEditing={isEditing}
            options={[
              ...(carePodData?.data?.map((pod) => ({label: pod.name, value: pod._id})) ?? []),
            ]}
            placeholder="---"
            setValue={(value: string): void => {
              setUser({...user, carePod: value});
            }}
            title="Care Pod"
            transform={(carePod): string => {
              if (!user?.carePod) {
                return "Not Set";
              }
              return populateId(carePod, carePodData)?.name ?? "Unknown";
            }}
            type="select"
            value={user.carePod}
            withConfirmation
            onSave={async (value): Promise<void> => {
              await updateUser({
                id: user._id,
                body: {carePod: value ? value : null},
              });
            }}
          />
          {[
            StaffRoles.Psychiatrist,
            StaffRoles.Therapist,
            StaffRoles.PatientGuide,
            StaffRoles.FamilyGuide,
          ].map((role) => {
            if (
              isGuideOnly(user) &&
              (role === StaffRoles.Psychiatrist || role === StaffRoles.Therapist)
            ) {
              return null;
            }
            const careTeamMember = user.careTeam[role as keyof typeof user.careTeam];
            return (
              <Box
                key={role}
                direction="row"
                justifyContent="between"
                paddingY={2}
                width="100%"
                {...rowBorder}
              >
                <Box>
                  <Text bold>{splitOnUpperCase(role)}</Text>
                </Box>
                <Box direction="row">
                  <Box>
                    <Text>
                      {careTeamMember ? (careTeamMember?.name ?? "Loading..") : "Not Set"}
                    </Text>
                  </Box>

                  <Box
                    accessibilityHint="Edit Care Team Member"
                    accessibilityLabel="Edit"
                    marginLeft={2}
                    onClick={(): void => {
                      navigation.navigate("UserPicker", {
                        staff: true,
                        userFilter: (u: User | null): boolean =>
                          Boolean(
                            u?.staffRoles[
                              role as "Psychiatrist" | "Therapist" | "PatientGuide" | "FamilyGuide"
                            ]
                          ),
                        onSelect: async (selectedUser: User | null) => {
                          await updateUser({
                            id: user._id,
                            body: {
                              type: user.type,
                              [`careTeam.${role}`]: selectedUser?._id ?? null,
                            },
                          });
                          const prevValue = `${role}: ${careTeamMember?.name || ""}`;
                          const newValue = `${role}: ${selectedUser?.name}`;
                          await logEvent({
                            name: "SetCareTeam",
                            role,
                            staffId: selectedUser?._id,
                            // type is reserved by mongo and cannot be a property
                            userType: user.type,
                            collectionModel: "users",
                            isActivityLogEvent: true,
                            appliedUserId: user._id,
                            docId: user._id,
                            payload: {
                              prevValue,
                              newValue,
                            },
                          });
                        },
                      });
                    }}
                  >
                    <Icon iconName="pencil" />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </>
      </UserInfoCard>
    </Box>
  );
};
