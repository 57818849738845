import {INACTIVITY_TIMEOUT_SECONDS} from "@constants";
import {useLogoutUser, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {skipToken} from "@reduxjs/toolkit/query";
import {StaffStackScreenProps} from "@types";
import {Audio} from "expo-av";
import {Sound} from "expo-av/build/Audio";
import {Box, Heading, Modal, Text, useToast} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {refreshAuthToken, shouldShowStillThereModal} from "../ferns-rtk";
import {useGetUserSessionsQuery, usePatchUserSessionsByIdMutation} from "../store";

async function loadStillThereSound(): Promise<Sound> {
  const {sound} = await Audio.Sound.createAsync(require("../assets/audio/notification.mp3"));
  const _onPlaybackStatusUpdate = async (playbackStatus: any): Promise<void> => {
    if (playbackStatus.didJustFinish) {
      await sound.unloadAsync();
    }
  };

  sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);
  return sound;
}

interface Props extends StaffStackScreenProps<"StillThere"> {}

export const StillThereModal = ({route}: Props): React.ReactElement => {
  // User has 60 seconds to hit yes.
  const [timer, setTimer] = useState(60);
  const logoutUser = useLogoutUser();
  const toast = useToast();
  const navigation = useNavigation<any>();

  const profile = useReadProfile();
  const {data: userSessionsData} = useGetUserSessionsQuery(
    profile ? {ownerId: profile._id} : skipToken
  );

  const userSessions = userSessionsData?.data?.[0];
  const [updateUserSessions] = usePatchUserSessionsByIdMutation();

  // After 60 seconds, the user is logged out by the backend.
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!(await shouldShowStillThereModal())) {
        navigation.goBack();
      }
      setTimer(timer - 1);
    }, 1000);
    return (): void => clearTimeout(timeout);
  }, [timer, navigation, logoutUser]);

  // When first showing the modal, play the sound.
  useEffect(() => {
    async function playSound(): Promise<void> {
      const sound = await loadStillThereSound();
      if (!__DEV__) {
        await sound.playAsync();
      }
    }
    void playSound();
  }, []);

  const confirmHere = (): void => {
    refreshAuthToken().catch(toast.catch);
    // Hacky way to make absolutely certain that a request is made when the user confirms they are
    // still there to avoid token expiration and lost work.
    if (userSessions?._id) {
      void updateUserSessions({
        id: userSessions._id,
        body: {
          lastOnlineWeb: new Date().toISOString(),
        },
      });
    }
    if (route.params.onStillThere) {
      route.params.onStillThere();
    }
  };

  return (
    <Modal
      primaryButtonOnClick={confirmHere}
      primaryButtonText="Yes"
      secondaryButtonOnClick={async (): Promise<void> => {
        await logoutUser();
      }}
      secondaryButtonText="Log Out"
      visible
      onDismiss={(): void => {}}
    >
      <Box>
        <Box marginBottom={4}>
          <Heading>Are you still there?</Heading>
        </Box>
        <Box paddingY={4}>
          <Text>
            The app automatically logs you out after {Math.floor(INACTIVITY_TIMEOUT_SECONDS / 60)}{" "}
            minutes for security and HIPAA compliance.
          </Text>
        </Box>
        <Box paddingY={4}>
          <Text>You will automatically be logged out in {Math.max(timer, 0)} seconds</Text>
        </Box>
      </Box>
    </Modal>
  );
};
