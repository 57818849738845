// A generic component for displaying a table of data.
// The intent is to open source this as part of ferns-api and ferns-ui to help build out an admin
// interface for data.
import {Box, Spinner, Table, TableHeader, TableHeaderCell, TableRow, Text} from "ferns-ui";
import React from "react";

import {CheckedCell} from "./CheckedCell";
import {NameCell} from "./NameCell";
import {TextCell} from "./TextCell";

interface DataExplorerProps {
  // When the row is expanded, the drawerContents are shown. If drawerContents is not provided,
  // no expandable arrow is shown.
  drawerComponent?: React.FC<{extraData: any; dataId: string; refetch: () => void}>;

  // A hook that returns the data for the table. We take a hook instead of the data so the table
  // can apply sorting and filtering.
  hook: any;

  // Optional prop for pagination
  page?: number;

  // Extra props for the hook
  filters?: Record<string, string | undefined>;
}

const COLUMN_MAP = {
  text: TextCell,
  email: TextCell,
  name: NameCell,
  boolean: CheckedCell,
};

export const DataExplorer: React.FC<DataExplorerProps> = ({
  // Rename because React gets confused by lowercase components.
  drawerComponent: DrawerComponent,
  hook,
  filters,
  page = 1,
}) => {
  const {
    data: explorerDataResult,
    refetch,
    isLoading,
  } = hook(
    {
      page,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const {columns, rowData: explorerData} = explorerDataResult?.data ?? {};

  if (columns === undefined || explorerData === undefined || isLoading) {
    return <Spinner />;
  }

  if (explorerData.length === 0) {
    return (
      <Box alignItems="center" paddingY={8}>
        <Text>No data found</Text>
      </Box>
    );
  }

  return (
    <Table columns={columns.map((c: any) => c.width)}>
      <TableHeader>
        {columns.map((column: any, index: number) => (
          <TableHeaderCell key={column.title} index={index} sortable={Boolean(column.sort)}>
            <Box flex="grow" justifyContent="center" width="100%" wrap>
              <Text bold size="sm">
                {column.title}
              </Text>
            </Box>
          </TableHeaderCell>
        ))}
      </TableHeader>
      {explorerData.map((userData: any, row: number) => {
        // First column is always the data id, last column is always extra data (if any) for the
        // drawer
        const dataId = userData?.[0];
        const extraData = userData?.[userData.length - 1];
        const data = userData?.slice(1, -1);

        return (
          <TableRow
            key={row}
            drawerContents={
              DrawerComponent ? (
                <DrawerComponent dataId={dataId} extraData={extraData} refetch={refetch} />
              ) : undefined
            }
          >
            {columns.map((column: any, index: number) => {
              let Component = COLUMN_MAP[column.columnType as keyof typeof COLUMN_MAP] as any;
              if (!Component) {
                console.warn(`Unknown column type ${column.columnType} for ${column.title}`);
                Component = TextCell;
              }
              if (typeof data[index] === "object") {
                return <Component key={column.title + index} {...data[index]} />;
              } else if (column.columnType === "boolean") {
                return <Component key={column.title + index} checked={data[index]} />;
              } else {
                return <Component key={column.title + index} text={data[index]} />;
              }
            })}
          </TableRow>
        );
      })}
    </Table>
  );
};
