import {CarePod, useGetCarePodsQuery} from "@store";
import {SelectField, SelectFieldPropsWithoutRequire} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React from "react";

interface CarePodDropdownProps extends Omit<SelectFieldPropsWithoutRequire, "options"> {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const CarePodDropdown = ({
  value,
  onChange,
  ...fieldProps
}: CarePodDropdownProps): React.ReactElement => {
  const {data: carepods} = useGetCarePodsQuery({});
  const options = [
    ...(sortBy(carepods?.data, "name") as CarePod[]).map((cp) => ({
      label: cp?.name ?? "",
      value: cp?._id ?? "",
    })),
  ];

  return (
    <SelectField
      requireValue={false}
      title="Care Pod"
      {...fieldProps}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
