import {
  Address,
  Contact,
  ContactTypes,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  useSentryAndToast,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {
  AddressField,
  BooleanField,
  Box,
  Button,
  EmailField,
  Page,
  PhoneNumberField,
  Spinner,
  TextArea,
  TextField,
} from "ferns-ui";
import cloneDeep from "lodash/cloneDeep";
import React, {useEffect, useState} from "react";

interface Props extends StaffStackScreenProps<"Contacts"> {}

export const ContactScreen = ({route, navigation}: Props): React.ReactElement => {
  const userId = route.params.userId;
  const paramTypes = route.params.types ?? [];
  const contactIndex = route.params.contactIndex;
  const user = useGetUsersByIdQuery(userId).data;
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const sentryAndToast = useSentryAndToast();

  const [contact, setContact] = useState<any>(null);
  const [otherTypesString, setOtherTypesString] = useState<string>("");
  const [emergencyContact, setEmergencyContact] = useState<boolean>(
    paramTypes.includes("Emergency Contact")
  );
  const [primaryCareDoctor, setPrimaryCareDoctor] = useState<boolean>(
    paramTypes.includes("Primary Care Doctor")
  );
  const [dentist, setDentist] = useState<boolean>(paramTypes.includes("Dentist"));
  const [guardian, setGuardian] = useState<boolean>(paramTypes.includes("Guardian"));
  const [csb, setCSB] = useState<boolean>(
    paramTypes.includes("Community Service Board Case Worker")
  );

  // If we have the contact data, set the contact state
  useEffect(() => {
    if (contactIndex !== undefined && user?.contacts?.[contactIndex]) {
      const c = user?.contacts?.[contactIndex];
      setContact({
        ...c,
      });
      setOtherTypesString(c.types?.filter((t) => !ContactTypes.includes(t)).join(", "));
      setEmergencyContact(c.types?.includes("Emergency Contact"));
      setPrimaryCareDoctor(c.types?.includes("Primary Care Doctor"));
      setDentist(c.types?.includes("Dentist"));
      setGuardian(c.types?.includes("Guardian"));
    } else {
      setContact({
        name: "",
        address: {address1: "", address2: "", city: "", state: "", zipcode: ""} as Address,
        types: [],
        phoneNumber: "",
        email: "",
        notes: "",
      });
    }
  }, [contactIndex, user?.contacts]);

  if (!user || !contact) {
    return <Spinner />;
  }

  const saveContact = async (): Promise<void> => {
    let types: string[] = [];
    if (emergencyContact) {
      types.push("Emergency Contact");
    }
    if (primaryCareDoctor) {
      types.push("Primary Care Doctor");
    }
    if (dentist) {
      types.push("Dentist");
    }
    if (guardian) {
      types.push("Guardian");
    }
    if (csb) {
      types.push("Community Service Board Case Worker");
    }
    types = [...types, ...otherTypesString.split(",").map((t: string) => t.trim())];
    const c: Contact = {
      ...contact,
      types,
    };
    const contacts = cloneDeep(user.contacts ?? []);

    if (contactIndex !== undefined) {
      contacts[contactIndex] = c;
    } else {
      contacts.push(c);
    }

    try {
      await updateUser({id: user._id, body: {contacts}}).unwrap();
    } catch (error: any) {
      sentryAndToast(`Error saving contact`, error);
      return;
    }
    navigation.goBack();
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box direction="column" gap={4} paddingX={4} paddingY={2} scroll>
        <TextField
          title="Name"
          type="text"
          value={contact?.name}
          onChange={(value: any): void => {
            setContact({...contact, name: value} as Contact);
          }}
        />

        <BooleanField
          title="Emergency Contact"
          value={emergencyContact}
          onChange={(value: any): void => {
            setEmergencyContact(value);
          }}
        />
        <BooleanField
          title="Primary Care Doctor"
          value={primaryCareDoctor}
          onChange={(value: any): void => {
            setPrimaryCareDoctor(value);
          }}
        />
        <BooleanField
          title="Dentist"
          value={dentist}
          onChange={(value: any): void => {
            setDentist(value);
          }}
        />
        <BooleanField
          title="Guardian"
          value={guardian}
          onChange={(value: any): void => {
            setGuardian(value);
          }}
        />
        <BooleanField
          title="Community Service Board Case Worker"
          value={csb}
          onChange={(value: any): void => {
            setCSB(value);
          }}
        />

        <TextField
          helperText="Separate types with commas. Examples: Sister, Best Friend."
          title="Other Contact Types"
          type="text"
          value={otherTypesString}
          onChange={(value: any): void => {
            setOtherTypesString(value);
          }}
        />
        <AddressField
          title="Address"
          value={contact?.address}
          onChange={(value: any): void => {
            setContact({...contact, address: value} as Contact);
          }}
        />
        <PhoneNumberField
          title="Phone Number"
          value={contact?.phoneNumber}
          onChange={(value: any): void => {
            setContact({...contact, phoneNumber: value} as Contact);
          }}
        />
        <EmailField
          title="Email"
          value={contact?.email}
          onChange={(value: any): void => {
            setContact({...contact, email: value} as Contact);
          }}
        />
        <TextArea
          title="Notes"
          value={contact?.notes}
          onChange={(value: any): void => {
            setContact({...contact, notes: value} as Contact);
          }}
        />
        <Box paddingY={2} width={200}>
          <Button disabled={isLoading} loading={isLoading} text="Save" onClick={saveContact} />
        </Box>
      </Box>
    </Page>
  );
};
