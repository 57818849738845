import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useDeleteHealthEventInstancesByIdMutation,
  useGetHealthEventInstancesByIdQuery,
  useGetHealthEventInstancesQuery,
  useGetHealthEventsQuery,
  usePatchHealthEventInstancesByIdMutation,
  usePostHealthEventInstancesMutation,
} from "@store";
import {getDateOnly} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  DateTimeField,
  printDate,
  SelectField,
  Text,
  TextArea,
  useToast,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useEffect, useState} from "react";

import {FilterItem} from "./FilterItems";

const HealthEventEditor = ({
  healthEventInstanceId,
  setShowEditor,
  userId,
}: {
  healthEventInstanceId?: string;
  setShowEditor: (showEditor: boolean) => void;
  userId: string;
}): ReactElement => {
  const toast = useToast();
  // use create health event instance mutation
  const [createHealthEventInstance] = usePostHealthEventInstancesMutation();
  const [updateHealthEventInstance] = usePatchHealthEventInstancesByIdMutation();
  const [removeHealthEventInstance, {isLoading: removeLoading}] =
    useDeleteHealthEventInstancesByIdMutation();
  const {data: healthEventData} = useGetHealthEventsQuery({});
  const {data: healthEventInstanceData} = useGetHealthEventInstancesByIdQuery(
    healthEventInstanceId ?? skipToken
  );
  const [event, setEvent] = useState("");
  const [severity, setSeverity] = useState("Mild");
  const [startDate, setStartDate] = useState<string>(DateTime.utc().toISODate());
  const [endDate, setEndDate] = useState<string>();
  const [details, setDetails] = useState<string>("");
  const [isOngoing, setIsOngoing] = useState<boolean>(false);

  // if we already have an existing health event instance,
  // set the values within the state here using those values. otherwise,
  // use the default values (assuming it is a new Health Event Instance)
  useEffect(() => {
    if (healthEventInstanceId && healthEventInstanceData?.event._id) {
      if (healthEventInstanceData) {
        setEvent(healthEventInstanceData.event._id);
        setStartDate(healthEventInstanceData.startDate);
        setEndDate(healthEventInstanceData.endDate);
        setDetails(healthEventInstanceData.details ?? "");
        setIsOngoing(healthEventInstanceData.isOngoing ?? false);
      }
    }
  }, [healthEventData?.data, healthEventInstanceData, healthEventInstanceId]);

  // if we have health event data and we don't have an event set, set the event to the first
  // this will prevent the event from being undefined
  useEffect(() => {
    if (healthEventData?.data?.length && !event) {
      setEvent(healthEventData.data[0]._id);
    }
  }, [event, healthEventData?.data]);

  const create = async (): Promise<void> => {
    await createHealthEventInstance({
      event: event as any,
      severity,
      details,
      // make sure we store date in UTC, not with timezone
      startDate: getDateOnly(startDate),
      endDate: getDateOnly(endDate),
      isOngoing,
      userId,
    });
    setShowEditor(false);
  };

  const update = async (): Promise<void> => {
    await updateHealthEventInstance({
      id: healthEventInstanceId!,
      body: {
        event: event as any,
        severity,
        details,
        startDate: getDateOnly(startDate),
        endDate: getDateOnly(endDate),
        isOngoing,
      },
    });
    setShowEditor(false);
  };

  const remove = async (): Promise<void> => {
    if (!healthEventInstanceId) {
      throw new Error("healthEventInstanceId is undefined so cannot remove");
    }
    await removeHealthEventInstance(healthEventInstanceId);
    setShowEditor(false);
  };

  return (
    <Box gap={4}>
      <Box>
        <Text bold size="lg">
          Health Event {Boolean(healthEventInstanceId) ? "Editor" : "Creator"}
        </Text>
      </Box>
      <SelectField
        options={
          healthEventData?.data?.map((he) => ({
            label: he.name,
            value: he._id,
          })) || []
        }
        requireValue
        title="Event Type"
        value={event}
        onChange={(value: string): void => {
          setEvent(value);
        }}
      />
      <SelectField
        options={[
          {label: "Mild", value: "Mild"},
          {label: "Moderate", value: "Moderate"},
          {label: "Severe", value: "Severe"},
        ]}
        requireValue
        title="Severity"
        value={severity}
        onChange={(value: string): void => {
          setSeverity(value);
        }}
      />
      <DateTimeField
        title="Start Date"
        type="date"
        value={startDate}
        onChange={(value?: string): void => {
          if (!value) {
            toast.error("Cannot clear start date");
            return;
          }
          setStartDate(value);
        }}
      />
      <BooleanField
        title="Is Ongoing"
        value={isOngoing}
        onChange={(value: boolean): void => {
          if (value) setEndDate(undefined);
          setIsOngoing(value);
        }}
      />
      <DateTimeField
        disabled={isOngoing}
        title="End Date"
        type="date"
        value={endDate}
        onChange={(value?: string): void => setEndDate(value)}
      />
      <TextArea
        title="Details"
        value={details}
        onChange={(value: string): void => setDetails(value)}
      />
      <Box direction="row" gap={2} width="100%">
        <Button
          text={Boolean(healthEventInstanceId) ? "Update Event" : "Create Event"}
          variant="secondary"
          onClick={async (): Promise<void> => {
            Boolean(healthEventInstanceId) ? await update() : await create();
          }}
        />
        {Boolean(healthEventInstanceId) && (
          <Button
            confirmationText="Are you sure you want to delete this health event? Your health event will not be recoverable."
            disabled={removeLoading}
            loading={removeLoading}
            text="Delete"
            variant="destructive"
            withConfirmation
            onClick={remove}
          />
        )}
        <Button
          confirmationText="Are you sure you want to close? Your changes will not be saved."
          text="Close"
          variant="muted"
          withConfirmation
          onClick={(): void => {
            setShowEditor(false);
          }}
        />
      </Box>
    </Box>
  );
};

export const HealthEvents = ({userId}: {userId: string}): ReactElement => {
  const {data: healthEventData} = useGetHealthEventInstancesQuery({userId});
  const [showEditor, setShowEditor] = useState(false);
  const [healthEventInstanceId, setHealthEventInstanceId] = useState<string>();
  return (
    <Box gap={4}>
      {healthEventData?.data?.map((he) => (
        <FilterItem
          key={he._id}
          confirmationText=""
          dismissable={false}
          editable
          showDelete
          withConfirmation
          onEdit={(): void => {
            setHealthEventInstanceId(he._id);
            setShowEditor(true);
          }}
        >
          <Box direction="column" flex="grow">
            <Text bold>
              {he.event.name} ({he.severity})
            </Text>
            <Box direction="row" marginTop={1}>
              <Box>
                <Text>{`${printDate(he.startDate)} ${
                  he.endDate ? `to ${printDate(he.endDate)}` : ""
                }${he.isOngoing ? "(ongoing)" : ""}`}</Text>
              </Box>
            </Box>
            <Box marginTop={1}>{Boolean(he.details) && <Text>{he.details}</Text>}</Box>
          </Box>
        </FilterItem>
      ))}
      {showEditor ? (
        <Box marginTop={4}>
          <HealthEventEditor
            healthEventInstanceId={healthEventInstanceId}
            setShowEditor={setShowEditor}
            userId={userId}
          />
        </Box>
      ) : (
        <Box direction="row" paddingY={3}>
          <Button
            iconName="plus"
            text="New Event"
            variant="secondary"
            onClick={(): void => {
              setHealthEventInstanceId(undefined);
              setShowEditor(true);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
