import {
  useDeleteCharmTokensByIdMutation,
  useGetCharmAuthorizeUrlQuery,
  useGetCharmTokensQuery,
  useSentryAndToast,
} from "@store";
import {openURL} from "expo-linking";
import {Box, Button, Heading, Text, useToast} from "ferns-ui";
import React from "react";

interface CharmTokenPaneProps {}

export const CharmTokenPane = ({}: CharmTokenPaneProps): React.ReactElement => {
  const {data: tokenData} = useGetCharmTokensQuery({});
  const {data: urlData} = useGetCharmAuthorizeUrlQuery({});
  const [deleteToken] = useDeleteCharmTokensByIdMutation();
  const token = tokenData?.data?.[0];
  const sentryAndToast = useSentryAndToast();
  const toast = useToast();

  const getAuthorizeUrl = async (): Promise<void> => {
    if (!urlData?.url) {
      sentryAndToast("No Charm authorize URL configured.");
      return;
    }
    await openURL(urlData.url);
  };

  const revokeCharmToken = async (): Promise<void> => {
    if (!token) {
      console.error("No Charm token found");
      return;
    }
    await deleteToken(token._id).unwrap().catch(toast.catch);
  };

  return (
    <Box gap={4} width="100%">
      <Heading size="lg">Charm Token</Heading>
      {token ? (
        <>
          <Box marginBottom={4}>
            <Text>Charm Token synced successfully</Text>
          </Box>
          <Button text="Revoke Charm Token" variant="muted" onClick={revokeCharmToken} />
        </>
      ) : (
        <>
          <Box marginBottom={2}>
            <Text>No Charm Token found, please create one below to use Charm.</Text>
          </Box>
          <Button text="Authorize Charm" onClick={getAuthorizeUrl} />
        </>
      )}
    </Box>
  );
};
