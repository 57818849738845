import {RecurringType, RecurringTypeOptions} from "@constants";
import {skipToken} from "@reduxjs/toolkit/query";
import {RecurringScheduleItemConfig, useGetRecurringScheduleItemsByIdQuery} from "@store";
import {Box, SelectField} from "ferns-ui";
import React, {ReactElement, useEffect} from "react";

import {RecurringScheduleItemManager} from "./RecurringScheduleItemManager";

interface RecurringSelectorProps {
  recurringScheduleItemId: string;
  recurringType: RecurringType;
  setRecurringType: (recurringType: RecurringType) => void;
  recurringScheduleItem: RecurringScheduleItemConfig;
  setRecurringScheduleItem: (recurringScheduleItem: RecurringScheduleItemConfig) => void;
  type: string;
}

export const RecurringSelector = ({
  recurringScheduleItemId,
  recurringType,
  setRecurringType,
  recurringScheduleItem,
  setRecurringScheduleItem,
  type,
}: RecurringSelectorProps): ReactElement => {
  const {data: recurringScheduleItemData} = useGetRecurringScheduleItemsByIdQuery(
    recurringScheduleItemId ?? skipToken
  );

  // Once we load the schedule item, set the state.
  useEffect(() => {
    if (recurringScheduleItemData) {
      setRecurringType(
        RecurringType[recurringScheduleItemData.frequency as keyof typeof RecurringType]
      );
      setRecurringScheduleItem({
        startDatetime: recurringScheduleItemData.startDatetime,
        endDatetime: recurringScheduleItemData.endDatetime,
        interval: recurringScheduleItemData.interval,
        durationMinutes: recurringScheduleItemData.durationMinutes,
        daysOfWeek: recurringScheduleItemData.daysOfWeek,
      });
    }
  }, [recurringScheduleItemData, recurringType, setRecurringScheduleItem, setRecurringType]);

  // we want to disable edit if it is a recurring schedule item and already created
  // recurring schedule items can only be deleted or ended
  const disableEdit = Boolean(recurringScheduleItemData?._id);

  return (
    <Box marginTop={2}>
      <Box paddingY={2}>
        <SelectField
          disabled={disableEdit || Boolean(recurringScheduleItemId)}
          options={RecurringTypeOptions}
          requireValue
          title="Repeat"
          value={recurringType}
          onChange={(val: string): void => {
            const newRecurring = RecurringType[val as keyof typeof RecurringType];
            setRecurringType(newRecurring);
          }}
        />
      </Box>
      {recurringType !== RecurringType.NoRepeat && (
        <Box marginBottom={2} paddingX={4}>
          <RecurringScheduleItemManager
            disabled={disableEdit}
            recurringScheduleItem={recurringScheduleItem}
            recurringType={recurringType}
            setRecurringScheduleItem={setRecurringScheduleItem}
            type={type}
          />
        </Box>
      )}
    </Box>
  );
};
