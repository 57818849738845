import {FitbitSleep, User} from "@store";
import {useTheme} from "ferns-ui";
import sortBy from "lodash/sortBy";
import {DateTime} from "luxon";
import React, {ReactElement} from "react";
import {VictoryBar, VictoryChart, VictoryLabel} from "victory-native";

import {VictoryTitle} from "./graphs/VictoryTitle";

interface UserSleepChartProps {
  user: User;
  sleep: FitbitSleep[];
}

export const UserSleepChart = ({sleep}: UserSleepChartProps): ReactElement | null => {
  const {theme} = useTheme();
  if (sleep.length === 0) {
    return null;
  }
  return (
    <VictoryChart domainPadding={15} scale={{x: "time", y: "linear"}}>
      <VictoryTitle title="User Sleep" />
      <VictoryBar
        data={sortBy(sleep, "dateOfSleep")
          .filter((d) => d.value.dateOfSleep && d.value.startTime && d.value.endTime)
          .map((s) => ({
            x: DateTime.fromISO(s.value.dateOfSleep!).toFormat("M/D"),
            y:
              DateTime.fromISO(s.value.endTime!).diff(DateTime.fromISO(s.value.startTime!))
                .minutes / 60,
          }))}
        labelComponent={<VictoryLabel dy={30} />}
        labels={({datum}): number => Math.floor(datum.y * 10) / 10}
        style={{data: {fill: theme.surface.error}, labels: {fill: theme.surface.base}}}
      />
    </VictoryChart>
  );
};
