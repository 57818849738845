import {Box, Modal, Text} from "ferns-ui";
import React, {FC} from "react";

import {useChatContext} from "./GiftedChatContext";

interface DeleteModalProps {}

export const DeleteModal: FC<DeleteModalProps> = () => {
  const {
    deleteModalVisible,
    removeMessageLoading,
    removeMessageError,
    setDeleteModalVisible,
    removeMessage,
    selectedMessage,
    setSeletectedMessage,
  } = useChatContext();

  return (
    <Modal
      primaryButtonDisabled={
        Boolean(removeMessageError) ||
        Boolean(removeMessageLoading) ||
        Boolean(selectedMessage?.sentAsSms)
      }
      primaryButtonOnClick={async (): Promise<void> => {
        if (selectedMessage?._id) {
          await removeMessage?.(selectedMessage?._id.toString());
          setSeletectedMessage(undefined);
          setDeleteModalVisible?.(false);
        }
      }}
      primaryButtonText="Yes, Delete Message"
      secondaryButtonOnClick={(): void => {
        setDeleteModalVisible(false);
      }}
      secondaryButtonText="Cancel"
      size="sm"
      title="Delete Message"
      visible={Boolean(deleteModalVisible)}
      onDismiss={(): void => {
        setDeleteModalVisible(false);
      }}
    >
      {Boolean(selectedMessage?.sentAsSms) ? (
        <Box>
          <Text>Messages sent as SMS cannot be deleted.</Text>
        </Box>
      ) : (
        <Box>
          <Box>
            <Text>Are you sure you want to delete the following message?</Text>
          </Box>
          <Box paddingX={2} paddingY={2}>
            <Text bold>&quot;{selectedMessage?.text}&quot;</Text>
          </Box>
          <Box>
            <Text>
              Users may still see your message if their app is open at the time your message was
              sent.
            </Text>
          </Box>
          {Boolean(removeMessageError) && (
            <Box paddingY={2}>
              <Text color="error">
                An error occurred while attempting to delete this message. Please try again later or
                contact support.
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Modal>
  );
};
