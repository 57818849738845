import {Badge, Box} from "ferns-ui";
import React, {ReactElement} from "react";

interface UserBadgeProps {
  title: string;
  status: "info" | "error" | "warning" | "success" | "neutral";
}

export const UserBadge = ({title, status}: UserBadgeProps): ReactElement => {
  return (
    <Box alignItems="center" direction="row" height={24}>
      <Badge status={status} value={title} />
    </Box>
  );
};
