import {FitbitHrv, User} from "@store";
import {useTheme} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React, {ReactElement} from "react";
import {VictoryBar, VictoryChart, VictoryLabel} from "victory-native";

interface UserHeartRateVariabilityChartProps {
  user: User;
  fitbitHrvs: FitbitHrv[];
}

export const UserHeartRateVariabilityChart = ({
  fitbitHrvs,
}: UserHeartRateVariabilityChartProps): ReactElement | null => {
  const {theme} = useTheme();
  const steps = fitbitHrvs.map((hrv) => {
    return {
      ...hrv,
      datetime: new Date(hrv.datetime),
    };
  });
  if (steps.length === 0) {
    return null;
  }
  return (
    <>
      <VictoryChart domainPadding={15} scale={{x: "time", y: "linear"}}>
        <VictoryLabel
          style={{fill: theme.surface.neutral, fontSize: 24}}
          text="User HRV - Daily RMSSD"
          textAnchor="start"
          x={100}
          y={18}
        />
        <VictoryBar
          data={sortBy(steps, "datetime").map((a) => ({
            x: a.datetime,
            y: a.value.dailyRmssd,
          }))}
          labelComponent={<VictoryLabel dy={30} />}
          style={{data: {fill: theme.text.error}, labels: {fill: theme.surface.base}}}
        />
      </VictoryChart>
      <VictoryChart domainPadding={15} scale={{x: "time", y: "linear"}}>
        <VictoryLabel
          style={{fill: theme.surface.neutral, fontSize: 24}}
          text="User HRV - Deep Sleep RMSSD"
          textAnchor="start"
          x={100}
          y={18}
        />
        <VictoryBar
          data={sortBy(steps, "datetime").map((a) => ({
            x: a.datetime,
            y: a.value.deepRmssd,
          }))}
          labelComponent={<VictoryLabel dy={30} />}
          style={{data: {fill: theme.text.error}, labels: {fill: theme.surface.base}}}
        />
      </VictoryChart>
    </>
  );
};
