import {useLogoutUser, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {isStaff} from "@utils";
import {useToast} from "ferns-ui";
import {useEffect} from "react";

import {getTokenExpirationTimes, shouldShowStillThereModal} from "../ferns-rtk";

// For staff users, show a modal when there is only 1 minute left on the token's expiration time.
export const useInactivityTimer = (): void => {
  const toast = useToast();
  const navigation = useNavigation<any>();
  const profile = useReadProfile();
  const logoutUser = useLogoutUser();

  // Check the token expiration time every 5 seconds
  useEffect(() => {
    const checkTokenExpiration = async (): Promise<void> => {
      if (!isStaff(profile?.type)) {
        return;
      }
      const {refreshRemainingSecs} = await getTokenExpirationTimes();
      if (refreshRemainingSecs === undefined) {
        return;
      }

      // Give a small grace period to make sure another tab isn't actively refreshing the token.
      if (refreshRemainingSecs < -5) {
        console.info(`Logging out user, refresh remaining seconds: ${refreshRemainingSecs}`);
        toast.show("Session expired, logging out.");
        await logoutUser();
        return;
      }

      shouldShowStillThereModal()
        .then((shouldShow) => {
          if (shouldShow) {
            navigation.navigate("StillThere", {
              onStillThere: (): void => {
                // Close the modal.
                navigation.goBack();
              },
            });
          }
        })
        .catch(toast.catch);
    };

    // Check the token expiration immediately
    checkTokenExpiration().catch(toast.catch);

    // Set up an interval to check the token expiration every 5 seconds
    const intervalId = setInterval(checkTokenExpiration, 5000);

    // Clean up the interval on component unmount
    return (): void => clearInterval(intervalId);
  }, [logoutUser, navigation, profile?.type, toast, toast.catch]);
};
