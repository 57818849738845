import type {Middleware} from "@reduxjs/toolkit";
import {captureException, captureMessage} from "@utils";
import {useToast} from "ferns-ui";
const ignoredErrors = [
  "Account locked due to too many failed login attempts",
  "Password or username is incorrect",
  "No token found for",
  "User interaction is not allowed",
  "Token refresh failed with 401",
  "Failed to refresh token",
  "Auth and refresh tokens are expired",
  "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
];

/**
 * Log a warning and send error from RTKQuery to Sentry.
 */
export const rtkQueryErrorMiddleware: Middleware = () => (next) => (action: any) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood,
  // so we're able to utilize these matchers!
  if (action?.error && action?.payload) {
    // If the APIError title isn't set and it doesn't have a message, send the entire payload to
    // Sentry. Otherwise we get a lot of "undefined"s, which aren't useful.
    // Some of these likely should be ignored.
    const errorMessage =
      action.payload?.data?.title ??
      action.payload?.data?.message ??
      JSON.stringify(action.payload);
    const message = `Rejected action ${action.meta?.baseQueryMeta?.request?.method} ${action.meta?.baseQueryMeta?.request?.url}: ${errorMessage}`;
    console.debug(message, JSON.stringify(action));

    // ignore all 'Not Found' (404) and 'Auth Error' (401) errors from showing up in Sentry
    if (action.payload.status === 404 || action.payload.status === 401) {
      return next(action);
    }

    // Ignore some common errors from showing up in Sentry.
    const shouldIgnore = ignoredErrors.some((ignoredError) => errorMessage.includes(ignoredError));
    if (!shouldIgnore) {
      console.warn(`sending data to Sentry: ${message}\n${action}`);
      captureException(new Error(message));
    }
  }

  return next(action);
};

/**
 * Log a warning, show an error toast and send error from UI to Sentry.
 */
export const useSentryAndToast = (): ((
  errorMessage: string,
  e?: Error,
  extraInfo?: string
) => void) => {
  const toast = useToast();
  return (error: string, e?: Error, extraInfo?: string): void => {
    if (!error) {
      console.debug("Unable to capture UI error");
      return;
    }

    toast.error(error);

    let warning = `Sending data to Sentry: ${error}`;
    if (e) {
      warning += `\nError: ${e}`;
    }
    if (extraInfo) {
      warning += `\nExtra Info: ${extraInfo}`;
    }

    console.warn(warning);
    captureException(e instanceof Error ? e : new Error(error));
  };
};

/**
 * A function that will throw an error in dev, but only log a warning in prod. In dev,
 * we'll get a stack trace.
 */
export const devError = (message: string): void => {
  if (__DEV__) {
    // throw new Error(message);
  } else {
    captureMessage(message);
    console.warn(message);
  }
};
