import {useReadProfile} from "@hooks";
import {
  Timezone,
  useGetUserSessionsQuery,
  usePatchUserSessionsByIdMutation,
  UserSession,
} from "@store";
import {Box, Heading, IconButton, Spinner, Text, useToast} from "ferns-ui";
import React, {useCallback} from "react";

import {TimeDropdown} from "./TimeDropdown";
import {TimezoneDropdown} from "./TimezoneDropdown";

type NotificationScheduleType = UserSession["notificationSchedule"][0];

const DEFAULTS: NotificationScheduleType[] = [
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Monday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Tuesday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Wednesday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Thursday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Friday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Saturday"},
  {startHour: 9, startMin: 0, endHour: 17, endMin: 0, day: "Sunday"},
];

const timeSlots: {label: string; value: {hour: number; min: number}}[] = [];

// Generate the list of time options from 12:00am to 11:30pm at half hour intervals
for (let hour = 0; hour < 24; hour++) {
  for (const min of [0, 30]) {
    const labelHour = hour % 12 === 0 ? 12 : hour % 12;
    const period = hour < 12 ? "am" : "pm";
    const label = `${labelHour}:${min.toString().padStart(2, "0")}${period}`;

    timeSlots.push({label, value: {hour, min}});
  }
}

type Days = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
interface NotificationScheduleDayProps {
  notificationSchedule?: NotificationScheduleType;
  onChange: (notificationSchedule: NotificationScheduleType) => void;
  onDelete: () => void;
  day: Days;
}

const NotificationScheduleDay = ({
  notificationSchedule,
  day,
  onChange,
  onDelete,
}: NotificationScheduleDayProps): React.ReactElement => {
  if (!notificationSchedule) {
    return (
      <Box alignItems="center" direction="row" height={70} width="100%">
        <Box marginRight={2} width={100}>
          <Text bold>{day}</Text>
        </Box>
        <Box>
          <IconButton
            accessibilityLabel={`add ${day} notification schedule`}
            iconName="plus"
            onClick={(): void => {
              onChange({...DEFAULTS.find((d) => d.day === day)!, day});
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box alignItems="center" direction="row" height={70} width="100%">
        <Box marginRight={2} width={80}>
          <Text bold>{day}</Text>
        </Box>
        <Box flex="grow" marginRight={2} maxWidth={100}>
          <TimeDropdown
            hour={notificationSchedule.startHour}
            minute={notificationSchedule.startMin}
            resolution="30"
            onChange={({minute, hour}): void => {
              onChange({...notificationSchedule, startHour: hour, startMin: minute});
            }}
          />
        </Box>
        <Box marginRight={2} width={16}>
          <Text>to</Text>
        </Box>
        <Box flex="grow" maxWidth={100}>
          <TimeDropdown
            hour={notificationSchedule.endHour}
            minute={notificationSchedule.endMin}
            resolution="30"
            onChange={({minute, hour}): void => {
              onChange({...notificationSchedule, endHour: hour, endMin: minute});
            }}
          />
        </Box>
        <Box marginLeft={4}>
          <IconButton
            accessibilityLabel="delete"
            iconName="trash"
            variant="destructive"
            onClick={onDelete}
          />
        </Box>
      </Box>
    );
  }
};

interface NotificationSchedulesScreenProps {}

export const NotificationSchedule = ({}: NotificationSchedulesScreenProps): React.ReactElement => {
  const profile = useReadProfile();
  const toast = useToast();
  const [updateUserSessions] = usePatchUserSessionsByIdMutation();
  const {data: userSessionsData, isLoading} = useGetUserSessionsQuery({page: 1});
  const userSession = userSessionsData?.data?.find((us) => us.ownerId === profile?._id);

  const [notificationSchedule, setNotificationSchedule] = React.useState<
    NotificationScheduleType[]
  >(userSession?.notificationSchedule?.length ? userSession?.notificationSchedule : []);
  const [timezone, setTimezone] = React.useState<Timezone>(
    userSession?.timezone ?? "America/New_York"
  );

  const save = useCallback(
    async (workHours: NotificationScheduleType[]): Promise<void> => {
      setNotificationSchedule(workHours);
      if (!userSession) {
        toast.error("No user session found, not saving notification schedule");
        return;
      }
      await updateUserSessions({
        id: userSession._id,
        body: {notificationSchedule: workHours},
      }).catch((e) => {
        toast.catch(e, "Failed to save notification schedule");
      });
    },
    [toast, updateUserSessions, userSession]
  );

  return (
    <Box width="100%">
      <Box paddingY={4}>
        <Heading size="sm">Notification Schedule:</Heading>
        <Text>
          You&apos;ll only receive notifications in the hours you choose. Outside of those times,
          notifications will be paused.
        </Text>
      </Box>

      {Boolean(isLoading) && (
        <Box width="100%">
          <Spinner />
        </Box>
      )}
      {Boolean(!isLoading) && (
        <>
          {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
            (d) => {
              const day = d as Days;
              const ns = notificationSchedule.find((n) => n.day === day);
              return (
                <NotificationScheduleDay
                  key={ns?._id + day}
                  day={day}
                  notificationSchedule={ns}
                  onChange={async (newNotificationSchedules): Promise<void> => {
                    const wh = [...notificationSchedule];
                    const index = wh.findIndex((n) => n.day === day);
                    if (index === -1) {
                      wh.push(newNotificationSchedules);
                    } else {
                      wh[index] = newNotificationSchedules;
                    }
                    await save(wh);
                  }}
                  onDelete={async (): Promise<void> => {
                    const wh = [...notificationSchedule];
                    const index = wh.findIndex((n) => n.day === day);
                    if (index === -1) {
                      console.warn(`Could not find notification schedule ${day} to delete`);
                      return;
                    }
                    wh.splice(index, 1);
                    await save(wh);
                  }}
                />
              );
            }
          )}
        </>
      )}
      <Box maxWidth={200} paddingY={2}>
        <TimezoneDropdown
          location="USA"
          title="Notifications Timezone"
          value={timezone}
          onChange={(newTz) => {
            setTimezone(newTz as Timezone);
            updateUserSessions({
              id: userSession!._id,
              body: {timezone: newTz as Timezone},
            }).catch((e) => {
              toast.catch(e, "Failed to save notification timezone");
            });
          }}
        />
      </Box>
    </Box>
  );
};
