import * as Clipboard from "expo-clipboard";
import {Text, useTheme, useToast} from "ferns-ui";
import React, {useCallback} from "react";
import {Pressable, Role, View, ViewStyle} from "react-native";

import {IsWeb} from "../../utils";
import Color from "./Color";
import {DeliveryIcons} from "./DeliveryIcons";
import {useChatContext} from "./GiftedChatContext";
import {MessageAudio} from "./MessageAudio";
import {MessageImage} from "./MessageImage";
import {MessageText} from "./MessageText";
import {MessageVideo} from "./MessageVideo";
import {
  BubbleProps,
  IMessage,
  RenderMessageAudioProps,
  RenderMessageImageProps,
  RenderMessageTextProps,
  RenderMessageVideoProps,
} from "./Models";
import {QuickReplies} from "./QuickReplies";
import {Time} from "./Time";

export const DEFAULT_OPTION_TITLES = ["Copy Text", "Cancel"];

export const Bubble = <TMessage extends IMessage = IMessage>(
  props: BubbleProps<TMessage>
): React.ReactElement => {
  const {
    user,
    touchableProps,
    onPress,
    renderMessageImage,
    renderMessageVideo,
    renderMessageAudio,
    renderMessageText,
    renderUsername,
    renderTime,
    renderQuickReplies,
    onQuickReply,
    position = "left",
    optionTitles,
    currentMessage,
    nextMessage,
    containerStyle,
    wrapperStyle,
    bottomContainerStyle,
    textStyle,
    linkStyle,
    textProps,
    customTextStyle,
    parsePatterns,
    timeFormat,
    timeTextStyle,
    renderUsernameOnMessage,
    quickReplyStyle,
    quickReplyTextStyle,
    renderQuickReplySend,
  } = props;
  const {
    actionSheet,
    onMarkUnread,
    shouldShowDeliveryIcons,
    enableMarkUnread,
    enableMessageDebugging,
    enableRemoveMessage,
    setDeleteModalVisible,
    setSeletectedMessage,
  } = useChatContext();
  const {theme} = useTheme();
  const toast = useToast();
  const left = position === "left";
  const styles = {
    container: {
      flex: 1,
      alignItems: left ? "flex-start" : "flex-end",
    },
    wrapper: {
      borderRadius: theme.primitives.radiusLg,
      backgroundColor: left ? theme.primitives.secondary100 : theme.surface.secondaryDark,
      marginRight: left ? 60 : 0,
      marginLeft: left ? 0 : 60,
      paddingTop: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      minHeight: 20,
      justifyContent: "flex-end",
      maxWidth: "90%",
    },

    bottom: {
      flexDirection: "row",
      justifyContent: left ? "flex-start" : "flex-end",
    },

    content: {
      // eslint-disable-next-line react-native/no-unused-styles
      tick: {
        fontSize: 10,
        backgroundColor: Color.backgroundTransparent,
        color: Color.white,
      },
      // eslint-disable-next-line react-native/no-unused-styles
      tickView: {
        flexDirection: "row",
        marginRight: 10,
      },
      // eslint-disable-next-line react-native/no-unused-styles
      username: {
        top: -3,
        left: 0,
        fontSize: 12,
        backgroundColor: "transparent",
        color: "#aaa",
      },
      // eslint-disable-next-line react-native/no-unused-styles
      usernameView: {
        flexDirection: "row",
        marginHorizontal: 10,
      },
    },
  };

  // We may want to support separate onPress and longPress in the future, but for now,
  // just treat them as one: long press on mobile, click on web.
  // Another option is onPress for both and onLongPress for mobile, right click for web for the same
  // menu.
  const doOnPress = useCallback((): void => {
    if (!currentMessage) {
      return;
    }

    if (onPress) {
      onPress(actionSheet, currentMessage);
      return;
    } else {
      let destructiveButtonIndex = -1;

      // Initialize the options array with common options
      const options: string[] = ["Copy Text"];

      // Allow all staff to mark unread
      if (enableMarkUnread?.(currentMessage)) {
        options.unshift("Mark Unread");
      }

      if (enableMessageDebugging) {
        // Adds to the end
        options.push("Copy Message ID");
        if (currentMessage.deliveryStatuses?.length) {
          options.push("Copy Notification IDs");
        }
      }

      if (enableRemoveMessage?.(currentMessage)) {
        options.push("Delete Message");
        destructiveButtonIndex = options.length - 1;
      }

      // Always include "Cancel" as the last option
      options.push("Cancel");

      const cancelButtonIndex = options.length - 1;

      actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
        },
        async (buttonIndex: number) => {
          switch (options[buttonIndex]) {
            case "Mark Unread":
              await onMarkUnread?.(currentMessage);
              break;
            case "Copy Text":
              Clipboard.setString(currentMessage.text);
              break;
            case "Delete Message":
              setSeletectedMessage(currentMessage);
              setDeleteModalVisible(true);
              break;
            case "Copy Message ID":
              Clipboard.setString(String(currentMessage?._id));
              break;
            case "Copy Notification IDs":
              if (currentMessage.deliveryStatuses?.length) {
                const notificationIds = currentMessage.deliveryStatuses
                  .map((s) => s._id)
                  .join(", ");
                Clipboard.setString(notificationIds);
              } else {
                toast.error("No notification IDs found for this message.");
              }
              break;
            default:
              // Handle cancel or other actions as necessary
              break;
          }
        }
      );
    }
  }, [
    actionSheet,
    currentMessage,
    enableMarkUnread,
    enableMessageDebugging,
    enableRemoveMessage,
    onMarkUnread,
    onPress,
    setDeleteModalVisible,
    setSeletectedMessage,
    toast,
  ]);

  const doRenderQuickReplies = (): React.ReactElement | null => {
    if (currentMessage && currentMessage.quickReplies) {
      const quickReplyProps = {};
      if (renderQuickReplies) {
        return renderQuickReplies(quickReplyProps);
      }
      return (
        <QuickReplies
          currentMessage={currentMessage}
          nextMessage={nextMessage}
          quickReplyStyle={quickReplyStyle}
          quickReplyTextStyle={quickReplyTextStyle}
          renderQuickReplySend={renderQuickReplySend}
          onQuickReply={onQuickReply}
        />
      );
    }
    return null;
  };

  const doRenderMessageText = (): React.ReactElement | null => {
    if (currentMessage && currentMessage.text) {
      const messageTextProps: RenderMessageTextProps<TMessage> = {
        containerStyle,
        position,
        currentMessage,
        optionTitles,
        textStyle,
        linkStyle,
        textProps,
        customTextStyle,
        parsePatterns,
      };
      if (renderMessageText) {
        return renderMessageText(messageTextProps);
      }
      return <MessageText {...messageTextProps} />;
    }
    return null;
  };

  const doRenderMessageImage = (): React.ReactElement | null => {
    if (currentMessage?.image) {
      const messageImageProps: RenderMessageImageProps<TMessage> = {
        containerStyle,
        position,
        currentMessage,
        optionTitles,
        textStyle,
        linkStyle,
        textProps,
        customTextStyle,
        parsePatterns,
      };
      if (renderMessageImage) {
        return renderMessageImage(messageImageProps);
      }
      return <MessageImage {...messageImageProps} />;
    }
    return null;
  };

  const doRenderMessageVideo = (): React.ReactElement | null => {
    if (currentMessage?.video) {
      const messageVideoProps: RenderMessageVideoProps<TMessage> = {
        containerStyle,
        position,
        currentMessage,
        optionTitles,
        textStyle,
        linkStyle,
        textProps,
        customTextStyle,
        parsePatterns,
      };
      if (renderMessageVideo) {
        return renderMessageVideo(messageVideoProps);
      }
      return <MessageVideo {...messageVideoProps} />;
    }
    return null;
  };

  const doRenderMessageAudio = (): React.ReactElement | null => {
    if (currentMessage?.audio) {
      const messageAudioProps: RenderMessageAudioProps<TMessage> = {
        containerStyle,
        position,
        currentMessage,
        optionTitles,
        textStyle,
        linkStyle,
        textProps,
        customTextStyle,
        parsePatterns,
      };
      if (renderMessageAudio) {
        return renderMessageAudio(messageAudioProps);
      }
      return <MessageAudio {...messageAudioProps} />;
    }
    return null;
  };

  const shouldRenderIcons = currentMessage && shouldShowDeliveryIcons?.(currentMessage);

  const doRenderTime = (): React.ReactElement | null => {
    if (currentMessage && currentMessage.createdAt) {
      const timeProps = {
        position,
        containerStyle,
        currentMessage,
        timeFormat,
        timeTextStyle,
      };
      if (renderTime) {
        return renderTime(timeProps);
      }
      return <Time {...timeProps} />;
    }
    return null;
  };

  const doRenderUsername = (): React.ReactElement | null => {
    if (renderUsername && currentMessage) {
      return renderUsername(currentMessage);
    }
    if (renderUsernameOnMessage && currentMessage) {
      if (user && currentMessage.user._id === user._id) {
        return null;
      }

      return (
        <View style={styles.content.usernameView as ViewStyle}>
          <Text size="sm">~ {currentMessage.user.name}</Text>
        </View>
      );
    }
    return null;
  };

  const renderBubbleContent = (): React.ReactElement => {
    return (
      <View>
        {doRenderMessageImage()}
        {doRenderMessageVideo()}
        {doRenderMessageAudio()}
        {doRenderMessageText()}
      </View>
    );
  };

  return (
    <View style={[styles.container as ViewStyle, containerStyle && containerStyle[position]]}>
      <Pressable
        role={"text" as Role}
        onLongPress={!IsWeb ? doOnPress : undefined}
        onPress={IsWeb ? doOnPress : undefined}
        {...touchableProps}
        style={[styles.wrapper as ViewStyle, wrapperStyle && wrapperStyle[position]]}
      >
        {renderBubbleContent()}
        <View
          style={[
            styles.bottom as ViewStyle,
            bottomContainerStyle && bottomContainerStyle[position],
          ]}
        >
          {doRenderUsername()}
          {doRenderTime()}
          {Boolean(shouldRenderIcons) && (
            <DeliveryIcons
              pushStatuses={
                currentMessage?.deliveryStatuses?.filter((s) => s.type === "push") || []
              }
              smsStatuses={currentMessage?.deliveryStatuses?.filter((s) => s.type === "sms") || []}
            />
          )}
        </View>
      </Pressable>
      {doRenderQuickReplies()}
    </View>
  );
};
