import {Avatar} from "ferns-ui";
import React, {ReactElement} from "react";
import {Pressable, View} from "react-native";

import Color from "./Color";
import {GiftedAvatarProps, IMessage} from "./Models";
import {isSameDay, isSameUser} from "./utils";

const DEFAULT_AVATAR_SIZE = 40;

export const GiftedAvatar = <TMessage extends IMessage>({
  user,
  avatarStyle,
  onPressAvatar,
  onLongPressAvatar,
  showAvatarForEveryMessage,
  renderAvatarOnTop,
  previousMessage,
  currentMessage,
  nextMessage,
  position = "left",
}: GiftedAvatarProps<TMessage>): ReactElement => {
  const messageToCompare = renderAvatarOnTop ? previousMessage : nextMessage;

  if (
    !showAvatarForEveryMessage &&
    currentMessage &&
    messageToCompare &&
    isSameUser(currentMessage, messageToCompare) &&
    isSameDay(currentMessage, messageToCompare)
  ) {
    // render placeholder
    return (
      <View
        role="img"
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            width: DEFAULT_AVATAR_SIZE,
            height: DEFAULT_AVATAR_SIZE,
            borderRadius: 16,
            marginLeft: position === "left" ? 0 : 12,
            marginRight: position === "left" ? 12 : 0,
            backgroundColor: Color.backgroundTransparent,
          },
          avatarStyle?.[position],
        ]}
      />
    );
  }
  if (typeof user?.avatar === "function") {
    return user?.avatar("");
  } else {
    return (
      <Pressable
        disabled={!onPressAvatar}
        role="img"
        style={{
          marginLeft: position === "left" ? 0 : 12,
          marginRight: position === "left" ? 12 : 0,
        }}
        onLongPress={onLongPressAvatar}
        onPress={onPressAvatar}
      >
        <Avatar hasBorder={false} name={user?.name ?? ""} size="sm" src={user?.avatar} />
      </Pressable>
    );
  }
};
