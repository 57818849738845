import {skipToken} from "@reduxjs/toolkit/query";
import {NotificationItem, useGetNotificationsQuery} from "@store";
import React, {ReactElement} from "react";

import {DeliveryIcons} from "../giftedChat";

interface DeliveryStatusesProps {
  notification: NotificationItem;
}
export const DeliveryStatuses = ({notification}: DeliveryStatusesProps): ReactElement | null => {
  const {data: notificationData} = useGetNotificationsQuery(
    Boolean(notification.notifications && notification.notifications.length > 0)
      ? {_id: {$in: notification.notifications}}
      : skipToken
  );
  const notificationIds = notification.notifications;
  if (!notificationData || !notificationData.data || !notificationIds) {
    return null;
  }
  const notifications = notificationData.data.filter((n) => notificationIds.includes(n._id));
  if (!notifications || notifications.length === 0) {
    return null;
  }

  const pushStatuses = notifications.filter((s) => s.sendAsPush);
  const smsStatuses = notifications.filter((s) => s.sendAsSms);

  return <DeliveryIcons pushStatuses={pushStatuses} smsStatuses={smsStatuses} />;
};
