// @ts-nocheck
// Ignoring typescript checks for the heap load snippet.
import {HEAP_ID} from "@constants";

export function setupHeap(): void {
  if (window.heap) {
    return;
  }
  (window.heap = window.heap || []),
    (heap.load = function (e, t): void {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      const r = document.createElement("script");
      (r.type = "text/javascript"),
        (r.async = !0),
        (r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`);
      const a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(r, a);
      for (
        let n = function (ev): () => void {
            return function (): void {
              // eslint-disable-next-line prefer-rest-params
              heap.push([ev].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          o = 0;
        o < p.length;
        o++
      )
        heap[p[o]] = n(p[o]);
    });
  heap.load(HEAP_ID);
}
