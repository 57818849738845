// A tool button navigates to a tool page.
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {StaffStackParamList} from "@types";
import {FeatureFlags, hasFeatureFlag, UserTypes} from "@utils";
import {Box, Button, ButtonProps, IconName} from "ferns-ui";
import React from "react";

import {useReadProfile} from "../hooks";

interface MutationToolButtonProps {
  iconName: IconName;
  tooltipText?: string;
  text: string;
  variant?: ButtonProps["variant"];
  screen: keyof StaffStackParamList;
  featureFlag?: FeatureFlags;
}

export const ToolButton: React.FC<MutationToolButtonProps> = ({
  text,
  variant = "secondary",
  iconName,
  tooltipText,
  screen,
  featureFlag,
}) => {
  const profile = useReadProfile();

  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();

  if (featureFlag && !hasFeatureFlag(profile!, featureFlag!)) {
    return null;
  }

  return (
    <Box width={250}>
      <Button
        fullWidth
        iconName={iconName}
        text={text}
        tooltipText={tooltipText}
        variant={variant}
        // I could not figure out how to make the types work out here,
        // but the props type correctly checks that we only navigate to staff screens like we'd
        // expect.
        onClick={(): void => navigation.navigate(screen as any)}
      />
    </Box>
  );
};
