import {Box, Icon, Tooltip} from "ferns-ui";
import React, {ReactElement} from "react";

import {MessageDeliveryStatus} from "./Models";

export interface Notification {
  _id: string;
  status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
}

// support passing in either MessageDeliveryStatus from gifted chat or Notification document itself
type DeliveryStatus = MessageDeliveryStatus | Notification;

interface DeliveryIconsProps {
  pushStatuses?: DeliveryStatus[];
  smsStatuses?: DeliveryStatus[];
}

export const DeliveryIcons = ({
  pushStatuses = [],
  smsStatuses = [],
}: DeliveryIconsProps): ReactElement | null => {
  if (pushStatuses.length === 0 && smsStatuses.length === 0) {
    return null;
  }

  // we will consider a delivery to be a success as long as one of the push statuses is a success
  const pushSuccess = pushStatuses.some((s) => s.status === "Completed");
  // fail if all failed
  const pushFailed = pushStatuses.every((s) => s.status === "Failed");
  const pushIcon = pushSuccess || !pushFailed ? "bell" : "bell-slash";
  let pushColor: "secondaryLight" | "error" | "success" = "secondaryLight";
  if (pushSuccess) {
    pushColor = "success";
  } else if (pushFailed) {
    pushColor = "error";
  }
  const smsSuccess = smsStatuses.some((s) => s.status === "Completed");
  const smsFailed = smsStatuses.every((s) => s.status === "Failed");
  const smsIcon = smsSuccess || !smsFailed ? "phone" : "phone-slash";
  let smsColor: "secondaryLight" | "error" | "success" = "secondaryLight";
  if (smsSuccess) {
    smsColor = "success";
  } else if (smsFailed) {
    smsColor = "error";
  }

  // get error message from either MessageDeliveryStatus or Notification
  const getErrorMessage = (status: DeliveryStatus): string | undefined => {
    if ("errorMessage" in status) {
      return status.errorMessage;
    } else if ("deliveryErrors" in status) {
      return status.deliveryErrors?.join(", ");
    }
    return undefined;
  };

  const pushDetails: string = pushStatuses
    .map((s) => {
      const error = getErrorMessage(s);
      return `Status: ${s.status}${error ? `\nError: ${error}` : ""}`;
    })
    .join("\n");

  const smsDetails: string = smsStatuses
    .map((s) => {
      const error = getErrorMessage(s);
      return `Status: ${s.status}${error ? `\nError: ${error}` : ""}`;
    })
    .join("\n");

  return (
    <Box direction="row">
      {pushStatuses.length > 0 && (
        <Tooltip text={pushDetails}>
          <Box padding={1}>
            <Icon color={pushColor} iconName={pushIcon} size="sm" />
          </Box>
        </Tooltip>
      )}
      {smsStatuses.length > 0 && (
        <Tooltip text={smsDetails}>
          <Box padding={1}>
            <Icon color={smsColor} iconName={smsIcon} size="sm" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
