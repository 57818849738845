import React, {useMemo, useState} from "react";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {useCallbackOne} from "use-memo-one";

import Color from "./Color";
import {warning} from "./logging";
import {IMessage, QuickRepliesProps, Reply} from "./Models";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: 300,
  },
  quickReply: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    maxWidth: 200,
    paddingVertical: 7,
    paddingHorizontal: 12,
    minHeight: 50,
    borderRadius: 13,
    margin: 3,
  },
  quickReplyText: {
    overflow: "visible",
  },
  sendLink: {
    borderWidth: 0,
  },
  sendLinkText: {
    color: Color.defaultBlue,
    fontWeight: "600",
    fontSize: 17,
  },
});

const sameReply =
  (currentReply: Reply) =>
  (reply: Reply): boolean =>
    currentReply.value === reply.value;

const diffReply =
  (currentReply: Reply) =>
  (reply: Reply): boolean =>
    currentReply.value !== reply.value;

export const QuickReplies = ({
  currentMessage,
  nextMessage,
  color = Color.peterRiver,
  quickReplyStyle,
  quickReplyTextStyle,
  onQuickReply,
  sendText = "Send",
  renderQuickReplySend,
}: QuickRepliesProps<IMessage>): React.ReactElement | null => {
  const {type} = currentMessage!.quickReplies!;
  const [replies, setReplies] = useState<Reply[]>([]);

  const shouldComponentDisplay = useMemo(() => {
    const hasReplies = !!currentMessage && !!currentMessage!.quickReplies;
    const hasNext = !!nextMessage && !!nextMessage!._id;
    const keepIt = currentMessage!.quickReplies!.keepIt;

    if (hasReplies && !hasNext) {
      return true;
    }

    return Boolean(hasReplies && hasNext && keepIt);
  }, [currentMessage, nextMessage]);

  const handlePress = useCallbackOne(
    (reply: Reply) => async (): Promise<void> => {
      if (currentMessage) {
        const {type: quickType} = currentMessage.quickReplies!;
        switch (quickType) {
          case "radio": {
            await handleSend([reply])();
            return;
          }
          case "checkbox": {
            if (replies.find(sameReply(reply))) {
              setReplies(replies.filter(diffReply(reply)));
            } else {
              setReplies([...replies, reply]);
            }
            return;
          }
          default: {
            warning(`onQuickReply unknown type: ${quickType}`);
            return;
          }
        }
      }
    },
    [replies, currentMessage]
  );

  const handleSend = (repliesData: Reply[]) => async (): Promise<void> => {
    await onQuickReply?.(
      repliesData.map((reply: Reply) => ({
        ...reply,
        messageId: currentMessage!._id,
      }))
    );
  };

  if (!shouldComponentDisplay) {
    return null;
  }

  return (
    <View style={styles.container}>
      {currentMessage!.quickReplies!.values.map((reply: Reply, index: number) => {
        const selected = type === "checkbox" && replies.find(sameReply(reply));

        return (
          <Pressable
            key={`${reply.value}-${index}`}
            style={[
              styles.quickReply,
              quickReplyStyle,
              {borderColor: color},
              selected && {backgroundColor: color},
            ]}
            onPress={handlePress(reply)}
          >
            <Text
              ellipsizeMode="tail"
              numberOfLines={10}
              style={[
                styles.quickReplyText,
                {color: selected ? Color.white : color},
                quickReplyTextStyle,
              ]}
            >
              {reply.title}
            </Text>
          </Pressable>
        );
      })}
      {replies.length > 0 && (
        <Pressable style={[styles.quickReply, styles.sendLink]} onPress={handleSend(replies)}>
          {renderQuickReplySend?.() || <Text style={styles.sendLinkText}>{sendText}</Text>}
        </Pressable>
      )}
    </View>
  );
};
