import {Text} from "ferns-ui";
import React, {useCallback} from "react";
import {Linking, Pressable} from "react-native";

interface LinkButtonProps {
  text: string;
  onClick?: () => void;
  href?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({text, href, onClick}) => {
  if (!onClick && !href) {
    console.warn("Link Button with no onClick or href");
  }

  const handlePress = useCallback(async () => {
    if (href) {
      await Linking.openURL(href);
    }
    if (onClick) {
      onClick();
    }
  }, [href, onClick]);

  return (
    <Pressable accessibilityHint="Opens link" accessibilityLabel="Link" onPress={handlePress}>
      <Text color="link" underline>
        {text}
      </Text>
    </Pressable>
  );
};
