import {ButtonProps} from "ferns-ui";

import {
  addTagTypes,
  GetAlertInstancesByIdRes,
  GetAlertsByIdRes,
  GetAppointmentSlotsRes,
  GetAuditLogByIdRes,
  GetAvatarsByIdRes,
  GetCarePodsByIdRes,
  GetCompanyOrganizationsRes,
  GetConversationsByIdRes,
  GetDotPhrasesByIdRes,
  GetExternalCliniciansByIdRes,
  GetFamilyUnitsByIdRes,
  GetFitbitHrvByIdRes,
  GetFitbitSleepByIdRes,
  GetFitbitStatusesByIdRes,
  GetFitbitStepsByIdRes,
  GetFormInstancesByIdRes,
  GetFormsByIdRes,
  GetGuidingHoursByIdRes,
  GetInsurancePlansByIdRes,
  GetMessagesByIdRes,
  GetPreClinicUpdatesByIdRes,
  GetPtoCoverageConfigsByIdRes,
  GetReferralMethodsByIdRes,
  GetReferralSourcesByIdRes,
  GetScheduleItemsByIdRes,
  GetTodoByIdRes,
  GetUserBioByIdRes,
  GetUsersByIdRes,
  GetUserSessionsByIdRes,
  GetUserSettingsByIdRes,
  GetUserStatusesByIdRes,
  GetUserUpdatesByIdRes,
  GetVoicemailsByIdRes,
  GetWorkflowMappingsByIdRes,
  GetWorkflowMappingsRes,
  LimitedUser,
} from "./openApiSdk";

// Constants
export const clinicalUpdateFieldOptions: ClinicalInfoSelectOptions = {
  clinicalStatus: [
    {label: "🔴 High risk / complex / severe symptoms", value: "High", score: 0},
    {label: "🟡 Moderate risk / stable", value: "Moderate", score: 0},
    {label: "🟢 Mild-Moderate risk / thriving", value: "Mild-Moderate", score: 0},
  ],
  therapyCadence: [
    {label: "Weekly", value: "Weekly"},
    {label: "Every other week", value: "EveryOtherWeek"},
    {label: "Twice weekly", value: "TwiceWeekly"},
  ],
};

export const ContactTypes = [
  "Emergency Contact",
  "Primary Care Doctor",
  "Dentist",
  "Community Service Board Case Worker",
];

// Copied from /backend. To update edit api/users first. Only include top level properties,
// do not nest props, "address.city" should be "address"
export const patientAllowProps = [
  "_id",
  "address",
  "bio",
  "carePlan",
  "careTeam",
  "consentFormAgreements",
  "email",
  "fitbitConfig",
  "gender",
  "genderSelfDescribe",
  "name",
  "namePronunciation",
  "online",
  "phoneNumber",
  "pronouns",
  "pushNotifications",
  "safetyPlan",
  "settings",
  "smsEnabled",
  "smsMessaging",
  "smsNotifications",
  "staffRoles",
  "thrivePlan",
  "timezone",
  "type",
  "usageData",
] as const;

export const SingleCheckboxOptions = {
  checked: "Checked - Yes/True",
  unchecked: "Unchecked - No/False",
};

// Enums

export enum StaffRoles {
  ClinicalDirector = "ClinicalDirector",
  ClinicalLeader = "ClinicalLeader",
  ClinicalQualityAssurance = "ClinicalQualityAssurance",
  EnrollmentCoordinator = "EnrollmentCoordinator",
  EnrollmentSupervisor = "EnrollmentSupervisor",
  FamilyGuide = "FamilyGuide",
  FamilyGuideSupervisor = "FamilyGuideSupervisor",
  MedicalDirector = "MedicalDirector",
  PatientGuide = "PatientGuide",
  PatientGuideSupervisor = "PatientGuideSupervisor",
  Psychiatrist = "Psychiatrist",
  RiskManager = "RiskManager",
  SoftwareEngineer = "SoftwareEngineer",
  SuperUser = "SuperUser",
  Therapist = "Therapist",
  TherapistSupervisor = "TherapistSupervisor",
}
// const must be used after enum to avoid TS error
export const SupervisorRoles: StaffRoles[] = [
  StaffRoles.FamilyGuideSupervisor,
  StaffRoles.EnrollmentSupervisor,
  StaffRoles.MedicalDirector,
  StaffRoles.PatientGuideSupervisor,
  StaffRoles.TherapistSupervisor,
];

// Interfaces
export interface AppState {
  // The user ID for the currently selected workflow
  workflowStaffId?: string;
  // The selected WorkflowMapping._id, representing a user in a workflow.
  workflowMappingId?: string;
  showConsentScreen?: boolean;
  lastBiometricsSuccess?: string;
  unreadUserUpdates?: boolean;
  userSettings?: UserSettings;
  collapseAllRightBar: boolean;
  infoModalDataKey: string;
  showSideDrawer: boolean;
  sideDrawerComponentName?: "AlertsView" | "UserUpdatesView" | "VoicemailsView" | undefined;
  internalChatConversationId?: string;
  internalChatIsFocused?: boolean;
}

export interface ConversationParticipant {
  _id?: string;
  userId?: User;
  role?: string;
}

export interface ConversationSplitPageItem {
  item: {
    avatar?: Avatar;
    conversation: Conversation;
    webPresence: {showOnlineWeb: boolean; showOnlineMobile: boolean; doNotDisturb: boolean};
    unreadCount?: number;
  };
  id: string;
}

export interface ConversationUser {
  // Can be undefined for system messages
  userId?: User;
  role?:
    | StaffRoles.PatientGuide
    | StaffRoles.Therapist
    | StaffRoles.Psychiatrist
    | StaffRoles.SuperUser
    | StaffRoles.FamilyGuide;
}

export interface CreateUserBio extends Omit<UpdateUserBio, "_id"> {}

export interface DownloadFileButtonProps extends Omit<ButtonProps, "onClick"> {
  url: string;
  filename: string;
}

export interface FetchPopulatedByIdRes<T> {
  data?: T;
  isSuccess: boolean;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

export interface FetchPopulatedRes<T> {
  data: {data: T[]; more?: boolean; page?: number; limit?: number; total?: number};
  isSuccess: boolean;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

export interface FormInstanceAnswer {
  prompt: string;
  questionId: string;
  answers: string[];
  score?: number;
  grouping?: string;
  _id: string;
  updated?: Date;
  created: Date;
  deleted?: boolean;
}

export interface NotificationItem {
  _id?: string;
  minutesBefore: number;
  sendAsSms: boolean;
  sendAsPush: boolean;
  taskName?: string;
  sent?: boolean;
  sentAt?: Date;
  notifications?: string[];
}

export interface PopulateFields {
  schema: string;
  key: string;
}

export interface RecurringScheduleItemConfig {
  startDatetime: string;
  endDatetime: string;
  interval?: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes?: number;
  skippedDates?: string[];
}

export interface ScheduleItemAttendee {
  _id?: string;
  userId: LimitedUser;
  role?: string;
}

export interface StaffHomeScreenViewProps {
  userId?: string;
}

export interface UpdateFamilyUnit extends Omit<FamilyUnit, "createdBy" | "created"> {}
export interface CreateFamilyUnit extends Omit<UpdateFamilyUnit, "_id"> {}

export interface UpdateUserBio extends Omit<UserBio, "created"> {}

// Types

export type ActivityLogEvent = AuditLog;

export type Address = User["address"];

export type Alert = GetAlertsByIdRes;

export type AlertInstance = GetAlertInstancesByIdRes;

export type AppointmentSlot = GetAppointmentSlotsRes["slots"][0][0];

export type AuditLog = GetAuditLogByIdRes;

export type Avatar = GetAvatarsByIdRes;

export type BillingInfo = User["billingInfo"];

export type CarePod = GetCarePodsByIdRes;

export type CharmKey =
  | "chiefComplaints"
  | "symptoms"
  | "physicalExamination"
  | "treatmentNotes"
  | "psychotherapyNotes"
  | "assessmentNotes"
  | "presentIllnessHistory"
  | "familySocialHistory"
  | "reviewOfSystems"
  | "pastMedicalHistory";

export type ClinicalInfoSelectOptions = {
  clinicalStatus: {
    label: string;
    value: User["clinicalStatus"]["status"];
    score?: number;
  }[];
  therapyCadence: {
    label: string;
    value: User["therapyCadence"]["status"];
  }[];
};

export type CompanyOrganizations = GetCompanyOrganizationsRes;

export type ConsentFormAgreement = User["consentFormAgreements"][0];

export type ConsentFormType = User["consentFormAgreements"][0]["consentFormType"];

export type Contact = User["contacts"][0];

export type Conversation = GetConversationsByIdRes;

export type ConversationMessage = GetMessagesByIdRes;

export type DotPhrase = GetDotPhrasesByIdRes;

export type ExternalClinician = GetExternalCliniciansByIdRes;

export type FamilyUnit = GetFamilyUnitsByIdRes;

export type FamilyUnitRelationship = {
  familyUserId: string;
  relationship: string;
};

export type FitbitActivity = GetFitbitStepsByIdRes;

export type FitbitHrv = GetFitbitHrvByIdRes;

export type FitbitSleep = GetFitbitSleepByIdRes;

export type FitbitSteps = GetFitbitStepsByIdRes;

export type FitbitStatus = GetFitbitStatusesByIdRes;

export type FollowUpResponseSettings = FormQuestion["followUpResponseSettings"];

export type Form = GetFormsByIdRes;

export type FormInstance = GetFormInstancesByIdRes;

export type FormInstanceType = "Assessment" | "Note" | "Survey";

export type FormInstanceWithForm = FormInstance & {form: Form};

export type FormQuestion = Form["questions"][0];

export type GuidingHour = GetGuidingHoursByIdRes;

export type InsurancePlan = GetInsurancePlansByIdRes;

export type Message = GetMessagesByIdRes;

export type OnlineStatus = User["online"];

export type PatientOrFamilyMem = Pick<GetUsersByIdRes, (typeof patientAllowProps)[number]>;

export type PatientsOrFamilyMems = PatientOrFamilyMem[];

export type PreClinicUpdate = GetPreClinicUpdatesByIdRes;

export type PreferredPharmacy = User["preferredPharmacies"][0];

export type ReferralMethod = GetReferralMethodsByIdRes;

export type ReferralSource = GetReferralSourcesByIdRes;

export type ScheduleItem = GetScheduleItemsByIdRes;

export type ScheduleItemType = NonNullable<ScheduleItem["type"]>;

export type SmsMessaging = User["smsMessaging"];

export type StaffRole = StaffRoles;

export type TagType = (typeof addTagTypes)[number];

export type TaskData = Omit<ScheduleItemAttendee, "userId"> & {userId: User};

export type TodoItem = GetTodoByIdRes;

export type User = GetUsersByIdRes;

export type UserBio = GetUserBioByIdRes;

export type UserSession = GetUserSessionsByIdRes;

export type UserSettings = GetUserSettingsByIdRes;

export type UserStatus = GetUserStatusesByIdRes;

export type UserType = User["type"];

export type UserUpdate = GetUserUpdatesByIdRes;

export type Voicemail = GetVoicemailsByIdRes;

export type WorkflowMapping = GetWorkflowMappingsByIdRes;

export type WorkflowMappings = GetWorkflowMappingsRes;

export interface EngagementData {
  userId: string;
  primaryCaregiverName?: string;
  primaryCaregiverId?: string;
  userStatusId: string;
  patientMessages: number;
  patientAttended: number;
  patientAttendedNonClinical: number;
  patientOpenNotes: number;
  familyMessages: number;
  familyAttended: number;
  familyAttendedNonClinical: number;
  familyOpenNotes: number;
  engagementDays: number;
  engagementMonths: {start?: string; end?: string}[];
  selectedEngagementMonthStart: string;
  selectedEngagementMonthEnd: string;
}

export type UserFlags = Omit<User["featureFlags"], "_id">;

export interface ExplorerUsers extends User {
  status: UserStatus;
}

export interface ExplorerAlerts extends AlertInstance {
  dueDate: Date;
  referencedUser: User;
  todoItem: TodoItem;
  parentAlert: Alert;
}

export type Timezone =
  | "America/New_York"
  | "America/Chicago"
  | "America/Denver"
  | "America/Los_Angeles"
  | "America/Anchorage"
  | "Pacific/Honolulu"
  | "America/Phoenix";

type Primitive = string | number | boolean | bigint | symbol | undefined | null;
type NotAnObject = Primitive | any[] | ((...args: any[]) => any);

// This is necessary to avoid infinitely deep recursion. We shouldn't be nesting our data more than
// 3 layers deep anyway (hopefully).

// First layer
type DotNotation<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number
        ? T[K] extends NotAnObject
          ? `${Prefix}${K}`
          : `${Prefix}${K}` | DotNotation2<T[K], `${Prefix}${K}.`>
        : never;
    }[keyof T];

// Second layer
type DotNotation2<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number
        ? T[K] extends NotAnObject
          ? `${Prefix}${K}`
          : `${Prefix}${K}` | DotNotation3<T[K], `${Prefix}${K}.`>
        : never;
    }[keyof T];

// Third layer - recursion stops here by not calling further DotNotation
type DotNotation3<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number ? `${Prefix}${K}` : never;
    }[keyof T];

export type UserDotNotation = DotNotation<User>;

export type PtoCoverageConfig = GetPtoCoverageConfigsByIdRes;
