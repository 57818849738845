import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  StaffHomeScreenViewProps,
  useGetFitbitHrvQuery,
  useGetFitbitSleepQuery,
  useGetFitbitStepsQuery,
  useGetUsersByIdQuery,
} from "@store";
import {Box, Link, SelectField, Text} from "ferns-ui";
import React, {useState} from "react";

import {FitbitStatusCard} from "./FitbitStatus";
import {UserHeartRateVariabilityChart} from "./UserHrvChart";
import {UserInfoCard} from "./UserInfoCard";
import {UserSleepChart} from "./UserSleepChart";
import {UserStepsChart} from "./UserStepsChart";

const fitbitTimeOptions = [
  {label: "1 day", value: "1d"},
  {label: "1 week", value: "7d"},
  {label: "2 weeks", value: "14d"},
  {label: "1 month", value: "1mo"},
  {label: "3 months", value: "3mo"},
];

export const FitbitView = ({userId}: StaffHomeScreenViewProps): React.ReactElement | null => {
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [selectedTimeframe, setSelectedTimeframe] = useState("7d");

  const {data: fitbitSleepData} = useGetFitbitSleepQuery({period: selectedTimeframe});
  const {data: fitbitStepData} = useGetFitbitStepsQuery({period: selectedTimeframe});
  const {data: fitbitHrvData} = useGetFitbitHrvQuery({period: selectedTimeframe});

  const fitbitSleep = fitbitSleepData?.data ?? [];
  const fitbitSteps = fitbitStepData?.data ?? [];
  const fitbitHrvs = fitbitHrvData?.data ?? [];

  const sleep = fitbitSleep.filter((s: any) => s.userId === user?._id);
  const steps = fitbitSteps.filter((s: any) => s.userId === user?._id);
  const hrvs = fitbitHrvs.filter((s: any) => s.userId === user?._id);

  if (!user) {
    return null;
  }

  return (
    <UserInfoCard title="Fitbit">
      <FitbitStatusCard userId={user._id} />
      {user.fitbitId ? (
        <>
          <SelectField
            options={fitbitTimeOptions}
            requireValue
            title="Show previous:"
            value={selectedTimeframe}
            onChange={setSelectedTimeframe}
          />
          <UserStepsChart fitbitSteps={steps} user={user} />
          <UserSleepChart sleep={sleep} user={user} />
          <UserHeartRateVariabilityChart fitbitHrvs={hrvs} user={user} />
        </>
      ) : (
        <Box direction="column">
          <Text color="error" size="lg">
            Fitbit not yet connected by user
          </Text>
          <Text color="secondaryLight" size="md">
            Please use the instructions{" "}
            <Link
              href="https://www.notion.so/flourishhealth/Fitbit-Setup-46a58384e8ee4d5baf8f82f7d266b9af?pvs=4"
              text="here"
            />{" "}
            to guide patient to connect their Fitbit account from their app
          </Text>
        </Box>
      )}
    </UserInfoCard>
  );
};
