import {SelectField} from "ferns-ui";
import React from "react";

export type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";
interface DayDropdownProps {
  day?: Day;
  onChange: (result: Day) => void;
}

export const DayDropdown = ({day, onChange}: DayDropdownProps): React.ReactElement => {
  const dayOptions = [
    {label: "Monday", value: "Monday"},
    {label: "Tuesday", value: "Tuesday"},
    {label: "Wednesday", value: "Wednesday"},
    {label: "Thursday", value: "Thursday"},
    {label: "Friday", value: "Friday"},
    {label: "Saturday", value: "Saturday"},
    {label: "Sunday", value: "Sunday"},
  ];
  return (
    <SelectField
      options={dayOptions}
      requireValue={false}
      value={day as string}
      onChange={(i): void => {
        onChange(i as Day);
      }}
    />
  );
};
