import {Box, Text} from "ferns-ui";
import PropTypes from "prop-types";
import React from "react";

import {IMessage, SystemMessageProps} from "./Models";
import {StylePropType} from "./utils";

export const SystemMessage = <TMessage extends IMessage = IMessage>({
  currentMessage,
}: SystemMessageProps<TMessage>): React.ReactElement | null => {
  if (!currentMessage || currentMessage?.system === false) {
    return null;
  }

  return (
    <Box color="neutral" margin={4} paddingX={4} paddingY={2} rounding="lg">
      <Text color="inverted">System Message: {currentMessage.text}</Text>
    </Box>
  );
};

SystemMessage.propTypes = {
  currentMessage: PropTypes.object,
  containerStyle: StylePropType,
  wrapperStyle: StylePropType,
  textStyle: StylePropType,
};
