import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {useGetAvatarsQuery, User, userName} from "@store";
import {isStaff, pageOnError} from "@utils";
import {Avatar, Box, Icon, Page, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

import {StaffStackParamList} from "../types";
import {CharmTokenPane} from "./CharmTokenPane";
import {LogoutButton} from "./LogoutButton";
import {ProfileFields} from "./ProfileFields";
import {ProfileSettings} from "./ProfileSettings";
import {SectionDivider} from "./SectionDivider";
import {VersionString} from "./VersionString";

interface StaffProfileProps {}

export const StaffProfile = ({}: StaffProfileProps): ReactElement | null => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const user = useReadProfile();
  const {data: userAvatarList} = useGetAvatarsQuery({ownerId: user?._id || ""});
  const userAvatar = userAvatarList?.data?.[0];

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  // Once the user is loaded, set the current user.
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  if (!user || !currentUser || !isStaff(user.type)) {
    return null;
  }

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box
        alignItems="center"
        alignSelf="center"
        color="base"
        gap={5}
        justifyContent="center"
        maxWidth={420}
        padding={6}
        width="100%"
      >
        <Box width="100%">
          <Box direction="row" justifyContent="center" marginBottom={2}>
            <Avatar
              hasBorder={false}
              name={userName(user)}
              size="lg"
              src={userAvatar?.imageMediaLink}
            />
          </Box>
          <Box
            accessibilityHint="Edit Bio / Profile Picture"
            accessibilityLabel="Edit"
            alignItems="center"
            direction="row"
            justifyContent="center"
            onClick={(): void => navigation.navigate("UserBio", {userId: user._id})}
          >
            <Box marginRight={2}>
              <Icon iconName="pencil" />
            </Box>
            <Text color="secondaryLight" size="lg">
              Edit Bio / Profile Picture
            </Text>
          </Box>
          {Boolean(!userAvatar) && (
            <Box alignItems="center" marginTop={2} width="100%">
              <Text color="error" italic size="sm">
                {`*It looks like you don't have a profile picture yet. Your profile picture shows up in your patient or family member's app and helps them feel more connected to you! You can add it with the "Edit Bio / Profile Picture" link above.`}
              </Text>
            </Box>
          )}
        </Box>

        <SectionDivider />

        <ProfileFields />

        <SectionDivider />

        <ProfileSettings />

        <SectionDivider />

        <CharmTokenPane />

        <SectionDivider />

        <LogoutButton />
      </Box>
      <VersionString />
    </Page>
  );
};
