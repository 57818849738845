import React from "react";

import {populateId} from "../ferns-rtk";
import {useGetInsurancePlansQuery, User} from "../store";
import {AutoTapToEdit, AutoTapToEditProps} from "./AutoTapToEdit";

interface AutoTapToEditInsuranceProps extends Omit<AutoTapToEditProps, "field"> {
  currentUser: User;
}

export const AutoTapToEditInsurance: React.FC<AutoTapToEditInsuranceProps> = ({
  currentUser,
  ...rest
}) => {
  const {data: insuranceData} = useGetInsurancePlansQuery({});

  return (
    <AutoTapToEdit
      {...rest}
      field="billingInfo.insurancePlan"
      options={[
        ...(insuranceData?.data ?? []).map((insurance) => ({
          label: insurance.name,
          value: insurance._id,
        })),
      ]}
      title="Insurance Plan"
      transform={(insuranceId): string => {
        if (!currentUser.billingInfo?.insurancePlan) {
          return "Not Set";
        }
        return populateId(insuranceId, insuranceData)?.name ?? "Unknown";
      }}
      type="select"
      value={currentUser.billingInfo?.insurancePlan}
    />
  );
};
