import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {Form, GetFormInstancesArgs, useGetFormInstancesQuery} from "@store";
import {PatientStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Badge, Box, Heading, Icon, printDateAndTime, Text} from "ferns-ui";
import React, {ReactElement} from "react";

interface FormInstanceListProps {
  formTypes: GetFormInstancesArgs["type"];
  userId: string;
}
export const FormInstanceList = ({formTypes, userId}: FormInstanceListProps): ReactElement => {
  const navigation =
    useNavigation<NativeStackNavigationProp<PatientStackParamList, UserTypes.Patient>>();
  const {data} = useGetFormInstancesQuery({userId, type: formTypes});
  const formInstancesData = data?.data;

  // sort forms by completion date, and any forms that are not completed are sorted to the top
  const formInstances = formInstancesData?.slice().sort((a, b) => {
    if (
      a.status === "Completed" &&
      b.status === "Completed" &&
      a.completedDate &&
      b.completedDate
    ) {
      return new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime();
    } else if (a.status === "Completed" && b.status !== "Completed") {
      return 1;
    } else if (a.status !== "Completed" && b.status === "Completed") {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <Box width="100%">
      <Box marginBottom={2}>
        <Heading>{formTypes === "Assessment" ? "Assessments" : "Documents"}</Heading>
      </Box>
      {!Boolean(formInstances?.length) && (
        <Text>Once you have documents, they will be displayed here.</Text>
      )}
      {Boolean(formInstances?.length) && (
        <Box paddingY={2}>
          {formInstances?.map((f) => {
            return (
              <Box
                key={f._id}
                accessibilityHint="View form details"
                accessibilityLabel="View"
                borderBottom="default"
                direction="row"
                flex="grow"
                paddingY={2}
                width="100%"
                onClick={() => navigation.navigate("ViewForm", {formId: f._id})}
              >
                <Box flex="grow">
                  <Box direction="column" justifyContent="center" marginLeft={4}>
                    <Text bold size="md">
                      {(f.form as Form).name}
                    </Text>
                    {Boolean(f.completedDate) ? (
                      <Text size="sm">{`Completed: ${printDateAndTime(f.completedDate!, {
                        showTimezone: true,
                      })}`}</Text>
                    ) : (
                      <Text size="sm">{`Created: ${printDateAndTime(f.created, {
                        showTimezone: true,
                      })}`}</Text>
                    )}
                  </Box>
                </Box>
                <Box alignItems="center" justifyContent="center">
                  <Box alignItems="center" direction="row" justifyContent="center">
                    {f.status === "Sent To User" && (
                      <Box marginRight={3}>
                        <Badge status="warning" value="Not Completed" />
                      </Box>
                    )}
                    {Boolean(["Completed", "Signed"].includes(f.status)) && (
                      <Box marginRight={3}>
                        <Badge status="success" value="Completed" />
                      </Box>
                    )}
                    <Icon iconName="chevron-right" />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
