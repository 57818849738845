import {NotificationManager, RecurringSelector, UserSelector} from "@components";
import {
  APPOINTMENT_CONFIG,
  DEFAULT_APPT_OPTIONS,
  NotificationsState,
  RecurringType,
  VIDEO_MEETINGS,
} from "@constants";
import {usePrevious} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  flourishApi,
  generateScheduleItemTitle,
  printAddress,
  RecurringScheduleItemConfig,
  ScheduleItem,
  ScheduleItemType,
  useAppDispatch,
  useDeleteRecurringScheduleItemsByIdMutation,
  useDeleteScheduleItemsByIdMutation,
  useGetRecurringScheduleItemsByIdQuery,
  useGetScheduleItemsByIdQuery,
  useGetUsersQuery,
  usePatchRecurringScheduleItemsByIdMutation,
  usePatchScheduleItemsByIdMutation,
  usePostRecurringScheduleItemsMutation,
  usePostScheduleItemsMutation,
  User,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {isFamilyMember, isPatient} from "@utils";
import {
  Box,
  Button,
  DateTimeField,
  Heading,
  Page,
  SelectField,
  Text,
  TextArea,
  TextField,
  useToast,
} from "ferns-ui";
import isEqual from "lodash/isEqual";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";

interface CreateScheduleItemScreenProps extends StaffStackScreenProps<"CreateScheduleItem"> {}

export const CreateScheduleItemScreen = ({
  route,
  navigation,
}: CreateScheduleItemScreenProps): ReactElement => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const {data: scheduledItemData} = useGetScheduleItemsByIdQuery(
    isDeleting || !route.params.scheduleItemId ? skipToken : route.params.scheduleItemId
  );
  const {data: recurringScheduleItemData} = useGetRecurringScheduleItemsByIdQuery(
    scheduledItemData?.recurringId ?? skipToken,
    {skip: isDeleting}
  );
  const [createScheduleItem, {isLoading: createLoading}] = usePostScheduleItemsMutation();
  const [updateScheduleItem, {isLoading: updateLoading}] = usePatchScheduleItemsByIdMutation();
  const [deleteScheduleItem] = useDeleteScheduleItemsByIdMutation();
  const [createRecurringScheduleItem] = usePostRecurringScheduleItemsMutation();
  const [updateRecurringScheduleItem] = usePatchRecurringScheduleItemsByIdMutation();
  const [deleteRecurringScheduleItem] = useDeleteRecurringScheduleItemsByIdMutation();
  const [notifications, setNotifications] = useState<NotificationsState>([
    {minutesBefore: 15, sendAsPush: true, sendAsSms: true},
  ]);

  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<ScheduleItemType | "">(route.params.type ?? "");
  const [userIds, setUserIds] = useState<string[]>(
    route.params.selectedUserId ? [route.params.selectedUserId] : []
  );
  const [staffIds, setStaffIds] = useState<string[]>([]);
  const {data: userData} = useGetUsersQuery(userIds.length > 0 ? {_id: {$in: userIds}} : skipToken);
  const users: User[] = useMemo(() => userData?.data || [], [userData]);
  const prevUsers = usePrevious(users);
  const {data: staffData} = useGetUsersQuery(
    staffIds.length > 0 ? {_id: {$in: staffIds}} : skipToken
  );
  const staff = useMemo(() => staffData?.data || [], [staffData]);
  const [startDatetime, setStartDatetime] = useState<ScheduleItem["startDatetime"]>(
    route.params.startDatetime
      ? route.params.startDatetime
      : DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0}).toISO()
  );
  const [userWithAddress, setUserWithAddress] = useState<string>("");
  const [staffNotes, setStaffNotes] = useState<ScheduleItem["staffNotes"]>("");
  const [userNotes, setUserNotes] = useState<ScheduleItem["userNotes"]>("");
  let startDt = DateTime.fromISO(startDatetime);
  if (!startDt.isValid) {
    startDt = DateTime.now();
  }
  const [endDatetime, setEndDatetime] = useState<ScheduleItem["endDatetime"]>(
    startDt.plus({minutes: 50}).toISO()
  );
  const [location, setLocation] = useState<ScheduleItem["location"]>("");
  const [locationHelperText, setLocationHelperText] = useState<string>("");

  const [recurringType, setRecurringType] = useState<RecurringType>(RecurringType.NoRepeat);
  const [recurringScheduleItem, setRecurringScheduleItem] = useState<RecurringScheduleItemConfig>({
    startDatetime,
    endDatetime,
    interval: 1,
    durationMinutes: 30,
    daysOfWeek: [startDt.toFormat("EEEE")],
  });

  // Use the edited scheduled item data to populate the form.
  useEffect(() => {
    if (scheduledItemData) {
      scheduledItemData.title && setTitle(scheduledItemData.title);
      scheduledItemData.type && setType(scheduledItemData.type);
      scheduledItemData.startDatetime && setStartDatetime(scheduledItemData.startDatetime);
      scheduledItemData.endDatetime && setEndDatetime(scheduledItemData.endDatetime);
      scheduledItemData.location && setLocation(scheduledItemData.location);
      scheduledItemData.staff && setStaffIds(scheduledItemData.staff.map((s) => s.userId._id));
      scheduledItemData.users && setUserIds(scheduledItemData.users.map((u) => u.userId._id));
      scheduledItemData.staffNotes && setStaffNotes(scheduledItemData.staffNotes);
      scheduledItemData.userNotes && setUserNotes(scheduledItemData.userNotes);
      scheduledItemData.notifications && setNotifications(scheduledItemData.notifications as any);
    }
  }, [scheduledItemData]);

  // If the start date or type changes, update the end date to the default for the type or 50
  // minutes after.
  useEffect(() => {
    const end = DateTime.fromISO(startDatetime);
    if (!end.isValid) {
      console.warn("Invalid start date", startDatetime);
      return;
    }
    const duration = APPOINTMENT_CONFIG[type as ScheduleItemType]?.duration ?? 50;
    setEndDatetime(end.plus({minutes: duration}).toISO());
    // If scheduling in the past, don't set notifications.
    if (DateTime.now() > end) {
      setNotifications([]);
    }
  }, [startDatetime, scheduledItemData, type]);

  const recurringValid =
    recurringType === RecurringType.NoRepeat ||
    (recurringScheduleItem.startDatetime &&
      recurringScheduleItem.endDatetime &&
      recurringScheduleItem.interval &&
      recurringScheduleItem.durationMinutes &&
      recurringType === RecurringType.Weekly &&
      recurringScheduleItem.daysOfWeek &&
      recurringScheduleItem.daysOfWeek.length > 0);

  const startIsAfterEnd = DateTime.fromISO(startDatetime) > DateTime.fromISO(endDatetime);
  // we want to disable edit if it is a recurring schedule item and already created
  // recurring schedule items can only be deleted or ended
  const disableEdit = Boolean(recurringScheduleItemData?._id);

  // See if any of the selected patients is under 18.
  const allPatientsOver18 =
    users.length === 0 ||
    !users
      .filter((u) => isPatient(u.type))
      .every((u) => u.birthday && DateTime.now().diff(DateTime.fromISO(u.birthday)).years >= 18);

  const requiresAdult = Boolean(["Psychiatry", "Psychiatry Intake"].includes(type));

  // If we already have family members set, don't mess with those.
  const hasFamilyMembersSet = users.some((u) => isFamilyMember(u.type));

  // Ensure at least one user has a care pod OR it is an Eligibility Interview,
  // or warn the item will not be copied to Google Calendar.
  const userHasCarePod = Boolean(
    users.find((u) => u?.carePod) || ["Eligibility Interview"].includes(type)
  );

  const linkError = useMemo(() => {
    // If the meeting contains "doxy.me" or "zoom.us" and the link doesn't contain "http", show an
    // error
    if (
      (location?.includes("doxy.me") || location?.includes("zoom.us")) &&
      !(location?.includes("https://") || location?.includes("http://"))
    ) {
      return "Please include 'http://' or 'https://' in the link";
    }
  }, [location]);

  // whenever there is an update to the staff/user list AND a type is selected, update the title
  useEffect(() => {
    if (type && users && staff) {
      // we have to filter here to clear out any users that still exist in the fetched user list
      // but have been removed from the selected list (userIds and staffIds)
      const filteredUsers = users.filter((u) => userIds.includes(u._id));
      const filteredStaff = staff.filter((s) => staffIds.includes(s._id));
      setTitle(generateScheduleItemTitle(type, filteredUsers, filteredStaff, recurringType));
      // also set the location to the first staff member's video chat link if it is a video meeting
      if (!location && VIDEO_MEETINGS.includes(type)) {
        // If this is a clinical intake, we always want to use the guide's link.
        if (type === "Clinical Intake") {
          const guide = filteredStaff?.find(
            (s) => s.staffRoles.PatientGuide && Boolean(s.videoChatLink)
          );
          if (guide?.videoChatLink) {
            setLocationHelperText(`Automatically set to ${guide.name}'s video chat link.`);
            setLocation(guide.videoChatLink);
          }
        } else {
          // In all other cases, grab the first staff with a video chat link.
          const videoStaff = filteredStaff?.find((s) => Boolean(s.videoChatLink));
          if (videoStaff?.videoChatLink) {
            setLocationHelperText(`Automatically set to ${videoStaff.name}'s video chat link.`);
            setLocation(videoStaff.videoChatLink);
          }
        }
      } else if (type === "In Home Onboarding Visit" || type === "In Home Guide Visit") {
        setLocationHelperText(`Autofilled address of ${userWithAddress}`);
        // Grab the first user with an address.
        const selectedAddressUser = users.find((m) => m.address?.address1);
        // For in person, automatically use the address of the first user.
        if (selectedAddressUser) {
          setUserWithAddress(selectedAddressUser.name);
          setLocation(printAddress(selectedAddressUser.address));
        }
      }
    }
  }, [recurringType, staff, staffIds, type, userIds, userWithAddress, users, location]);

  // this is to set the staffIds based on the type selected
  useEffect(() => {
    // We only want to do this if it's a new schedule item, overwise UI will display incorrect data
    if (route.params?.scheduleItemId) {
      return;
    }
    // only do this if the users have been updated,
    // otherwise you wont be able to remove staff members in the UI since this will constantly
    // re-add them
    if (!isEqual(users, prevUsers)) {
      const newStaffIds: string[] = [];
      for (const role of APPOINTMENT_CONFIG[type as ScheduleItemType]?.staffRoles ?? []) {
        users.forEach((user) => {
          if ([...Object.keys(user.careTeam)].includes(role)) {
            // this makes sure we aren't inserting "" into the array
            if (user.careTeam[role as keyof typeof user.careTeam]?._id) {
              newStaffIds.push(user.careTeam[role as keyof typeof user.careTeam]._id);
            }
          }
        });
      }
      // if the current staffIds are not the same as the newStaffIds, update the staffIds
      if (!isEqual(staffIds, newStaffIds)) {
        setStaffIds(newStaffIds);
      }
    }
  }, [prevUsers, route.params?.scheduleItemId, staffIds, type, users]);

  const onTypeChange = useCallback(
    (value: keyof typeof APPOINTMENT_CONFIG): void => {
      setTitle("");
      const start = DateTime.fromISO(startDatetime);

      // If scheduling in the past, don't set notifications.
      if (
        start > DateTime.now() &&
        APPOINTMENT_CONFIG[value as ScheduleItemType]?.reminders?.length
      ) {
        // The users don't have the app yet, so we default to only SMS notifications.
        setNotifications(APPOINTMENT_CONFIG[value as ScheduleItemType]!.reminders);
      }

      if (!start.isValid) {
        console.warn("Invalid start date", startDatetime);
        return;
      }
      setType(value);
      setEndDatetime(
        start
          .plus({minutes: APPOINTMENT_CONFIG[value as ScheduleItemType]?.duration ?? 50})
          .toString()
      );
      setRecurringScheduleItem({
        ...recurringScheduleItem,
        durationMinutes: APPOINTMENT_CONFIG[value as ScheduleItemType]?.duration ?? 30,
        daysOfWeek: start.toFormat("EEEE") ? [start.toFormat("EEEE")] : [],
      });
      if (APPOINTMENT_CONFIG[value as ScheduleItemType]?.staffRoles) {
        // get the staff member with the correct role that is assigned to the user (patient)
        const newStaffIds: string[] = [];
        if (userIds.length === 0) {
          return;
        }
        for (const role of APPOINTMENT_CONFIG[value as ScheduleItemType]?.staffRoles ?? []) {
          users.forEach((user) => {
            if ([...Object.keys(user.careTeam)].includes(role)) {
              // this makes sure we aren't inserting "" into the array
              if (user.careTeam[role as keyof typeof user.careTeam]?._id) {
                newStaffIds.push(user.careTeam[role as keyof typeof user.careTeam]._id);
              }
            }
          });
        }
        setStaffIds(newStaffIds);
      }

      // If scheduling in the past, don't set notifications.
      if (start > DateTime.now()) {
        // The users don't have the app yet, so we default to only SMS notifications.
        if (value && APPOINTMENT_CONFIG[value as ScheduleItemType]?.reminders?.length) {
          setNotifications(APPOINTMENT_CONFIG[value as ScheduleItemType]!.reminders);
        } else {
          setNotifications([
            {minutesBefore: 15, sendAsPush: false, sendAsSms: true},
            {minutesBefore: 24 * 60, sendAsPush: false, sendAsSms: true},
          ]);
        }
      }
    },
    [recurringScheduleItem, startDatetime, userIds, users]
  );

  const onUserChange = useCallback((newUserList: string[]): void => {
    if (newUserList.length === 0) {
      setUserIds([]);
      setStaffIds([]);
      return;
    }
    setUserIds(newUserList);
  }, []);

  const onSave = async (): Promise<void> => {
    // Only send user ids and role, instead of the entire user objects.
    // That was causing request too large errors.
    const userIdsMapped = users.map((u) => ({userId: u._id})) as unknown as ScheduleItem["staff"];
    const staffIdsMapped = staff.map((u) => ({userId: u._id})) as unknown as ScheduleItem["users"];

    if (!type) {
      return;
    }

    try {
      if (recurringType === RecurringType.NoRepeat) {
        if (scheduledItemData?._id) {
          await updateScheduleItem({
            id: scheduledItemData._id,
            body: {
              title,
              type,
              startDatetime,
              endDatetime,
              location,
              staff: staffIdsMapped as any,
              users: userIdsMapped as any,
              staffNotes,
              userNotes,
              notifications: notifications as any,
            },
          }).unwrap();
          toast.show("Schedule item updated");
        } else {
          await createScheduleItem({
            title,
            type,
            startDatetime,
            endDatetime,
            location,
            staff: staffIdsMapped as any,
            users: userIdsMapped as any,
            staffNotes,
            userNotes,
            notifications: notifications as any,
          }).unwrap();
          toast.show("Schedule item created");
        }
      } else {
        // it is a recurring type
        await createRecurringScheduleItem({
          title,
          type,
          location,
          staff: staffIdsMapped as any,
          users: userIdsMapped as any,
          staffNotes,
          userNotes,
          notifications: notifications as any,
          frequency: recurringType.toString() as any,
          interval: recurringScheduleItem.interval,
          daysOfWeek: recurringScheduleItem.daysOfWeek,
          durationMinutes: recurringScheduleItem.durationMinutes,
          endDatetime: recurringScheduleItem.endDatetime.toString(),
          startDatetime: recurringScheduleItem.startDatetime.toString(),
        }).unwrap();
        toast.show("Recurring schedule item created");
      }
    } catch (error: any) {
      toast.catch(error, `Error ${scheduledItemData?._id ? "updating" : "creating"} schedule item`);
      return;
    }
    navigation.pop();
  };

  const onDelete = async (): Promise<void> => {
    setIsDeleting(true);
    try {
      await deleteScheduleItem(scheduledItemData?._id ?? "").unwrap();
    } catch (error: any) {
      setIsDeleting(false);
      toast.catch(error, "Error deleting schedule item");
      return;
    }
    navigation.pop();
  };

  const onDeleteRecurring = async (): Promise<void> => {
    setIsDeleting(true);
    if (!scheduledItemData?.recurringId) {
      return;
    }
    try {
      await deleteRecurringScheduleItem(scheduledItemData.recurringId).unwrap();
      dispatch(flourishApi.util.invalidateTags(["scheduleitems"]));
    } catch (error: any) {
      setIsDeleting(false);
      toast.catch(error, "Error deleting schedule item");
      return;
    }
    toast.show("Recurring schedule item deleted");
    navigation.pop();
  };

  const onEndRecurring = async (): Promise<void> => {
    if (!scheduledItemData?.recurringId) {
      return;
    }
    try {
      await updateRecurringScheduleItem({
        id: scheduledItemData.recurringId,
        body: {
          endDatetime: DateTime.now().toISO(),
        },
      }).unwrap();
      // need this extra dispatch because we aren't altering the scheduleitems directly
      dispatch(flourishApi.util.invalidateTags(["scheduleitems"]));
    } catch (error: any) {
      toast.catch(error, "Error deleting schedule item");
      return;
    }
    navigation.pop();
    toast.show("Recurring schedule item ended");
  };

  const onReset = (): void => {
    setTitle("");
    setType("");
    setStartDatetime(DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0}).toISO());
    setEndDatetime(DateTime.now().plus({days: 1}).set({hour: 13, minute: 0, second: 0}).toISO());
    setLocation("");
    setStaffIds([]);
    setUserIds([]);
    setStaffNotes("");
    setUserNotes("");
    setNotifications([{minutesBefore: 15, sendAsPush: true, sendAsSms: false}]);
  };

  // If we passed in the type via params, use that and set defaults based on it.
  // Only run this once on page load.
  useEffect(() => {
    // We only want to do this if it's a new schedule item
    if (route.params?.scheduleItemId) {
      return;
    }
    if (route.params.type) {
      onTypeChange(route.params.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      navigation={navigation}
      title={scheduledItemData?._id ? "Update Schedule Item" : "Create Schedule Item"}
    >
      <SelectField
        disabled={disableEdit}
        options={DEFAULT_APPT_OPTIONS}
        requireValue={false}
        title="Type"
        value={type}
        onChange={(value: string | undefined) => {
          onTypeChange(value as keyof typeof APPOINTMENT_CONFIG);
        }}
      />
      <TextField disabled={disableEdit} title="Title" value={title} onChange={setTitle} />
      <UserSelector disabled={disableEdit} userIds={userIds} onChangeUserIds={onUserChange} />
      <UserSelector disabled={disableEdit} staff userIds={staffIds} onChangeUserIds={setStaffIds} />
      <RecurringSelector
        recurringScheduleItem={recurringScheduleItem}
        recurringScheduleItemId={scheduledItemData?.recurringId}
        recurringType={recurringType}
        setRecurringScheduleItem={setRecurringScheduleItem}
        setRecurringType={setRecurringType}
        type={type}
      />
      {Boolean(recurringType === RecurringType.NoRepeat) && (
        <Box direction="row" paddingY={2} width="100%">
          <Box flex="grow" marginRight={2} maxWidth="50%">
            <DateTimeField
              disabled={disableEdit}
              title="Start Time"
              type="datetime"
              value={startDatetime}
              onChange={(r?: string): void => {
                if (!r) {
                  return;
                }
                const start = DateTime.fromISO(r);
                const end = DateTime.fromISO(endDatetime);
                const newStart = DateTime.fromISO(r);
                if (!start.isValid || !end.isValid || !newStart.isValid) {
                  console.warn("Invalid start or end date", startDatetime, endDatetime, newStart);
                  return;
                }
                const diff = end.startOf("minute").diff(start.startOf("minute")).minutes;
                setEndDatetime(newStart.plus({minutes: diff}).toString());
                setStartDatetime(r);
              }}
            />
          </Box>
          <Box flex="grow" maxWidth="50%">
            <DateTimeField
              disabled={disableEdit}
              title="End Time"
              type="datetime"
              value={endDatetime}
              onChange={(r?: string): void => {
                if (!r) {
                  return;
                }
                setEndDatetime(r);
              }}
            />
          </Box>
        </Box>
      )}
      <Heading size="sm">Patient Notifications</Heading>
      {Boolean(scheduledItemData?._id) && (
        <Text>
          Note: You cannot edit notifications of an existing event. Please delete and re-create if
          you need to edit.
        </Text>
      )}
      <NotificationManager
        attendeeIds={userIds}
        // disable if editing existing schedule item because we don't have the logic on the backend
        // to update notifications
        disabled={disableEdit || Boolean(scheduledItemData?._id)}
        notifications={notifications}
        setNotifications={setNotifications}
      />
      <Box paddingY={2}>
        <TextField
          disabled={disableEdit}
          errorText={linkError}
          helperText={locationHelperText}
          title="Location (Zoom Link, Phone #, Physical Address, etc.)"
          value={location}
          onBlur={(value: string): void => {
            setLocation(value.trim());
          }}
          onChange={(value: string): void => {
            setLocation(value.trim());
          }}
        />
      </Box>
      <TextArea
        disabled={disableEdit}
        helperText="Internal notes about the meeting. These will not show up in the user's app."
        title="Staff-Facing Notes"
        value={staffNotes}
        onChange={(value: string): void => {
          setStaffNotes(value);
        }}
      />

      <TextArea
        disabled={disableEdit}
        helperText="These will show up in the user's app"
        title="User-Facing Notes"
        value={userNotes}
        onChange={(value: string): void => {
          setUserNotes(value);
        }}
      />
      {Boolean(allPatientsOver18 && !hasFamilyMembersSet && requiresAdult) && (
        <Box paddingY={2}>
          <Text color="error">
            Patients under 18 require a family member to be present for {type}.
          </Text>
        </Box>
      )}
      {Boolean(!userHasCarePod && !["Eligibility Interview"].includes(type)) && (
        <Box paddingY={2}>
          <Text color="error">
            No care pod found for any user, will not be copied to Google Calendar.
          </Text>
        </Box>
      )}

      <Box direction="row" paddingY={4} width={200}>
        {Boolean(!scheduledItemData?.recurringId) && (
          <Box marginRight={4}>
            <Button
              disabled={
                !type ||
                createLoading ||
                updateLoading ||
                startIsAfterEnd ||
                !recurringValid ||
                !userHasCarePod ||
                Boolean(linkError) ||
                userIds.length < 1 ||
                staffIds.length < 1
              }
              loading={createLoading || updateLoading}
              text={scheduledItemData?._id ? "Update Schedule Item" : "Create Schedule Item"}
              onClick={onSave}
            />
          </Box>
        )}
        {Boolean(scheduledItemData?._id) && (
          <Box marginRight={4}>
            <Button
              confirmationText={
                scheduledItemData?.recurringId
                  ? "This action will remove this single event from the recurring series, but leave the rest in place. Do you want to continue?"
                  : "This action will remove this event from the schedule. Do you want to continue?"
              }
              disabled={createLoading || updateLoading || startIsAfterEnd}
              loading={createLoading || updateLoading}
              text="Delete Schedule Item"
              variant="destructive"
              withConfirmation
              onClick={onDelete}
            />
          </Box>
        )}
        {Boolean(
          scheduledItemData?._id && scheduledItemData?.recurringId && DateTime.now() < startDt
        ) && (
          <Box marginRight={4}>
            <Button
              confirmationText="This will delete the entire recurring series of schedule items, except the ones that occurred in the past. Are you sure?"
              disabled={createLoading || updateLoading || startIsAfterEnd}
              loading={createLoading || updateLoading}
              text="Delete Recurring Series"
              variant="destructive"
              withConfirmation
              onClick={onDeleteRecurring}
            />
          </Box>
        )}
        {DateTime.now() > startDt && (
          <Box marginRight={4}>
            <Button
              confirmationText="This will end the recurring series as of now and delete all future recurring schedule items for this series. Are you sure?"
              disabled={createLoading || updateLoading || startIsAfterEnd}
              loading={createLoading || updateLoading}
              text="End Recurring Series"
              variant="destructive"
              withConfirmation
              onClick={onEndRecurring}
            />
          </Box>
        )}

        <Box width={140}>
          <Button iconName="arrow-rotate-left" text="Reset" variant="outline" onClick={onReset} />
        </Box>
      </Box>
    </Page>
  );
};
