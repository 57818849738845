import {AlertInstance, usePatchAlertInstancesByIdMutation} from "@store";
import {Banner, BannerProps} from "ferns-ui";
import React from "react";

interface AlertBannerProps {
  alertInstance?: AlertInstance;
  status?: BannerProps["status"];
}

export const AlertBanner = ({
  alertInstance,
  status = "warning",
}: AlertBannerProps): React.ReactElement | null => {
  const [updateAlert] = usePatchAlertInstancesByIdMutation();
  if (!alertInstance) {
    return null;
  }
  const text = alertInstance?.alertText ?? "";
  const buttonText = alertInstance?.resolved ? "Unresolve" : "✓ Resolve";
  const resolveAlert = async (): Promise<void> => {
    await updateAlert({id: alertInstance._id, body: {resolved: true}});
  };

  return (
    <Banner
      buttonOnClick={resolveAlert}
      buttonText={buttonText}
      id="patientAlert"
      status={status}
      text={text}
    />
  );
};
