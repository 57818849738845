import {IsNative} from "@utils";
import {Box, Heading, Modal, Text} from "ferns-ui";
import React, {useState} from "react";
import {browserName, isMobile, OsTypes} from "react-device-detect";

import {AppStoreLink} from "./AppStoreLink";
import {PlayStoreLink} from "./PlayStoreLink";

function shouldShowPatientBrowserAlert(): boolean {
  if (IsNative) {
    return false;
  }
  if (isMobile) {
    return true;
  }
  return browserName !== "Chrome";
}

export const PatientBrowserChecker = (): React.ReactElement => {
  const [visible, setVisible] = useState<boolean>(shouldShowPatientBrowserAlert());

  let alert;
  if (isMobile) {
    alert = (
      <Box>
        <Box marginBottom={4}>
          <Heading>Mobile Web Detected</Heading>
        </Box>
        <Box paddingY={4}>
          <Text>
            This app is only supported on native mobile. Unexpected issues may occur if you continue
            to use this mobile browser. Please download the mobile app with the following link:
          </Text>
          <br />
          {OsTypes.IOS ? <AppStoreLink /> : <PlayStoreLink />}
        </Box>
      </Box>
    );
  } else {
    alert = (
      <Box>
        <Box marginBottom={4}>
          <Heading>Unsupported Browser Detected</Heading>
        </Box>
        <Box paddingY={4}>
          <Text>
            This app is only supported on Desktop Chrome or native mobile. Unexpected issues may
            occur if you continue to use this desktop browser. Please switch to Chrome if you are on
            a computer or download the mobile app with the following links:
          </Text>
          <br />
          <AppStoreLink />
          <br />
          <PlayStoreLink />
        </Box>
      </Box>
    );
  }

  return (
    <Modal
      primaryButtonOnClick={(): void => setVisible(false)}
      primaryButtonText="Close"
      visible={visible}
      onDismiss={(): void => setVisible(false)}
    >
      {alert}
    </Modal>
  );
};
