import {useInvalidateTags} from "@hooks";
import {Alert, useGetAlertsQuery, usePatchAlertsByIdMutation} from "@store";
import {BooleanField, Box, Heading, Icon, Modal, Text} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface AlertEvent {
  value: string;
  label: string;
}

const AlertTriggeringEvents: {[key: string]: AlertEvent} = {
  ASSIGN_TODO: {value: "assignTodo", label: "A todo is Assigned with a User Alert"},
  ATTENDANCE_STATUS: {value: "attendanceStatus", label: "Attendance Status"},
  CREATED_FORM_INSTANCE: {value: "createdFormInstance", label: "Created Form Instance"},
  NOTE_HAS_RISK_LANGUAGE: {
    value: "noteHasRiskLanguage",
    label: "A Note is Signed and Contains Risk Language",
  },
  MISSING_COMPLETED_FORM: {value: "missingCompletedForm", label: "Missing Completed Form:"},
  MISSING_PATIENT_INFO: {value: "missingPatientInfo", label: "Missing Patient Info:"},
  REMINDER: {value: "reminder", label: "Reminder"},
};

export const AlertsControlPanel = (): ReactElement | null => {
  const {data: alerts} = useGetAlertsQuery({});
  const [updateAlert] = usePatchAlertsByIdMutation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null);
  const invalidateTags = useInvalidateTags();
  const sortedAlerts = alerts?.data?.length
    ? [...alerts.data].sort((a, b) => a.title.localeCompare(b.title))
    : [];

  function getLabelForEvent(triggeringEventKey: string | undefined): string {
    if (!triggeringEventKey) {
      return "";
    }
    const triggeringEvent = Object.values(AlertTriggeringEvents).find(
      (event) => event.value === triggeringEventKey
    );
    return triggeringEvent?.label ?? "";
  }

  function createEventDetailsText(
    triggeringEvent: Alert["triggeringEvent"],
    triggeringEventKey: keyof Alert["triggeringEvent"]
  ): string {
    const eventValue: any = triggeringEvent[triggeringEventKey];
    if (!eventValue) {
      return "";
    }
    switch (triggeringEventKey) {
      case AlertTriggeringEvents.MISSING_PATIENT_INFO.value:
        return eventValue.join(", ");
      case AlertTriggeringEvents.ATTENDANCE_STATUS.value:
        return eventValue.join(", ");
      default:
        return "";
    }
  }

  function createWhenText(
    alert: Alert,
    triggeringEventKey: keyof Alert["triggeringEvent"]
  ): string {
    const {chronology, increment, isImmediately, scheduleItemType, unit} = alert.occurs as any;
    const baseText = isImmediately
      ? `immediately ${chronology}`
      : `${increment} ${unit} ${chronology}`;

    if (triggeringEventKey === AlertTriggeringEvents.REMINDER.value) {
      return (
        `${baseText} ${alert.triggeringEvent?.reminder?.queryConstructor?.model} ` +
        `- ${alert.triggeringEvent?.reminder?.queryConstructor?.dateField}`
      );
    } else if (triggeringEventKey === AlertTriggeringEvents.CREATED_FORM_INSTANCE.value) {
      return `${baseText} form instance ${alert.triggeringEvent?.createdFormInstance?.formInternalKey} creation`;
    } else {
      return `${baseText}${scheduleItemType ? ` ${scheduleItemType}` : ""}`;
    }
  }

  const createDescriptionText = (alert: Alert): string => {
    const triggeringEventKey = Object.keys(
      alert?.triggeringEvent
    )[0] as keyof Alert["triggeringEvent"];
    const recipient = alert?.recipientStaffRole?.length
      ? alert.recipientStaffRole.join(", ")
      : alert.recipientUserType;
    const when = createWhenText(alert, triggeringEventKey);
    const triggeringEventLabel = getLabelForEvent(triggeringEventKey);
    const details = createEventDetailsText(alert.triggeringEvent, triggeringEventKey);

    return (
      `Creates alerts about ${alert.associatedUserType}, delivered to ${recipient}, ${when};` +
      ` ${triggeringEventLabel} ${details}`
    );
  };

  function dismissUpdateAlertModal(): void {
    setShowConfirmationModal(false);
    setSelectedAlert(null);
  }

  async function updateAlertStatus(): Promise<void> {
    if (!selectedAlert) {
      return;
    }
    await updateAlert({id: selectedAlert._id, body: {isActive: !selectedAlert.isActive}});
    invalidateTags(["alertinstances"]);
    dismissUpdateAlertModal();
  }

  const renderConfirmationModal = (): ReactElement | null => {
    if (!showConfirmationModal) {
      return null;
    }

    const buttonText = selectedAlert?.isActive ? "Deactivate" : "Activate";
    const headingText = selectedAlert?.isActive ? "Deactivate Alert" : "Activate Alert";
    const warningText = selectedAlert?.isActive
      ? "Are you sure you want to deactivate this alert?"
      : "Are you sure you want to activate this alert?";

    const warningSubText = selectedAlert?.isActive
      ? "This will immediately cease creation of future alerts."
      : "This will immediately create alerts for eligible recipient(s).";

    return (
      <Modal
        primaryButtonOnClick={updateAlertStatus}
        primaryButtonText={buttonText}
        secondaryButtonOnClick={dismissUpdateAlertModal}
        secondaryButtonText="Cancel"
        size="sm"
        subtitle={selectedAlert?.title}
        title={headingText}
        visible={showConfirmationModal}
        onDismiss={() => setShowConfirmationModal(false)}
      >
        <Box alignItems="center" display="flex" paddingX={1}>
          <Box alignItems="center" display="flex" marginBottom={2}>
            <Text bold color="warning" size="lg">
              {warningText}
            </Text>
          </Box>
          <Text>
            <Text bold>Warning:</Text>
            {warningSubText}
          </Text>
        </Box>
      </Modal>
    );
  };

  const AlertBooleanFieldComponent = ({alert}: {alert: Alert}): ReactElement => {
    const alertDescription = createDescriptionText(alert);

    return (
      <Box border="dark" color="base" margin={2} rounding="md" shadow>
        <Box direction="row" justifyContent="between" padding={1} width="100%" wrap>
          <Box width="80%">
            <Heading size="sm">{alert.title}</Heading>
            <Box marginTop={1}>
              <Text size="sm">{alertDescription}</Text>
            </Box>
            <Box marginTop={1}>
              <Text color="secondaryLight" size="sm">
                Text: {alert?.text}
              </Text>
            </Box>
          </Box>
          <Box alignItems="center" justifyContent="center" marginTop={2}>
            <BooleanField
              value={alert.isActive}
              onChange={() => {
                setSelectedAlert(alert);
                setShowConfirmationModal(true);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      <Heading size="lg">Alerts Control Panel</Heading>
      {renderConfirmationModal()}
      <Box paddingY={1}>
        <Text>
          <Text bold>
            <Icon color="warning" iconName="skull" size="md" /> Note:{" "}
          </Text>
          Activating an alert will immediately trigger alert creations based on the current settings
          and recipient criteria. Deactivating an alert will immediately cease creation of future
          alerts. Please use this feature cautiously to ensure necessary communication is maintained
          without interruption.
        </Text>
      </Box>
      {sortedAlerts?.length
        ? sortedAlerts.map((alert) => <AlertBooleanFieldComponent key={alert._id} alert={alert} />)
        : null}
    </Box>
  );
};
