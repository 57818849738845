import {AlertsExplorerController, useAlertsExplorerContext} from "@components";
import {Box, Table, TableHeader, TableHeaderCell, TableRow, Text} from "ferns-ui";
import React from "react";

import {
  alertCreatedDateColumn,
  alertTypeColumn,
  dueDateColumn,
  resolvedColumn,
  staffNameColumn,
  StaffTypeColumn,
  userNameColumn,
  userTypeColumn,
} from "../components/AlertsExplorerColumns";

const columns = [
  resolvedColumn,
  staffNameColumn,
  StaffTypeColumn,
  userNameColumn,
  userTypeColumn,
  alertTypeColumn,
  alertCreatedDateColumn,
  dueDateColumn,
];

export const AlertsExplorerTable = (): React.ReactElement => {
  const context = useAlertsExplorerContext()!;
  const {setSort, setPage, page, total, userAlertInstances} = context;

  const totalPages = Math.ceil((total ?? 0) / 50);

  return (
    <Table
      columns={columns.map((c) => c.width)}
      more={page * 50 < total}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
    >
      <TableHeader>
        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.title}
            index={index}
            sortable={Boolean(column.sort)}
            onSortChange={(direction): void => {
              if (!column.sort) {
                setSort(undefined);
              } else {
                setSort(direction ? [column.sort, direction] : undefined);
              }
            }}
          >
            <Box flex="grow" justifyContent="center" width="100%" wrap>
              <Text bold size="sm">
                {column.title}
              </Text>
            </Box>
          </TableHeaderCell>
        ))}
      </TableHeader>
      {userAlertInstances.map((alertInstance) => (
        <TableRow key={alertInstance._id}>
          {columns.map((column) => (
            <React.Fragment key={column.title}>{column.Component(alertInstance)}</React.Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

export const AlertsExplorerScreen = (): React.ReactElement => {
  return (
    <Box height="100%" paddingX={4} paddingY={4}>
      <AlertsExplorerController>
        <AlertsExplorerTable />
      </AlertsExplorerController>
    </Box>
  );
};
