import {useSelectCurrentUserId} from "@ferns-rtk";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Conversation,
  setWorkflowMappingId,
  useAppDispatch,
  useGetConversationsQuery,
  useGetUsersByIdQuery,
  useGetWorkflowMappingsQuery,
  usePatchWorkflowMappingsByIdMutation,
  User,
  useSelectWorkflowMappingId,
  useSelectWorkflowStaffId,
  useSentryAndToast,
  WorkflowMapping,
  WorkflowMappings,
} from "@store";
import {isPatientOrFamilyMember, UserTypes} from "@utils";
import {useToast} from "ferns-ui";
import {useEffect, useMemo, useState} from "react";

export function useArchiveWorkflowMapping(): () => void {
  const sentryAndToast = useSentryAndToast();
  const dispatch = useAppDispatch();
  const staffId = useSelectWorkflowStaffId();
  const workflowMappingId = useSelectWorkflowMappingId();
  const [updateWorkflowMapping] = usePatchWorkflowMappingsByIdMutation();
  const workflowMappingData = useGetWorkflowMappingsQuery(staffId ? {staffId} : skipToken);
  const toast = useToast();

  return async (): Promise<void> => {
    if (!staffId || !workflowMappingId) {
      sentryAndToast("Cannot archive workflow item, no workflow or workflow user selected");
      return;
    }

    // Grab the WorkflowMapping so we can log the userId that was archived and move to the next
    // WorkflowMapping after archiving.
    const workflowMappingIndex = workflowMappingData.data?.data?.findIndex(
      (wu) => wu._id === workflowMappingId
    );

    try {
      await updateWorkflowMapping({id: workflowMappingId, body: {archived: true}})
        .unwrap()
        .catch(toast.catch);
    } catch (error: any) {
      toast.catch(error, "Error archiving workflow");
      return;
    }

    // If there is a next WorkflowMapping in the list, select that WorkflowMapping,
    // otherwise select either the first WorkflowMapping or leave it as undefined if there are no
    // more WorkflowMappings.
    if (workflowMappingIndex && workflowMappingData.data?.data?.[workflowMappingIndex + 1]) {
      dispatch(setWorkflowMappingId(workflowMappingData.data.data[workflowMappingIndex + 1]._id));
    } else {
      const first = workflowMappingData.data?.data?.[0];
      if (first) {
        dispatch(setWorkflowMappingId(first._id));
      } else {
        dispatch(setWorkflowMappingId(undefined));
      }
    }
  };
}

export function useCurrentWorkflowOwner(): User | undefined {
  const workflowId = useSelectWorkflowStaffId();
  const userData = useGetUsersByIdQuery(workflowId ? workflowId : skipToken);
  return userData.data;
}

export function useCurrentWorkflowMapping(): WorkflowMapping | undefined {
  const workflowId = useSelectWorkflowStaffId();
  const workflowMappingId = useSelectWorkflowMappingId();

  // This could be a read, but the list should already be loaded by RTK.
  const workflowMappingData = useGetWorkflowMappingsQuery(
    workflowId ? {staffId: workflowId} : skipToken
  );
  return workflowMappingData.data?.data?.find((wu) => wu._id === workflowMappingId);
}

interface GetConversationForWorkflowMappingResponse {
  conversation: Conversation | undefined;
  isLoading: boolean;
  isFetching: boolean;
}

export function useGetConversationForWorkflowMapping(
  workflowMapping?: WorkflowMapping
): GetConversationForWorkflowMappingResponse {
  const sentryAndToast = useSentryAndToast();
  const [notifiedUser, setNotifiedUser] = useState(false);
  const query = {
    $and: [
      {"users.userId": workflowMapping?.staffId},
      {"users.userId": workflowMapping?.userId?._id},
    ],
    type: {$in: [UserTypes.Patient, UserTypes.FamilyMember]},
  } as any;
  const {
    data: conversationData,
    isLoading,
    isFetching,
  } = useGetConversationsQuery(
    workflowMapping?.userId && workflowMapping?.staffId ? query : skipToken
  );

  // If the workflowMapping changes, we should reset the notifiedUser state so that we can notify
  // the user again on errors.
  useEffect(() => {
    // if the workflow user id changes, we should reset the notifiedUser state
    if (workflowMapping?.userId) {
      setNotifiedUser(false);
    }
  }, [workflowMapping?.userId]);

  // use separate memo to avoid re-running unless the result of conversations changes
  return useMemo(() => {
    // If a staff adds themselves to their workflow,
    // don't show a conversation (it would be a random conversation the staff has with another
    // user).
    if (
      !workflowMapping?.staffId ||
      !workflowMapping.userId ||
      workflowMapping?.staffId === workflowMapping.userId?._id
    ) {
      return {conversation: undefined, isLoading, isFetching};
    }

    const conversations = conversationData?.data?.filter(
      (c) =>
        isPatientOrFamilyMember(c.type as UserTypes) &&
        c.users &&
        c.users.find((u) => u.userId?._id === workflowMapping.userId?._id) &&
        c.users.find((u) => u.userId?._id === workflowMapping.staffId)
    );

    if (conversations && conversations.length > 1 && !notifiedUser) {
      sentryAndToast(
        `There are more than one conversations for this user and your message may not be seen. User: ${workflowMapping?.staffId}`,
        undefined,
        `${conversations.length} conversations found for workflow mapping ${workflowMapping?._id}, ` +
          `staffId ${workflowMapping?.staffId}, userId ${workflowMapping.userId?._id}}`
      );
      // we only want to notify the user once
      setNotifiedUser(true);
    }
    return {conversation: conversations?.[0], isLoading, isFetching};
  }, [
    conversationData?.data,
    isFetching,
    isLoading,
    notifiedUser,
    sentryAndToast,
    workflowMapping?._id,
    workflowMapping?.staffId,
    workflowMapping?.userId,
  ]);
}

// It returns the user's in the current (profile) users workflow
export function useReadProfileUsersWorkFlow(): WorkflowMappings | undefined {
  const currentUserId = useSelectCurrentUserId();
  const {data: profileUsersWorkflow} = useGetWorkflowMappingsQuery(
    currentUserId ? {staffId: currentUserId} : skipToken
  );
  if (!currentUserId || !profileUsersWorkflow) {
    return undefined;
  }
  return profileUsersWorkflow;
}
