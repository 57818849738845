import {DateTimeField, Field, TextField} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useEffect, useState} from "react";

type fieldTypes = "date" | "number" | "phoneNumber" | "email";

type BaseInputProps = {
  answerRequiredErr?: string;
  disabled: boolean;
  value: string[];
  onChange: (value: string[]) => void | Promise<void>;
  onBlur: (value: string[]) => Promise<void>;
  onFocus?: () => void;
};

type InputProps = BaseInputProps & {
  type: fieldTypes;
};

const Input = ({
  answerRequiredErr,
  disabled,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
}: InputProps): ReactElement => {
  return (
    <Field
      disabled={disabled}
      errorText={answerRequiredErr}
      type={type}
      value={value[0] ?? ""}
      onBlur={async (result: any): Promise<void> => {
        await onBlur([result]);
      }}
      onChange={async (result: any): Promise<void> => {
        await onChange([result]);
      }}
      onFocus={() => onFocus?.()}
    />
  );
};

export const DateInput = ({
  answerRequiredErr,
  disabled = false,
  value = [""],
  onChange,
  onBlur,
}: BaseInputProps): ReactElement => {
  const [date, setDate] = useState<string>("");
  // Some Dates were save as 'M/d/yyyy' format during onBlur,
  // we need to convert them to 'YYYY-MM-DD' format
  const convertAmericanDate = (dateStr: string): string => {
    const americanaDate = DateTime.fromFormat(dateStr, "M/d/yyyy");
    return americanaDate.toISODate() ?? ""; // 'YYYY-MM-DD' format
  };

  // convert strings save as 'M/d/yyyy' to 'YYYY-MM-DD'
  useEffect(() => {
    if (value[0] && value[0] !== "" && value[0].includes("/")) {
      const convertedDate = convertAmericanDate(value[0]);
      setDate(convertedDate);
    } else {
      setDate(value[0]);
    }
  }, [value]);

  // Check if date is valid with luxon, if not, we show it as text.
  if (date && !DateTime.fromISO(date).isValid) {
    return (
      <TextField
        disabled
        errorText="Invalid datetime string, cannot be changed. Contact the dev team if you need help changing this."
        value={value[0] ?? ""}
        onChange={() => {}}
      />
    );
  }

  if (disabled) {
    return <TextField disabled value={date} onChange={() => {}} />;
  }

  return (
    <DateTimeField
      errorText={answerRequiredErr}
      type="date"
      value={date}
      onChange={async (result: any): Promise<void> => {
        await onChange([result]);
        await onBlur([result]);
      }}
    />
  );
};

export const NumberInput = ({
  answerRequiredErr,
  disabled = false,
  value = [""],
  onChange,
  onBlur,
  onFocus,
}: BaseInputProps): ReactElement => {
  return (
    <Input
      answerRequiredErr={answerRequiredErr}
      disabled={disabled}
      type="number"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export const PhoneNumberInput = ({
  answerRequiredErr,
  disabled = false,
  value = [""],
  onChange,
  onBlur,
  onFocus,
}: BaseInputProps): ReactElement => {
  return (
    <Input
      answerRequiredErr={answerRequiredErr}
      disabled={disabled}
      type="phoneNumber"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

export const EmailInput = ({
  answerRequiredErr,
  disabled = false,
  value = [""],
  onChange,
  onBlur,
  onFocus,
}: BaseInputProps): ReactElement => {
  return (
    <Input
      answerRequiredErr={answerRequiredErr}
      disabled={disabled}
      type="email"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};
