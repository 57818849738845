import {FormQuestion, SingleCheckboxOptions} from "@store";
import {Box, CheckBox} from "ferns-ui";
import React, {ReactElement} from "react";

import {QuestionPromptText} from "./QuestionPromptText";

export const QuestionCheckBox = ({
  disabled,
  index,
  question,
  value = [SingleCheckboxOptions.unchecked],
  onChange,
  onBlur,
}: {
  disabled?: boolean;
  index: number;
  question: FormQuestion;
  value: string[];
  onChange: (value: any) => void | Promise<void>;
  onBlur: (value: any) => Promise<void>;
}): ReactElement | null => {
  return (
    <Box alignItems="center" direction="row">
      <Box marginBottom={1}>
        <QuestionPromptText index={index} prompt={question.prompt} />
      </Box>
      <Box
        accessibilityHint="Check survey question"
        accessibilityLabel="Checkbox"
        marginLeft={2}
        onClick={async (): Promise<void> => {
          if (disabled) {
            return;
          }
          let newValue;
          if (value.includes(SingleCheckboxOptions.checked)) {
            newValue = [SingleCheckboxOptions.unchecked];
          } else {
            newValue = [SingleCheckboxOptions.checked];
          }
          await onChange(newValue);
          await onBlur(newValue);
        }}
      >
        <CheckBox selected={value.includes(SingleCheckboxOptions.checked)} size="md" />
      </Box>
    </Box>
  );
};
