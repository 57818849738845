import {useReadProfile} from "@hooks";
import {UserSession} from "@store";
import {Box, CheckBox, Heading, Text, useToast} from "ferns-ui";
import React from "react";

import {useGetUserSessionsQuery, usePatchUserSessionsByIdMutation} from "../store";

type NotificationSettingToggleProps = {
  label: string;
  hint: string;
  isSelected: boolean;
  onToggle: () => Promise<void>;
};

const NotificationSettingToggle: React.FC<NotificationSettingToggleProps> = ({
  label,
  hint,
  isSelected,
  onToggle,
}) => {
  return (
    <Box alignItems="center" direction="row">
      <Box
        accessibilityHint={hint}
        accessibilityLabel="Checkbox"
        justifyContent="center"
        onClick={onToggle}
      >
        <CheckBox selected={isSelected} />
      </Box>
      <Text> {label}</Text>
    </Box>
  );
};

export const NotificationSettings: React.FC<{}> = () => {
  const profile = useReadProfile();
  const toast = useToast();
  const [updateUserSessions] = usePatchUserSessionsByIdMutation();

  const {data: userSessionsData} = useGetUserSessionsQuery({page: 1});
  const userSession = userSessionsData?.data?.find((us) => us.ownerId === profile?._id);

  const notificationSettings = userSession?.notificationSettings;

  const handleToggle = async (key: keyof UserSession["notificationSettings"]): Promise<void> => {
    if (!userSession || !notificationSettings) return;

    await updateUserSessions({
      id: userSession._id,
      body: {
        notificationSettings: {
          ...notificationSettings,
          [key]: !notificationSettings[key],
        },
      },
    })
      .unwrap()
      .then(() => {
        toast.success("Notification settings updated");
      })
      .catch((e: any) => {
        toast.catch(e, "Failed to update notification settings");
      });
  };

  if (!userSession || !notificationSettings) {
    return null;
  }
  return (
    <Box>
      <Heading size="sm">Notify me about:</Heading>
      <NotificationSettingToggle
        hint="toggle internal chat message notifications on or off"
        isSelected={Boolean(notificationSettings.notifyInternalChatMessages)}
        label="Internal chat message"
        onToggle={() => handleToggle("notifyInternalChatMessages")}
      />
      <NotificationSettingToggle
        hint="toggle internal chat mention notifications on or off"
        isSelected={Boolean(notificationSettings.notifyInternalChatMentions)}
        label="Internal chat @mention"
        onToggle={() => handleToggle("notifyInternalChatMentions")}
      />
      <NotificationSettingToggle
        hint="toggle user update notifications on or off"
        isSelected={Boolean(notificationSettings.notifyUserUpdates)}
        label="User update"
        onToggle={() => handleToggle("notifyUserUpdates")}
      />
      <NotificationSettingToggle
        hint="toggle user alert notifications on or off"
        isSelected={Boolean(notificationSettings.notifyUserAlerts)}
        label="User alert"
        onToggle={() => handleToggle("notifyUserAlerts")}
      />
    </Box>
  );
};
