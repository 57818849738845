import {FormInstance, FormInstanceWithForm, FormQuestion} from "./modelTypes";

// // IMPORTANT! This file is copied and pasted to the backend
// If you update this file, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts

// This constant is also used in the backend if updating, UPDATE BOTH
export const OPEN_NOTE_TYPES_ENGAGEMENT = [
  "Guide Phone Call",
  "Guide Video Session",
  "Guide In Person Visit",
];

// If you change FormValidator, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
export const FormValidator = {
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAttendanceDetails: (formInstance: FormInstance): string | undefined => {
    if (
      formInstance.attendanceStatus === "Attended" &&
      (!formInstance.attended || !formInstance.attended.length)
    ) {
      return `Status is marked as attended but no attended users are provided. Please provide at least one attended user.`;
    }
    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateServiceDateAndAttendance: (formInstance: FormInstance): string | undefined => {
    if (
      !formInstance.serviceDate ||
      !formInstance.attendanceStatus ||
      formInstance.attendanceStatus === "Unknown"
    ) {
      const missingParts = [];
      if (!formInstance.serviceDate) {
        missingParts.push("service date");
      }
      if (!formInstance.attendanceStatus) {
        missingParts.push("attendance status");
      }
      return `Missing ${missingParts.join(" and ")}`;
    }
    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAnswerRequirements: (
    formQuestion: FormQuestion,
    answers: string[],
    isSupervisor = false
  ): string | undefined => {
    // ignore isRequired if the question is supervisor only and the user is not a supervisor
    // to allow user to complete the form before signed by supervisor
    if (formQuestion.isSupervisorOnly && !isSupervisor) {
      return undefined;
    }
    if (formQuestion.isRequired && !answers?.[0]) {
      return "This is required to submit.";
    }

    for (const answer of answers) {
      if (answer.includes("***")) {
        return "Please fill in all *** fields.";
      }
    }

    return undefined;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateAnswers: (formInstance: FormInstanceWithForm, isSupervisor = false): string[] | null => {
    const errors = new Set<string>();
    for (const question of formInstance.form.questions) {
      const answer =
        formInstance.answers.find((a) => a.questionId.toString() === question?._id?.toString())
          ?.answers ?? [];
      const error = FormValidator.validateAnswerRequirements(question, answer, isSupervisor);
      if (error) {
        errors.add("Missing required fields.");
      }
    }
    const errorArray = Array.from(errors);
    return errorArray.length ? errorArray : null;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateServiceDate: (formInstance: FormInstance): string | null => {
    if (formInstance.serviceDate && new Date(formInstance.serviceDate as string) > new Date()) {
      return "Service date cannot be in the future.";
    }
    return null;
  },
  // If you change this, ALSO UPDATE THE BACKEND /backend/src/utils/formValidator.ts
  validateFormInstance: (
    formInstance: FormInstanceWithForm,
    isSupervisor = false
  ): string[] | null => {
    const errors: string[] = [];
    // flatAnswers and includesEngagement are used for Open Notes form validation
    const flatAnswers = formInstance.answers.map((a) => a.answers).flat();
    const includesEngagement = flatAnswers.some((a) => OPEN_NOTE_TYPES_ENGAGEMENT.includes(a));
    if (
      formInstance.scheduleItemId ||
      // Open Notes is an engagement type form and requires service date and attendance status
      (formInstance.form.name === "Open Notes" && includesEngagement)
    ) {
      const attendanceError = FormValidator.validateServiceDateAndAttendance(formInstance);
      if (attendanceError) {
        errors.push(attendanceError);
      }
    }

    const attendanceDetailsErr = FormValidator.validateAttendanceDetails(formInstance);
    if (attendanceDetailsErr) {
      errors.push(attendanceDetailsErr);
    }

    const answersErrs = FormValidator.validateAnswers(formInstance, isSupervisor);
    if (answersErrs) {
      errors.push(...answersErrs);
    }

    return errors.length ? errors : null;
  },
  // if you add more functions to this, ALSO ADD to THE BACKEND /backend/src/utils/formValidator.ts
};
