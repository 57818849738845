// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export interface ServiceWorkerConfig {
  onUpdateSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onRegistered?: (registration: ServiceWorkerRegistration) => void;
}

let registered = false;

export async function register(config: ServiceWorkerConfig): Promise<void> {
  if (registered) {
    return;
  }

  const isEnvProduction = process.env.NODE_ENV === "production";
  if (!isEnvProduction) {
    console.debug(
      "Not in production, skipping service worker registration. Use `yarn web-prd` to test service workers locally."
    );
  } else if (!("serviceWorker" in navigator)) {
    console.debug("Service worker not supported");
    return;
  }
  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL!, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    console.warn(
      "Public URL origin is different from window location origin",
      publicUrl.origin,
      window.location.origin
    );
    return;
  }

  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  if (isLocalhost) {
    // This is running on localhost. Let's check if a service worker still exists or not.
    if (!(await checkValidServiceWorker(swUrl, config))) {
      return;
    }
    await registerValidSW(swUrl, config);

    // Add some additional logging to localhost, pointing developers to the
    // service worker/PWA documentation.
    await navigator.serviceWorker.ready;
    registered = true;
    console.warn(
      "This web app is being served cache-first by a service " +
        "worker. To learn more, visit https://cra.link/PWA"
    );
  } else {
    // Is not localhost. Just register service worker
    await registerValidSW(swUrl, config);
    registered = true;
  }
}

async function registerValidSW(swUrl: string, config: ServiceWorkerConfig): Promise<void> {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);
    console.debug("Service worker registered");
    config.onRegistered?.(registration);
    registration.onupdatefound = (): void => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = (): void => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller) {
            // Execute callback
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            // Execute callback
            if (config && config.onUpdateSuccess) {
              config.onUpdateSuccess(registration);
            }
          }
        }
      };
    };
  } catch (error) {
    console.error("Error during service worker registration:", error);
  }
}

function checkValidServiceWorker(
  swUrl: string,
  _config: ServiceWorkerConfig
): Promise<boolean | undefined> {
  // Check if the service worker can be found. If it can't reload the page.
  return fetch(swUrl, {
    headers: {"Service-Worker": "script"},
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready
          .then((registration) => {
            registration
              .unregister()
              .then(() => {
                console.error("Service worker not found. Reload the page.");
                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error.message);
          });
      } else {
        // Service worker found. Proceed as normal.
        return true;
      }
    })
    .catch(() => {
      console.warn("No internet connection found. App is running in offline mode.");
      return false;
    });
}

export function unregister(): void {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  navigator.serviceWorker.ready
    .then(async (registration) => {
      await registration.unregister();
    })
    .catch((error) => {
      console.error(error.message);
    });
}
