import {Platform} from "react-native";

export const MIN_COMPOSER_HEIGHT = Platform.select({
  ios: 41,
  android: 41,
  web: 33,
});
export const MAX_COMPOSER_HEIGHT = 200;
export const DEFAULT_PLACEHOLDER = "Type a message...";
export const DATE_FORMAT = "ll";
export const TIME_FORMAT = "LT";

export const TEST_ID = {
  WRAPPER: "GC_WRAPPER",
  LOADING_WRAPPER: "GC_LOADING_CONTAINER",
  SEND_TOUCHABLE: "GC_SEND_TOUCHABLE",
};
