import {PatientProfile, StaffProfile} from "@components";
import {useReadProfile} from "@hooks";
import {StaffTabScreenProps} from "@types";
import {isStaff} from "@utils";
import React, {ReactElement} from "react";

interface ProfileProps extends StaffTabScreenProps<"Profile"> {}

export const ProfilePage = ({}: ProfileProps): ReactElement | null => {
  const user = useReadProfile();

  if (!user) {
    return null;
  }

  return isStaff(user.type) ? <StaffProfile /> : <PatientProfile />;
};
