import {PatientStackScreenProps} from "@types";
import {Box, Button, Heading} from "ferns-ui";
import React, {ReactElement} from "react";

export const NotFoundScreen = ({navigation}: PatientStackScreenProps<"NotFound">): ReactElement => {
  return (
    <Box alignItems="center" height="100%" justifyContent="center" width="100%">
      <Box paddingY={4}>
        <Heading>This screen doesn&apos;t exist.</Heading>
      </Box>
      <Button text="Go to home screen!" onClick={(): void => navigation.replace("NotFound")} />
    </Box>
  );
};
