import {InsurancePlan, ScheduleItem, User} from "@store";
import {printDate} from "ferns-ui";

import {ListResponse, populateId} from "../ferns-rtk/mongooseSlice";

export type IntakeScheduleType =
  | "Clinical Intake"
  | "Eligibility Interview"
  | "Psychiatry Intake"
  | "Therapy Intake"
  | "In Home Onboarding Visit"
  | "Meet and Greet";

export const isBillingInfoFinished = (user: User): boolean => {
  return Boolean(user.billingInfo.insurancePlan) && Boolean(user.billingInfo.memberId);
};

export const isBillable = (user: User, insurancePlans?: ListResponse<InsurancePlan>): boolean => {
  return populateId(user.billingInfo?.insurancePlan, insurancePlans)?.billable ?? false;
};

export const isAcceptingReferrals = (
  user: User,
  insurancePlans?: ListResponse<InsurancePlan>
): boolean => {
  return populateId(user.billingInfo?.insurancePlan, insurancePlans)?.acceptingReferrals ?? false;
};

export const hasPreferredPharmacy = (user: User): boolean => {
  return Boolean(user.preferredPharmacies.length > 0);
};

export const getScheduledItemFor = (
  user: User,
  type: IntakeScheduleType,
  scheduledItems?: ScheduleItem[]
): ScheduleItem | undefined => {
  const items = scheduledItems?.filter(
    (item) => item.type === type && item.users?.find((u) => u.userId?._id === user._id)
  );
  if (!items || items.length === 0) {
    return undefined;
  }
  // Return the most recent one.
  return items.sort((a, b) => {
    if (!a.startDatetime || !b.startDatetime) {
      return 0;
    }
    return new Date(b.startDatetime).getTime() - new Date(a.startDatetime).getTime();
  })[0];
};

export const hasScheduledItemFor = (
  user: User,
  type: IntakeScheduleType,
  scheduledItems?: ScheduleItem[]
): string => {
  const item = getScheduledItemFor(user, type, scheduledItems);
  if (item) {
    return printDate(item.startDatetime);
  } else {
    return "";
  }
};

export const primaryCareDoctorContact = (user: User): string => {
  return user.contacts?.filter((c) => c.types.includes("Primary Care Doctor"))?.[0]?.name ?? "";
};

export const hasPrimaryCareDoctorContact = (user: User): boolean => {
  return Boolean(primaryCareDoctorContact(user));
};

export const isPersonalPhoneNumberRequired = (user: User): boolean => {
  return Boolean(
    user.staffRoles.PatientGuide ||
      user.staffRoles.FamilyGuide ||
      user.staffRoles.Therapist ||
      user.staffRoles.Psychiatrist ||
      user.staffRoles.FamilyGuideSupervisor ||
      user.staffRoles.PatientGuideSupervisor ||
      user.staffRoles.TherapistSupervisor ||
      user.staffRoles.ClinicalLeader ||
      user.staffRoles.ClinicalDirector ||
      user.staffRoles.RiskManager
  );
};

export const isStaffPhoneNumberRequired = (user: User): boolean => {
  return Boolean(
    user.staffRoles.PatientGuide ||
      user.staffRoles.FamilyGuide ||
      user.staffRoles.Therapist ||
      user.staffRoles.Psychiatrist
  );
};

export const isVideoPlatformLinkRequired = (user: User): boolean => {
  return Boolean(
    user.staffRoles.Therapist || user.staffRoles.Psychiatrist || user.staffRoles.PatientGuide
  );
};

export const getMissingProfileFields = (user: User): string[] => {
  const missingFields = [];
  if (isPersonalPhoneNumberRequired(user)) {
    if (!user.phoneNumber) {
      missingFields.push("Personal Phone Number");
    }
  }
  if (isStaffPhoneNumberRequired(user)) {
    if (!user.staffPhoneNumber) {
      missingFields.push("Staff Phone Number");
    }
  }
  if (isVideoPlatformLinkRequired(user)) {
    if (!user.videoChatLink) {
      missingFields.push("Video Platform Link");
    }
  }
  return missingFields;
};
