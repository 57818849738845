import {useAnalytics, useReadProfile} from "@hooks";
import {Form, useGetFormInstancesByIdQuery, usePatchFormInstancesByIdMutation} from "@store";
import {Box, Button, DateTimeField, SignatureField, Text, TextField} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useState} from "react";

interface FormSignatureProps {
  formId: string;
  setShouldScroll?: (shouldScroll: boolean) => void;
}
export const FormSignature = ({
  formId,
  setShouldScroll,
}: FormSignatureProps): ReactElement | null => {
  const profile = useReadProfile();
  const logEvent = useAnalytics();
  const {data} = useGetFormInstancesByIdQuery(formId);
  const [updateFormInstance, {isLoading}] = usePatchFormInstancesByIdMutation();
  const form = data?.form as Form | undefined;
  const [signature, setSignature] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const now = DateTime.now().toISO();
  const userSignatures = data?.userSignatures || [];

  if (!form || !form.requireUserSignature) {
    return null;
  }

  const onSign = async (): Promise<void> => {
    if (signature && name) {
      const updatedVal = {
        signedByUserId: profile?._id,
        signedDate: now,
        typedName: name,
        image: signature,
      };
      await updateFormInstance({
        id: formId,
        body: {
          userSignatures: [...userSignatures, updatedVal],
          status: "Completed",
        },
      });
      void logEvent({
        name: "FormInstanceCompletedByUser",
        userType: profile?.type,
        collectionModel: "formInstances",
        isUserUpdateEvent: true,
        appliedUserId: data?.userId?._id,
        docId: formId,
      });
    }
  };

  return (
    <Box gap={4}>
      <Text bold size="lg">
        To complete this form, please sign and type your name below.
      </Text>
      <SignatureField
        disabled={isLoading}
        title="Signature"
        onChange={setSignature}
        onEnd={() => {
          if (setShouldScroll) {
            setShouldScroll(true);
          }
        }}
        onStart={() => {
          if (setShouldScroll) {
            setShouldScroll(false);
          }
        }}
      />
      <Box direction="column">
        <Box marginRight={2}>
          <TextField
            disabled={isLoading}
            helperText="Type your name here to complete signature"
            title="Type Name"
            type="text"
            value={name}
            onChange={setName}
          />
        </Box>
        <DateTimeField disabled title="Date" type="date" value={now} onChange={() => {}} />
      </Box>
      <Button
        disabled={isLoading || !signature || !name}
        text="Sign & Complete"
        variant="primary"
        onClick={onSign}
      />
    </Box>
  );
};
