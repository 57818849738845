import {AppUpdateBanner, OfflineBanner, SessionFeedbackModal} from "@components";
import {useAnalytics, useReadProfile} from "@hooks";
import {
  getStaffRole,
  useGetAvatarsQuery,
  useGetConversationsSummaryQuery,
  useGetUsersQuery,
} from "@store";
import {PatientStackScreenProps} from "@types";
import {UserTypes} from "@utils";
import {Avatar, Box, humanDate, Spinner, SurfaceColor, Text} from "ferns-ui";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import React, {ReactElement} from "react";
import {FlatList} from "react-native";

interface Props extends PatientStackScreenProps<"Conversations"> {}

export const ConversationScreen = ({navigation}: Props): ReactElement => {
  const logEvent = useAnalytics();
  const user = useReadProfile();

  const {data: conversationSummaries, isLoading} = useGetConversationsSummaryQuery();
  const conversationUserIds = uniq(
    flatten(conversationSummaries?.map((c) => c.users?.map((u) => u.userId)))
  ).filter((u) => u) as string[];
  const otherUserIds = conversationUserIds.filter((u) => u !== user?._id);
  const {data: otherUsersData} = useGetUsersQuery({_id: {$in: otherUserIds}});
  const {data: avatarListResponse} = useGetAvatarsQuery({ownerId: {$in: conversationUserIds}});
  const avatarList = avatarListResponse?.data ?? [];

  const getConversationName = (conversationId: string): string => {
    const conversation = conversationSummaries?.find((c) => c._id === conversationId);
    if (!conversation) {
      return "Unknown Conversation";
    }
    const staffUserId = conversation.users.find((u) => u.userId !== user?._id)?.userId;
    const staffUser = otherUsersData?.data?.find((u) => u._id === staffUserId);

    if (staffUser) {
      const role = getStaffRole(staffUser);
      if (role) {
        return `${staffUser.name} - ${role}`;
      } else {
        return staffUser.name ?? "";
      }
    } else {
      return "Unknown User";
    }
  };

  if (isLoading || !user) {
    return <Spinner />;
  }

  return (
    <>
      <SessionFeedbackModal />
      <AppUpdateBanner />
      <FlatList
        data={conversationSummaries}
        keyExtractor={(item): string => item._id}
        renderItem={({item: conversation, index}): ReactElement | null => {
          const staffUserId = conversation.users.find((u) => u.userId !== user?._id)?.userId;
          const staffUser = otherUsersData?.data?.find((u) => u._id === staffUserId);
          const staffAvatar = avatarList.find((a) => staffUser?._id === a.ownerId);
          const onlineForPatients = staffUser?.online?.forPatients;
          const onlineForFamilyMembers = staffUser?.online?.forFamilyMembers;

          const getStatusColor = (
            type: string,
            isOnlineForPatients?: boolean,
            isOnlineForFamilyMembers?: boolean
          ): SurfaceColor => {
            if (type === UserTypes.Patient) {
              return isOnlineForPatients ? "success" : "neutral";
            }
            if (type === UserTypes.FamilyMember) {
              return isOnlineForFamilyMembers ? "success" : "neutral";
            }
            return "neutral";
          };

          const onlineStatusDisplayColor = getStatusColor(
            conversation.type,
            onlineForPatients,
            onlineForFamilyMembers
          );

          return (
            <Box
              key={conversation._id}
              accessibilityHint="Go to conversation"
              accessibilityLabel="Navigate"
              borderBottom="default"
              color="base"
              padding={2}
              testID={`conversation-${index}`}
              width="100%"
              onClick={async (): Promise<void> => {
                navigation.navigate("Chat", {conversationId: conversation._id});
                await logEvent({
                  name: "GoToConversation",
                  docId: conversation._id,
                });
              }}
            >
              <Box direction="row" width="100%">
                <Box alignItems="center" justifyContent="center" marginRight={2} width={60}>
                  <Avatar
                    hasBorder={Boolean(conversation.unreadCount)}
                    name={staffUser?.name ?? "Flourish Health"}
                    src={staffAvatar?.imageMediaLink}
                  />
                </Box>
                <Box direction="column" flex="grow" justifyContent="center" paddingY={1}>
                  <Box alignItems="center" direction="row" flex="grow">
                    <Text
                      bold={Boolean(conversation.unreadCount)}
                      numberOfLines={1}
                      size="lg"
                      truncate
                    >
                      {getConversationName(conversation._id)}
                    </Text>
                    <Box
                      color={onlineStatusDisplayColor}
                      height={12}
                      marginLeft={2}
                      rounding="circle"
                      width={12}
                    />
                  </Box>
                  <Box marginBottom={1}>
                    <Text bold={Boolean(conversation.unreadCount)} numberOfLines={1} truncate>
                      {conversation.mostRecentMessage?.text ?? "No messages yet"}
                    </Text>
                  </Box>
                  {Boolean(conversation.lastMessageSentDate) && (
                    <Box marginBottom={1}>
                      <Text
                        bold={Boolean(conversation.unreadCount)}
                        color="secondaryLight"
                        numberOfLines={1}
                        size="sm"
                        truncate
                      >
                        {Boolean(conversation.unreadCount)
                          ? `${conversation.unreadCount} unread - `
                          : ""}
                        {humanDate(conversation.lastMessageSentDate)}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      />
      <Box
        alignItems="center"
        color="neutralLight"
        justifyContent="center"
        paddingX={2}
        paddingY={2}
      >
        <OfflineBanner />
      </Box>
    </>
  );
};
