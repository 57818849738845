import {clinicalUpdateFieldOptions, FormQuestion} from "@store";
import {SelectField, SelectFieldProps} from "ferns-ui";
import React, {ReactElement} from "react";

export const SingleSelect = ({
  answerRequiredErr,
  label,
  disabled,
  question,
  value = [""],
  onChange,
  onBlur,
}: {
  answerRequiredErr: string | undefined;
  label?: string;
  disabled?: boolean;
  question: FormQuestion;
  value: string[];
  onChange: (value: any) => void | Promise<void>;
  onBlur?: (value: any) => Promise<void>;
}): ReactElement | null => {
  const options =
    question.type === "ClinicalStatus"
      ? (clinicalUpdateFieldOptions.clinicalStatus as SelectFieldProps["options"])
      : question.options?.map((o) => ({label: o.label, value: o.label}));

  return (
    <SelectField
      disabled={disabled}
      errorText={answerRequiredErr ?? undefined}
      options={[...(options || [])]}
      placeholder="Select an option"
      requireValue={false}
      title={label}
      value={value[0]}
      onChange={async (result: string | undefined): Promise<void> => {
        await onChange([result]);
        // We do the actual saving in onBlur, but the SelectField does not have an onBlur event.
        await onBlur?.([result]);
      }}
    />
  );
};
