import {useReadProfile} from "@hooks";
import {Box, Modal, Text, TextArea, useToast} from "ferns-ui";
import React, {useState} from "react";

import {usePatchUsersByIdMutation} from "../store";

interface GPTMemoryModalProps {
  visible: boolean;
  onDismiss: () => void;
}

export const GPTMemoryModal: React.FC<GPTMemoryModalProps> = ({visible, onDismiss}) => {
  const user = useReadProfile();
  const [updateUser] = usePatchUsersByIdMutation();
  const toast = useToast();
  const [memory, setMemory] = useState<string>(user?.gptMemory ?? "");

  if (!user) {
    return null;
  }

  return (
    <Modal
      primaryButtonOnClick={async () => {
        try {
          await updateUser({id: user._id, body: {gptMemory: memory}}).unwrap();
        } catch (error) {
          toast.catch(error);
          return;
        }
        toast.show("Updated GPT Memory");
        onDismiss();
      }}
      primaryButtonText="Save"
      secondaryButtonOnClick={() => {
        onDismiss();
      }}
      secondaryButtonText="Close"
      visible={visible}
      onDismiss={onDismiss}
    >
      <Box gap={4}>
        <Text>
          Memory is added to the start of every new conversation. This is a good spot to put things
          that you need to add to most queries, such as &quot;be concise&quot;, &quot;our company is
          called Flourish Health&quot;, etc. We have added a default value that you may change.
        </Text>
        <TextArea grow title="GPT Memory" value={memory} onChange={setMemory} />
      </Box>
    </Modal>
  );
};
