// Notification settings for end users. Staff configure notification settings via the
// gear icon on the StaffHomeScreen.
import {useReadProfile} from "@hooks";
import {usePatchUsersByIdMutation} from "@store";
import {PatientStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Card, Page, Text, useToast} from "ferns-ui";
import React from "react";

import {CheckboxAgreement} from "./ConsentFormScreen";

interface NotificationSettingsProps extends PatientStackScreenProps<"NotificationsSettings"> {}

export const NotificationsSettingsScreen = ({
  navigation,
}: NotificationSettingsProps): React.ReactElement | null => {
  const user = useReadProfile();
  const toast = useToast();
  const [updateUser] = usePatchUsersByIdMutation();
  const [pushNotifications, setPushNotifications] = React.useState(
    user?.pushNotifications ?? false
  );
  const [smsNotifications, setSmsNotifications] = React.useState(user?.smsNotifications ?? false);
  const [smsMessaging, setSmsMessaging] = React.useState(user?.smsMessaging ?? false);

  if (!user) {
    return null;
  }

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Card marginBottom={3} marginTop={3} width="100%">
        <Box paddingY={2}>
          <Text bold size="lg">
            Notifications
          </Text>
        </Box>
        <Box paddingY={2} width="100%" />
        <Box paddingY={2} width="100%">
          <CheckboxAgreement
            explanation="Flourish Health uses push notifications to alert you to chat messages, reminders, and other important information."
            title="Push Notifications"
            value={pushNotifications}
            onChange={async (value): Promise<void> => {
              setPushNotifications(value);
              await updateUser({id: user._id, body: {pushNotifications: value}})
                .unwrap()
                .catch(toast.catch);
              if (value) {
                toast.show("You will now receive push notifications.");
              } else {
                toast.show("You will no longer receive push notifications.");
              }
            }}
          />
          <CheckboxAgreement
            explanation="Allow Flourish Health to text you notifications about appointments and other important information."
            title="SMS Notifications"
            value={smsNotifications}
            onChange={async (value): Promise<void> => {
              setSmsNotifications(value);
              await updateUser({id: user._id, body: {smsNotifications: value}})
                .unwrap()
                .catch(toast.catch);
              if (value) {
                toast.show("You will now receive SMS notifications.");
              } else {
                toast.show("You will no longer receive SMS notifications.");
              }
            }}
          />
          <CheckboxAgreement
            explanation="Allow Flourish Health to text you to communicate with you about your care team. Note: SMS is not secure and others may be able to see your messages with your care team."
            title="SMS Messaging"
            value={smsMessaging}
            onChange={async (value): Promise<void> => {
              setSmsMessaging(value);
              await updateUser({id: user._id, body: {smsMessaging: value}})
                .unwrap()
                .catch(toast.catch);
              if (value) {
                toast.show("You will now receive SMS messages from your care team.");
              } else {
                toast.show("You will no longer receive SMS messages from your care team.");
              }
            }}
          />
        </Box>
      </Card>
    </Page>
  );
};
