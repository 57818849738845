import {
  Address,
  PreferredPharmacy,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  useSentryAndToast,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {
  AddressField,
  AddressInterface,
  BooleanField,
  Box,
  Button,
  Page,
  PhoneNumberField,
  Spinner,
  TextArea,
  TextField,
} from "ferns-ui";
import cloneDeep from "lodash/cloneDeep";
import React, {useEffect, useState} from "react";

interface Props extends StaffStackScreenProps<"PreferredPharmacy"> {}

export const PreferredPharmacyScreen = ({route, navigation}: Props): React.ReactElement => {
  const userId = route.params.userId;
  const pharmacyIndex = route.params.pharmacyIndex;
  const user = useGetUsersByIdQuery(userId).data;
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const sentryAndToast = useSentryAndToast();

  const [pharmacy, setPharmacy] = useState<PreferredPharmacy | null>(null);

  // When the pharmacyIndex changes, update the pharmacy.
  useEffect(() => {
    if (pharmacyIndex !== undefined && user?.preferredPharmacies?.[pharmacyIndex]) {
      setPharmacy(user?.preferredPharmacies[pharmacyIndex]);
    } else {
      setPharmacy({
        primary: user?.preferredPharmacies.length === 0,
        name: "",
        address: {address1: "", address2: "", city: "", state: "", zipcode: ""} as Address,
        phoneNumber: "",
        faxNumber: "",
        notes: "",
      });
    }
  }, [pharmacyIndex, user?.preferredPharmacies]);

  if (!user || !pharmacy) {
    return <Spinner />;
  }

  const savePharmacy = async (): Promise<void> => {
    let preferredPharmacies = cloneDeep(user.preferredPharmacies ?? []);

    if (pharmacy.primary) {
      for (const p of preferredPharmacies) {
        p.primary = false;
      }
    }

    if (pharmacyIndex !== undefined) {
      preferredPharmacies[pharmacyIndex] = pharmacy;
    } else {
      preferredPharmacies.push(pharmacy);
    }

    // Make sure primary is first
    preferredPharmacies = preferredPharmacies.sort((a): number => (a.primary ? -1 : 1));

    try {
      await updateUser({id: user._id, body: {preferredPharmacies}}).unwrap();
    } catch (error: any) {
      sentryAndToast(`Error saving preferred pharmacy`, error);
      return;
    }
    navigation.goBack();
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box direction="column" paddingX={4} paddingY={2} scroll>
        <BooleanField
          title="Primary"
          value={pharmacy?.primary}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, primary: value} as PreferredPharmacy);
          }}
        />
        <TextField
          title="Name"
          value={pharmacy?.name}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, name: value} as PreferredPharmacy);
          }}
        />
        <AddressField
          title="Address"
          value={(pharmacy?.address ?? {}) as AddressInterface}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, address: value} as PreferredPharmacy);
          }}
        />
        <PhoneNumberField
          title="Phone Number"
          value={pharmacy?.phoneNumber}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, phoneNumber: value} as PreferredPharmacy);
          }}
        />
        <PhoneNumberField
          title="Fax Number"
          value={pharmacy?.faxNumber}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, faxNumber: value} as PreferredPharmacy);
          }}
        />
        <TextArea
          title="Notes"
          value={pharmacy?.notes}
          onChange={(value: any): void => {
            setPharmacy({...pharmacy, notes: value} as PreferredPharmacy);
          }}
        />
        <Box paddingY={2} width={200}>
          <Button disabled={isLoading} loading={isLoading} text="Save" onClick={savePharmacy} />
        </Box>
      </Box>
    </Page>
  );
};
